import { Box, Grid, Link, Typography } from "@mui/material";
import { ReactComponent as FooterLogo } from "../../../Assets/Icons/WeedXFooterLogo.svg";
import styled from "styled-components";
import theme from "../../../theme";
import FaceBookSvG from "../../../Assets/Icons/Facebook";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CustomLink = styled(Link)((props) => ({
  textDecoration: "none !important",
  color: props.color ? props.color : theme.palette.primary.black + "!important",
  fontSize: props.fontSize + "!important",
  fontWeight: props.fontWeight ? props.fontWeight : "400",
  cursor: "pointer",
}));

export default function Footer() {
  const userData = useSelector((state) => state.userRegReducer?.userAuth);

  const navigate = useNavigate();
  return (
    <>
      <Grid
        container
        sx={{
          padding: { xs: "1.5rem", md: "3rem", lg: "6rem 10rem 3rem 10rem" },
          display: { xs: "flex", sm: "auto" },
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { xs: "center", sm: "start" },
          alignItems: { xs: "center", sm: "flex-start" },
        }}
        spacing={{ sm: 2, lg: 5 }}
      >
        <Grid
          item
          xs={12}
          sm={3.25}
          sx={{
            padding: "1rem 0",
            display: { xs: "flex", sm: "grid" },
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: { xs: "center", sm: "start" },
            alignItems: { xs: "center", sm: "flex-start" },
          }}
        >
          <Box onClick={() => navigate("/")}>
            <FooterLogo />
          </Box>

          <Typography
            mt={2}
            sx={{
              color: theme.palette.primary.mediumGrey,
              fontWeight: "300",
              fontSize: { xs: "14px", sm: "12px", lg: "14px" },
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            A community connecting cannabis Retailers, Grower, Processors and Brands
          </Typography>
          <ul
            style={{
              listStyle: "none",
              display: "flex",
              paddingLeft: 0,
            }}
          >
            {/* <li className="socialMedia">
              <CustomLink>
                <Twitter />
              </CustomLink>
            </li> */}
            <li
              className="socialMedia"
              onClick={() => window.open("https://www.facebook.com/profile.php?id=100091293528796&mibextid=LQQJ4d", "_blank")}
            >
              <CustomLink>
                <FaceBookSvG />
              </CustomLink>
            </li>
            {/* <li className="socialMedia">
              <CustomLink>
                <Instagram />
              </CustomLink>
            </li>
            <li className="socialMedia">
              <CustomLink>
                <GithubSVG />
              </CustomLink>
            </li> */}
          </ul>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2.25}
          sx={{
            textAlign: { xs: "center", sm: "left" },
            padding: { lg: "1rem 0" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", sm: "18px", lg: "20px" },
              fontWeight: "600",
              color: theme.palette.primary.black,
            }}
          >
            About
          </Typography>
          <ul
            style={{
              listStyle: "none",
              display: "flex",
              paddingLeft: 0,
              flexDirection: "column",
              rowGap: "10px",
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            <li>
              <CustomLink color="#596570" fontWeight="300" fontSize={{ xs: "14px", sm: "12px", lg: "14px" }} onClick={() => navigate("/about-us")}>
                Company
              </CustomLink>
            </li>
            <li>
              <CustomLink color="#596570" fontWeight="300" fontSize={{ xs: "14px", sm: "12px", lg: "14px" }} onClick={() => navigate("/contact")}>
                Contact us
              </CustomLink>
            </li>
            <li>
              <CustomLink color="#596570" fontWeight="300" fontSize={{ xs: "14px", sm: "12px", lg: "14px" }} onClick={() => navigate("/faqs")}>
                FAQ
              </CustomLink>
            </li>
            <li>
              <CustomLink color="#596570" fontWeight="300" fontSize={{ xs: "14px", sm: "12px", lg: "14px" }} onClick={() => navigate("/pricing")}>
                Pricing
              </CustomLink>
            </li>
          </ul>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2.25}
          sx={{
            textAlign: { xs: "center", sm: "left" },
            padding: { lg: "1rem 0" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", sm: "18px", lg: "20px" },
              fontWeight: "600",
              color: theme.palette.primary.black,
            }}
          >
            Discover
          </Typography>
          <ul
            style={{
              listStyle: "none",
              display: "flex",
              paddingLeft: 0,
              flexDirection: "column",
              rowGap: "10px",
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            <li>
              <CustomLink
                color="#596570"
                fontWeight="300"
                fontSize={{ xs: "14px", sm: "12px", lg: "14px" }}
                onClick={() => navigate("/dispensaries")}
              >
                Dispensaries
              </CustomLink>
            </li>
            <li>
              <CustomLink color="#596570" fontWeight="300" fontSize={{ xs: "14px", sm: "12px", lg: "14px" }} onClick={() => navigate("/brands")}>
                Brands
              </CustomLink>
            </li>
            <li>
              <CustomLink color="#596570" fontWeight="300" fontSize={{ xs: "14px", sm: "12px", lg: "14px" }} onClick={() => navigate("/signup")}>
                Claim Business
              </CustomLink>
            </li>
            {!userData?.authorized && (
              <li>
                <CustomLink color="#596570" fontWeight="300" fontSize={{ xs: "14px", sm: "12px", lg: "14px" }} onClick={() => navigate("/login")}>
                  Login
                </CustomLink>
              </li>
            )}
          </ul>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2.25}
          sx={{
            textAlign: { xs: "center", sm: "left" },
            padding: { lg: "1rem 0" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", sm: "18px", lg: "20px" },
              fontWeight: "600",
              color: theme.palette.primary.black,
            }}
          >
            Legal
          </Typography>
          <ul
            style={{
              listStyle: "none",
              display: "flex",
              paddingLeft: 0,
              flexDirection: "column",
              rowGap: "10px",
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            {/* <li>
              <CustomLink onClick={() => navigate("/t&c")} color="#596570" fontWeight="300" fontSize={{ xs: "14px", sm: "12px", lg: "14px" }}>
                Terms of use
              </CustomLink>
            </li> */}
            <li>
              <CustomLink
                onClick={() => navigate("/privacy-policy")}
                color="#596570"
                fontWeight="300"
                fontSize={{ xs: "14px", sm: "12px", lg: "14px" }}
              >
                Privacy Policy & Terms of Use
              </CustomLink>
            </li>
          </ul>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          sx={{
            background: theme.palette.primary.black,
            padding: "1rem 1rem",
            borderRadius: "8px",
            textAlign: { xs: "center", sm: "left" },
            width: { xs: "100%", sm: "auto" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", sm: "18px", lg: "20px" },
              fontWeight: "600",
              color: theme.palette.primary.white,
            }}
          >
            Newsletter
          </Typography>
          <ul
            style={{
              listStyle: "none",
              display: "flex",
              paddingLeft: 0,
              flexDirection: "column",
              rowGap: "10px",
            }}
          >
            <li>
              <CustomLink
                color={theme.palette.primary.footerGrey}
                fontSize={{ xs: "14px", sm: "12px", lg: "14px" }}
                fontWeight="300"
                onClick={() => navigate("/blog")}
              >
                Blog
              </CustomLink>
            </li>
            {/* <li>
              <CustomLink
                color={theme.palette.primary.footerGrey}
                fontSize={{ xs: "14px", sm: "12px", lg: "14px" }}
                fontWeight="300"
              >
                Get started
              </CustomLink>
            </li>
            <li>
              <CustomLink
                color={theme.palette.primary.footerGrey}
                fontSize={{ xs: "14px", sm: "12px", lg: "14px" }}
                fontWeight="300"
              >
                Retailers
              </CustomLink>
            </li>
            <li>
              <CustomLink
                color={theme.palette.primary.footerGrey}
                fontSize={{ xs: "14px", sm: "12px", lg: "14px" }}
                fontWeight="300"
              >
                Brands
              </CustomLink>
            </li>
            <li>
              <CustomLink
                color={theme.palette.primary.footerGrey}
                fontSize={{ xs: "14px", sm: "12px", lg: "14px" }}
                fontWeight="300"
              >
                Claim business
              </CustomLink>
            </li>
            <li>
              <CustomLink
                color={theme.palette.primary.footerGrey}
                fontSize={{ xs: "14px", sm: "12px", lg: "14px" }}
                fontWeight="300"
              >
                Contact us
              </CustomLink>
            </li> */}
          </ul>
        </Grid>
        <Grid item xs={12} mt={{ xs: 3, lg: 10 }}>
          <hr
            style={{
              borderTop: "1px solid rgba(0, 0, 0, 0.05)",
              marginBottom: "25px",
            }}
          ></hr>
          <Typography
            sx={{
              color: theme.palette.primary.inputTextColor,
              fontSize: { xs: "12px", lg: theme.typography.fontSize },
              fontWeight: "300",
            }}
          >
            © Copyright 2024, All Rights Reserved by WeedX
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
