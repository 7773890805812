import React, { useCallback, useEffect, useState } from "react";
import LoginResponsiveSidebar from "../../../Components/Custom/LoginResponsiveSidebar";
import theme from "../../../theme";
import { Box, Stack, Typography, Select, MenuItem } from "@mui/material";
import SearchInputWithIcon from "../../../Components/Custom/SearchInput";
import { useLocation, useNavigate } from "react-router-dom";
import BasicPagination from "../../../Components/Custom/Pagination";
import CustomTable from "./Components/CustomTable";
import Filter from "./Components/Filter";
import { useSelector } from "react-redux";
import restClient from "../../../util/restClient";
import { ToastContainer, toast } from "react-toastify";
export default function Inbox() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const { state } = useLocation();
  const userData = useSelector((state) => state.userRegReducer?.userAuth);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const navigate = useNavigate();
  const [messagesCount, setMessagesCount] = useState({
    sent: 0,
    received: 0,
  });
  const [activeTab, setActiveTab] = useState("sent");
  const getMessages = useCallback(
    async (keyword = "", status) => {
      setIsLoading(true);
      try {
        if (!status) status = activeTab;
        const {
          data: { data },
        } = await restClient({
          method: "GET",
          url: `/user/queries?page=${page}&limit=${pageSize}&type=${status}&message=${keyword}`,
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        });
        setData(data.queries);
        setMessagesCount({
          sent: data?.statistics?.sent,
          received: data?.statistics?.received,
        });
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    [page, pageSize]
  );
  const handleQueryDelete = async (queryId) => {
    try {
      setIsLoading(true);
      const response = await restClient({
        method: "DELETE",
        url: `user/queries/${queryId}`,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      if (response) {
        toast.success("Query deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        getMessages("", activeTab);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getMessages("", activeTab);
  }, []);
  useEffect(() => {
    getMessages("", activeTab);
  }, [activeTab]);
  return (
    <LoginResponsiveSidebar>
      <Box>
        <ToastContainer />
        <Box
          sx={{
            padding: { xs: "20px", sm: "22px 36px" },
            fontWeight: 500,
            backgroundColor: theme.palette.primary.white,
            // display: "flex",
            // flexDirection: { xs: "column", sm: "row" },
            // justifyContent: { sm: "space-between" },
            // alignItems: { sm: "center", lg: "flex-start" },
            // rowGap: { xs: "20px", sm: "0px" },
          }}
        >
          <Typography sx={{ fontSize: "24px", color: theme.palette.primary.black }}>Messages</Typography>
          <Typography sx={{ fontSize: "18px", color: theme.palette.primary.mediumGrey }} style={{ textAlign: "center" }}>
            Please Check Your Email Spam Folder for Message
          </Typography>
        </Box>
        <Box
          sx={{
            margin: { xs: "15px", sm: "36px" },
            backgroundColor: "transparent",
            overflowY: { md: "auto" },
            height: { md: "80vh" },
          }}
          className="scrollbar"
        >
          <Box
            sx={{
              margin: { xs: "12px", lg: "36px" },
              backgroundColor: {
                xs: "transparent",
                md: theme.palette.primary.white,
              },
              border: { md: "1px solid #0000000D" },
              borderRadius: "4px",
            }}
          >
            <Stack
              sx={{
                padding: { sm: "16px", md: "10px 24px" },
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                justifyContent: { sm: "space-between" },
                rowGap: { xs: "20px" },
                columnGap: { sm: "20px" },
              }}
            >
              {data && <Filter data={data} isActive={activeTab} setIsActive={setActiveTab} messagesCount={messagesCount} />}
              <Box
                sx={{
                  border: "1px solid lightgrey",
                  borderRadius: "4px",
                  padding: "2px 4px",
                  width: { xs: "90%", sm: "100%" },
                }}
              >
                <SearchInputWithIcon placeholder="Search messages" callbackFunction={getMessages} />
              </Box>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                height: {
                  xs: "50vh",
                  md: "66vh",
                },
                position: "relative",
              }}
            >
              <CustomTable
                page={page}
                pageSize={pageSize}
                isLoading={isLoading}
                data={data}
                activeTab={activeTab}
                onRowClick={(rowData) => {
                  navigate(`/product-overview/${rowData.productId}`);
                }}
                handleDelete={(rowData) => handleQueryDelete(rowData._id)}
                popupValues={[
                  {
                    title: "Trash",
                    callbackFunction: (productId) => {},
                  },
                  {
                    title: "Active",
                    callbackFunction: (productId, status) => {
                      if (status && status === "draft") {
                        navigate(`/edit-product/${productId}`);
                      }
                      // handleProductStatusAPI(productId, "active");
                    },
                  },
                  {
                    title: "Inactive",
                    callbackFunction: (productId) => {
                      // handleProductStatusAPI(productId, "inactive");
                    },
                  },
                  {
                    title: "Delete",
                    callbackFunction: (productId) => {
                      // setIsModalOpen(true);
                      // setDeleteProductId(productId);
                    },
                  },
                  {
                    title: "Edit",
                    callbackFunction: (productId) => {
                      navigate(`/edit-product/${productId}`);
                    },
                  },
                  {
                    title: "Feature",
                    callbackFunction: (productId) => {
                      navigate(`/featured-product-payment/${productId}`);
                    },
                  },
                ]}
              />
              {messagesCount[activeTab] / pageSize > 0 && (
                <Stack direction="row" sx={{ padding: "10px" }} spacing={4} justifyContent={"flex-end"}>
                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    <span
                      style={{
                        fontSize: "14px",
                        color: theme.palette.primary.black,
                      }}
                    >
                      Rows per page:
                    </span>
                    <Select
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(e.target.value);
                        setPage(1);
                      }}
                      variant="standard"
                      color="success"
                    >
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={27}>27</MenuItem>
                      <MenuItem value={54}>54</MenuItem>
                    </Select>
                  </Stack>
                  <BasicPagination
                    currentPage={page}
                    handlePageChange={(event, page) => {
                      setPage(page);
                    }}
                    count={Math.ceil(messagesCount[activeTab] / pageSize)}
                  />
                </Stack>
              )}
            </Stack>
          </Box>
        </Box>
      </Box>
    </LoginResponsiveSidebar>
  );
}
