import * as Yup from "yup";

export const validationSchemaCP = Yup.object().shape({
  oldPassword: Yup.string().required("Password is required"),
  password: Yup.string().required("New password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const initialValuesCP = {
  oldPassword: "",
  password: "",
  confirmPassword: "",
};
