import axios from "axios";

// const REACT_BACKEND_URL = "http://localhost:3008/api";
const REACT_BACKEND_URL = "https://api.weedxhub.com/api";

const restClient = axios.create({
  baseURL: REACT_BACKEND_URL,
});

export default restClient;
