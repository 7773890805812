import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ToastContainer, toast } from "react-toastify";
import SearchInputWithIcon from "../../../Components/Custom/SearchInput";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import MUITable from "../../../Components/Custom/MUITable";
import restClient from "../../../util/restClient";
import columns from "../../../util/Tables/BrandManagement/showBrandManagement";
import theme from "../../../theme";
import { Link, useNavigate } from "react-router-dom";
import SideBarLayout from "../../../SharedComponents/SideBarLayout";
import heroSubTabBannerGreen from "../../../Assets/Images/heroSubTabBannerGreen.png";
import { ReactComponent as LogoWhite } from "../../../Assets/Icons/LogoWhite.svg";
import MenuIcon from "@mui/icons-material/Menu";
import BrandCard from "./components/BrandCard";
import BasicPagination from "../../../Components/Custom/Pagination";

let pageSize = 3;

function BrandManagement() {
  const userData = useSelector((state) => state.userRegReducer?.userAuth);
  const observerTarget = useRef(null);
  const [data, setData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const getBrandData = useCallback(
    async (keyword = "") => {
      setIsLoading(true);
      try {
        const { data } = await restClient({
          method: "GET",
          url: `/user/brands?page=${page}&limit=${pageSize}&name=${keyword}`,
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        });

        //pageSize += 10;
        setTotalPageCount(Math.ceil(data?.data?.total / pageSize));
        setData(data?.data?.brands);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    [userData?.token, page],
  );

  console.log(data, "data");

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       if (entries[0].isIntersecting) getBrandData();
  //     },
  //     { threshold: 1 }
  //   );

  //   let currentTarget = observerTarget.current;
  //   if (currentTarget) observer.observe(currentTarget);

  //   return () => {
  //     if (currentTarget) observer.unobserve(currentTarget);
  //   };
  //   // getBrandData();
  // }, [observerTarget, getBrandData]);

  useEffect(() => {
    getBrandData();
  }, [page]);

  const navigate = useNavigate();
  const handleDeleteBrandAPI = async (brandId) => {
    try {
      const { data } = await restClient({
        method: "DELETE",
        url: `/user/brands/${brandId}`,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      console.log(data, "brand delete response");
      if (data.success) {
        toast.success("Brand deleted Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        getBrandData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <Box
        sx={{
          height: { md: "100vh" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          overflow: "hidden",
          background: theme.palette.primary.dashboardBack,
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "inline-flex" },
            height: "100%",
            width: { xs: "75%", sm: "40%", md: "30%", lg: "20%" },
          }}
        >
          <SideBarLayout />
        </Box>
        <Box
          sx={{
            display: { md: "none" },
          }}
        >
          {open && <SideBarLayout setOpen={setOpen} />}
          <Grid
            item
            container
            sx={{
              padding: { xs: "1.5rem", sm: "1.5rem 2rem", lg: "0 10rem" },
              background: `url(${heroSubTabBannerGreen})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              display: { md: "none" },
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: { md: "none" },
                justifyContent: "space-between",
                alignItems: "center",
              }}
              container
            >
              <Link to={"/"}>
                <LogoWhite />
              </Link>
              <Box
                onClick={() => {
                  setOpen(true);
                  document.body.style.overflow = "hidden";
                }}
              >
                <MenuIcon
                  sx={{ color: "white", width: "25px", height: "25px" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            overflow: "hidden",
            height: { md: "100vh" },
            width: { md: "70%", lg: "80%" },
          }}
        >
          <Box>
            <Box
              sx={{
                padding: { xs: "20px", sm: "22px 36px" },
                fontWeight: 500,
                backgroundColor: theme.palette.primary.white,
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: { sm: "space-between" },
                alignItems: { sm: "center", lg: "flex-start" },
                rowGap: { xs: "20px", sm: "0px" },
              }}
            >
              <Typography
                sx={{ fontSize: "24px", color: theme.palette.primary.black }}
              >
                Brand Management
              </Typography>

              <PrimaryButton
                onClick={() => navigate("/add-new-brand")}
                icon={<AddIcon />}
              >
                Add Brand
              </PrimaryButton>
            </Box>
            <Box
              sx={{
                margin: { xs: "15px", sm: "36px" },
                backgroundColor: "transparent",
                overflowY: { md: "auto" },
                height: { md: "80vh" },
              }}
              className="scrollbar"
            >
              <Box sx={{ padding: "16px 24px" }}>
                <Box
                  sx={{
                    border: "1px solid lightgrey",
                    borderRadius: "4px",
                    padding: "2px 4px",
                    background: "white",
                  }}
                >
                  <SearchInputWithIcon
                    placeholder="Search (Ctrl + /)"
                    callbackFunction={getBrandData}
                  />
                </Box>
              </Box>
              <Grid
                container
                spacing={5}
                sx={{ padding: { xs: "10px", lg: "20px" } }}
              >
                {isLoading ? (
                  <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ minHeight: "380px" }}
                    flexGrow={1}
                  >
                    <CircularProgress />
                  </Stack>
                ) : data.length > 0 ? (
                  data?.map((brand, index) => {
                    return (
                      <Grid key={brand._id} item xs={12} sm={6} lg={4}>
                        <BrandCard
                          brand={brand}
                          index={index}
                          popupValues={[
                            {
                              title: "View Brand",
                              callbackFunction: (brandId) => {
                                navigate(`/brand-details/${brandId}`);
                              },
                            },
                            {
                              title: "Edit",
                              callbackFunction: () => {
                                navigate(`/edit-brand/${brand._id}`);
                              },
                            },
                            {
                              title: "Delete",
                              callbackFunction: () => {
                                handleDeleteBrandAPI(brand._id);
                              },
                            },
                          ]}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <Grid item xs={12} sx={{ textAlign: "center" }}>
                    <Typography>No available brands at this time</Typography>
                  </Grid>
                )}
              </Grid>
              {/* <div ref={observerTarget}>{"  "}</div> */}

              {(data.length !== 0 || isLoading) && (
                <Box
                  sx={{
                    padding: "10px",
                  }}
                >
                  <BasicPagination
                    handlePageChange={(e, page) => {
                      setPage(page);
                    }}
                    count={totalPageCount}
                    currentPage={page}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default BrandManagement;
