import React, { useEffect } from "react";

import { Box, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function ImageModal({ imageSelected, closeModal }) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        top: "-30px",
        left: 0,
        bottom: 0,
        right: 0,
        bgcolor: "rgba(0,0,0,0.5)",
        zIndex: 9999999,
      }}
      onClick={closeModal}
    >
      <Stack
        spacing={1}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          p: { xs: "8px", sm: "16px" },
          bgcolor: "#fff",
          border: "none",
          borderRadius: "4px",
          boxSizing: "border-box",
        }}
      >
        <CloseIcon
          onClick={closeModal}
          sx={{ cursor: "pointer", alignSelf: "flex-end" }}
        />
        <Box
          sx={{
            width: { xs: "280px", sm: "560px" },
            height: { xs: "280px", sm: "560px" },
          }}
        >
          <img
            loading="lazy"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "4px",
            }}
            src={imageSelected}
            alt=""
          />
        </Box>
      </Stack>
    </Box>
  );
}

export default ImageModal;
