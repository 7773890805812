import { Box, CircularProgress, Stack } from "@mui/material";

function PrimaryButton({
  onClick,
  children,
  isLoading,
  disabled,
  backgroundColor,
  color,
}) {
  return (
    <button
      style={{
        fontFamily: "inherit",
        width: "100%",
        border: "none",
        height: "40px",
        backgroundColor: disabled
          ? "#E0E0E0"
          : backgroundColor
          ? backgroundColor
          : "#1B8A79",
        color: color ? color : "white",
        fontWeight: "500",
        letterSpacing: "0.5px",
        borderRadius: "4px",
        cursor: "pointer",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={2}
        justifyContent={"center"}
      >
        <Box>{children}</Box>
        {isLoading && <CircularProgress size={20} sx={{ color: "green" }} />}
      </Stack>
    </button>
  );
}

export default PrimaryButton;
