import { Box, CircularProgress, Stack } from "@mui/material";
import StarRating from "../../components/StarRating";
import RatingsBar from "../../components/RatingsBar";

function Ratings({ statistics, isLoading }) {
  return (
    <Stack
      justifyContent={"space-between"}
      spacing={2}
      direction={{ xs: "column", lg: "row" }}
    >
      <Stack
        sx={{
          bgcolor: "white",
          width: { xs: "auto", lg: "24%" },
          padding: { xs: "40px 16px" },
        }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {isLoading ? (
          <Box alignSelf={"center"}>
            <CircularProgress />
          </Box>
        ) : (
          <Stack alignItems={"center"} spacing={1}>
            <span
              style={{ fontSize: "64px", fontWeight: "600", lineHeight: 1 }}
            >
              {statistics?.averageRating?.toFixed(1) || 0}
            </span>
            <StarRating
              value={Math.round(statistics?.averageRating) || 0}
              name="read-only"
              behavior="readOnly"
            />
            <span style={{ fontWeight: "300" }}>
              Customer reviews ({statistics?.totalReviews || 0})
            </span>
          </Stack>
        )}
      </Stack>

      <Stack
        flexGrow={1}
        sx={{ bgcolor: "white", padding: { xs: "24px", lg: "32px" } }}
        spacing={1}
      >
        {isLoading ? (
          <Box alignSelf={"center"}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <RatingsBar
              rating={5}
              percent={statistics?.fiveStar?.percentage || 0}
              count={statistics?.fiveStar?.count || 0}
            />
            <RatingsBar
              rating={4}
              percent={statistics?.fourStar?.percentage || 0}
              count={statistics?.fourStar?.count || 0}
            />
            <RatingsBar
              rating={3}
              percent={statistics?.threeStar?.percentage || 0}
              count={statistics?.threeStar?.count || 0}
            />
            <RatingsBar
              rating={2}
              percent={statistics?.twoStar?.percentage || 0}
              count={statistics?.twoStar?.count || 0}
            />
            <RatingsBar
              rating={1}
              percent={statistics?.oneStar?.percentage || 0}
              count={statistics?.oneStar?.count || 0}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
}

export default Ratings;
