import { Box, Stack } from "@mui/material";

import activeIcon from "../../../../Assets/Icons/Status/active.svg";
import inactiveIcon from "../../../../Assets/Icons/Status/inactive.svg";
import draftIcon from "../../../../Assets/Icons/Status/draft.svg";
import featuredIcon from "../../../../Assets/Icons/Status/featured.svg";

function Status({ status }) {
  switch (status?.toLowerCase()) {
    case "active":
      return (
        <Stack
          direction={"row"}
          spacing={1}
          sx={{
            color: "#24B314",
            fontSize: { xs: "10px", lg: "12px" },
            fontWeight: "500",
            textTransform: "uppercase",
            padding: "4px 8px",
            borderRadius: "4px",
            bgcolor: "#E5F7E1",
          }}
        >
          <img src={activeIcon} alt="inactive icon" />
          <Box component={"span"}>Active</Box>
        </Stack>
      );

    case "inactive":
      return (
        <Stack
          direction={"row"}
          spacing={1}
          sx={{
            color: "#EE4C1A",
            bgcolor: "#FCEAE8",
            fontSize: "11px",
            textTransform: "uppercase",
            fontWeight: "500",
            padding: "4px 8px",
            borderRadius: "4px",
          }}
        >
          <img src={inactiveIcon} alt="inactive icon" />
          <Box component={"span"}>Inactive</Box>
        </Stack>
      );

    case "draft":
      return (
        <Stack
          direction={"row"}
          spacing={1}
          sx={{
            color: "#596570",
            bgcolor: "#E8EAF6",
            fontSize: "11px",
            textTransform: "uppercase",
            fontWeight: "500",
            padding: "4px 8px",
            borderRadius: "4px",
          }}
        >
          <img src={draftIcon} alt="inactive icon" />
          <Box component={"span"}>draft</Box>
        </Stack>
      );
    case "featured":
      return (
        <Stack
          direction={"row"}
          spacing={1}
          sx={{
            color: "#3323EE",
            bgcolor: "#E8EAF6",
            fontSize: "11px",
            textTransform: "uppercase",
            fontWeight: "500",
            padding: "4px 8px",
            borderRadius: "4px",
          }}
        >
          <img src={featuredIcon} alt="inactive icon" />
          <Box component={"span"}>featured</Box>
        </Stack>
      );

    default:
      return <div>Unknown</div>;
  }
}

export default Status;
