import React from "react";
import TopHeader from "../Header/TopHeader";
import MenuBar from "../../../Components/Custom/MenuBar";
import SmallBanner from "../../../Components/Custom/SmalBanner";
import Plans from "../../LandingPages/HomePage/Plans";
import { Grid } from "@mui/material";
import SmallFooter from "../SmallFooter/SmallFooter";

function Pricing() {
  return (
    <>
      <TopHeader />
      <MenuBar isVisible={false} />
      <SmallBanner
        breadcrumbsMainTitle={"Pricing"}
        breadcrumbsSmallTitle={"Pricing"}
      />
      <Grid container my={10}>
        <Grid item xs={12}>
          <Plans />
        </Grid>
      </Grid>
      <SmallFooter />
    </>
  );
}

export default Pricing;
