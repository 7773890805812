import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useMediaQuery, Tab, Tabs, Stack, Box, Grid } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import SideBarLayout from "../../../SharedComponents/SideBarLayout";
import restClient from "../../../util/restClient";
import Products from "./Products/Products";
import About from "./About/About";
import Reviews from "./Reviews/Reviews";
import Header from "./Header/Header";
import heroSubTabBannerGreen from "../../../Assets/Images/heroSubTabBannerGreen.png";
import { ReactComponent as LogoWhite } from "../../../Assets/Icons/LogoWhite.svg";
import TopHeader from "../Header/TopHeader";
import MenuBar from "../../../Components/Custom/MenuBar";
import SmallBannerV2 from "../../../Components/Custom/SmallBannerV2";

function SellerProfile() {
  const userData = useSelector((state) => state.userRegReducer?.userAuth);
  const [tabSelected, setTabSelected] = useState("aboutProfile");
  const tabVariant = useMediaQuery("(max-width: 450px)")
    ? "scrollable"
    : "fullWidth";
  const [open, setOpen] = useState(false);
  const { brandID } = useParams();
  const [brandData, setBrandData] = useState({});
  const [isLoader, setIsLoader] = useState(false);

  const getBrandData = async () => {
    try {
      setIsLoader(true);
      const response = await restClient({
        method: "GET",
        url: `/user/brands-by-username/${brandID}`,
      });

      setBrandData(response?.data?.data?.result[0] || {});
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    getBrandData();
  }, []);

  return (
    <>
      {!userData?.authorized && (
        <>
          <TopHeader />
          <MenuBar isVisible={false} />
          <SmallBannerV2 />
        </>
      )}
      <Stack direction={{ xs: "column", md: "row" }}>
        {userData?.authorized && (
          <>
            <Box
              sx={{
                display: { xs: "none", md: "inline-flex" },
                height: "100vh",
                width: { sm: "40%", md: "30%", lg: "20%" },
                position: "fixed",
              }}
            >
              <SideBarLayout />
            </Box>
            <Box sx={{ display: { md: "none" } }}>
              {open && <SideBarLayout setOpen={setOpen} />}
              <Grid
                item
                container
                sx={{
                  padding: { xs: "1.5rem", sm: "1.5rem 2rem", lg: "0 10rem" },
                  background: `url(${heroSubTabBannerGreen})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  display: { md: "none" },
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: { md: "none" },
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  container
                >
                  <Link to={"/"}>
                    <LogoWhite />
                  </Link>
                  <Box
                    sx={{ cursor: "pointer", display: { md: "none" } }}
                    onClick={() => {
                      setOpen(true);
                      document.body.style.overflow = "hidden";
                    }}
                  >
                    <MenuIcon
                      sx={{ color: "white", width: "25px", height: "25px" }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        <Box
          sx={{
            flexGrow: 1,
            marginLeft: userData?.authorized ? { md: "30%", lg: "20%" } : {},
          }}
        >
          <Header brandDetails={brandData} />
          <Stack
            spacing={3}
            sx={{
              padding: {
                xs: "24px",
                md: "36px",
              },
            }}
          >
            <Tabs
              value={tabSelected}
              onChange={(event, newValue) => {
                setTabSelected(newValue);
              }}
              variant={tabVariant}
              scrollButtons="auto"
              textColor="inherit"
              sx={{
                borderRadius: "4px",
                bgcolor: "white",
                "& .MuiTabs-indicator": {
                  backgroundColor: "#F78B8B",
                },
              }}
              aria-label="scrollable auto tabs "
            >
              <Tab value="allProducts" label="All products" />
              <Tab value="aboutProfile" label="About profile" />
              <Tab value="reviews" label="Reviews" />
            </Tabs>
            {tabSelected === "allProducts" && (
              <Products brandID={brandData._id} />
            )}
            {tabSelected === "aboutProfile" && (
              <About isLoader={isLoader} brandData={brandData} />
            )}
            {tabSelected === "reviews" && (
              <Reviews brandOwnerId={brandData.userId} />
            )}
          </Stack>
        </Box>
      </Stack>
    </>
  );
}

export default SellerProfile;
