import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Logo from "../../Assets/Images/Logo.png";
import { ReactComponent as Cross } from "../../Assets/Icons/cross.svg";
import { ReactComponent as SignUpIcon } from "../../Assets/Icons/SignUpBtnIcon.svg";
import { ReactComponent as LoginIcon } from "../../Assets/Icons/LoginIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import SearchInputWithIcon from "./SearchInput";
import SecondaryButton from "./SecondaryButton";
import PrimaryButton from "./PrimaryButton";
import theme from "../../theme";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import restClient from "../../util/restClient";
import { useSelector } from "react-redux";
import ActionPopup from "../../Features/Admin/BrandManagement/components/ActionPopup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const HamburgerMenu = (props) => {
  const selectUserAuth = (state) => state.userRegReducer?.userAuth;
  const userData = useSelector(selectUserAuth);
  const [globalSearchData, setGlobalSearchData] = useState([]);
  const [openSearchDropdown, setOpenSearchDropdown] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const getStoresAndBrands = useCallback(async (keyword = "") => {
    try {
      // const response = await axios.get(
      //   `https://staging.weedxhub.com/api/admin/scrap/globalSearch/?page=1&pageSize=3&name=${keyword}`
      // );
      const response = await restClient({
        method: "GET",
        url: `/admin/scrap/globalSearch/?page=1&pageSize=3&name=${keyword}`,
      });
      if (response) {
        console.log(response, "global search api response");
        setGlobalSearchData([
          ...response?.data?.data?.documents?.brands,
          ...response?.data?.data?.documents?.dispensaries,
        ]);
        setOpenSearchDropdown(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Grid
      container
      sx={{
        display: { sm: "none" },
        position: "absolute",
        background: "white",
        width: { xs: "90%" },
        right: 0,
        top: 0,
        padding: "1.5rem",
        height: "100vh",
        zIndex: 10000,
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <img src={Logo} alt="" style={{ width: "100%" }} />
        </Box>
        <Box onClick={props.onClose}>
          <Cross />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <hr style={{ border: "1px solid #0000000D", width: "100%" }}></hr>
      </Grid>
      <Grid item xs={12} sx={{ margin: "0.5rem 0rem 1rem 0rem" }}>
        <SearchInputWithIcon
          background="#F9F9FC"
          border="1px solid #0000001A"
          placeholder={"Stores or Brands"}
          callbackFunction={getStoresAndBrands}
        />
        {openSearchDropdown &&
          globalSearchData &&
          globalSearchData.length > 0 && (
            <Box
              sx={{
                position: "absolute",
                zIndex: 20,
                background: "white",
                width: "80%",
                marginTop: 1,
                border: "1px solid lightgrey",
                boxShadow:
                  "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
                borderRadius: "10px",
              }}
            >
              {openSearchDropdown && (
                <Box
                  onClick={() => {
                    setOpenSearchDropdown(false);
                    setGlobalSearchData([]);
                  }}
                  sx={{
                    cursor: "pointer",
                    display: "flex",
                    margin: "0.5rem",
                    justifyContent: "flex-end",
                  }}
                >
                  <CloseIcon />
                </Box>
              )}
              {openSearchDropdown &&
                globalSearchData &&
                globalSearchData.length > 0 &&
                globalSearchData.slice(0, 5).map((data, index) => (
                  <Box
                    key={data?._id}
                    sx={{
                      display: "flex",
                      columnGap: "10px",
                      alignItems: "center",
                      padding: "0.7rem 1rem",
                      cursor: "pointer",
                      borderRadius:
                        index === 0
                          ? "10px 10px 0px 0px"
                          : index === 3
                          ? "0px 0px 10px 10px"
                          : "0px",
                      "&:hover": {
                        backgroundColor: "#EFF2F4",
                      },
                    }}
                    onClick={() => {
                      setOpenSearchDropdown(false);
                      if (data?.["Brand Name"]) {
                        navigate(`/brand-details/${data?.username}`);
                      } else {
                        navigate(`/dispensary-detail/${data?._id}`);
                      }
                    }}
                  >
                    {console.log(data, "data")}
                    {data?.["Brand Logo"] && (
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={data?.["Brand Logo"]}
                        alt=""
                      />
                    )}
                    {data?.["Business Logo"] && (
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={data?.["Business Logo"]}
                        alt=""
                      />
                    )}
                    {data?.["Brand Name"] && (
                      <Typography sx={{ fontSize: "12px" }}>
                        {data?.["Brand Name"]}{" "}
                        <span style={{ fontSize: "8px", color: "grey" }}>
                          (Public Brand)
                        </span>
                      </Typography>
                    )}
                    {data?.["Profile Name"] && (
                      <Typography sx={{ fontSize: "12px" }}>
                        {data?.["Profile Name"]}{" "}
                        <span style={{ fontSize: "8px", color: "grey" }}>
                          (Dispensary)
                        </span>
                      </Typography>
                    )}
                  </Box>
                ))}
            </Box>
          )}
      </Grid>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "1rem",
          flexGrow: 1,
          height: "60%",
        }}
      >
        <Link
          style={{
            fontSize: " 14px",
            color: theme.palette.primary.black,
            fontWeight: "500",
            textDecoration: "none",
          }}
          to="/dispensaries"
        >
          Dispensaries
        </Link>
        <Link
          style={{
            fontSize: " 14px",
            color: theme.palette.primary.black,
            fontWeight: "500",
            textDecoration: "none",
          }}
          to="/brands"
        >
          Brands
        </Link>
        <Link
          style={{
            fontSize: " 14px",
            color: theme.palette.primary.black,
            fontWeight: "500",
            textDecoration: "none",
          }}
          to="/blog"
        >
          Blog
        </Link>
        <Link
          style={{
            fontSize: " 14px",
            color: theme.palette.primary.black,
            fontWeight: "500",
            textDecoration: "none",
          }}
          to="/about-us"
        >
          About
        </Link>
        <Link
          style={{
            fontSize: " 14px",
            color: theme.palette.primary.black,
            fontWeight: "500",
            textDecoration: "none",
          }}
          to="/pricing"
        >
          Pricing
        </Link>
        <Link
          style={{
            fontSize: " 14px",
            color: theme.palette.primary.black,
            fontWeight: "500",
            textDecoration: "none",
          }}
          to="/faqs"
        >
          FAQs
        </Link>
        <Link
          style={{
            fontSize: " 14px",
            color: theme.palette.primary.black,
            fontWeight: "500",
            textDecoration: "none",
          }}
          to="/contact"
        >
          Contact us
        </Link>
        {!userData?.authorized ? (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              columnGap: "10px",
            }}
          >
            <Link to={"/login"}>
              <SecondaryButton width="100%" icon={<LoginIcon />}>
                Log In
              </SecondaryButton>
            </Link>
            <Link to={"/signup"}>
              <PrimaryButton width="100%" icon={<SignUpIcon />}>
                Sign Up
              </PrimaryButton>
            </Link>
          </Grid>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginRight: "auto",
              padding: "4px 10px",
              bgcolor: theme.palette.primary.main,
              borderRadius: "4px",
            }}
          >
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                bgcolor: "#fff",
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                margin: "auto",
                marginRight: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: theme.palette.primary.main,
                }}
              >
                {userData?.user?.fullname.charAt(0)}
              </Typography>
            </Stack>
            <Box sx={{ marginRight: "10px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: theme.palette.primary.white,
                  fontWeight: 500,
                }}
              >
                {userData?.user?.fullname}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: theme.palette.primary.white,
                }}
              >
                {userData?.user?.email}
              </Typography>
            </Box>
            <ActionPopup
              popUpIcon={{
                icon: (
                  <KeyboardArrowDownIcon
                    sx={{
                      color: theme.palette.primary.black,
                      cursor: "pointer",
                      background: theme.palette.primary.white,
                      borderRadius: "50%",
                      width: 16,
                      height: 16,
                      marginTop: 1,
                    }}
                  />
                ),
              }}
              popupValues={[
                {
                  title: "Marketplace",
                  callbackFunction: () => {
                    navigate("/marketplace");
                    document.body.style.overflow = "auto";
                  },
                },
                {
                  title: "My Account",
                  callbackFunction: () => {
                    navigate("/my-account");
                    document.body.style.overflow = "auto";
                  },
                },
                {
                  title: "Logout",
                  callbackFunction: () => {
                    sessionStorage.removeItem("persist:root");
                    navigate("/");
                    document.body.style.overflow = "auto";
                    window.location.reload(true);
                  },
                },
              ]}
              hoverColor={"#f78b8b"}
            />
          </Box>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: "13px",
            color: theme.palette.primary.mediumGrey,
            fontWeight: "300",
          }}
        >
          WeedX © 2023 All Rights Reserved
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HamburgerMenu;
