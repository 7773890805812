import React, { useState } from "react";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import styled from "styled-components";

const InputPasswordField = styled(OutlinedInput)((props) => ({
  background: props.background ? props.background : "transparent",
  // color: theme.palette.primary.green_dark,
  width: "100%",
  //border: "1px solid lightGrey",
  // borderRadius: "5px",
  // margin: margin || "",
  boxShadow: 0,
  "& .MuiInputBase-input": {
    width: "100%",
    borderRadius: "5px",
    fontSize: "14px",
    padding: "10px",
    boxShadow: 0,
    "& hover": {
      border: "none !important",
    },
  },
  //   background: "white",
  //   // color: theme.palette.primary.green_dark,
  //   width: "100%",
  //   border: "none !important",
  //   borderRadius: "5px",
  //   boxShadow: 0,
  //   "& .MuiInputBase-input": {
  //     width: "100%",
  //     borderRadius: "5px",
  //     fontSize: "14px",
  //     padding: "10px",
  //     boxShadow: 0,
  //     borderBottom: "none !important",
  //     "& hover": {
  //       border: "none !important",
  //     },
  //   },
  //   "& .MuiInput-root:before": {
  //     borderBottom: "0 !important",
  //   },
  //   "& .MuiInput-root:after": {
  //     borderBottom: "0 !important",
  //   },
  //   "& .Mui-focused": {
  //     color: "rgba(0, 0, 0, 0.87)",
  //     borderBottom: 0,
  //     boxShadow: 0,
  //   },
}));

export default function InputPassword(props) {
  const { placeholder, name, onChange, onBlur, value, ...otherProps } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <InputPasswordField
        variant="outlined"
        type={showPassword ? "text" : "password"}
        placeholder={placeholder ? placeholder : ""}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        error={props.error}
        background={props.background ? props.background : "transparent"}
        {...otherProps}
        sx={{
          borderRadius: "4px",
          padding: "0px",
          fontSize: "14px",
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? (
                <VisibilityOutlinedIcon />
              ) : (
                <VisibilityOffOutlinedIcon />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
    </>
  );
}
