import { Stack } from "@mui/material";
import LoginResponsiveSidebar from "../../../Components/Custom/LoginResponsiveSidebar";
import Header from "./Header/Header";
import Sidebar from "./Chats/Sidebar/Sidebar";
import ActiveChat from "./Chats/ActiveChat/ActiveChat";

function Messages() {
  return (
    <LoginResponsiveSidebar>
      <Stack sx={{ minHeight: { xs: "calc(100vh - 96px)", md: "100vh" } }}>
        <Header />
        <Stack
          sx={{
            margin: { md: "20px 36px", xs: "20px 10px" },
            bgcolor: "#fff",
            flexGrow: 1,
          }}
          direction={"row"}
        >
          <Sidebar />
          <ActiveChat />
        </Stack>
      </Stack>
    </LoginResponsiveSidebar>
  );
}

export default Messages;
