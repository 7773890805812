import { Box, Stack } from "@mui/material";

import styles from "./styles.module.css";

function ChatPreview({ chatList, selectedChat, setSelectedChat }) {
  return (
    <div>
      <Stack spacing={1}>
        {chatList.map((chat) => (
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            key={chat.id}
            onClick={() => setSelectedChat(chat)}
            className={
              selectedChat.id === chat.id ? styles.selected : styles.chat
            }
            padding="10px 20px"
          >
            <img src={chat.imageURL} alt="" />
            <Stack flexGrow={1}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box
                  sx={{
                    fontWeight: "500",
                    color: "#2E3A48",
                  }}
                >
                  {chat.name}
                </Box>
                <Box
                  sx={{
                    fontWeight: "300",
                    color: "#A3ACBA",
                    fontSize: "12px",
                  }}
                >
                  {chat.lastMessageTime}
                </Box>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box
                  sx={{
                    fontWeight: "300",
                    color: "#A3ACBA",
                    fontSize: "14px",
                  }}
                >
                  {chat.lastMessage}
                </Box>
                {chat.unreadMessages > 0 && (
                  <Stack
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{
                      backgroundColor: "#F78B8B",
                      color: "white",
                      borderRadius: "50%",
                      height: "20px",
                      width: "20px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    {chat.unreadMessages}
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </div>
  );
}

export default ChatPreview;
