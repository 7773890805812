import React, { useEffect, useState } from "react";
import TopHeader from "../Header/TopHeader";
import MenuBar from "../../../Components/Custom/MenuBar";
import SmallBanner from "../../../Components/Custom/SmalBanner";
import { Grid, Typography } from "@mui/material";
import SmallFooter from "../SmallFooter/SmallFooter";
import theme from "../../../theme";


const TermsAndConditions = () => {
  

  return (
    <>
      <TopHeader />
      <MenuBar />
      <SmallBanner breadcrumbsMainTitle={"Terms & conditions"} breadcrumbsSmallTitle={"Terms & conditions"} />
      <Grid
        container
        sx={{
          justifyContent: "center",
          my: { xs: 4, sm: 7 },
          padding: { lg: "0rem 10rem" },
        }}
      >
        <Grid item xs={10} sm={8}>
          <Typography
            sx={{
              color: theme.palette.primary.mediumGrey,
              fontSize: { xs: "12px", sm: "16px" },
              fontWeight: "300",
            }}
          >
            Your use of our website is governed by the following terms and conditions (“Terms of Use”), as well as the WeedX and other operating
            rules, minimum qualifications and cautions posted throughout the website or presented to you individually during the course of your use of
            the website (collectively, the “Terms”). The Terms govern your use of the website and WeedX reserves the right to update or replace the
            Terms any time without notice. You are advised to review the Terms for any changes when you visit the website even if you have not
            received a notification of changes as you are bound by them even if you have not reviewed them. Your viewing and use of the website after
            such change constitutes your acceptance of the Terms and any changes to such terms. If at any time you do not want to be bound by the
            Terms you should logout, exit and cease using the website immediately.
          </Typography>
        </Grid>
        <Grid item xs={10} sm={8}>
          <Typography
            sx={{
              color: theme.palette.primary.inputLabelColor,
              fontSize: { xs: "24px", sm: "28px" },
              fontWeight: "600",
              mt: 5,
              mb: 2,
            }}
          >
            Intended Use of Website
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.mediumGrey,
              fontSize: { xs: "12px", sm: "16px" },
              fontWeight: "300",
            }}
          >
            WeedX is not a broker-dealer or placement agent. At no time does CARDONE CAPITAL offer, broker, advise, purchase, sell or otherwise
            transact in securities regulated by the SEC or federal or state law. WeedX does not accept, hold or transfer cash or securities. CARDONE
            CAPITAL does not guarantee that a Company seeking investment will achieve any level of success or that any proposed offering will qualify
            under applicable federal and state securities laws.
          </Typography>
        </Grid>

        <Grid item xs={10} sm={8}>
          <Typography
            sx={{
              color: theme.palette.primary.inputLabelColor,
              fontSize: { xs: "24px", sm: "28px" },
              fontWeight: "600",
              mt: 5,
              mb: 2,
            }}
          >
            User Registration
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.mediumGrey,
              fontSize: { xs: "12px", sm: "16px" },
              fontWeight: "300",
              mb: 1,
            }}
          >
            If you are accepting the Terms on behalf of an organization or entity, rather than in an individual capacity, you represent and warrant
            that you are authorized to accept the Terms on that organization or entity’s behalf and bind them to these Terms (in which case, the
            references to “you” and “your” in these Terms, except for in this sentence, refer to that organization or entity).
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.mediumGrey,
              fontSize: { xs: "12px", sm: "16px" },
              fontWeight: "300",
              mb: 1,
            }}
          >
            Only real persons at or above the age of 18 may register for an account and use the website. Registering for an account on the website
            creates no commitment or obligation on the registered user to make any investment or seek any investment. All information you provide to
            the website must be truthful, accurate and complete in all material respects. Our registration process may use third-party validation
            technology, including those provided by third-party social media sites, to attempt to confirm your qualification to use the website.
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.mediumGrey,
              fontSize: { xs: "12px", sm: "16px" },
              fontWeight: "300",
              mb: 1,
            }}
          >
            CARDONE CAPITAL may reject any application to register an individual or an organization or entity for failure to achieve validation
            through available methods or otherwise meet CARDONE CAPITAL’s registration requirements.
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.mediumGrey,
              fontSize: { xs: "12px", sm: "16px" },
              fontWeight: "300",
            }}
          >
            Your registration and the use of any third-party site is subject to the terms and conditions and policies of such sites and CARDONE
            CAPITAL is not responsible or liable for any harm resulting from the use or misuse of those sites, including when such harm could or does
            affect your account on this website or use of the website.
          </Typography>
        </Grid>

        <Grid item xs={10} sm={8}>
          <Typography
            sx={{
              color: theme.palette.primary.inputLabelColor,
              fontSize: { xs: "24px", sm: "28px" },
              fontWeight: "600",
              mt: 5,
              mb: 2,
            }}
          >
            Registered Account Obligations
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.mediumGrey,
              fontSize: { xs: "12px", sm: "16px" },
              fontWeight: "300",
              mb: 1,
            }}
          >
            The named registered user of an account is the only person that may use the account and it may not be transferred to anyone else.
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.mediumGrey,
              fontSize: { xs: "12px", sm: "16px" },
              fontWeight: "300",
            }}
          >
            You are responsible for maintaining the confidentiality of your username and password and to periodically change your password to maintain
            security. If you have concerns that your username or password may have been compromised and suspect that unauthorized access to your
            account or the website has occurred, you must immediately contact CARDONE CAPITAL’s investor support through a secure method (which may
            not be through your website account).
          </Typography>
        </Grid>

        <Grid item xs={10} sm={8}>
          <Typography
            sx={{
              color: theme.palette.primary.inputLabelColor,
              fontSize: { xs: "24px", sm: "28px" },
              fontWeight: "600",
              mt: 5,
              mb: 2,
            }}
          >
            Content Use Limitations
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.mediumGrey,
              fontSize: { xs: "12px", sm: "16px" },
              fontWeight: "300",
            }}
          >
            Your use of the website and its videos, webinars, images, infographics, alerts, text, articles, assessments, checklists, forms, ratings,
            design, data, source code, analytics, photos, software, trademarks, copyrights, and other information (“Content”) may only be used for the
            lawful and intended purposes expressly authorized by CARDONE CAPITAL. If you access this website from outside the United States, you are
            solely responsible for ensuring compliance with the laws of your specific jurisdiction, as well as any restrictions that you may be
            subject to by a department of the United States government. Any misuse or unauthorized use of the website and its Content, or other
            violations of the Terms may violate Applicable Law (see below), including without limitation SEC regulations and applicable state
            securities laws, copyright laws (including the Digital Millennium Copyright Act), trademark laws, the laws of privacy, laws of publicity,
            identity theft and communications statutes and regulations, in which case CARDONE CAPITAL is authorized to terminate your account and
            access to the website at any time and without notice and report you to the appropriate authorities and other interested parties.
          </Typography>
        </Grid>

        <Grid item xs={10} sm={8}>
          <Typography
            sx={{
              color: theme.palette.primary.inputLabelColor,
              fontSize: { xs: "24px", sm: "28px" },
              fontWeight: "600",
              mt: 5,
              mb: 2,
            }}
          >
            Prospective Investor Accounts
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.mediumGrey,
              fontSize: { xs: "12px", sm: "16px" },
              fontWeight: "300",
            }}
          >
            Any person or entity that is considering making an investment with a Company that posts its fundraising plans on the website, or, after
            the fundraising plan becomes a qualified offering, makes an investment, does so at his or her own risk. All investment carries risk that
            you may lose some or all of your investment. No Content on the website is a replacement for performing your own due diligence, exercising
            good judgement, and seeking financial, investment, tax or legal advice from qualified and licensed professionals with knowledge of your
            personal circumstances. Any registered financial, legal or tax representatives or firm working for or with CARDONE CAPITAL or
            communicating with you or users in general through the website are not your personal advisors and do not have knowledge about your
            personal circumstances and anything they post is for informational purposes only and may not be accurate to your situation and you agree
            that you shall not rely on Content on the website in making personal decisions about an investment or the potential legal, tax or
            financial consequences of such investment. You are encouraged to seek personal professional advice from qualified and licensed
            professionals.
          </Typography>
        </Grid>
      </Grid>
      <SmallFooter />
    </>
  );
};

export default TermsAndConditions;
