import Button from "@mui/material/Button";
import theme from "../../theme";
import { CircularProgress } from "@mui/material";

function PrimaryButton(props) {
  return (
    <Button
      component="label"
      variant="contained"
      startIcon={props.icon}
      endIcon={props.endicon}
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
      disabled={props.disabled}
      type={props.type}
      sx={{
        backgroundColor: props.backgroundColor || theme.palette.primary.main,
        marginTop: props.margin || "",
        color: props.color || theme.palette.primary.white,
        textTransform: props.textTransform || "capitalize",
        width: props.width || "auto",
        height: props.height || "auto",
        boxShadow: "none !important",
        "&:hover": {
          backgroundColor: props.backgroundColor || theme.palette.primary.main,
          boxShadow: "none !important",
          cursor: props.disabled ? "not-allowed" : "pointer",
        },
        padding: props.padding || "auto",
        fontSize: props.fontSize || "14px",
        fontWeight: props.fontWeight || "400",
        borderRadius: props.borderRadius || "auto",
      }}
    >
      {props.children}
      {props.isLoading && (
        <CircularProgress
          size={"1.2rem"}
          sx={{
            color: theme.palette.primary.main,
            position: "relative",
            left: "10px",
          }}
        />
      )}
    </Button>
  );
}

export default PrimaryButton;
