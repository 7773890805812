import { Box, TextareaAutosize, Typography } from "@mui/material";
import React, { useState } from "react";
import PrimaryHeading from "../../../../Components/Custom/PrimaryHeading";
import theme from "../../../../theme";
import { Link, useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../Components/Custom/PrimaryButton";
import SecondaryButton from "../../../../Components/Custom/SecondaryButton";
import InputFieldLabel from "../../../../Components/Custom/InnputFieldLabel";
import InputPassword from "../../../../Components/Custom/InputPassword";
import InputField from "../../../../Components/Custom/InputField";
import { ReactComponent as Completed } from "../../../../Assets/Icons/Complete.svg";
import CustomCheckbox from "../../../../Components/Custom/CheckBox";

export default function CancelSubscription(props) {
  const [password, setPassword] = useState("");
  const [deleteText, setDeleteText] = useState("");
  const [feedback, setFeedback] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  console.log(password, feedback, deleteText);
  console.log(props.cancelSub, "Type");
  const navigate = useNavigate();

  const handleSecondaryButton = async () => {
    if (props.cancelSub === "agree") {
      if (feedback !== null && termsAccepted) {
        props.setCancelSub("confirmSub");
      }
    } else if (props.cancelSub === "confirmSub") {
      if (password && deleteText === "Delete") {
        const response = await props.handleDeleteAccount(password);
        if (response) {
          props.setCancelSub("cancelledSub");
          sessionStorage.removeItem("persist:root");
          navigate("/");
          window.location.reload();
        }
      }
    }
  };

  return (
    <>
      {props.cancelSub === "agree" && (
        <>
          <Box>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize,
                color: theme.palette.primary.mediumGrey,
              }}
            >
              {props?.subHeading
                ? props?.subHeading
                : "Are you sure your want to permanently cancel your subscription? This cannot be undone."}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 1,
                mt: 3,
              }}
            >
              <PrimaryHeading
                fontSize={"12px"}
                color={theme.palette.primary.inputLabelColor}
              >
                Feedback
              </PrimaryHeading>
            </Box>
            <TextareaAutosize
              minRows="7"
              placeholder="Write your feedback here"
              style={{ width: "95%" }}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
              }}
            >
              <CustomCheckbox
                onChange={() => setTermsAccepted(!termsAccepted)}
                checked={termsAccepted}
              />
              <Typography
                sx={{
                  color: theme.palette.primary.mediumGrey,
                  fontSize: theme.typography.fontSize,
                }}
              >
                I agree with the
                <Link
                  style={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                    pl: 1,
                  }}
                >
                  Terms and Conditions
                </Link>
              </Typography>
            </Box>
          </Box>
        </>
      )}

      {props.cancelSub === "confirmSub" && (
        <>
          <Box mb={2}>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize,
                color: theme.palette.primary.mediumGrey,
              }}
            >
              {props?.tertiaryHeading
                ? props?.tertiaryHeading
                : "Enter your password to Cancel your Subscription"}
            </Typography>

            <InputFieldLabel margin="1.5vw">Password</InputFieldLabel>
            <InputPassword
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <InputFieldLabel margin="1.5vw">
              Write “Delete” here
            </InputFieldLabel>
            <InputField
              background="white"
              placeholder="Write “Delete” here"
              value={deleteText}
              onChange={(e) => setDeleteText(e.target.value)}
            />
          </Box>
        </>
      )}

      {props.cancelSub === "cancelledSub" && (
        <>
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Box mb={2}>
              <Completed />
            </Box>
            <PrimaryHeading fontSize={"20px"}>
              {props?.statusText?.heading
                ? props?.statusText?.heading
                : "Subscription canceled"}
            </PrimaryHeading>
            <Typography
              sx={{
                color: theme.palette.primary.mediumGrey,
                fontSize: theme.typography.fontSize,
                mt: 1,
                mb: 2,
              }}
            >
              {props?.statusText?.description
                ? props?.statusText?.description
                : "Your subscription has been canceled"}
            </Typography>
            <PrimaryButton width={"100%"}>Got It!</PrimaryButton>
          </Box>
        </>
      )}

      {props.cancelSub !== "cancelledSub" && (
        <Box display={"flex"} pt={2}>
          <SecondaryButton onClick={handleSecondaryButton} width={"100%"}>
            {props.cancelSub === "agree" ? (
              <>Proceed</>
            ) : props.cancelSub === "confirmSub" ? (
              <>Delete</>
            ) : null}
          </SecondaryButton>

          <PrimaryButton
            onClick={() => {
              props.setOpen();
            }}
            width={"100%"}
          >
            Cancel
          </PrimaryButton>
        </Box>
      )}
    </>
  );
}
