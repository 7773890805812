import { useState, useRef, useEffect } from "react";
import { Box, Stack } from "@mui/material";
import cheveronDown from "../../../../../Assets/Icons/SellerProfile/cheveronDown.svg";
import Popup from "../../../../../Components/Custom/Popup";
import theme from "../../../../../theme";

function SortPopup({ popupValues, onClickHandler }) {
  const popupRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  return (
    <Box ref={popupRef} sx={{ position: "relative" }}>
      <Stack
        onClick={() => {
          setShowPopup((prev) => !prev);
        }}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          cursor: "pointer",
          borderRadius: "50%",
          width: "24px",
          height: "24px",
          "&:hover": { backgroundColor: "#D9D9D9" },
        }}
      >
        <img src={cheveronDown} alt="" />
      </Stack>

      {showPopup && (
        <Popup
          customStyles={{
            left: "100%",
            right: "unset",
          }}
        >
          {popupValues?.map((value, index) => (
            <Box
              component={"span"}
              key={index}
              onClick={() => {
                onClickHandler(value);
                setShowPopup(false);
              }}
              sx={{
                cursor: "pointer",
                fontSize: "12px",
                fontWeight: "400",
                "&:hover": {
                  color: theme.palette.primary.white,
                  backgroundColor: theme.palette.primary.main,
                },
                width: "100%",
                margin: "0px !important",
                padding: "6px 0",
                textAlign: "left",
              }}
            >
              <Box component={"span"} sx={{ marginLeft: "6px" }}>
                {value?.label}
              </Box>
            </Box>
          ))}
        </Popup>
      )}
    </Box>
  );
}

export default SortPopup;
