import { Box, Grid } from "@mui/material";
import React from "react";
import InputFieldLabel from "../../../Components/Custom/InnputFieldLabel";
import InputField from "../../../Components/Custom/InputField";
//import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import theme from "../../../theme";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import { validationSchema, initialValues } from "./contactUsValidation";
import restClient from "../../../util/restClient";
import InputWrapper from "../../Admin/BrandManagement/components/InputWrapper";

const DetailsFormSection = () => {
  const handleSendMessage = async (details) => {
    try {
      // const response = await axios.post(
      //   `https://staging.weedxhub.com/api/user/create-contact`,
      //   details
      // );
      const response = await restClient({
        method: "POST",
        url: "/user/create-contact",
        data: details,
      });

      if (response?.data?.success) {
        toast.success(`${response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`${response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      console.error(err.response);
      toast.error(`${err?.response?.data?.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log(values, "contact us values");
      handleSendMessage(values);
      resetForm({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        businessName: "",
        message: "",
      });
    },
  });

  const formatPhoneNumber = (input) => {
    const numericInput = input.replace(/\D/g, "");
    if (!numericInput || isNaN(numericInput)) {
      return "";
    }
    return `(${numericInput.slice(0, 3)}) ${numericInput.slice(3, 6)}-${numericInput.slice(6, 10)}`;
  };

  const handlePhoneChange = (event) => {
    const input = event.target.value;

    if (event.nativeEvent.inputType === "deleteContentBackward") {
      // Handle backspace key
      formik.setFieldValue("mobile", input);
      //field.onChange(event);
      return;
    }

    const formattedNumber = formatPhoneNumber(input);

    formik.setFieldValue("mobile", formattedNumber);
    //field.onChange(event);
  };

  return (
    <>
      <form>
        <Box
          sx={{
            padding: {
              xs: "0rem 2rem 2rem 2rem",
              sm: "0rem 5rem 2rem 5rem",
              md: "0rem 5rem 2rem 5rem",
              lg: "2rem 10rem",
            },
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "flex-start" },
            justifyContent: "center",
          }}
        >
          <ToastContainer />
          <Grid
            container
            sx={{
              background: "white",
              padding: "1rem",
              borderRadius: "10px",
              rowGap: "20px",
              margin: "20px 0px",
            }}
            columnSpacing={2}
          >
            <Grid item xs={12} sm={6}>
              <InputFieldLabel>First Name</InputFieldLabel>
              <InputField
                background="white"
                placeholder="Enter First Name"
                name="firstName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                error={formik.errors.firstName ? true : false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputFieldLabel>Last Name</InputFieldLabel>
              <InputField
                background="white"
                placeholder="Enter Last Name"
                name="lastName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                error={formik.errors.lastName ? true : false}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputWrapper label={"Email Address"} required={true}>
                <InputField
                  background="white"
                  placeholder="Enter Email Address"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.errors.email ? true : false}
                  required={true}
                />
                {formik.touched.email && formik.errors.email && <div className="error">{formik.errors.email}</div>}
              </InputWrapper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputFieldLabel>Phone Number</InputFieldLabel>
              <div>
                <InputField
                  background="white"
                  placeholder="Enter Phone Number"
                  name="mobile"
                  type="text"
                  onChange={handlePhoneChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                  error={formik.errors.mobile ? true : false}
                />
                {/* {formik.touched.mobile && formik.errors.mobile && <div className="error">{formik.errors.mobile}</div>} */}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputFieldLabel>Business Name</InputFieldLabel>
              <InputField
                background="white"
                placeholder="Enter Business Name"
                name="businessName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.businessName}
                error={formik.errors.businessName ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <InputWrapper required={true} label="Leave a Message">
                <InputField
                  placeholder="Write a Message"
                  name="message"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                  multiline
                  minRows={5}
                  maxRows={7}
                />
                {formik.touched.message && formik.errors.message && <div className="error">{formik.errors.message}</div>}
              </InputWrapper>
            </Grid>
          </Grid>
          <PrimaryButton
            fontSize={{ xs: "10px", sm: "14px" }}
            width={{ xs: "100%", sm: "auto" }}
            backgroundColor={theme.palette.primary.peachRed}
            onClick={formik.handleSubmit}
          >
            Send Message
          </PrimaryButton>
        </Box>
      </form>
    </>
  );
};

export default DetailsFormSection;
