import { Box, Stack } from "@mui/material";

import BannerImage from "../../../../../Assets/Images/AddBrand/banner.svg";
import NoImage from "../../../../../Assets/Images/NoImage.svg";
import FacebookColor from "../../../../../Assets/Icons/FacebookColor.svg";
import InstagramColor from "../../../../../Assets/Icons/InstagramColor.svg";
import TwitterColor from "../../../../../Assets/Icons/Twitter.svg";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import Modal from "../../../../../Components/Custom/Modal";
import PrimaryButton from "../../../../../Components/Custom/CustomPrimaryButton";
import restClient from "../../../../../util/restClient";

function Header({ dispensaryDetails }) {
  const [isLoading, setIsLoading] = useState(false);
  const [claimPopup, setClaimPopup] = useState(false);
  const userData = useSelector((state) => state.userRegReducer?.userAuth);

  const handleClaim = async () => {
    try {
      setIsLoading(true);
      const {
        data: { success, message },
      } = await restClient({
        method: "POST",
        url: `user/claim`,
        data: {
          on: dispensaryDetails?._id,
          onModel: "dispensaries",
        },
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      if (success) {
        toast.success(message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message || "Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
      setClaimPopup(false);
    }
  };
  return (
    <>
      <ToastContainer />

      <Modal
        open={claimPopup}
        handleClose={() => {
          setClaimPopup(false);
        }}
        isLoading={isLoading}
        headerText={"Claim Dispensary"}
        leftButton={"Cancel"}
        handleSubmit={handleClaim}
        rightButton={"Send"}
        maxWidth="xs"
        fullWidth
      >
        <span>Send request to claim this dispensary.</span>
      </Modal>
      <Box sx={{ position: "relative" }}>
        <Box sx={{ height: { xs: "120px", lg: "340px" }, overflow: "hidden" }}>
          <img style={{ width: "100%" }} src={dispensaryDetails?.bannerImage || BannerImage} alt="" />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "0",
            left: { xs: "50%", sm: "100px" },
            transform: { xs: "translate(-50%,50%)", sm: "translate(0%,50%)" },
            borderRadius: "50%",
            width: { xs: "120px", sm: "180px" },
            height: { xs: "120px", sm: "180px" },
            overflow: "hidden",
            border: "4px solid #fff",
            bgcolor: "#fff",
          }}
        >
          <img style={{ width: "100%" }} src={dispensaryDetails?.logo || NoImage} alt="" />
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: { xs: "64px", sm: "16px" },
          marginLeft: { xs: "0", sm: "300px" },
          textAlign: { xs: "center", sm: "left" },
        }}
      >
        <Stack>
          <span style={{ fontSize: "24px", fontWeight: "500" }}>{dispensaryDetails?.dispensaryName || "Profile Name"}</span>
          <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={{ xs: "center", sm: "flex-start" }} sx={{ minHeight: "24px" }}>
            {[
              { name: "fbLink", icon: FacebookColor },
              { name: "twitterLink", icon: TwitterColor },
              { name: "instagramLink", icon: InstagramColor },
            ].map((item, index) => (
              <>
                {dispensaryDetails?.[item.name] && dispensaryDetails?.[item.name] !== "-" && (
                  <Box key={index + "key"} sx={{ width: "24px", height: "24px", cursor: "pointer" }}>
                    <img
                      src={item.icon}
                      alt=""
                      onClick={() => {
                        window.open(dispensaryDetails?.[item.name], "_blank");
                      }}
                      style={{ width: "100%" }}
                    />
                  </Box>
                )}
              </>
            ))}
          </Stack>
          <Stack alignItems={{ xs: "center", sm: "start" }} justifyContent={{ xs: "center", sm: "center" }}>
            <Box sx={{ width: "20%", cursor: "pointer" }}>
              {!dispensaryDetails?.userId && (userData?.user?.role === "BOTH" || userData?.user?.role === "BUYER") && (
                <PrimaryButton onClick={() => setClaimPopup(true)} color="white" fontSize={{ xs: "12px", sm: "14px" }}>
                  Claim
                </PrimaryButton>
              )}
            </Box>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}

export default Header;
