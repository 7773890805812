import * as Yup from "yup";

// export const validateMetrcSchema = Yup.object().shape({
//   inputFields: Yup.object().shape(
//     Yup.object().shape({
//       companyName: Yup.string().required("Company name is required"),
//       // licenseNumber: Yup.string().required("License number is required"),
//       licenseNumber: Yup.array().of(Yup.string().min(1, "At least one License Number is required")),
//       facilityType: Yup.array().min(1, "At least one Facility Type is required"),
//       // metrcAPIKey: Yup.string().required("Metrc API key is required"),
//       facilityAddress: Yup.string().required("Facility address is required"),
//     })
//   ),
// });
export const validateMetrcSchema = Yup.object().shape({
  inputFields: Yup.object().shape({
    companyName: Yup.string().required("Company name is required"),
    licenseNumber: Yup.array().of(Yup.string().min(1, "At least one License Number is required")),
    facilityType: Yup.array().min(1, "At least one Facility Type is required"),
    facilityAddress: Yup.string().required("Facility address is required"),
  }),
});
export const initialValues = {
  inputFields: {
    companyName: "",
    licenseNumber: [""],
    facilityType: [],
    // metrcAPIKey: "",
    facilityAddress: "",
  },
};
