import React from "react";
import { Grid, List, ListItemButton, ListItemText } from "@mui/material";
import { styled } from "@mui/material/styles";
import theme from "../../theme";
import { useLocation, useNavigate } from "react-router-dom";

function MenuBar(props) {
  const CustomListItem = styled(ListItemButton)(() => ({
    width: "unset",
    color: props.textColor ? props.textColor : theme.palette.primary.black,
    fontSize: props.fontSize ? props.fontSize : "inherit",
  }));

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Grid
      container
      sx={{
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : "white",
        display: {
          xs: props.isVisible ? "inline-flex" : "none",
          sm: "inline-flex",
        },
      }}
    >
      <Grid item xs={12}>
        <List
          component="nav"
          aria-label="main menu"
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            columnGap: { xs: "40px", sm: "0px" },
            width: "auto",
            marginTop: props.marginTop ? props.marginTop : "0px",
            padding: props.padding
              ? props.padding
              : { sm: "0rem 3rem 0rem 3rem", lg: "0rem 10rem 0rem 10rem" },
          }}
        >
          {/* <CustomListItem
            sx={{
              textAlign: "center",
              color: props.textColor
                ? props.textColor
                : theme.palette.primary.black,
             
              "&.Mui-selected": {
                borderTop: "2px solid #1B8A79",
                backgroundColor: "transparent",
                color: props.textColor
                  ? props.textColor
                  : theme.palette.primary.main,
                fontWeight: "500 !important",
              },
              "&.Mui-focusVisible": {
                backgroundColor: "transparent",
              },
              "&.Mui-selected:hover": {
                backgroundColor: "transparent",
              },
              ":hover": {
                backgroundColor: "transparent",
              },
            }}
            selected={location.pathname === "/"}
            onClick={() => navigate("/")}
          >
            <ListItemText
              primary="Main"
              disableTypography
              sx={{ fontSize: { xs: "14px", sm: "10px", md: "14px" } }}
            />
          </CustomListItem> */}

          <CustomListItem
            sx={{
              textAlign: "center",
              padding: { xs: "0rem", lg: "0.5rem" },
              color: props.textColor
                ? props.textColor
                : theme.palette.primary.black,

              "&.Mui-selected": {
                borderTop: "2px solid #1B8A79",
                backgroundColor: "transparent",
                color: props.textColor
                  ? props.textColor
                  : theme.palette.primary.main,
                fontWeight: "500 !important",
              },
              "&.Mui-focusVisible": {
                backgroundColor: "transparent",
              },
              "&.Mui-selected:hover": {
                backgroundColor: "transparent",
              },
              ":hover": {
                backgroundColor: "transparent",
              },
            }}
            selected={location.pathname === "/dispensaries"}
            onClick={() => navigate("/dispensaries")}
          >
            <ListItemText
              primary="Dispensaries"
              disableTypography
              sx={{
                fontSize: { xs: "14px", sm: "12px", md: "14px" },
                padding: { sm: "0.5rem 0rem" },
              }}
            />
          </CustomListItem>

          <CustomListItem
            sx={{
              textAlign: "center",
              padding: { xs: "0rem", lg: "0.5rem" },
              color: props.textColor
                ? props.textColor
                : theme.palette.primary.black,

              "&.Mui-selected": {
                borderTop: "2px solid #1B8A79",
                backgroundColor: "transparent",
                color: props.textColor
                  ? props.textColor
                  : theme.palette.primary.main,
                fontWeight: "500 !important",
              },
              "&.Mui-focusVisible": {
                backgroundColor: "transparent",
              },
              "&.Mui-selected:hover": {
                backgroundColor: "transparent",
              },
              ":hover": {
                backgroundColor: "transparent",
              },
            }}
            selected={location.pathname === "/brands"}
            onClick={() => navigate("/brands")}
          >
            <ListItemText
              primary="Brands"
              disableTypography
              sx={{ fontSize: { xs: "14px", sm: "12px", md: "14px" } }}
            />
          </CustomListItem>

          {/* <CustomListItem onClick={toggleServices}>
            <ListItemText primary="Dispensaries" />
            {openServices ? <ExpandLess /> : <ExpandMore />}
          </CustomListItem> */}
          {/* <Popover
            open={openServices}
            anchorEl={servicesAnchor}
            onClose={closeServices}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            sx={{
              "&.MuiPopover-paper": {
                boxShadow: "0",
              },
            }}
          >
            <List component="div" disablePadding>
              <ListItemButton>
                <ListItemText primary="Service 1" />
              </ListItemButton>
              <ListItemButton>
                <ListItemText primary="Service 2" />
              </ListItemButton>
              <ListItemButton>
                <ListItemText primary="Service 3" />
              </ListItemButton>
            </List>
          </Popover> */}
          {/* <CustomListItem button onClick={toggleServices}>
            <ListItemText primary="Find Brands" />
            {openServices ? <ExpandLess /> : <ExpandMore />}
          </CustomListItem> */}
          {/* <Popover
            open={openServices}
            anchorEl={servicesAnchor}
            onClose={closeServices}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <List component="div" disablePadding>
              <ListItemButton>
                <ListItemText primary="Service 1" />
              </ListItemButton>
              <ListItemButton>
                <ListItemText primary="Service 2" />
              </ListItemButton>
              <ListItemButton>
                <ListItemText primary="Service 3" />
              </ListItemButton>
            </List>
          </Popover> */}

          <CustomListItem
            sx={{
              textAlign: "center",
              padding: { xs: "0rem", lg: "0.5rem" },
              color: props.textColor
                ? props.textColor
                : theme.palette.primary.black,

              "&.Mui-selected": {
                borderTop: "2px solid #1B8A79",
                backgroundColor: "transparent",
                color: props.textColor
                  ? props.textColor
                  : theme.palette.primary.main,
                fontWeight: "500 !important",
              },
              "&.Mui-focusVisible": {
                backgroundColor: "transparent",
              },
              "&.Mui-selected:hover": {
                backgroundColor: "transparent",
              },
              ":hover": {
                backgroundColor: "transparent",
              },
            }}
            selected={location.pathname === "/blog"}
            onClick={() => navigate("/blog")}
          >
            <ListItemText
              primary="Blog"
              disableTypography
              sx={{ fontSize: { xs: "14px", sm: "12px", md: "14px" } }}
            />
          </CustomListItem>
          <CustomListItem
            sx={{
              textAlign: "center",
              padding: { xs: "0rem", lg: "0.5rem" },
              color: props.textColor
                ? props.textColor
                : theme.palette.primary.black,

              "&.Mui-selected": {
                borderTop: "2px solid #1B8A79",
                backgroundColor: "transparent",
                color: props.textColor
                  ? props.textColor
                  : theme.palette.primary.main,
                fontWeight: "500 !important",
              },
              "&.Mui-focusVisible": {
                backgroundColor: "transparent",
              },
              "&.Mui-selected:hover": {
                backgroundColor: "transparent",
              },
              ":hover": {
                backgroundColor: "transparent",
              },
            }}
            selected={location.pathname === "/about-us"}
            onClick={() => navigate("/about-us")}
          >
            <ListItemText
              primary="About"
              disableTypography
              sx={{ fontSize: { xs: "14px", sm: "12px", md: "14px" } }}
            />
          </CustomListItem>
          <CustomListItem
            sx={{
              textAlign: "center",
              padding: { xs: "0rem", lg: "0.5rem" },
              color: props.textColor
                ? props.textColor
                : theme.palette.primary.black,

              "&.Mui-selected": {
                borderTop: "2px solid #1B8A79",
                backgroundColor: "transparent",
                color: props.textColor
                  ? props.textColor
                  : theme.palette.primary.main,
                fontWeight: "500 !important",
              },
              "&.Mui-focusVisible": {
                backgroundColor: "transparent",
              },
              "&.Mui-selected:hover": {
                backgroundColor: "transparent",
              },
              ":hover": {
                backgroundColor: "transparent",
              },
            }}
            selected={location.pathname === "/pricing"}
            onClick={() => navigate("/pricing")}
          >
            <ListItemText
              primary="Pricing"
              disableTypography
              sx={{ fontSize: { xs: "14px", sm: "12px", md: "14px" } }}
            />
          </CustomListItem>
          <CustomListItem
            sx={{
              textAlign: "center",
              padding: { xs: "0rem", lg: "0.5rem" },
              color: props.textColor
                ? props.textColor
                : theme.palette.primary.black,

              "&.Mui-selected": {
                borderTop: "2px solid #1B8A79",
                backgroundColor: "transparent",
                color: props.textColor
                  ? props.textColor
                  : theme.palette.primary.main,
                fontWeight: "500 !important",
              },
              "&.Mui-focusVisible": {
                backgroundColor: "transparent",
              },
              "&.Mui-selected:hover": {
                backgroundColor: "transparent",
              },
              ":hover": {
                backgroundColor: "transparent",
              },
            }}
            selected={location.pathname === "/faqs"}
            onClick={() => navigate("/faqs")}
          >
            <ListItemText
              primary="FAQ"
              disableTypography
              sx={{ fontSize: { xs: "14px", sm: "12px", md: "14px" } }}
            />
          </CustomListItem>
          <CustomListItem
            sx={{
              textAlign: "center",
              padding: { xs: "0rem", lg: "0.5rem" },
              color: props.textColor
                ? props.textColor
                : theme.palette.primary.black,

              "&.Mui-selected": {
                borderTop: "2px solid #1B8A79",
                backgroundColor: "transparent",
                color: props.textColor
                  ? props.textColor
                  : theme.palette.primary.main,
                fontWeight: "500 !important",
              },
              "&.Mui-focusVisible": {
                backgroundColor: "transparent",
              },
              "&.Mui-selected:hover": {
                backgroundColor: "transparent",
              },
              ":hover": {
                backgroundColor: "transparent",
              },
            }}
            selected={location.pathname === "/contact"}
            onClick={() => navigate("/contact")}
          >
            <ListItemText
              primary="Contact Us"
              disableTypography
              sx={{ fontSize: { xs: "14px", sm: "12px", md: "14px" } }}
            />
          </CustomListItem>
        </List>
      </Grid>
    </Grid>
  );
}

export default MenuBar;
