import React, { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { Box } from "@mui/material";

const TextFieldInputBase = styled(TextField)(
  ({ theme, background, margin }) => ({
    background: background || "transparent",
    color: theme.palette.primary.green_dark,
    width: "99.5%",
    border: "1px solid lightGrey",
    borderRadius: "5px",
    margin: margin || "",
    boxShadow: 0,
    "& .MuiInputBase-input": {
      width: "100%",
      borderRadius: "5px",
      fontSize: "14px",
      padding: "10px",
      boxShadow: 0,
      "& hover": {
        border: "none !important",
      },
    },
    "& .MuiInput-root:before": {
      borderBottom: "0",
    },
    "& .MuiInput-root:after": {
      borderBottom: "0",
    },
    "& .Mui-focused": {
      color: "rgba(0, 0, 0, 0.87)",
      borderBottom: 0,
      boxShadow: 0,
    },
  }),
);

const InputFieldLocation = ({
  apiKey,
  background,
  onPlaceSelected,
  placeholder = "Debounce 500 ms",
  name,
  value,
  onBlur,
  disabled,
  suggestionStyles,
}) => {
  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey,
    });
  const [inputValue, setInputValue] = useState(value ? value : "");
  const renderItem = (item) => (
    <div
      key={item?.place_id}
      onClick={() => {
        // Handle place selection and pass it to the parent component
        if (placesService) {
          placesService?.getDetails(
            {
              placeId: item?.place_id,
            },
            (placeDetails) => {
              console.log("Selected Place Details:", placeDetails);
              //setSelectedPlace(placeDetails);
              setInputValue(placeDetails?.formatted_address);
              onPlaceSelected(placeDetails);
            },
          );
          // Clear predictions and hide the prediction list
          getPlacePredictions({ input: "" });
        }
      }}
    >
      {item?.description}
    </div>
  );

  useEffect(() => {
    if (value === "") {
      setInputValue("");
    } else if (value?.length > 0) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <>
      <TextFieldInputBase
        InputLabelProps={{
          sx: {
            // color: "blue",
            [`&.${inputLabelClasses.shrink}`]: {
              color: "#5D953C !important",
            },
          },
        }}
        background={background}
        variant="standard"
        size="standerd"
        placeholder={placeholder}
        name={name}
        value={inputValue}
        onChange={(evt) => {
          setInputValue(evt.target.value);
          // Trigger prediction based on user input
          getPlacePredictions({ input: evt.target.value });
          // Hide the prediction list when the user starts typing
          //setSelectedPlace(null);
        }}
        onBlur={onBlur}
        disabled={disabled}
      />
      {/* Hide the prediction list when a place is selected */}
      {placePredictions?.length > 0 && inputValue && (
        <Box
          className="predictions-container"
          sx={suggestionStyles ? suggestionStyles : {}}
        >
          {placePredictions?.map((item) => renderItem(item))}
        </Box>
      )}
    </>
  );
};

export default InputFieldLocation;
