import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import theme from "../../theme";
import { ReactComponent as CircleTick } from "../../Assets/Icons/CircleTick.svg";
import { ReactComponent as Location } from "../../Assets/Icons/Location.svg";
import { ReactComponent as License } from "../../Assets/Icons/Lienncence.svg";
import { ReactComponent as Url } from "../../Assets/Icons/Url.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as PhoneIcon } from "../../Assets/Icons/Phone.svg";
import { ReactComponent as Facebook } from "../../Assets/Icons/FacebookColor.svg";
import { ReactComponent as Instagram } from "../../Assets/Icons/InstagramColor.svg";
import { ReactComponent as Twitter } from "../../Assets/Icons/Twitter.svg";
import { ReactComponent as Email } from "../../Assets/Icons/EmailIcon2.svg";
import { removeQueryParameters } from "../../util/functions";
import heroSubTabBanner from "../../Assets/Images/heroSubTabBanner.png";
import HamburgerMenu from "./HamburgerMenu";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as LogoWhite } from "../../Assets/Icons/LogoWhite.svg";

const CustomLink = styled(Link)(() => ({
  textDecoration: "none !important",
  cursor: "pointer",
}));

export default function LoginSmallBanner(props) {
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);
  console.log(props.facebookLink, props.twitterLink, props.twitterLink);
  return (
    <>
      {openHamburgerMenu && (
        <HamburgerMenu onClose={() => setOpenHamburgerMenu(false)} />
      )}
      <Grid container>
        <Grid
          item
          container
          xs={12}
          sx={{
            width: "100vw",
            maxHeight: "70vh",
            height: { xs: "190px", sm: "270px", md: "330px", lg: "auto" },
            position: "relative",
            backgroundColor: "white",
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              backdropSize: "cover",
            }}
            src={
              props?.backImage
                ? removeQueryParameters(props?.backImage)
                : heroSubTabBanner
            }
            alt=""
          />
          <Grid
            item
            xs={12}
            sx={{
              display: { sm: "none" },
              justifyContent: "space-between",
              position: "absolute",
              padding: "20px",
            }}
            container
          >
            <Link to={"/"}>
              <LogoWhite />
            </Link>
            <Box onClick={() => setOpenHamburgerMenu(true)}>
              <MenuIcon
                sx={{ color: "white", width: "25px", height: "25px" }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          container
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            padding: {
              xs: 0,
              sm: "0rem 0rem 0rem 1rem",
              md: "0rem 0rem 0rem 4rem",
              lg: "0rem 0rem 0rem 6rem",
              xl: "0rem 0rem 0rem 8rem",
            },
          }}
        >
          <Grid
            item
            container
            sm={2}
            sx={{
              position: "relative",
              display: "flex",
              alignItems: { xs: "center" },
              justifyContent: { xs: "center", sm: "start" },
            }}
          >
            <Box
              item
              sx={{
                background: "white",
                width: { xs: "5.5rem", sm: "7rem", md: "9.5rem", lg: "12rem" },
                height: { xs: "5.5rem", sm: "7rem", md: "9.5rem", lg: "12rem" },
                borderRadius: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundSize: "cover",
                boxShadow:
                  "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
                position: "absolute",
              }}
            >
              <img
                src={removeQueryParameters(props?.logo)}
                style={{
                  width: "95%",
                  height: "95%",
                  borderRadius: "100%",
                }}
                alt=""
              />
            </Box>
          </Grid>

          <Grid item container>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", sm: "start" },
                columnGap: "10px",

                margin: {
                  xs: "3rem 0rem 1rem 0rem",
                  sm: "1rem 0rem 0rem 8rem",
                  md: "1rem 0rem 0rem 10rem",
                  lg: "1rem 0rem 0rem 13rem",
                },
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.primary.black,
                  fontSize: { xs: "24px", lg: "24px" },
                  fontWeight: "500",
                }}
              >
                {props.title}
              </Typography>
              <CircleTick />
            </Grid>

            <Grid
              item
              container
              sx={{
                margin: {
                  xs: "0rem",
                  sm: "0rem 0rem 0rem 8rem",
                  md: "0rem 0rem 0rem 10rem",
                  lg: "0rem 0rem 0rem 13rem",
                },
              }}
            >
              <Grid
                item
                xs={12}
                sm={8}
                md={9}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { xs: "center", sm: "flex-start" },
                  justifyContent: { xs: "center", sm: "start" },
                  rowGap: { xs: "0.7rem", sm: 0 },
                  columnGap: { xs: 0, sm: "0.7rem" },
                  margin: "0.7rem 0rem",
                  flexWrap: "wrap",
                }}
              >
                {props.location && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      columnGap: "10px",
                    }}
                  >
                    <Location />
                    <Typography
                      sx={{
                        color: theme.palette.primary.inputTextColor,
                        fontSize: {
                          xs: "12px",
                          sm: "10px",
                          md: "12px",
                          lg: theme.typography.fontSize,
                        },
                        fontWeight: "300",
                      }}
                    >
                      {props.location}
                    </Typography>
                  </Box>
                )}

                {props.url && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      columnGap: "10px",
                    }}
                  >
                    <Url />
                    <Typography
                      sx={{
                        color: theme.palette.primary.inputTextColor,
                        fontSize: {
                          xs: "12px",
                          sm: "10px",
                          md: "12px",
                          lg: theme.typography.fontSize,
                        },
                        fontWeight: "300",
                      }}
                    >
                      {props.url}
                    </Typography>
                  </Box>
                )}

                {props.license && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      columnGap: "10px",
                    }}
                  >
                    <License />
                    <Typography
                      sx={{
                        color: theme.palette.primary.inputTextColor,
                        fontSize: {
                          xs: "12px",
                          sm: "10px",
                          md: "12px",
                          lg: theme.typography.fontSize,
                        },
                        fontWeight: "300",
                      }}
                    >
                      {props.license}
                    </Typography>
                  </Box>
                )}

                {props.email && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      columnGap: "10px",
                    }}
                  >
                    <Email />
                    <Typography
                      sx={{
                        color: theme.palette.primary.inputTextColor,
                        fontSize: {
                          xs: "12px",
                          sm: "10px",
                          md: "12px",
                          lg: theme.typography.fontSize,
                        },
                        fontWeight: "300",
                      }}
                    >
                      {props.email}
                    </Typography>
                  </Box>
                )}

                {props.phoneNumber && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      columnGap: "10px",
                    }}
                  >
                    <PhoneIcon />
                    <Typography
                      sx={{
                        color: theme.palette.primary.inputTextColor,
                        fontSize: {
                          xs: "12px",
                          sm: "10px",
                          md: "12px",
                          lg: theme.typography.fontSize,
                        },
                        fontWeight: "300",
                      }}
                    >
                      {props.phoneNumber}
                    </Typography>
                  </Box>
                )}
                {props.recreationalOrMedical && (
                  <Box
                    sx={{
                      background: "rgba(36, 179, 20, 0.2)",
                      padding: "5px 8px",
                      borderRadius: "3px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.primary.darkGreen,
                        fontSize: {
                          xs: "10px",
                          lg: "11px",
                        },
                        fontWeight: "400",
                      }}
                    >
                      {props.recreationalOrMedical}
                    </Typography>
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: { xs: "center", sm: "flex-start" },
                    justifyContent: "center",
                    columnGap: "5px",
                  }}
                >
                  {/* <Box className="socialMedia">
                    <CustomLink>
                      <EmailIcon />
                    </CustomLink>
                  </Box> */}
                  {/* {props?.phoneNumber && (
                    <Box className="socialMedia">
                      <CustomLink>
                        <PhoneIcon />
                      </CustomLink>
                    </Box>
                  )} */}
                  {props?.facebookLink && props?.facebookLink !== "-" && (
                    <Box
                      sx={{ width: "20px", height: "20px", cursor: "pointer" }}
                    >
                      <CustomLink
                        onClick={() =>
                          window.open(props.facebookLink, "_blank")
                        }
                      >
                        <Facebook />
                      </CustomLink>
                    </Box>
                  )}
                  {props?.instagramLink && props.instagramLink !== "-" && (
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    >
                      <CustomLink
                        onClick={() =>
                          window.open(props.instagramLink, "_blank")
                        }
                      >
                        <Instagram />
                      </CustomLink>
                    </Box>
                  )}
                  {props?.twitterLink && props.twitterLink !== "-" && (
                    <Box
                      sx={{ width: "20px", height: "20px", cursor: "pointer" }}
                    >
                      <CustomLink
                        onClick={() => window.open(props.twitterLink, "_blank")}
                      >
                        <Twitter />
                      </CustomLink>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid
        container
        sx={{
          background: `url(${props.backImage ? props.backImage : BrandBanner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          pb: 7,
          height: "30vw",
          width: "100vw",
          padding: { xs: "0rem", sm: "0rem 3rem", lg: "0 10rem" },
          position: { xs: "static", sm: "relative" },
        }}
      >
        <Box
          sx={{
            position: { xs: "static", sm: "absolute" },
            bottom: { xs: 0, sm: -90 },
            width: { xs: "100%", sm: "88%", lg: "80%" },
            background: "red",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={2}
              sx={{
                background: "white",
                height: { xs: "5rem", sm: "7rem", md: "9.5rem", lg: "12rem" },
                borderRadius: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundSize: "cover",
                boxShadow:
                  "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
              }}
            >
              <img
                src={`${DispensaryImage}`}
                style={{
                  width: "95%",
                  height: "95%",
                  borderRadius: "100%",
                  objectFit: "cover",
                }}
                alt=""
              />
            </Grid>
            <Grid
              item
              xs={10}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                pl: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.black,
                    fontSize: { sm: "18px", md: "20px", lg: "24px" },
                    fontWeight: "500",
                    pr: 2.5,
                    pl: 0.5,
                  }}
                >
                  {props.title}
                </Typography>
                <CircleTick />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {props.location ? (
                    <>
                      <Box display={"flex"} alignItems={"center"}>
                        <Location />
                        <Typography
                          sx={{
                            color: theme.palette.primary.inputTextColor,
                            fontSize: {
                              sm: "8px",
                              md: "12px",
                              lg: theme.typography.fontSize,
                            },
                            fontWeight: "300",
                            pr: 2.5,
                            pl: 0.5,
                          }}
                        >
                          {props.locationDes}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    ""
                  )}

                  {props.url ? (
                    <>
                      <Box display={"flex"} alignItems={"center"}>
                        <Url />
                        <Typography
                          sx={{
                            color: theme.palette.primary.inputTextColor,
                            fontSize: {
                              sm: "8px",
                              md: "12px",
                              lg: theme.typography.fontSize,
                            },
                            fontWeight: "300",
                            pr: 2.5,
                            pl: 0.5,
                          }}
                        >
                          {props.urlDes}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    ""
                  )}

                  {props.license ? (
                    <>
                      <Box display={"flex"} alignItems={"center"}>
                        <License />
                        <Typography
                          sx={{
                            color: theme.palette.primary.inputTextColor,
                            fontSize: {
                              sm: "8px",
                              md: "12px",
                              lg: theme.typography.fontSize,
                            },
                            fontWeight: "300",
                            pr: 2.5,
                            pl: 0.5,
                          }}
                        >
                          {props.license}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    ""
                  )}

                  {props.email ? (
                    <>
                      <Box display={"flex"} alignItems={"center"}>
                        <Email />
                        <Typography
                          sx={{
                            color: theme.palette.primary.inputTextColor,
                            fontSize: {
                              sm: "8px",
                              md: "12px",
                              lg: theme.typography.fontSize,
                            },
                            fontWeight: "300",
                            pr: 2.5,
                            pl: 0.5,
                          }}
                        >
                          {props.emailDes}
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    ""
                  )}
                </Box>
                <Box>
                  <ul
                    style={{
                      listStyle: "none",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: 0,
                      margin: 0,
                    }}
                  >
                    <li className="socialMedia">
                      <CustomLink>
                        <EmailIcon />
                      </CustomLink>
                    </li>
                    <li className="socialMedia">
                      <CustomLink>
                        <PhoneIcon />
                      </CustomLink>
                    </li>
                    <li className="socialMedia">
                      <CustomLink>
                        <Facebook />
                      </CustomLink>
                    </li>
                    <li className="socialMedia">
                      <CustomLink>
                        <Instagram />
                      </CustomLink>
                    </li>
                    <li className="socialMedia">
                      <CustomLink>
                        <Twitter />
                      </CustomLink>
                    </li>
                  </ul>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid> */}
    </>
  );
}
