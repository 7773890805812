import { Box, Stack } from "@mui/material";
import { MenuItem, Select } from "@mui/material";

function CustomSelectV2({
  item,
  detailsData,
  setFormData,
  setCategorySelected,
  errors,
  keyValue,
}) {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      padding={"16px"}
      sx={{
        borderRadius: "4px",
        bgcolor: "#fff",
      }}
      spacing={2}
    >
      <span style={{ color: "#87929E", fontSize: "14px", fontWeight: "300" }}>
        {item.placeholder}
      </span>
      <Stack alignItems={"flex-end"}>
        <Select
          variant="filled"
          color="success"
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          value={detailsData[item.key] || ""}
          onChange={(e) => {
            if (item.key === "Category") setCategorySelected(e.target.value);

            setFormData((prev) => ({
              ...prev,
              details: { ...prev.details, [item.key]: e.target.value },
            }));
          }}
          sx={{
            fontSize: "14px",
            color: "#87929E",
            width: { xs: "140px", md: "160px", lg: "200px" },
            "& .MuiSelect-select": {
              padding: "0 12px",
              bgcolor: "#fff",
            },
          }}
        >
          <MenuItem value="">
            <span>- Select -</span>
          </MenuItem>
          {item.options?.map((option, key) => (
            <MenuItem key={key + "key"} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <Box
          sx={{
            fontSize: "12px",
            marginTop: "4px",
            color: "#FF0000",
          }}
          component={"span"}
        >
          {errors?.[keyValue]}
        </Box>
      </Stack>
    </Stack>
  );
}

export default CustomSelectV2;
