import { Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CustomCheckbox from "../../../Components/Custom/CheckBox";
import PrimaryButton from "../../../Components/Custom/CustomPrimaryButton";
import InputFieldLabel from "../../../Components/Custom/InnputFieldLabel";
import InputField from "../../../Components/Custom/InputField";
import InputFieldLocation from "../../../Components/Custom/InputFieldLocation";
import theme from "../../../theme";
import { updateCompanyData } from "../SignUpIntegration/userRegSlice";
import { initialValues, validateMetrcSchema } from "../SignUpIntegration/validateMetrc";
import CustomMultiSelect from "./CustomMultiSelect";
import SecondaryButton from "../../../Components/Custom/SecondaryButton";
import AddIcon from "@mui/icons-material/Add";

export default function Step3Content(props) {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [licenseKey, setLicenseKey] = useState([]);
  console.log(isLoading, "isLoading");

  const formik = useFormik({
    initialValues,
    validationSchema: validateMetrcSchema,

    onSubmit: (values) => {
      console.log("values", values.inputFields);
      console.log("on submit is called");
      setIsLoading(true);

      dispatch(updateCompanyData({ data: values.inputFields })).then((res) => {
        if (res?.payload?.success) {
          toast.success(`${res?.payload?.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
          if (props.handleNext && !isLoading) {
            props.handleNext();
          }
        } else {
          setIsLoading(false);

          toast.error(`Metrc API key not found, Please check key and try again`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    },
  });

  const addInputField = () => {
    const updatedFields = [...formik.values.inputFields, { companyName: "", metrcAPIKey: "", facilityAddress: "" }];
    formik.setFieldValue("inputFields", updatedFields);
  };

  const addLicenseInputField = () => {
    formik.setFieldValue("inputFields.licenseNumber", [...formik.values.inputFields.licenseNumber, ""]);
  };
  const removeInputField = (index) => {
    const updatedFields = [...formik.values.inputFields];
    updatedFields.splice(index, 1);
    formik.setFieldValue("inputFields", updatedFields);
  };

  console.log(formik.values.inputFields, "input fields");

  return (
    <>
      <ToastContainer />
      {/* <Box
        sx={{
          position: { sm: "absolute" },
          top: { sm: "0%" },
          bottom: { sm: "0%" },
          left: { sm: "0%" },
          width: { sm: "50vw" },
          height: { sm: "100%" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "1.5rem",
        }}
      >
        <Box
          sx={{
            background: theme.palette.primary.dashboardBack,
            borderRadius: "10px",
            padding: { xs: 3, md: 5 },
            boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
            marginTop: { xs: "30px", md: "50px", xl: "0px" },
            maxWidth: { xs: "fit-content", sm: "auto" },
            width: { sm: "70%", md: "50%", lg: "40%" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", xl: "28px" },
              fontWeight: "600",
              color: theme.palette.primary.black,
            }}
          >
            How to add METRC API key to Weed
            <span style={{ color: theme.palette.primary.main }}>X</span>
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "12px", xl: "16px" },
              fontWeight: "500",
              color: theme.palette.primary.black,
              marginTop: "20px",
            }}
          >
            In order to access your METRC API Key, use the following steps:
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: { xs: "10px", sm: "5px", xl: "10px" },
              marginTop: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", xl: "16px" },
                fontWeight: "500",
                color: theme.palette.primary.mediumGrey,
              }}
            >
              <span style={{ color: theme.palette.primary.peachRed }}>1. </span>
              Log into METRC - Visit https://www.metrc.com/ - click “More” in the main menu, and select your licensing state to log into your account
              with your username and password. (Make sure to be an admin in metrc)
            </Typography>

            <Typography
              sx={{
                fontSize: { xs: "12px", xl: "16px" },
                fontWeight: "500",
                color: theme.palette.primary.mediumGrey,
              }}
            >
              <span style={{ color: theme.palette.primary.peachRed }}>2. </span>
              Go to the API Keys page. Click on your user profile in the top right-hand corner of the screen and select API Keys from the dropdown.
            </Typography>

            <Typography
              sx={{
                fontSize: { xs: "12px", xl: "16px" },
                fontWeight: "500",
                color: theme.palette.primary.mediumGrey,
              }}
            >
              <span style={{ color: theme.palette.primary.peachRed }}>3. </span>
              Copy your API Key (select & highlight the API key then right-click to copy).
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px", xl: "16px" },
                fontWeight: "500",
                color: theme.palette.primary.mediumGrey,
              }}
            >
              <span style={{ color: theme.palette.primary.peachRed }}>4. </span>
              Right click to Paste your API Key(s) and add the facility name to Register your account.
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px", xl: "16px" },
                fontWeight: "500",
                color: theme.palette.primary.mediumGrey,
              }}
            >
              <span style={{ color: theme.palette.primary.peachRed }}>5. </span>
              Click Next to save your Key
            </Typography>
          </Box>
        </Box>
      </Box> */}
      <form>
        {/* {formik.values.inputFields.map((data, index) => ( */}
        <Box
          sx={{
            mt: 2,
          }}
        >
          <div>
            <InputFieldLabel margin="1.5vw">Company name</InputFieldLabel>
            <InputField
              background="white"
              placeholder="Enter your Company name"
              name={`inputFields.companyName`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values?.inputFields?.companyName}
              error={formik.errors?.inputFields?.companyName ? true : false}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  formik.handleSubmit();
                }
              }}
            />
            {formik.touched?.inputFields?.companyName && formik.errors?.inputFields?.companyName && (
              <div className="error">{formik.errors.inputFields?.companyName}</div>
            )}
          </div>
          <div>
            <InputFieldLabel margin="1.5vw">License Number</InputFieldLabel>
            <Box
              sx={
                {
                  // display: "flex",
                  // alignItems: "center",
                  // justifyContent: "center",
                  // flexDirection: {
                  //   xs: "column",
                  //   sm: "row",
                  // },
                  // columnGap: {
                  //   xs: "0px",
                  //   sm: "20px",
                  // },
                }
              }
            >
              {formik.values?.inputFields?.licenseNumber.map((licenseNumber, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                    rowGap: {
                      xs: "5px",
                    },
                  }}
                >
                  <InputField
                    key={index}
                    background="white"
                    placeholder="Enter your License Number"
                    name={`inputFields.licenseNumber[${index}]`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={licenseNumber}
                    error={formik.errors?.inputFields?.licenseNumber ? true : false}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        formik.handleSubmit();
                      }
                    }}
                  />
                  {formik.values.inputFields.licenseNumber.length - 1 === index && (
                    <SecondaryButton
                      marginLeft="1vw"
                      width={{ xs: "auto", sm: "100%" }}
                      padding="8px"
                      margin={{ xs: "5px", sm: "0px" }}
                      bordersColor={`1px solid ${theme.palette.primary.main}`}
                      onClick={addLicenseInputField}
                      icon={<AddIcon />}
                      fontSize={{ xs: "10px", sm: "12px" }}
                    >
                      Add License Number
                    </SecondaryButton>
                  )}
                </Box>
              ))}
            </Box>
            {formik.touched?.inputFields?.licenseNumber && formik.errors?.inputFields?.licenseNumber && (
              <div className="error">{formik.errors?.inputFields?.licenseNumber}</div>
            )}
          </div>
          <div>
            <InputFieldLabel margin="1.5vw">Facility Type</InputFieldLabel>
            <CustomMultiSelect
              background="white"
              name={`inputFields.facilityType`}
              placeholder="Select your facility type"
              value={formik.values.inputFields.facilityType}
              onChange={formik.handleChange}
              error={formik.errors?.inputFields?.facilityType ? true : false}
              onBlur={formik.handleBlur}
              options={["Grow", "Processor", "Dispo"]}
            />
            {formik.touched.inputFields?.facilityType && formik.errors.inputFields?.facilityType && (
              <div className="error">{formik.errors.inputFields.facilityType}</div>
            )}
          </div>
          {/* <div>
              <InputFieldLabel margin="1.5vw">Metrc API key</InputFieldLabel>
              <InputField
                background="white"
                placeholder="Enter your Metrc API key"
                name={`inputFields.metrcAPIKey`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values?.inputFields?.metrcAPIKey}
                error={formik.errors?.inputFields?.metrcAPIKey ? true : false}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    formik.handleSubmit();
                  }
                }}
              />
              {formik.touched?.inputFields?.metrcAPIKey && formik.errors?.inputFields?.metrcAPIKey && (
                <div className="error">{formik.errors?.inputFields?.metrcAPIKey}</div>
              )}
            </div> */}
          <div>
            <InputFieldLabel margin="1.5vw">Address</InputFieldLabel>
            <InputFieldLocation
              apiKey={process.env.REACT_APP_GOOGLE}
              background="white"
              placeholder="Enter your Address"
              name="facilityAddress"
              onPlaceSelected={(place) => {
                formik.setFieldValue(`inputFields.facilityAddress`, place?.formatted_address);
                console.log("place", place);
              }}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.inputFields?.facilityAddress ? true : false}
            />
            {formik.touched.inputFields?.facilityAddress && formik.errors.inputFields?.facilityAddress && (
              <div className="error">{formik.errors.inputFields?.facilityAddress}</div>
            )}
          </div>
          {formik.values.inputFields.length > 1 && (
            <button
              style={{
                marginTop: "1rem",
                maxWidth: "40%",
                fontWeight: 500,
                fontSize: "10px",
                textTransform: "none",
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.white,
                boxShadow: "none !important",
                cursor: "pointer",
                border: "none",
                padding: "5px 10px",
                borderRadius: "3px",
              }}
              // onClick={() => removeInputField(index)}
            >
              Remove
            </button>
          )}
        </Box>
        {/* ))} */}
        {/* <SecondaryButton
          width="100%"
          margin="30px"
          bordersColor={`1px solid ${theme.palette.primary.main}`}
          onClick={addInputField}
          icon={<AddIcon />}
          fontSize={{ xs: "10px", sm: "12px" }}
        >
          Add another facility Metric API key
        </SecondaryButton> */}
        <Box display="flex" alignItems="center" mt={2}>
          <CustomCheckbox onChange={() => setTermsAccepted(!termsAccepted)} checked={termsAccepted} />
          <Typography
            sx={{
              color: theme.palette.primary.mediumGrey,
              fontSize: {
                xs: "10px",
                sm: "12px",
                md: theme.typography.fontSize,
              },
            }}
          >
            I accept the{" "}
            <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
              Terms of Use and Privacy Policy
            </Link>
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <PrimaryButton
            type="submit"
            disabled={!termsAccepted || isLoading}
            isLoading={isLoading}
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            Next
          </PrimaryButton>
        </Box>
      </form>
    </>
  );
}
