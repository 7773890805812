import * as React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import theme from "../../theme";
import { useState } from "react";
import { useEffect } from "react";
import { useDebounce } from "../../CustomHooks/use-debounce";

export default function SearchInputWithIcon(props) {
  const [value, setValue] = useState("");
  const { callbackFunction } = props;

  const debouncedValue = useDebounce(value);

  useEffect(() => {
    // if (debouncedValue) {
    callbackFunction(debouncedValue);
    // }
  }, [debouncedValue]);

  const handleChange = (e) => {
    console.log("e", e.target.value);
    setValue(e.target.value);
  };
  return (
    <TextField
      sx={{
        background: props.background ? props.background : "inherit",
        width: "100%",
        borderRadius: "5px",
        border: !props.border ? props.border : "1px solid lightgrey",
        padding: props.padding ? props.padding : "inherit",
        display: "flex",
        alignItems: "start",
        "& ::placeholder": {
          fontWeight: 400,
          fontSize: { xs: "14px", sm: "16px" },
          textOverflow: "ellipsis !important",
          color: props.placeholderColor || theme.palette.primary.inputTextColor,
        },
      }}
      id="input-with-icon-textfield"
      value={value}
      onChange={handleChange}
      placeholder={props.placeholder}
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{
              padding: "10px 0px 10px 10px",
            }}
          >
            <SearchIcon
              sx={{
                color: props.color || theme.palette.primary.black,
                width: { xs: "16px", sm: "auto" },
              }}
            />
          </InputAdornment>
        ),
      }}
      variant="standard"
    />
  );
}
