import { Box, Stack } from "@mui/material";
import React from "react";

function InputWrapper({ label, children }) {
  return (
    <Stack direction={{ xs: "column", sm: "row" }} alignItems={"flex-start"}>
      <span
        style={{
          color: "#212B36",
          fontSize: "12px",
          fontWeight: "500",
          marginTop: "4px",
          width: "120px",
        }}
      >
        {label}
      </span>
      <Box>{children}</Box>
    </Stack>
  );
}

export default InputWrapper;
