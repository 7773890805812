import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import BuildImage from "../../../Assets/Images/build.png";
import theme from "../../../theme";
import { ReactComponent as License } from "../../../Assets/Icons/License.svg";
import { Grid } from "@mui/material";

export default function BuildSection() {
  return (
    <>
      <Grid
        container
        sx={{
          padding: { sm: "0 2rem", lg: "0 10rem" },
          height: { sm: "100vh" },
        }}
      >
        <Grid item xs={12}>
          <Box
            mt={{ xs: 5, sm: 10, md: 20 }}
            sx={{
              width: "100%",
              position: "relative",
            }}
          >
            <Box
              sx={{
                p: { sm: 3, md: 6, lg: 11 },
                borderRadius: 2,
                fontSize: "0.875rem",
                fontWeight: "700",
                position: { sm: "absolute" },
                top: { sm: 100, md: 130, lg: 100 },
                left: "35%",
                zIndex: "tooltip",
                boxShadow:
                  "box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.04), 0px 0px 4px 4px rgba(0, 0, 0, 0.04)",
                backgroundColor: theme.palette.primary.white,
                display: { xs: "none", sm: "inline" },
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.primary.black,
                  fontSize: {
                    sm: "30px",
                    md: "35px",
                    lg: theme.typography.fontSize45,
                  },
                  fontWeight: theme.typography.heading1.fontWeight,
                  lineHeight: { sm: "35px", md: "40px", lg: "52px" },
                }}
              >
                Build your Image, Stand out to the crowd
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.primary.black,
                  fontSize: { sm: "14px", md: theme.typography.fontSize16 },
                  fontWeight: "500",
                  mt: { sm: 2, lg: 4 },
                }}
              >
                Joining this exclusive platform will earn you higher prices for
                your products. Grow your business and connect with licensed
                facilities in your state.
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.primary.mediumGrey,
                  fontSize: { sm: "12px", md: theme.typography.fontSize },
                  mt: { sm: 2, lg: 4 },
                  padding: "20px",
                  paddingLeft: 4,
                  borderLeft: theme.shapes.borderLeft,
                }}
              >
                Expand your product offerings by using this online hub to source
                , sell, and showcase products. A 24/7 centralized platform to
                browse, compare, negotiate and connect directly with suppliers.
                Fostering new industry connections.
                <span
                  style={{
                    mt: 2,
                    color: theme.palette.primary.peachRed,
                    fontSize: "12px",
                  }}
                >
                  No commission Fees!
                </span>
              </Typography>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: { xs: "-10px", md: "-50px" },
                left: { xs: "60%", sm: "50%", lg: "43%" },
                zIndex: 1000,
                width: { xs: "100px", md: "160px" },
                height: { xs: "100px", md: "160px" },
                background: "linear-gradient(90deg, #FFA078 0%, #FF7B88 100%)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <License />
              <Typography
                sx={{
                  fontSize: { xs: "25px", md: "30px", lg: "40px" },
                  fontWeight: theme.typography.heading1.fontWeight,
                  color: theme.palette.primary.white,
                }}
              >
                100%
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "8px",
                    md: "12px",
                    lg: theme.typography.fontSize,
                  },
                  color: theme.palette.primary.white,
                  fontWeight: "500",
                }}
              >
                Licensed
              </Typography>
            </Box>
            <Box
              sx={{
                position: { sm: "absolute" },
                top: { sm: 0 },
                left: { sm: 0 },
                zIndex: 0,
                height: { xs: "100%", sm: "500px", md: "auto" },
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                }}
                src={`${BuildImage}`}
                alt=""
              ></img>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            padding: "1rem",
            textAlign: "center",
            display: {
              sm: "none",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "25px",
              fontWeight: "600",
              color: theme.palette.primary.black,
              lineHeight: "30px",
            }}
          >
            Build your Image, Stand out to a wider reach
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: theme.palette.primary.black,
              margin: "1rem auto",
              width: {
                xs: "100%",
                sm: "70%",
              },
            }}
          >
            Joining this exclusive platform will earn you higher prices for your
            products. Grow your business and connect with licensed facilities in
            your state.
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "300",
              color: theme.palette.primary.mediumGrey,
              margin: "auto",
              width: {
                xs: "100%",
                sm: "70%",
              },
            }}
          >
            Expand your product offerings by using this online hub to source ,
            sell, and showcase products. A 24/7 centralized platform to browse,
            compare, negotiate and connect directly with suppliers. Fostering
            new industry connections.
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "300",
              color: theme.palette.primary.peachRed,
              margin: "1rem 0rem",
            }}
          >
            No commission Fees!
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
