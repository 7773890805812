import { Typography } from "@mui/material";
import theme from "../../theme";

function PrimaryHeading(props) {
  return (
    <>
      <Typography
        sx={{
          color: props.color ? props.color : theme.palette.primary.black,
          fontSize: props.fontSize
            ? props.fontSize
            : theme.typography.heading1.fontSize,
          fontWeight: props.fontWeight
            ? props.fontWeight
            : theme.typography.heading1.fontWeight,
          textAlign: props.textAlign ? props.textAlign : "left",
          textTransform: props.textTransform ? props.textTransform : "none",
        }}
      >
        {props.children}
      </Typography>
    </>
  );
}

export default PrimaryHeading;
