import { Box } from "@mui/material";

function BoxWrapperV2({ heading, children, bgcol, padding, width, margin }) {
  return (
    <Box
      sx={{
        padding: padding || "24px",
        bgcolor: bgcol || "#fff",
        borderRadius: "4px",
        width: width || "auto",
        margin: margin || "auto",
        position: "relative",
      }}
    >
      <h2
        style={{
          color: "#2E3A48",
          fontSize: "18px",
          fontWeight: "500",
          margin: "0",
        }}
      >
        {heading}
      </h2>
      <Box sx={{ marginTop: "12px" }}>{children}</Box>
    </Box>
  );
}

export default BoxWrapperV2;
