import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import theme from "../../../theme";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  resendAsyncOTP,
  verifyAsyncOTP,
} from "../SignUpIntegration/userRegSlice";

export default function Step2Content(props) {
  const [otp, setOtp] = useState("");

  const dispatch = useDispatch();

  const selectUserAuth = (state) => state.userRegReducer?.userAuth;
  const userData = useSelector(selectUserAuth);

  const handleSubmit = async (otp) => {
    dispatch(verifyAsyncOTP({ OTP: otp })).then((res) => {
      console.log("then", res);
      if (res.payload.success) {
        toast.success(`${res.payload.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });

        props.handleNext();
      } else {
        toast.error(`${res.payload.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const resendOTP = async (email) => {
    dispatch(resendAsyncOTP({ email: email })).then((res) => {
      console.log("then", res);
      if (res.payload.success) {
        toast.success(`${res.payload.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`${res.payload.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  return (
    <>
      <Typography
        sx={{
          color: theme.palette.primary.black,
          fontSize: theme.typography.fontSize,
          mb: 2,
          textAlign: "center",
        }}
      >
        Enter code from email <strong>{userData?.userEmail}</strong>. If you
        don’t see it, check Spam
      </Typography>
      <ToastContainer />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (otp.length === 4) {
            handleSubmit(otp);
          } else {
            toast.error(`Please enter a valid OTP`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderInput={(props) => <input type="number" {...props} />}
            inputStyle={"otp-input"}
            isInputNum={true}
            shouldAutoFocus={true}
            type="tel"
            inputType="tel"
          />
        </Box>
        <button
          style={{
            marginTop: "2rem",
            width: "100%",
            fontWeight: 500,
            fontSize: "16px",
            textTransform: "none",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.white,
            boxShadow: "none !important",
            cursor: "pointer",
            border: "none",
            padding: "10px",
            borderRadius: "3px",
          }}
          type="submit"
        >
          Verify email
        </button>
      </form>

      <Typography
        sx={{
          color: theme.palette.primary.black,
          fontSize: theme.typography.fontSize,
          mt: 2,
          textAlign: "center",
        }}
      >
        Didn’t receive the email?{" "}
        <Link
          style={{
            color: theme.palette.primary.main,
            textDecoration: "none",
          }}
          onClick={() => resendOTP(userData?.userEmail)}
        >
          Click to resend
        </Link>
      </Typography>
    </>
  );
}
