import * as React from "react";
import Pagination from "@mui/material/Pagination";

export default function BasicPagination(props) {
  return (
    <Pagination
      onChange={props.handlePageChange}
      page={props.currentPage}
      count={props.count ?? 10}
      siblingCount={0}
      boundaryCount={1}
    />
  );
}
