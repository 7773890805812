import InputField from "../../../../Components/Custom/InputField";
import InputWrapper from "./InputWrapper";
import { Box, Grid, Stack, Chip } from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import CustomSelect from "./CustomSelect";
import CustomSelectV2 from "./CustomSelectV2";
import CustomInput from "./CustomInput";
import CustomMultiSelect from "./CustomMultiSelect";
import { DropzoneArea } from "material-ui-dropzone";
import CustomDatePicker from "./CustomDatePicker";
import InputFieldLocation from "../../../../Components/Custom/InputFieldLocation";

function isValidNonNegativeNumber(input) {
  if (input.startsWith("-")) return 0;
  if (input.length > 10) return input.slice(0, 10);

  return input;
}

export const AddImagesContent = ({ handleImageChange, children }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <label
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          opacity: 0,
          zIndex: 1,
          cursor: "pointer",
        }}
        htmlFor="upload-images"
      >
        Upload
      </label>
      <input
        id="upload-images"
        type="file"
        accept="image/*,video/*"
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
      {children}
    </Box>
  );
};

export const ImageWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        width: { xs: "60px", sm: "112px" },
        height: { xs: "60px", sm: "112px" },
        borderRadius: "4px",
        overflow: "hidden",
      }}
    >
      {children}
    </Box>
  );
};

export const ProductContent = ({
  productBrands,
  productData,
  setFormData,
  errors,
}) => {
  return (
    <>
      <InputWrapper
        label={<span className="required-field">Product name</span>}
        errors={errors}
        keyValue="productName"
      >
        <InputField
          value={productData.productName}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              product: { ...prev.product, productName: e.target.value },
            }))
          }
          placeholder="Enter Product name"
        ></InputField>
      </InputWrapper>
      <InputWrapper
        errors={errors}
        keyValue="productBrand"
        label={<span className="required-field">Brand</span>}
      >
        <CustomSelect
          options={productBrands}
          placeholder="Select Brand"
          productData={productData}
          setFormData={setFormData}
        ></CustomSelect>
      </InputWrapper>
      <InputWrapper
        errors={errors}
        keyValue="productPrice"
        label={<span className="required-field">Price ($)</span>}
      >
        <InputField
          type="number"
          value={productData.productPrice}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              product: {
                ...prev.product,
                productPrice: isValidNonNegativeNumber(e.target.value),
              },
            }))
          }
          placeholder="Enter asking price"
        ></InputField>
      </InputWrapper>
    </>
  );
};

export const DescriptionContent = ({
  descriptionData,
  setFormData,
  errors,
}) => {
  return (
    <Stack>
      <BaseTextareaAutosize
        value={descriptionData}
        onChange={(e) =>
          setFormData((prev) => ({ ...prev, description: e.target.value }))
        }
        aria-label="empty textarea"
        style={{
          padding: "16px",
          borderRadius: "4px",
          border: "1px solid #87929E",
          outline: "none",
          resize: "vertical",
        }}
        minRows={6}
        placeholder="Enter product details"
      />
      <Box
        sx={{
          fontSize: "12px",
          marginTop: "2px",
          color: "#FF0000",
        }}
        component={"span"}
      >
        {errors?.["description"]}
      </Box>
    </Stack>
  );
};

export const DetailsContent = ({
  data,
  detailsData,
  setFormData,
  setCategorySelected,
  errors,
  labFile,
  onDropzoneAreaChangeLab,
}) => {
  return (
    <Grid container spacing={2}>
      {data?.map((item, key) => (
        <Grid
          key={key + "key"}
          item
          xs={12}
          sm={6}
          sx={{
            position: "relative",
            zIndex: 700,
          }}
        >
          {item.type === "location" && (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              padding={"16px"}
              sx={{
                borderRadius: "4px",
                bgcolor: "#fff",
              }}
            >
              <span
                style={{
                  color: "#87929E",
                  fontSize: "14px",
                  fontWeight: "300",
                }}
              >
                {item.placeholder}
              </span>
              <Stack
                direction={"column"}
                alignItems={"flex-end"}
                sx={{
                  position: "relative",
                  width: "260px",
                  maxWidth: "260px",
                  zIndex: 700,
                }}
              >
                <InputFieldLocation
                  apiKey={process.env.REACT_APP_GOOGLE}
                  background="white"
                  placeholder="Enter your product address"
                  name={item.key}
                  value={detailsData["Product Location"]}
                  onPlaceSelected={(place) => {
                    setFormData((prev) => ({
                      ...prev,
                      details: {
                        ...prev.details,
                        [item.key]: place.formatted_address,
                      },
                    }));
                  }}
                  suggestionStyles={{
                    position: { xs: "unset", md: "absolute" },
                    maxWidth: "220px",
                    top: "50px",
                    zIndex: 999,
                  }}
                />
                <Box
                  sx={{
                    fontSize: "12px",
                    marginTop: "4px",
                    color: "#FF0000",
                  }}
                  component={"span"}
                >
                  {errors?.[item.key]}
                </Box>
              </Stack>
            </Stack>
          )}
          {item.type === "select" && (
            <CustomSelectV2
              item={item}
              detailsData={detailsData}
              setFormData={setFormData}
              setCategorySelected={setCategorySelected}
              errors={errors}
              keyValue={item.key}
            />
          )}
          {(item.type === "text" ||
            item.type === "number" ||
            item.type === "percent") && (
            <CustomInput
              item={item}
              detailsData={detailsData}
              setFormData={setFormData}
              placeholder={item.placeholder}
              errors={errors}
            />
          )}
          {item.type === "multiselect" && (
            <CustomMultiSelect
              item={item}
              detailsData={detailsData}
              setFormData={setFormData}
            />
          )}
          {item.type === "upload" && (
            <>
              <DropzoneArea
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewText={
                  <span
                    style={{
                      marginTop: "10px",
                      fontSize: "14px",
                      color: "#87929E",
                    }}
                  >
                    Uploaded File
                  </span>
                }
                dropzoneClass="dropzoneAreaSmall"
                filesLimit={1}
                maxFileSize={1048576 * 10} //10 MB
                acceptedFiles={["application/pdf", "image/jpeg", "image/png"]}
                onChange={onDropzoneAreaChangeLab}
                dropzoneText={
                  <span
                    style={{
                      color: "#2E3A48",
                      fontSize: "14px",
                    }}
                  >
                    Drag to upload lab results here or{" "}
                    <span style={{ color: "#F78B8B" }}>select file</span>
                  </span>
                }
              />
              <Stack
                sx={{
                  width: "10rem",
                  marginTop: "10px",
                }}
              >
                {labFile[0]?.name?.includes("https") && (
                  <Chip
                    label={labFile[0]?.name}
                    onClick={() => window.open(labFile[0]?.name, "_blank")}
                    onDelete={() => {
                      onDropzoneAreaChangeLab([]);
                    }}
                  />
                )}
              </Stack>
            </>
          )}
          {item.type === "date" && (
            <CustomDatePicker
              item={item}
              detailsData={detailsData}
              setFormData={setFormData}
              placeholder={item.placeholder}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};
