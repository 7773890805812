import { Box, Typography } from "@mui/material";
import InputFieldLabel from "../../../Components/Custom/InnputFieldLabel";
import InputField from "../../../Components/Custom/InputField";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import { ReactComponent as RightArrow } from "../../../Assets/Icons/RightAuthArrow.svg";
import { ReactComponent as GoogleIcon } from "../../../Assets/Icons/GoogleIcon.svg";
import InputPassword from "../../../Components/Custom/InputPassword";
import SecondaryButton from "../../../Components/Custom/SecondaryButton";
import { Link } from "react-router-dom";
import theme from "../../../theme";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useGoogleLogin } from "@react-oauth/google";

import {
  validationSchema,
  initialValues,
} from "../SignUpIntegration/userRegValidation";
import { addAsyncData } from "../SignUpIntegration/userRegSlice";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

export default function Step1Content(props) {
  const dispatch = useDispatch();

  const login = useGoogleLogin({
    onSuccess: async (respose) => {
      console.log("resp", respose);
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${respose.access_token}`,
            },
          }
        );
        console.log("res In OnSuccess", res.data);
        const values = {
          fullname: res.data.name,
          email: res.data.email,
          userImage: res.data.picture,
          logintype: "GOOGLE",
        };
        dispatch(addAsyncData(values)).then((res) => {
          console.log("then", res);
          if (res.payload.success) {
            toast.success(`${res.payload.message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            props.handleNext();
            console.log("props", props);
          } else {
            toast.error(`${res.payload.message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
      } catch (err) {
        console.log(err);
        toast.error(`${err}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(addAsyncData(values)).then((res) => {
        console.log("then", res);
        if (res.payload.success) {
          toast.success(`${res.payload.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          props.handleNext();
        } else {
          toast.error(`${res.payload.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
      //props.handleNext();
    },
  });

  const formatPhoneNumber = (input) => {
    const numericInput = input.replace(/\D/g, "");
    if (!numericInput || isNaN(numericInput)) {
      return "";
    }
    return `(${numericInput.slice(0, 3)}) ${numericInput.slice(
      3,
      6
    )}-${numericInput.slice(6, 10)}`;
  };

  const handlePhoneChange = (event) => {
    const input = event.target.value;

    if (event.nativeEvent.inputType === "deleteContentBackward") {
      // Handle backspace key
      formik.setFieldValue("phone", input);
      return;
    }

    const formattedNumber = formatPhoneNumber(input);

    formik.setFieldValue("phone", formattedNumber);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <ToastContainer />
        <form>
          <div>
            <InputFieldLabel>Name</InputFieldLabel>
            <InputField
              background="white"
              placeholder="Enter your full name"
              name="fullname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fullname}
              error={formik.errors.fullname ? true : false}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  formik.handleSubmit();
                }
              }}
            />
            {formik.touched.fullname && formik.errors.fullname && (
              <div className="error">{formik.errors.fullname}</div>
            )}
          </div>
          <div>
            <InputFieldLabel margin="1.5vw">Email</InputFieldLabel>
            <InputField
              background="white"
              placeholder="Enter your email address"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.errors.email ? true : false}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  formik.handleSubmit();
                }
              }}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="error">{formik.errors.email}</div>
            )}
          </div>
          <div>
            <InputFieldLabel margin="1.5vw">Phone</InputFieldLabel>
            <InputField
              background="white"
              placeholder="Enter Phone Number"
              name="phone"
              type="text"
              onChange={handlePhoneChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              error={formik.errors.phone ? true : false}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  formik.handleSubmit();
                }
              }}
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className="error">{formik.errors.phone}</div>
            )}
          </div>

          <div>
            <InputFieldLabel margin="1.5vw">Password</InputFieldLabel>
            <InputPassword
              background="white"
              placeholder="Enter your password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.errors.password ? true : false}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  formik.handleSubmit();
                }
              }}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="error">{formik.errors.password}</div>
            )}
          </div>
          <div>
            <InputFieldLabel margin="1.5vw">Confirm Password</InputFieldLabel>
            <InputPassword
              background="white"
              placeholder="Enter your confirm password"
              name="confirmPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              error={formik.errors.confirmPassword ? true : false}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  formik.handleSubmit();
                }
              }}
            />
            {formik.touched.confirmPassword &&
              formik.errors.confirmPassword && (
                <div className="error">{formik.errors.confirmPassword}</div>
              )}
          </div>

          <PrimaryButton
            text="Next"
            width="100%"
            margin={4}
            endicon={<RightArrow />}
            onClick={formik.handleSubmit}
            fontWeight="500"
            fontSize="14px"
            textTransform="none"
          >
            Next
          </PrimaryButton>
        </form>
        {/* <SecondaryButton
          icon={<GoogleIcon />}
          text="Continue with Google"
          width="100%"
          margin={2}
          bordersColor="1px solid lightGrey !important"
          fontWeight="500"
          textTransform="none"
          color={theme.palette.primary.black}
          fontSize="14px"
          background="white"
          onClick={login}
        >
          Continue with Google
        </SecondaryButton> */}

        <Box display={"flex"} justifyContent={"center"} mt={3}>
          <Typography
            sx={{
              color: theme.palette.primary.black,
              fontSize: theme.typography.fontSize,
            }}
          >
            Already have an account
            <Link
              style={{
                color: theme.palette.primary.main,
                paddingLeft: "10px",
                textDecoration: "none",
              }}
              to="/login"
            >
              Login
            </Link>
          </Typography>
        </Box>
      </Box>
    </>
  );
}
