import { Box, MenuItem, Select, Stack } from "@mui/material";
import InputField from "../../../../../Components/Custom/InputField";
import InputFieldLocation from "../../../../../Components/Custom/InputFieldLocation";
import formatPhoneNumber from "./formatPhoneNumber";

function AddEditInput({
  item,
  dispensaryData,
  setDispensary,
  error,
  licenseValuesDropdown,
}) {
  if (item?.name === "location")
    return (
      <Stack>
        <InputFieldLocation
          apiKey={process.env.REACT_APP_GOOGLE}
          background="white"
          placeholder={item?.placeholder}
          value={dispensaryData?.[item.name] || ""}
          onPlaceSelected={(place) => {
            setDispensary((prev) => ({
              ...prev,
              [item.name]: place.formatted_address,
            }));
          }}
          suggestionStyles={{
            position: "absolute",
            maxWidth: "240px",
            width: "240px",
            top: "50px",
            zIndex: 999,
            backgroundColor: "white",
            border: "1px solid #ccc",
          }}
        />
        <Box
          sx={{
            fontSize: "12px",
            marginTop: "2px",
            color: "#FF0000",
          }}
          component={"span"}
        >
          {error}
        </Box>
      </Stack>
    );

  if (item?.name === "stateLicense")
    return (
      <Stack>
        <Select
          color="success"
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          value={dispensaryData?.[item?.name] || ""}
          onChange={(e) => {
            setDispensary((prev) => ({
              ...prev,
              [item.name]: e.target.value,
            }));
          }}
          sx={{
            fontSize: "14px",
            color: "#87929E",
            width: "100%",
            height: "40px",
          }}
        >
          <MenuItem value="" disabled>
            <span>Select</span>
          </MenuItem>
          {licenseValuesDropdown.map((option, key) => (
            <MenuItem key={key + "key"} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <Box
          sx={{
            fontSize: "12px",
            marginTop: "2px",
            color: "#FF0000",
          }}
          component={"span"}
        >
          {error}
        </Box>
      </Stack>
    );

  return (
    <InputField
      placeholder={item?.placeholder}
      onChange={(e) => {
        if (item?.name === "phoneNumber")
          setDispensary((prev) => ({
            ...prev,
            [item.name]: formatPhoneNumber(e, e.target.value),
          }));
        else
          setDispensary((prev) => ({
            ...prev,
            [item.name]: e.target.value,
          }));
      }}
      value={
        item.name === "phoneNumber"
          ? formatPhoneNumber(null, dispensaryData?.[item.name] || "")
          : dispensaryData?.[item?.name] || ""
      }
      error={error}
    />
  );
}

export default AddEditInput;
