import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, CircularProgress, Grid, Stack, TextareaAutosize } from "@mui/material";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactImageMagnify from "react-image-magnify";
import ReactPlayer from "react-player";

import restClient from "../../../../util/restClient";
import NoImage from "../../../../Assets/Images/NoImage.svg";
import StarRating from "../components/StarRating";
import { formatDate } from "../helpers/dateFormatter";
import { checkURLType } from "../../../Admin/ProductManagement/helpers/checkFileType";
import DeleteModal from "../../../../Components/Custom/DeleteModal";
import Modal from "../../../../Components/Custom/Modal";
import SecondaryButton from "../../../../Components/Custom/SecondaryButton";
import InputField from "../../../../Components/Custom/InputField";
import { ToastContainer, toast } from "react-toastify";
import PrimaryButton from "../../../../Components/Custom/PrimaryButton";

function Header({ isLoader, productData, reviewsData }) {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userRegReducer?.userAuth);
  const [imagePreview, setImagePreview] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openSyncPopup, setOpenSyncPopup] = useState(false);
  const [message, setMessage] = useState("Hi, I am interested in your item.");
  const handleSendMessage = async () => {
    try {
      setIsLoading(true);
      console.log(userData?.token);
      const response = await restClient({
        method: "POST",
        url: `user/queries`,
        data: {
          message: message,
          productId: productData?._id,
        },
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      console.log(response);
      toast.success("Message sent successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setMessage("Hi, I am interested in your item.");
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
      setOpenSyncPopup(false);
    }
  };

  const handleProductDelete = async (productId) => {
    try {
      setIsLoading(true);
      const response = await restClient({
        method: "DELETE",
        url: `user/products/${productId}`,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      if (response?.status === 200) navigate("/product-management");
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setImagePreview(productData?.images?.[0] || NoImage);
  }, [productData]);

  return (
    <>
      {isModalOpen && (
        <DeleteModal
          headingText={"Delete Product"}
          subHeadingText={"Are you sure you want to delete this product?"}
          handleSubmit={() => handleProductDelete(productData?._id)}
          handleClose={() => setIsModalOpen(false)}
          isLoading={isLoading}
        />
      )}
      <Grid
        sx={{
          padding: {
            xs: "16px",
            lg: "36px",
          },
          boxSizing: "border-box",
        }}
        spacing={{ xs: 2, lg: 6 }}
        container
      >
        {isLoader ? (
          <Grid item xs={12}>
            <Stack minHeight={"200px"} alignItems={"center"} justifyContent={"center"}>
              <CircularProgress />
            </Stack>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                {checkURLType(imagePreview) === "image" && (
                  <>
                    <Stack
                      alignItems={"center"}
                      sx={{
                        borderRadius: "4px",
                        bgcolor: "white",
                        border: "1px solid #dddddd",
                        display: { xs: "none", md: "flex" },
                      }}
                    >
                      <Box
                        sx={{
                          width: { lg: "400px" },
                          height: { lg: "400px" },
                        }}
                      >
                        <ReactImageMagnify
                          {...{
                            smallImage: {
                              alt: "Product image",
                              isFluidWidth: true,
                              src: imagePreview,
                            },
                            largeImage: {
                              src: imagePreview,
                              width: 1400,
                              height: 1400,
                            },
                            className: "magnify_image_cont",
                            imageClassName: "small_magnify_image",
                            lensStyle: { backgroundColor: "rgba(0,0,0,.6)" },
                          }}
                        />
                      </Box>
                    </Stack>
                    <Box
                      sx={{
                        width: "100%",
                        height: { xs: "200px", lg: "400px" },
                        borderRadius: "4px",
                        bgcolor: "white",
                        border: "1px solid #dddddd",
                        display: { xs: "block", md: "none" },
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        src={imagePreview}
                        alt=""
                      />
                    </Box>
                  </>
                )}
                {checkURLType(imagePreview) === "video" && (
                  <Box sx={{ height: { xs: "200px", lg: "400px" } }}>
                    <ReactPlayer url={imagePreview} width="100%" height="100%" controls={true} />
                  </Box>
                )}

                {productData?.images?.length > 1 && (
                  <Stack sx={{ padding: "8px 0" }} direction={"row"} spacing={2}>
                    {productData?.images?.map((item, index) => {
                      if (checkURLType(item) === "image")
                        return (
                          <Box
                            width={{ xs: "60px", sm: "120px" }}
                            height={{ xs: "60px", sm: "120px" }}
                            sx={{
                              overflow: "hidden",
                              position: "relative",
                              borderRadius: "4px",
                              cursor: "pointer",
                            }}
                            key={index + "key"}
                            onClick={() => setImagePreview(item)}
                          >
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                              src={item}
                              alt=""
                            />
                          </Box>
                        );

                      if (checkURLType(item) === "video")
                        return (
                          <Box
                            width={{ xs: "60px", sm: "120px" }}
                            height={{ xs: "60px", sm: "120px" }}
                            sx={{
                              overflow: "hidden",
                              position: "relative",
                              borderRadius: "4px",
                            }}
                            key={index + "key"}
                          >
                            <ReactPlayer url={item} width="100%" height="100%" controls={true} />
                          </Box>
                        );
                    })}
                  </Stack>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <Stack>
                  <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <span style={{ fontSize: "24px", fontWeight: "500" }}>{productData["Product Name"] || ""}</span>
                    {userData?.user?._id === productData?.user?._id && (
                      <Stack direction={"row"} spacing={1}>
                        <EditIcon
                          sx={{ color: "#1B8A79", cursor: "pointer" }}
                          onClick={() => {
                            navigate(`/edit-product/${productData?._id}`);
                          }}
                        />
                        <DeleteIcon
                          sx={{ color: "#F78B8B", cursor: "pointer" }}
                          onClick={() => {
                            setIsModalOpen(true);
                          }}
                        />
                      </Stack>
                    )}
                  </Stack>
                  <span style={{ fontWeight: "300" }}>
                    ${productData["Rate"]}/{productData["Unit"]}
                  </span>
                </Stack>
                <Stack spacing={1} direction={"row"} alignItems={"center"}>
                  <StarRating size="small" name="read-only" value={reviewsData?.averageRating || 0} behavior="readOnly" />
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "300",
                      color: "#87929E",
                    }}
                  >
                    {reviewsData?.averageRating || 0}
                  </span>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "300",
                      color: "#87929E",
                    }}
                  >
                    {reviewsData?.totalReviews || 0} Reviews
                  </span>
                </Stack>
                <Box
                  sx={{
                    padding: "12px",
                    bgcolor: "white",
                    border: "1px solid #dddddd",
                  }}
                >
                  <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Box
                        sx={{
                          height: "64px",
                          width: "64px",
                          borderRadius: "50%",
                          overflow: "hidden",
                        }}
                      >
                        <img style={{ width: "100%" }} src={productData?.brand?.["Brand Logo"] || NoImage} alt="" />
                      </Box>
                      <Stack>
                        <span
                          style={{
                            fontWeight: "500",
                            textDecoration: "underline",
                            color: "#1B8A79",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate(`/brand-details/${productData?.brand?.username}`);
                          }}
                        >
                          {productData?.brand?.["Brand Name"] || ""}
                        </span>
                        <span style={{ fontWeight: "400" }}>{productData?.user?.fullname || ""}</span>
                        <span style={{ fontWeight: "300", fontSize: "14px" }}>Joined WeedX in {formatDate(productData?.user?.createdAt)}</span>
                      </Stack>
                    </Stack>
                    {/* <Box
                      sx={{
                        padding: "12px 24px",
                        borderRadius: "4px",
                        border: "1px solid #0000001A",
                        display: { xs: "none", md: "block" },
                      }}
                    > */}
                    {/* <span style={{ fontWeight: "500", fontSize: "14px" }}>Message Seller</span> */}

                    {userData?.user._id !== productData?.user?._id && (
                      <SecondaryButton onClick={() => setOpenSyncPopup(true)} color="black" fontSize={{ xs: "12px", sm: "14px" }}>
                        Message Seller
                      </SecondaryButton>
                    )}

                    <ToastContainer />
                    <Modal
                      open={openSyncPopup}
                      handleClose={() => {
                        setOpenSyncPopup(false);
                      }}
                      isLoading={isLoading}
                      headerText={"Message Seller"}
                      leftButton={"Cancel"}
                      handleSubmit={handleSendMessage}
                      rightButton={"Send"}
                      maxWidth="xs"
                      fullWidth
                    >
                      <TextareaAutosize
                        minRows="7"
                        placeholder="Write your message here"
                        style={{ width: "95%" }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </Modal>
                    {/* </Box> */}
                    {/* <Box
                      sx={{
                        padding: "10px 10px 6px 10px",
                        borderRadius: "4px",
                        border: "1px solid #0000001A",
                        display: { xs: "block", md: "none" },
                      }}
                    >
                      <DraftsOutlinedIcon sx={{ color: "#87929E", fontSize: "20px" }} />
                    </Box> */}
                  </Stack>
                </Box>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default Header;
