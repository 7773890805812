import { Box, Stack } from "@mui/material";

const getColor = (status) => {
  switch (status) {
    case "all":
      return {
        bgcolor: "#212B36",
        color: "#fff",
      };

    case "inactive":
      return {
        color: "#EE4C1A",
        bgcolor: "#FFECEB",
      };

    case "active":
      return {
        color: "#24B314",
        bgcolor: "#E6FFED",
      };

    case "draft":
      return {
        color: "#596570",
        bgcolor: "#F5F6F7",
      };

    case "featured":
      return {
        color: "#3323EE",
        bgcolor: "#ECEAFF",
      };

    default:
      return {};
  }
};

const getItemNumber = (data = [], itemName) => {
  console.log("data", data, itemName);
  if (itemName === "all") return data?.length;
  console.log("Featured", itemName, data?.filter((item) => item["Status"]?.toLowerCase() === itemName).length);
  return data?.filter((item) => item["Status"]?.toLowerCase() === itemName).length;
};

const formatCount = (count) => {
  if (count === 0) return 0;
  const suffixes = ["", "k", "M", "B", "T"];
  const suffixIndex = Math.floor(Math.log10(count) / 3);
  const formattedCount = count / Math.pow(10, suffixIndex * 3);

  // Check if the formatted count has decimal places
  const decimalCount = formattedCount % 1 === 0 ? 0 : 1;

  // If there are decimal places, return the formatted count with one decimal place
  if (decimalCount > 0) {
    return formattedCount.toFixed(1) + suffixes[suffixIndex];
  } else {
    return formattedCount + suffixes[suffixIndex];
  }
};

function Filter({ data, isActive, setIsActive, productCount }) {
  console.log("Filter", data, isActive);
  return (
    <Stack direction="row" spacing={{ xs: 1, sm: 3 }} sx={{ width: "100%", justifyContent: "space-between" }}>
      {["all", "inactive", "active", "draft", "featured"].map((item, i) => {
        return (
          <Stack
            key={i}
            direction="row"
            spacing={1}
            alignItems={"center"}
            sx={{
              borderBottom: item === isActive ? "2px solid #F78B8B" : "2px solid transparent",
              padding: "16px 0",
              cursor: "pointer",
            }}
            onClick={() => setIsActive(item)}
          >
            <Box
              component={"span"}
              sx={{
                fontWeight: "500",
                fontSize: { xs: "10px", sm: "14px" },
                color: item === isActive ? "#212B36" : "#87929E",
                textTransform: "capitalize",
                "&:hover": {
                  color: "#212B36",
                },
              }}
            >
              {item}
            </Box>
            <Stack
              // sx={{
              //   width: { xs: "12px", sm: "18px" },
              //   height: { xs: "12px", sm: "18px" },
              //   borderRadius: "50%",
              //   fontSize: { xs: "8px", sm: "11px" },
              //   fontWeight: "500",
              //   ...getColor(item),
              // }}
              alignItems={"center"}
              justifyContent={"center"}
              component={"span"}
              sx={{
                // width: { xs: '12px', sm: '18px' },
                width: "fit-content",
                height: { xs: "12px", sm: "18px" },
                paddingX: "4px",
                borderRadius: "20px",
                fontSize: { xs: "8px", sm: "11px" },
                fontWeight: "500",
                ...getColor(item),
              }}
            >
              {/* {getItemNumber(data, item)} */}
              {formatCount(productCount[item])}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}

export default Filter;
