import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as StatesIcon } from "../../../Assets/Icons/StatesIcon.svg";
import { ReactComponent as SalesIcon } from "../../../Assets/Icons/SalesIcon.svg";
import theme from "../../../theme";
import StateImage from "../../../Assets/Images/StatesImage.png";

export default function StatesSection() {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundImage: `url(${StateImage})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          height: "80vh",
          margin: { xs: "5rem 0rem", sm: "10rem 0rem 5rem 0rem" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundBlendMode: "multiply",
          backgroundColor: theme.palette.primary.dashboardBack,
        }}
      >
        <Grid
          container
          spacing={4}
          sx={{
            padding: { xs: "0rem 1.5rem 1.5rem 1.5rem", lg: "0 10rem" },
          }}
        >
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                boxShadow:
                  "0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.04)",
                background: "rgba(255, 255, 255, 0.90)",
                borderRadius: "4px",
                padding: {
                  xs: "40px",
                  sm: "60px",
                  md: "50px",
                  lg: "70px",
                },
              }}
            >
              <StatesIcon />
              <Typography
                sx={{
                  color: theme.palette.primary.peachRed,
                  fontSize: { xs: "35px", lg: "55px" },
                  my: 1,
                  fontWeight: theme.typography.heading1.fontWeight,
                }}
              >
                15+ States
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.primary.mediumGrey,
                  fontSize: { xs: "14px", lg: theme.typography.fontSize16 },
                  fontWeight: "300",
                  textAlign: "center",
                }}
              >
                Weed X is available in 15+ markets and actively expanding
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                boxShadow:
                  "0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.04)",
                background: "rgba(255, 255, 255, 0.90)",
                borderRadius: "4px",
                padding: {
                  xs: "40px",
                  sm: "60px",
                  md: "50px",
                  lg: "70px",
                },
              }}
            >
              <SalesIcon />
              <Typography
                sx={{
                  color: theme.palette.primary.peachRed,
                  fontSize: { xs: "35px", lg: "55px" },
                  my: 1,
                  fontWeight: theme.typography.heading1.fontWeight,
                }}
              >
                +80%
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.primary.mediumGrey,
                  fontSize: { xs: "14px", lg: theme.typography.fontSize16 },
                  fontWeight: "300",
                  textAlign: "center",
                }}
              >
                Increase in sales after adding products
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
