export function formatDateDifference(dateString) {
  const providedDate = new Date(dateString);
  const currentDate = new Date();

  const timeDifferenceInMilliseconds = currentDate - providedDate;
  const timeDifferenceInDays = Math.floor(
    timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24),
  );

  if (timeDifferenceInDays === 0) return "Today";
  if (timeDifferenceInDays === 1) return "1 day ago";

  return `${timeDifferenceInDays} days ago`;
}

export function formatDate(inputDateString) {
  if (!inputDateString) return null;

  const inputDate = new Date(inputDateString);

  const day = String(inputDate.getUTCDate()).padStart(2, "0");
  const month = String(inputDate.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = inputDate.getUTCFullYear();

  return `${month}/${day}/${year}`;
}
