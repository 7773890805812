import { Box, Grid } from "@mui/material";
import { height } from "@mui/system";

function BoxWrapperV2({ heading, children, width, padding }) {
  return (
    <Box
      sx={{
        padding: padding || "32px",
        bgcolor: "#fff",
        borderRadius: "4px",
        width: width || "auto",
      }}
    >
      <h2
        style={{
          color: "#2E3A48",
          fontSize: "18px",
          fontWeight: "500",
          margin: "0",
        }}
      >
        {heading}
      </h2>
      <Box sx={{ marginTop: "12px" }}>{children}</Box>
    </Box>
  );
}

export default BoxWrapperV2;
