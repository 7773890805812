import { MenuItem, Select } from "@mui/material";
import React from "react";

function CustomSelect({ placeholder, options, productData, setFormData }) {
  return (
    <Select
      color="success"
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
      value={productData.productBrand || ""}
      onChange={(e) =>
        setFormData((prev) => ({
          ...prev,
          product: { ...prev.product, productBrand: e.target.value },
        }))
      }
      sx={{
        fontSize: "14px",
        color: "#87929E",
        width: "100%",
        height: "40px",
      }}
      placeholder={placeholder}
    >
      {options.map((option, key) => (
        <MenuItem key={key + "key"} value={option._id}>
          {option.brandName}
        </MenuItem>
      ))}
    </Select>
  );
}

export default CustomSelect;
