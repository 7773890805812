import React, { useState } from "react";
import { Box, Stack } from "@mui/material";

import messageIcon from "../../../../../Assets/Icons/chatTwo.svg";
import dummyUser from "../../../../../Assets/Images/DemoUser.svg";
import ChatPreview from "./ChatPreview";

const chatListDummy = [
  {
    id: 1,
    name: "Linda Martin",
    lastMessage: "Yah, nice design",
    lastMessageTime: "12:30 AM",
    unreadMessages: 8,
    imageURL: dummyUser,
  },
  {
    id: 2,
    name: "Daniel Dreher",
    lastMessage: "Wow really Cool 🔥",
    lastMessageTime: "Yesterday",
    unreadMessages: 1,
    imageURL: dummyUser,
  },
  {
    id: 3,
    name: "Elizabeth Sutter",
    lastMessage: "Hello Everyone",
    lastMessageTime: "03/24/2023",
    unreadMessages: 0,
    imageURL: dummyUser,
  },
];

function Sidebar() {
  const [chatList, setChatList] = useState(chatListDummy);
  const [selectedChat, setSelectedChat] = useState(chatListDummy[0]);

  return (
    <Stack
      sx={{
        width: "40%",
        borderRight: "1px solid #0000000D",
      }}
    >
      <Stack direction={"row"} spacing={1} sx={{ padding: "20px" }}>
        <img src={messageIcon} alt="" />
        <Box
          sx={{
            fontSize: "14px",
            fontWeight: "300",
            color: "#596570",
          }}
        >
          All messages
        </Box>
      </Stack>
      <ChatPreview
        chatList={chatList}
        selectedChat={selectedChat}
        setSelectedChat={setSelectedChat}
      />
    </Stack>
  );
}

export default Sidebar;
