import { Box, CircularProgress, Stack } from "@mui/material";

function CustomSecondaryButton({ onClick, children, isLoading, disabled }) {
  return (
    <button
      style={{
        fontFamily: "inherit",
        width: "100%",
        outline: "none",
        height: "40px",
        color: disabled ? "#E0E0E0" : "#1B8A79",
        backgroundColor: "white",
        fontWeight: "500",
        letterSpacing: "0.5px",
        borderRadius: "4px",
        cursor: "pointer",
        border: `1px solid ${disabled ? "#E0E0E0" : "#1B8A79"}`,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        spacing={2}
        justifyContent={"center"}
      >
        <Box>{children}</Box>
        {isLoading && <CircularProgress size={20} sx={{ color: "green" }} />}
      </Stack>
    </button>
  );
}

export default CustomSecondaryButton;
