import { useState, useRef, useEffect } from "react";
import { Box } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popup from "../../../../Components/Custom/Popup";
import theme from "../../../../theme";

function PrimaryPopup({ popupValues, card, onClickHandler }) {
  const popupRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  return (
    <Box ref={popupRef} sx={{ position: "relative" }}>
      <MoreVertIcon
        sx={{
          fontSize: "20px",
          color: theme.palette.primary.black,
          cursor: "pointer",
          "&:hover": {
            color: theme.palette.primary.main,
          },
        }}
        onClick={() => {
          setShowPopup((prev) => !prev);
        }}
      />

      {showPopup && (
        <Popup>
          {popupValues?.map((value, index) => (
            <Box
              component={"span"}
              key={index}
              onClick={() => {
                setShowPopup(false);
                onClickHandler(card, value.action);
              }}
              sx={{
                cursor: "pointer",
                fontSize: "12px",
                fontWeight: "400",
                "&:hover": {
                  color: theme.palette.primary.white,
                  backgroundColor: theme.palette.primary.main,
                },
                width: "100%",
                margin: "0px !important",
                padding: "6px 0",
                textAlign: "left",
              }}
            >
              <Box component={"span"} sx={{ marginLeft: "6px" }}>
                {value?.label}
              </Box>
            </Box>
          ))}
        </Popup>
      )}
    </Box>
  );
}

export default PrimaryPopup;
