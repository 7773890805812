import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Box, Typography } from "@mui/material";
import PrimaryHeading from "../../../Components/Custom/PrimaryHeading";
import theme from "../../../theme";
import { ReactComponent as ArrowLeftSlider } from "../../../Assets/Icons/ArrowLeft.svg";
import { ReactComponent as ArrowRightSlider } from "../../../Assets/Icons/ArrowRight.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { removeQueryParameters } from "../../../util/functions";
import restClient from "../../../util/restClient";

function FeatureBrands(props) {
  const [publicBrands, setPublicBrands] = useState([]);
  //const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 3000, min: 768 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  useEffect(() => {
    const getPublicBrands = async () => {
      try {
        // const response = await axios.get(
        //   `https://staging.weedxhub.com/api/admin/scrap/brands?page=${1}&pageSize=${20}`
        // );
        const response = await restClient({
          method: "GET",
          url: `/admin/scrap/brands?page=${1}&pageSize=${20}`,
        });
        console.log(response, "public brands response");
        if (response?.status === 200) {
          setPublicBrands([...response.data.data.documents]);
          //setPageCount(Math.ceil(response?.data.data.total / pageSize));
        }
      } catch (err) {
        console.log(err);
      }
    };

    getPublicBrands();
  }, []);

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    // const {
    //   carouselState: { currentSlide },
    // } = rest;
    return (
      <>
        <Box
          sx={{
            position: { lg: "absolute" },
            top: "11px",
            right: { lg: 0 },
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "center", lg: "start" },
            columnGap: "20px",
            margin: { xs: "2rem", lg: "0rem" },
          }}
        >
          <Box
            onClick={() => previous()}
            sx={{
              width: "40px",
              height: "40px",
              backgroundColor: theme.palette.primary.white,
              border: "1px solid lightgrey",
              borderRadius: "128px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <ArrowLeftSlider />
          </Box>
          <Box
            sx={{
              width: "40px",
              height: "40px",
              backgroundColor: theme.palette.primary.white,
              border: "1px solid lightgrey",
              borderRadius: "128px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => next()}
          >
            <ArrowRightSlider />
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          margin: { xs: "1.5rem", lg: "0 10rem" },
        }}
      >
        <Box
          mt={{ xs: 4, lg: 12 }}
          mb={4}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            alignItems: "center",
          }}
        >
          <PrimaryHeading>Featured brands</PrimaryHeading>
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: theme.typography.fontSize,
              fontWeight: "300",
              ml: 1,
              mt: 1,
              textDecoration: "underline",
            }}
            onClick={() => navigate("/brands")}
          >
            Discover More Brands
          </Typography>
        </Box>

        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroup />}
          arrows={false}
        >
          {publicBrands &&
            publicBrands.length > 0 &&
            publicBrands.map((brand) => (
              <Box
                key={brand?._id}
                sx={{
                  width: { xs: "148px", md: "184px" },
                  height: { xs: "148px", md: "184px" },
                  boxShadow:
                    "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "4px",
                  }}
                  src={removeQueryParameters(brand?.["Brand Logo"])}
                  alt=""
                ></img>
              </Box>
            ))}
        </Carousel>
      </Box>
    </>
  );
}

export default FeatureBrands;
