import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Box, CircularProgress, Grid, Pagination, Stack } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import MenuIcon from "@mui/icons-material/Menu";

import restClient from "../../../../util/restClient";
import PrimaryButton from "../../../../Components/Custom/PrimaryButton";
import SearchInputWithIcon from "../../../../Components/Custom/SearchInput";
import DispensaryCard from "./components/DispensaryCard";
import SideBarLayout from "../../../../SharedComponents/SideBarLayout";
import heroSubTabBannerGreen from "../../../../Assets/Images/heroSubTabBannerGreen.png";
import { ReactComponent as LogoWhite } from "../../../../Assets/Icons/LogoWhite.svg";

function DispensaryMangement() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userRegReducer?.userAuth);
  const [dispensaries, setDispensaries] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [open, setOpen] = useState(false);
  const [loder, setLoder] = useState(false);

  const getDispensaries = useCallback(
    async (keyword) => {
      try {
        const url = keyword
          ? `/user/dispensaries/?page=${page}&limit=3&name=${keyword}`
          : `/user/dispensaries?page=${page}&limit=3`;

        if (keyword) setPage(1);
        setLoder(true);
        const response = await restClient({
          method: "GET",
          url: url,
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        });

        setTotalPages(Math.ceil(response?.data?.data?.total / 3));
        setDispensaries(response?.data?.data?.brands || []);
      } catch (error) {
        console.log(error);
      } finally {
        setLoder(false);
      }
    },
    [userData?.token],
  );

  const deleDispensary = async (dispensaryID) => {
    try {
      setLoder(true);
      const response = await restClient({
        method: "DELETE",
        url: `/user/dispensaries/${dispensaryID}`,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      if (response.status === 200) {
        toast.success(`${response.data.message}`);
        getDispensaries();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoder(false);
    }
  };

  useEffect(() => {
    getDispensaries();
  }, [page]);

  return (
    <Stack direction={{ xs: "column", md: "row" }}>
      <ToastContainer />
      <Box
        sx={{
          display: { xs: "none", md: "inline-flex" },
          height: "100vh",
          width: { xs: "75%", sm: "40%", md: "30%", lg: "20%" },
        }}
      >
        <SideBarLayout />
      </Box>
      <Box sx={{ display: { md: "none" } }}>
        {open && <SideBarLayout setOpen={setOpen} />}
        <Grid
          item
          container
          sx={{
            padding: { xs: "1.5rem", sm: "1.5rem 2rem", lg: "0 10rem" },
            background: `url(${heroSubTabBannerGreen})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            display: { md: "none" },
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: { md: "none" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
            container
          >
            <Link to={"/"}>
              <LogoWhite />
            </Link>
            <Box
              sx={{ cursor: "pointer", display: { md: "none" } }}
              onClick={() => {
                setOpen(true);
                document.body.style.overflow = "hidden";
              }}
            >
              <MenuIcon
                sx={{ color: "white", width: "25px", height: "25px" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ width: "100%" }}>
        <Stack
          direction={{ md: "row", sm: "column" }}
          justifyContent={{ md: "space-between", sm: "center" }}
          alignItems="center"
          sx={{
            padding: "20px 36px",
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              fontSize: { md: "24px", xs: "18px" },
              fontWeight: 500,
              marginBottom: {
                xs: "10px",
                md: "0px",
              },
            }}
          >
            Dispensary Management
          </Box>
          <PrimaryButton
            onClick={() => navigate("/add-new-dispensary")}
            icon={<AddIcon />}
          >
            Add Dispensary
          </PrimaryButton>
        </Stack>

        <Stack sx={{ padding: { xs: "24px", md: "16px 36px" } }}>
          <Box
            sx={{
              overflow: "hidden",
              border: "1px solid lightgrey",
              borderRadius: "4px",
              padding: "2px 4px",
              bgcolor: "#fff",
              marginBottom: { xs: "24px" },
            }}
          >
            <SearchInputWithIcon
              placeholder="Search products"
              callbackFunction={getDispensaries}
            />
          </Box>
          {loder ? (
            <Stack
              sx={{ minHeight: "400px" }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CircularProgress />
            </Stack>
          ) : (
            <Grid
              container
              spacing={{ md: 5, xs: 3 }}
              sx={{ padding: { xs: "10px", lg: "20px" } }}
              minHeight={"200px"}
            >
              {dispensaries?.map((dispensary) => (
                <Grid key={dispensary._id} item xs={12} sm={6} lg={4}>
                  <DispensaryCard
                    brand={dispensary}
                    popupValues={[
                      {
                        title: "View",
                        callbackFunction: (dispensaryID) => {
                          navigate(`/dispensary-detail/${dispensaryID}`);
                        },
                      },
                      {
                        title: "Edit ",
                        callbackFunction: (dispensaryID) => {
                          navigate(`/edit-dispensary/${dispensaryID}`);
                        },
                      },
                      {
                        title: "Delete",
                        callbackFunction: (dispensaryID) => {
                          deleDispensary(dispensaryID);
                        },
                      },
                    ]}
                  />
                </Grid>
              ))}
            </Grid>
          )}
          {dispensaries.length === 0 && !loder && (
            <Stack alignItems={"center"} justifyContent={"center"}>
              No Dispensaries Found
            </Stack>
          )}
          {(dispensaries.length !== 0 || loder) && (
            <Box sx={{ marginLeft: "auto", width: "fit-content" }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={(e, value) => {
                  setPage(value);
                }}
              />
            </Box>
          )}
        </Stack>
      </Box>
    </Stack>
  );
}

export default DispensaryMangement;
