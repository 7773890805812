import { Grid, Typography } from "@mui/material";
import theme from "../../../theme";
import AboutUsImage from "../../../Assets/Images/StatesImage.png";

export default function WhatWeDoSection() {
  return (
    <>
      <Grid
        container
        sx={{
          padding: { xs: "1.5rem", sm: "2rem", lg: "5rem 10rem" },
          background: theme.palette.primary.white,
          mb: { xs: 4, lg: 8 },
        }}
      >
        <Grid item xs={12} sm={6}>
          <Typography
            sx={{
              fontSize: { xs: "36px", sm: "30px", lg: "40px" },
              textAlign: { xs: "center", sm: "left" },
              fontWeight: "600",
              color: theme.palette.primary.black,
            }}
          >
            What We Do
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: "14px",
                sm: "12px",
                lg: theme.typography.fontSize16,
              },
              color: theme.palette.primary.black,
              mt: 4,
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            Welcome to Weed X , the leading Cannabis SaaS platform that
            revolutionizes the way growers, processors, and dispensaries connect
            and thrive in the cannabis industry.
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: "14px",
                sm: "12px",
                lg: theme.typography.fontSize16,
              },
              color: theme.palette.primary.mediumGrey,
              mt: { xs: 1, lg: 4 },
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            Our platform combines cutting-edge technology with a social
            networking approach to foster collaboration, promote transparency,
            and empower businesses to make informed decisions.
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: "14px",
                sm: "12px",
                lg: theme.typography.fontSize16,
              },
              color: theme.palette.primary.mediumGrey,
              p: 3,
              mt: { xs: 2, lg: 6 },
              borderLeft: "2px solid" + theme.palette.primary.main,
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            With our innovative features , we aim to provide a seamless
            experience for cannabis professionals to showcase their products,
            connect with like-minded individuals, and stay informed about
            industry trends.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img src={`${AboutUsImage}`} alt="" style={{ width: "100%" }} />
        </Grid>
      </Grid>
    </>
  );
}
