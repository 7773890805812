import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import PrimaryHeading from "../../../Components/Custom/PrimaryHeading";
import LoginImage from "../../../Assets/Images/Login.png";
import theme from "../../../theme";
import InputFieldLabel from "../../../Components/Custom/InnputFieldLabel";
import InputField from "../../../Components/Custom/InputField";
import CustomCheckbox from "../../../Components/Custom/CheckBox";
import { Link } from "react-router-dom";
import SecondaryButton from "../../../Components/Custom/SecondaryButton";
import { ReactComponent as GoogleIcon } from "../../../Assets/Icons/GoogleIcon.svg";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import InputPassword from "../../../Components/Custom/InputPassword";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { validationSchema, initialValues } from "../LoginIntegration/loginValidation";
import {
  userAsyncLogin,
  //verifyAsyncOTP,
} from "../SignUpIntegration/userRegSlice";
import { ToastContainer, toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import HamburgerMenu from "../../../Components/Custom/HamburgerMenu";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as LogoWhite } from "../../../Assets/Icons/LogoWhite.svg";
import heroSubTabBanner from "../../../Assets/Images/heroSubTabBanner.png";
import Step2Content from "../SignUp/Step2Content";
import Step3Content from "../SignUp/Step3Content";
import Step5Content from "../SignUp/Step5Content";
import Step6Content from "../SignUp/Step6Content";

export default function Login({ activeStep, setActiveStep, handleNext, handleBack, ...props }) {
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);
  const selectUserAuth = (state) => state.userRegReducer?.userAuth;
  const userData = useSelector(selectUserAuth);

  console.log(userData, "userData");

  const dispatch = useDispatch();

  const login = useGoogleLogin({
    onSuccess: async (respose) => {
      console.log("resp", respose);
      try {
        const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `Bearer ${respose.access_token}`,
          },
        });
        console.log("res In OnSuccess", res.data);
        const values = {
          email: res.data.email,
        };
        dispatch(userAsyncLogin(values)).then((res) => {
          console.log("then", res);
          if (res?.payload?.success) {
            toast.success(`${res?.payload?.message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(`${res.payload.message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });
      } catch (err) {
        console.log(err);
        toast.error(`${err}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
  });

  console.log(activeStep, "activeStep");

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(userAsyncLogin(values)).then((res) => {
        console.log("then", res);
        if (res?.payload?.data?.method === "E_USER_NOTVERIFIED") {
          //setCurrentScreen("verify-otp");
          setActiveStep(1);
        } else if (res?.payload?.data?.method === "E_METRC_INCOMPLETE") {
          //setCurrentScreen("verify-business");
          setActiveStep(2);
        } else if (res?.payload?.data?.method === "E_SUBSCRIPTION_REQUIRED") {
          setActiveStep(3);
        }
        if (res.payload.success) {
          toast.success(`${res.payload.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          //navigate("/my-account");
        } else {
          toast.error(`${res.payload.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    },
  });

  const stepContent = ["", "Confirm your email", "Facility Metrc API keys", "Order confirmation"];

  const getStepContent = (step) => {
    switch (step) {
      case 1:
        return (
          <Step2Content
            handleNext={handleNext}
            activeStep={activeStep}
            //steps={steps}
          />
        );
      case 2:
        return (
          <Step3Content
            handleNext={handleNext}
            activeStep={activeStep}
            //steps={steps}
          />
        );
      case 3:
        return (
          <Step5Content
            handleNext={handleNext}
            activeStep={activeStep}
            //steps={steps}
          />
        );
      case 4:
        return (
          <Step6Content
            handleNext={handleNext}
            activeStep={activeStep}
            //steps={steps}
          />
        );
      default:
        return;
    }
  };

  return (
    <>
      <Grid container>
        <ToastContainer />
        {openHamburgerMenu && <HamburgerMenu onClose={() => setOpenHamburgerMenu(false)} />}
        <Grid
          item
          container
          sx={{
            padding: { xs: "1.5rem", sm: "0rem 3rem", lg: "0 10rem" },
            background: `url(${heroSubTabBanner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: { sm: "none" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
            container
          >
            <Link to={"/"}>
              <LogoWhite />
            </Link>
            <Box onClick={() => setOpenHamburgerMenu(true)}>
              <MenuIcon sx={{ color: "white", width: "25px", height: "25px" }} />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={6} sx={{ display: { xs: "none", sm: "inline" } }}>
          <Box
            sx={{
              background: `url(${props.backImage ? props.backImage : LoginImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "100vh",
              position: "relative",
              width: "100%",
              backgroundPositionY: "bottom",
            }}
          >
            <Box sx={{ padding: 2, position: "absolute", top: 0, zIndex: 100 }}>
              <Link to={"/"}>
                <LogoWhite />
              </Link>
            </Box>
            <Box sx={{ position: "absolute", bottom: 0, padding: 2 }}>
              <Typography
                sx={{
                  color: theme.palette.primary.white,
                  fontSize: { xs: "12px", lg: theme.typography.fontSize },
                  fontWeight: "300",
                }}
              >
                © Copyright 2024, All Rights Reserved by WeedX
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            overflow: "auto",
          }}
        >
          {activeStep === 0 && (
            <form>
              <Box
                sx={{
                  px: { xs: 3, sm: 5, md: 10, lg: 25 },
                  py: 5,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "80vh",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <PrimaryHeading color={theme.palette.primary.inputLabelColor} fontSize={{ xs: "28px" }} fontWeight="600">
                    Log in
                  </PrimaryHeading>
                  <Typography
                    sx={{
                      color: theme.palette.primary.mediumGrey,
                      fontSize: theme.typography.fontSize16,
                      mb: 3,
                    }}
                  >
                    Sign in to your account
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <Box>
                    <InputFieldLabel>Email</InputFieldLabel>
                    <InputField
                      background="white"
                      placeholder="Enter your Email"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      error={formik.errors.email ? true : false}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          formik.handleSubmit();
                        }
                      }}
                    />
                    {formik.touched.email && formik.errors.email && <div className="error">{formik.errors.email}</div>}
                  </Box>
                  <Box mt={{ xs: 2 }}>
                    <InputFieldLabel>Password</InputFieldLabel>
                    <InputPassword
                      background="white"
                      placeholder="Enter your password"
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      error={formik.errors.password ? true : false}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          formik.handleSubmit();
                        }
                      }}
                    />
                    {formik.touched.password && formik.errors.password && <div className="error">{formik.errors.password}</div>}
                  </Box>

                  <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mt={2}>
                    <Box display={"flex"} alignItems={"center"}>
                      <CustomCheckbox />
                      <Typography
                        sx={{
                          color: theme.palette.primary.mediumGrey,
                          fontSize: theme.typography.fontSize,
                        }}
                      >
                        Remember me
                      </Typography>
                    </Box>
                    <Link
                      style={{
                        color: theme.palette.primary.main,
                        fontSize: theme.typography.fontSize,
                        textDecoration: "none",
                      }}
                      to="/forgot-password"
                    >
                      Forgot Password
                    </Link>
                  </Box>

                  <PrimaryButton width="100%" margin={4} onClick={formik.handleSubmit} fontWeight="500" fontSize="14px" textTransform="none">
                    Log in
                  </PrimaryButton>
                  {/* 
                  <SecondaryButton
                    icon={<GoogleIcon />}
                    text="Continue with Google"
                    width="100%"
                    margin={2}
                    bordersColor="1px solid lightGrey !important"
                    background="white"
                    fontWeight="500"
                    textTransform="none"
                    color={theme.palette.primary.black}
                    fontSize="14px"
                    onClick={login}
                  >
                    Continue with Google
                  </SecondaryButton> */}

                  <Box display={"flex"} justifyContent={"center"} mt={3}>
                    <Typography
                      sx={{
                        color: theme.palette.primary.mediumGrey,
                        fontSize: theme.typography.fontSize,
                      }}
                    >
                      Don’t have an account?
                      <Link
                        style={{
                          color: theme.palette.primary.main,
                          paddingLeft: "10px",
                          textDecoration: "none",
                        }}
                        to="/signup"
                      >
                        Register
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </form>
          )}
          {activeStep !== 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                px: { xs: 3, sm: 5, md: 10 },
                py: 5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <PrimaryHeading color={theme.palette.primary.inputLabelColor} fontSize={{ xs: "28px" }} fontWeight="600">
                  {stepContent[activeStep]}
                </PrimaryHeading>
              </Box>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                {getStepContent(activeStep)}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
}
