import React from "react";
//import BasicTabs from "../../../../Components/Custom/Tabs";
import { Box, Grid, Stack, Typography } from "@mui/material";
import InputFieldLabel from "../../../../Components/Custom/InnputFieldLabel";
import InputField from "../../../../Components/Custom/InputField";
import PrimaryButton from "../../../../Components/Custom/PrimaryButton";
import theme from "../../../../theme";
import { useFormik } from "formik";
import { validationCardSchema } from "../StoreFrontValidations/storeFrontSettingValidations";
import axios from "axios";
import { useSelector } from "react-redux";
import restClient from "../../../../util/restClient";
import HttpsIcon from "@mui/icons-material/Https";
import AmericanExpress from "../../../../Assets/Images/AmericanExpress.png";
import UnionPay from "../../../../Assets/Images/UnionPay.png";
import Visa from "../../../../Assets/Images/Visa.png";
import MasterCard from "../../../../Assets/Images/MasterCard.png";

// function Monthly() {
//   return (
//     <>
//       <Box mt={3}>
//         <InputFieldLabel marginTop={"25px"}>Card number</InputFieldLabel>
//         <InputField background="white" placeholder="1421 5235 1251 1512" />

//         <InputFieldLabel marginTop={"25px"}>Expiration date</InputFieldLabel>
//         <InputField background="white" placeholder="12/2024" />

//         <InputFieldLabel marginTop={"25px"}>Name on card</InputFieldLabel>
//         <InputField background="white" placeholder="Sean Thomas" />

//         <InputFieldLabel marginTop={"25px"}>Secure code</InputFieldLabel>
//         <InputField background="white" placeholder="Enter your full name" />
//       </Box>
//       <Box>
//         <Typography
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             flexDirection: "column",
//             background: theme.palette.primary.dashboardBack,
//             p: 3,
//             my: 2,
//             color: theme.palette.primary.mediumGrey,
//             fontSize: theme.typography.fontSize,
//           }}
//         >
//           Your card will be charged on 22-07-23
//           <Typography
//             sx={{
//               color: theme.palette.primary.peachRed,
//               fontSize: theme.typography.fontSize,
//               fontWeight: theme.typography.heading1.fontWeight,
//             }}
//           >
//             $150/month
//           </Typography>
//         </Typography>
//       </Box>
//       <Box>
//         <PrimaryButton width={"100%"}>Save Payment Method</PrimaryButton>
//       </Box>
//     </>
//   );
// }

// function Annual() {
//   return (
//     <>
//       <Box mt={3}>
//         <InputFieldLabel marginTop={"25px"}>Card number</InputFieldLabel>
//         <InputField background="white" placeholder="1421 5235 1251 1512" />

//         <InputFieldLabel marginTop={"25px"}>Expiration date</InputFieldLabel>
//         <InputField background="white" placeholder="12/2024" />

//         <InputFieldLabel marginTop={"25px"}>Name on card</InputFieldLabel>
//         <InputField background="white" placeholder="Sean Thomas" />

//         <InputFieldLabel marginTop={"25px"}>Secure code</InputFieldLabel>
//         <InputField background="white" placeholder="Enter your full name" />
//       </Box>
//       <Box>
//         <Typography
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             flexDirection: "column",
//             background: theme.palette.primary.dashboardBack,
//             p: 3,
//             my: 2,
//             color: theme.palette.primary.mediumGrey,
//             fontSize: theme.typography.fontSize,
//           }}
//         >
//           Your card will be charged on 22-07-23
//           <Typography
//             sx={{
//               color: theme.palette.primary.peachRed,
//               fontSize: theme.typography.fontSize,
//               fontWeight: theme.typography.heading1.fontWeight,
//             }}
//           >
//             $1998/year
//           </Typography>
//         </Typography>
//       </Box>
//       <Box>
//         <PrimaryButton width={"100%"}>Save Payment Method</PrimaryButton>
//       </Box>
//     </>
//   );
// }

export default function AddPaymentMethod({ handleClose, getCardDetails }) {
  const selectUserAuth = (state) => state.userRegReducer?.userAuth;
  const userData = useSelector(selectUserAuth);
  // const tabs = [
  //   {
  //     label: "Monthly",
  //     content: <Monthly />,
  //   },
  //   {
  //     label: "Annual",
  //     content: <Annual />,
  //   },
  // ];
  const formatExpDate = (input) => {
    let expDateInput = input.replace(/\D/g, ""); // Remove non-numeric characters

    // Format the CVV to MM/YY
    if (expDateInput.length > 2) {
      expDateInput = `${expDateInput.slice(0, 2)}/${expDateInput.slice(2, 4)}`;
    }

    return expDateInput;
  };

  const handleExpDateChange = (event) => {
    const input = event.target.value;

    if (event.nativeEvent.inputType === "deleteContentBackward") {
      // Handle backspace key
      formik.setFieldValue("expDate", input);
      //field.onChange(event);
      return;
    }

    const formattedDate = formatExpDate(input);

    formik.setFieldValue("expDate", formattedDate);
    //field.onChange(event);
  };
  const formik = useFormik({
    initialValues: {
      cardNumber: "",
      expDate: "",
      nameOnCard: "",
      secureCode: "",
    },
    validationSchema: validationCardSchema,
    onSubmit: async (values) => {
      console.log(values, "values");

      try {
        // const response = await axios.post(
        //   "https://staging.weedxhub.com/api/user/add-payment-profile",
        //   {
        //     data: {
        //       ...values,
        //     },
        //   },
        //   { headers: { Authorization: `Bearer ${userData?.token}` } }
        // );
        const response = await restClient({
          method: "POST",
          url: "/user/add-payment-profile",
          data: {
            data: {
              ...values,
            },
          },
          headers: { Authorization: `Bearer ${userData?.token}` },
        });

        if (response) {
          handleClose();
          getCardDetails();
        }
        console.log(response, "add card response");
      } catch (err) {
        console.log(err);
      }
    },
  });

  // const getCardDetails = async () => {
  //   try {
  //     // const response = await axios.get(
  //     //   "https://staging.weedxhub.com/api/user/get-customer-profile",
  //     //   { headers: { Authorization: `Bearer ${userData?.token}` } }
  //     // );
  //     const response = await restClient({
  //       method: "GET",
  //       url: "/user/get-customer-profile",
  //       headers: { Authorization: `Bearer ${userData?.token}` },
  //     });
  //     console.log(response, "subscriptions");
  //     let cardData = [];
  //     if (response?.data?.success) {
  //       const res = response?.data?.data?.paymentProfile?.paymentProfiles;
  //       console.log(res, "res");
  //       for (let i = 0; i < res.length; i++) {
  //         let card = {};
  //         card = {
  //           ...res[i]?.payment?.creditCard,
  //           name: res[i]?.billTo?.firstName + " " + res[i]?.billTo?.lastName,
  //         };
  //         cardData.push(card);

  //         console.log(card, "card");
  //       }
  //     }

  //     setCardDetails([...cardData]);

  //     console.log(cardData, "cardData");
  //     console.log(response, "card details api");
  //   } catch (err) {
  //     console.log(err.response);
  //   }
  // };
  return (
    <Box>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          rowGap: "20px",
        }}
      >
        <Grid item xs={12}>
          <img src="https://verify.authorize.net/anetseal/images/secure90x72.gif" alt="authorize.net logo" />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{
            marginBottom: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={3} lg={2}>
            <img
              // src="https://www.authorize.net/_jcr_content/root/container/responsivegrid/contentwell_copy_cop/contentWell/columncontrol/par2/contentwell_67198405/contentWell/contentwell/contentWell/imagecontainer/images/contentwell_929033021/contentWell/image_copy_202153645.img.png/1700067762940.png"
              src={AmericanExpress}
              alt="american-express"
              width="70px"
            />
          </Grid>

          <Grid item xs={3} lg={2}>
            <img
              // src="https://www.authorize.net/_jcr_content/root/container/responsivegrid/contentwell_copy_cop/contentWell/columncontrol/par2/contentwell_67198405/contentWell/contentwell_copy/contentWell/imagecontainer/images/contentwell_copy/contentWell/image_copy.img.png/1700067746976.png"
              src={UnionPay}
              alt="union-pay"
              width="70px"
            />
          </Grid>

          <Grid item xs={3} lg={2}>
            <img
              // src="https://www.authorize.net/_jcr_content/root/container/responsivegrid/contentwell_copy_cop/contentWell/columncontrol/par2/contentwell_67198405/contentWell/contentwell_copy/contentWell/imagecontainer/images/contentwell_copy_1558768459/contentWell/image_1738459615_cop.img.png/1700087749780.png"
              src={Visa}
              alt="visa"
              width="70px"
            />
          </Grid>

          <Grid item xs={3} lg={2}>
            <img
              // src="https://www.authorize.net/_jcr_content/root/container/responsivegrid/contentwell_copy_cop/contentWell/columncontrol/par2/contentwell_67198405/contentWell/contentwell_copy/contentWell/imagecontainer/images/contentwell_copy_117/contentWell/image_copy_656778450.img.png/1700067764762.png"
              src={MasterCard}
              alt="mastercard"
              width="70px"
            />
          </Grid>
        </Grid>
      </Grid>
      <form>
        <div>
          <InputFieldLabel margin="1.5vw">Name on card</InputFieldLabel>
          <InputField
            background="white"
            placeholder="Sean Thomas"
            name="nameOnCard"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.nameOnCard}
          />
          {formik.touched.nameOnCard && formik.errors.nameOnCard && <div className="error">{formik.errors.nameOnCard}</div>}
        </div>
        <div>
          <InputFieldLabel margin="1.5vw">Card number</InputFieldLabel>
          <InputField
            background="white"
            placeholder="1421 5235 1251 1512"
            name="cardNumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cardNumber}
          />
          {formik.touched.cardNumber && formik.errors.cardNumber && <div className="error">{formik.errors.cardNumber}</div>}
        </div>
        <div>
          <InputFieldLabel margin="1.5vw">Expiration date</InputFieldLabel>
          <InputField
            background="white"
            placeholder="12/24"
            name="expDate"
            onChange={handleExpDateChange}
            onBlur={formik.handleBlur}
            value={formik.values.expDate}
          />
          {formik.touched.expDate && formik.errors.expDate && <div className="error">{formik.errors.expDate}</div>}
        </div>

        <div>
          <InputFieldLabel margin="1.5vw">CVV</InputFieldLabel>
          <InputField
            background="white"
            placeholder="* * *"
            name="secureCode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.secureCode}
          />
          {formik.touched.secureCode && formik.errors.secureCode && <div className="error">{formik.errors.secureCode}</div>}
        </div>
        <PrimaryButton width="100%" fontSize="14px" fontWeight="500" margin="1rem" onClick={formik.handleSubmit}>
          Continue
        </PrimaryButton>
        <Stack
          marginTop={"10px"}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={0.6}
          sx={{ color: "#555555", fontSize: "14px", fontWeight: "500" }}
        >
          <HttpsIcon sx={{ fontSize: "16px" }} />
          <Box>SSL Secure Payment</Box>
        </Stack>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            color: theme.palette.primary.inputTextColor,
            textAlign: "center",
            margin: "10px 0",
          }}
        >
          You can cancel anytime
        </Typography>
      </form>
    </Box>
  );
}
