import { useState } from "react";
import { Box, Stack } from "@mui/material";
import Modal from "../../../../../../Components/Custom/Modal";
import { AddImagesContent } from "../../../../../Admin/ProductManagement/components/boxContent";
import addImage from "../../../../../../Assets/Images/AddProduct/addImage.svg";
import addSmallImage from "../../../../../../Assets/Images/AddProduct/addSmallImage.svg";
import deleteImage from "../../../../../../Assets/Images/AddProduct/deleteImage.svg";

function AddImagePopup({ open, handleClose, imagesList, setImagesList }) {
  const [imageHovered, setImageHovered] = useState("");

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagesList((prev) => [...prev, { file, src: reader.result }]);
      };

      reader.readAsDataURL(file);
    }
  };

  const removeImageHandler = () => {
    setImagesList((prev) => prev.filter((item) => item.src !== imageHovered));
  };

  return (
    <Modal
      open={open}
      handleClose={() => {
        handleClose();
        setImagesList([]);
      }}
      handleSubmit={() => {
        handleClose();
      }}
      headerText="Add Images (Max 4)"
      leftButton="Cancel"
      rightButton="Save"
    >
      {imagesList.length === 0 && (
        <AddImagesContent handleImageChange={handleImageChange}>
          <Box
            sx={{
              width: {
                sx: "200px",
                lg: "400px",
              },
              height: {
                sx: "200px",
                lg: "400px",
              },
              cursor: "pointer",
              overflow: "hidden",
            }}
          >
            <img
              style={{ width: "100%", cursor: "pointer" }}
              src={addImage}
              alt="add_image"
            />
          </Box>
        </AddImagesContent>
      )}

      {imagesList.length > 0 && (
        <Stack spacing={1}>
          <Box
            sx={{
              overflow: "hidden",
              width: {
                sx: "200px",
                lg: "400px",
              },
              height: {
                sx: "200px",
                lg: "400px",
              },
              position: "relative",
            }}
          >
            <img
              onMouseOver={(e) => setImageHovered(e.target.src)}
              onMouseOut={() => setImageHovered("")}
              style={{
                width: "100%",
                opacity: imageHovered === imagesList[0].src ? 0.6 : 1,
              }}
              src={imagesList[0].src}
              alt=""
            />
            {imageHovered === imagesList[0].src && (
              <img
                onClick={removeImageHandler}
                src={deleteImage}
                onMouseOver={(e) => setImageHovered(imagesList[0].src)}
                style={{
                  position: "absolute",
                  zIndex: 1,
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  cursor: "pointer",
                }}
                alt=""
              />
            )}
          </Box>
          <Stack direction={"row"} spacing={1}>
            {imagesList.map((item, index) => {
              if (index === 0) return null;

              return (
                <Box
                  width={{ xs: "60px", sm: "112px" }}
                  height={{ xs: "60px", sm: "112px" }}
                  sx={{ overflow: "hidden", position: "relative" }}
                  key={index + "key"}
                >
                  <img
                    onMouseOver={(e) => setImageHovered(e.target.src)}
                    onMouseOut={() => setImageHovered("")}
                    style={{
                      width: "100%",
                      opacity: imageHovered === item.src ? 0.6 : 1,
                    }}
                    src={item.src}
                    alt=""
                  />
                  {imageHovered === item.src && (
                    <img
                      src={deleteImage}
                      onClick={removeImageHandler}
                      onMouseOver={(e) => setImageHovered(item.src)}
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        cursor: "pointer",
                      }}
                      alt=""
                    />
                  )}
                </Box>
              );
            })}
            {imagesList.length <= 3 && (
              <AddImagesContent handleImageChange={handleImageChange}>
                <Box
                  width={{ xs: "60px", sm: "112px" }}
                  height={{ xs: "60px", sm: "112px" }}
                >
                  <img
                    height="100%"
                    width="100%"
                    onClick={handleImageChange}
                    src={addSmallImage}
                    alt="add_small_image"
                  />
                </Box>
              </AddImagesContent>
            )}
          </Stack>
        </Stack>
      )}
    </Modal>
  );
}

export default AddImagePopup;
