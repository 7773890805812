import { Box, Checkbox, Chip, FormControl, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material";
import React from "react";

const CustomMultiSelect = (props) => {
  const { name, value, onChange, onBlur, error, options, ...otherProps } = props;

  return (
    <Box sx={{ height: "100%", width: "100%", background: "white" }}>
      <FormControl fullWidth error={error}>
        <Select
          multiple
          value={value}
          onChange={(event) => {
            onChange({
              target: {
                name,
                value: event.target.value,
              },
            });
          }}
          onBlur={onBlur}
          input={<OutlinedInput id="select-multiple-chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          {...otherProps}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={value.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {error !== null && (
        <Box
          sx={{
            color: "red",
            fontSize: "12px",
            fontWeight: "400",
            marginTop: "5px",
          }}
        >
          {error}
        </Box>
      )}
    </Box>
  );
};

export default CustomMultiSelect;
