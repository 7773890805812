import { useState } from "react";
import { Box, Checkbox, FormControlLabel, Stack } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

function CategoriesFilter({ category, categoriesChecked, onClickHandler }) {
  const [showSubCategories, setShowSubCategories] = useState(false);

  return (
    <Box
      sx={{ padding: "16px", borderBottom: "1px solid  rgba(0, 0, 0, 0.05)" }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <FormControlLabel
          key={category.label}
          control={
            <Checkbox
              sx={{
                color: "#0000001A",
                "&.Mui-checked": {
                  color: "#F78B8B",
                },
              }}
              checked={
                categoriesChecked[category.label]?.checkedAll ? true : false
              }
              indeterminate={
                categoriesChecked[category.label]?.subCategories?.length > 0 &&
                !categoriesChecked[category.label]?.checkedAll
                  ? true
                  : false
              }
              onChange={(e) => onClickHandler(e, category.label, "All")}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={<span style={{ fontWeight: "500" }}>{category.label}</span>}
        />
        {showSubCategories ? (
          <ExpandLessIcon
            sx={{ cursor: "pointer" }}
            onClick={() => setShowSubCategories(false)}
          />
        ) : (
          <ExpandMoreIcon
            sx={{ cursor: "pointer" }}
            onClick={() => setShowSubCategories(true)}
          />
        )}
      </Stack>
      {showSubCategories && (
        <Stack sx={{ paddingLeft: "20px" }}>
          {category.subCategories.map((subCategory) => (
            <FormControlLabel
              sx={{ width: "max-content" }}
              key={subCategory}
              control={
                <Checkbox
                  sx={{
                    color: "#0000001A",
                    "&.Mui-checked": {
                      color: "#F78B8B",
                    },
                  }}
                  checked={
                    categoriesChecked[category.label]?.subCategories?.includes(
                      subCategory,
                    ) || false
                  }
                  onChange={(e) =>
                    onClickHandler(e, category.label, subCategory)
                  }
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={
                <span style={{ fontWeight: "300", color: "#596570" }}>
                  {subCategory}
                </span>
              }
            />
          ))}
        </Stack>
      )}
    </Box>
  );
}

export default CategoriesFilter;
