import { Box } from "@mui/material";

function BoxWrapper({ heading, children }) {
  return (
    <Box sx={{ padding: "32px", bgcolor: "#fff", borderRadius: "4px" }}>
      <h2
        style={{
          color: "#2E3A48",
          fontSize: "18px",
          fontWeight: "500",
          margin: "0",
        }}
      >
        {heading}
      </h2>
      {children}
    </Box>
  );
}

export default BoxWrapper;
