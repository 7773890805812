import { MenuItem, Select, Checkbox, Stack } from "@mui/material";

function CustomMultiSelect({ item, detailsData, setFormData }) {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      padding={"16px"}
      sx={{
        borderRadius: "4px",
        bgcolor: "#fff",
      }}
      spacing={1}
    >
      <span style={{ color: "#87929E", fontSize: "14px", fontWeight: "300" }}>
        {item.placeholder}
      </span>
      <Select
        variant="filled"
        color="success"
        displayEmpty
        multiple
        value={detailsData[item.key] || []}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <span>- Select -</span>;
          }
          return selected.join(", ");
        }}
        onChange={(e) => {
          const {
            target: { value },
          } = e;

          setFormData((prev) => ({
            ...prev,
            details: { ...prev.details, [item.key]: value },
          }));
        }}
        sx={{
          fontSize: "14px",
          color: "#87929E",
          width: { xs: "140px", md: "160px", lg: "200px" },
          "& .MuiSelect-select": {
            padding: "0px 12px",
            bgcolor: "#fff",
            borderBottom: "1px solid rgb(230, 230, 230)",
          },
        }}
      >
        {item.options?.map((option, key) => (
          <MenuItem
            sx={{
              fontSize: "14px",
              padding: "0",
            }}
            key={key + "key"}
            value={option.value}
          >
            <Checkbox
              color="success"
              checked={detailsData[item.key]?.includes(option.value) || false}
            />
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
}

export default CustomMultiSelect;
