import axios from "axios";
import Constant from "../../../Routes/constantRoute";

const API_BASE_URL = Constant.BASE_URL;

const createAxiosInstance = (data) => {
  console.log("OTP Token", data);

  const persistedJSON = window.sessionStorage.getItem("persist:root");
  const parsedData = JSON.parse(persistedJSON);
  const accessToken = parsedData.userRegReducer;
  const token = JSON.parse(accessToken);
  const authToken = token.userAuth.token;
  const instance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      Authorization: `Bearer ${data.token || authToken}`,
    },
  });

  return instance;
};

// Common function for making GET requests
export const userPostReq = async (route, data) => {
  const instance = axios.create({
    baseURL: API_BASE_URL,
  });

  try {
    const response = await instance.post(route, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const userPatchReq = async (route, data) => {
  const persistedJSON = window.sessionStorage.getItem("persist:root");
  const parsedData = JSON.parse(persistedJSON);
  const accessToken = parsedData.userRegReducer;
  const token = JSON.parse(accessToken);
  const authToken = token.userAuth.token;
  const requestBody = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    body: data,
  };

  try {
    const response = await fetch(`${API_BASE_URL}${route}`, requestBody);
    const result = await response.json();
    console.log(result);

    const obj = {
      data: {
        user: result?.data?.user,
        token: authToken,
      },
      message: result?.message,
      success: result.success,
    };
    return obj;
  } catch (error) {
    throw error;
  }
};

// Reusable function for making HTTP requests (POST, PUT, DELETE)
export const makeHttpRequest = async (method, route, data = null, id) => {
  console.log(data);
  const instance = await createAxiosInstance(data);

  try {
    let response;

    if (method === "POST") {
      response = await instance.post(route, data);
    } else if (method === "PUT") {
      response = await instance.put(route, data);
    } else if (method === "DELETE") {
      response = await instance.delete(route, id);
    } else if (method === "PATCH") {
      response = await instance.patch(route, id);
    } else {
      throw new Error("Unsupported HTTP method");
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};
