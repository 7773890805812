import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { makeHttpRequest } from "./APIFunction";
import apiRoutes from "../../../Routes/apiRoute";

const generateUniqueInvoiceNumber = async () => {
  // Generate a timestamp to ensure uniqueness
  const timestamp = new Date().getTime();

  // Combine the timestamp and random string
  const invoiceNumber = `#${timestamp}`;

  return invoiceNumber;
};

// adding Payment Subs
export const userAsyncPayment = createAsyncThunk("payment/subscription", async (data) => {
  try {
    const response = await makeHttpRequest("POST", apiRoutes.subscriptionRoute, data);
    console.log(response, "payment subscription");
    return response;
  } catch (error) {
    console.log(error.response, "payment subscription");
    return error.response.data;
  }
});

export const checkAsyncCard = createAsyncThunk("payment/checkAsyncCard", async (data) => {
  try {
    console.log("dataaa", data);
    // const response = await fetch(
    //   `https://lookup.binlist.net/${data.data.cardNumber}`
    // );
    //Check-1
    const response = await fetch(`https://api.weedxhub.com/api/user/check-credit-card`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: { ...data.data } }),
    });

    console.log("Resp", response);
    if (response.ok) {
      const cardDetail = await response.json();
      const orderNumber = await generateUniqueInvoiceNumber();
      const currentDate = new Date();
      const endDate = new Date(currentDate);
      endDate.setDate(currentDate.getDate() + 30);

      const storeResp = {
        data,
        cardDetail: cardDetail.scheme,
        orderNumber,
        currentDate,
        endDate,
        success: true,
      };

      return storeResp;
    } else {
      const resp = await response.json();
      const storeResp = {
        message: resp.message,
        success: false,
      };
      return storeResp;
    }
  } catch (error) {
    return error.response.data;
  }
});

// Create the Payment slice
const userPaymentSlice = createSlice({
  name: "payment",
  initialState: {
    userSubs: {},
    loading: false,
    error: null,
  },
  reducers: {
    resetPayment: (state) => {
      state.userSubs = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAsyncCard.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkAsyncCard.fulfilled, (state, action) => {
        state.loading = false;
        state.userSubs = action.payload;
      })
      .addCase(checkAsyncCard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(userAsyncPayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userAsyncPayment.fulfilled, (state, action) => {
        if (action.payload.success === false) {
          state.loading = false;
          state.error = action.payload.message;
        } else if (action.payload.success === true) {
          state.loading = false;
        }
      })
      .addCase(userAsyncPayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetPayment } = userPaymentSlice.actions;

export const userPaymentReducer = userPaymentSlice.reducer;
