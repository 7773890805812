import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { Box, Grid, Stack } from "@mui/material";

import BoxWrapper from "./components/BoxWrapper";
import {
  AddImagesContent,
  DescriptionContent,
  DetailsContent,
  ImageWrapper,
  ProductContent,
} from "./components/boxContent";
import BoxWrapperV2 from "./components/BoxWrapperV2";
import SecondaryButton from "../../../Components/Custom/SecondaryButton";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import restClient from "../../../util/restClient";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import theme from "../../../theme";
import SideBarLayout from "../../../SharedComponents/SideBarLayout";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as LogoWhite } from "../../../Assets/Icons/LogoWhite.svg";
import heroSubTabBannerGreen from "../../../Assets/Images/heroSubTabBannerGreen.png";
import { Link } from "react-router-dom";
import addSmallImage from "../../../Assets/Images/AddProduct/addSmallImage.svg";
import addImage from "../../../Assets/Images/AddProduct/addImage.svg";
import deleteImage from "../../../Assets/Images/AddProduct/deleteImage.svg";
import validateFormData from "./helpers/errors";
import { ToastContainer, toast } from "react-toastify";
import areAllFieldsNull from "./helpers/checkAllFields";
import CropEasy from "../../../Components/crop/CropEasy";
import { checkFileType, checkURLType } from "./helpers/checkFileType";
import CustomPrimaryButton from "../../../Components/Custom/CustomPrimaryButton";

const productDetailKeys = [
  "THC",
  "Category",
  "SubCategory",
  "CBD",
  "Grow Type",
  "Strain Type",
  "Sample Available",
  "Lab Results",
  "Sales Type",
  "Unit",
  "Package Date",
  "Quantity",
  "State",
  "City",
  "Strain Effect",
  "Accepted Payment Terms",
  "Product Location",
  "Scent",
];

function AddNewProduct({ editState = false }) {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userRegReducer?.userAuth);
  const productId = useParams().id;

  const onDropzoneAreaChangeLab = (files) => {
    setLabFile([...files]);
  };

  const [imagesList, setImagesList] = useState([]);
  const [imageHovered, setImageHovered] = useState("");
  const [isLoading, setIsLoading] = useState(null);
  const [deleteImageSelected, setDeleteSelected] = useState("");

  const [labFile, setLabFile] = useState([]);

  const [formData, setFormData] = useState({
    product: {
      productName: "",
      productPrice: "",
      productBrand: "",
    },
    description: "",
    details: {},
  });

  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const [productBrands, setProductBrands] = useState([]);
  const [productDetails, setProductDetails] = useState([
    {
      placeholder: <span className="required-field">Category:</span>,
      key: "Category",
      options: [],
      type: "select",
    },
    {
      placeholder: <span className="required-field">Subcategory:</span>,
      key: "SubCategory",
      options: [],
      type: "select",
    },
    {
      placeholder: "Sales Type:",
      key: "Sales Type",
      options: [
        {
          label: "Recreational",
          value: "Recreational",
        },
        {
          label: "Medical",
          value: "Medical",
        },
      ],
      type: "multiselect",
    },
    {
      placeholder: "Accepted Payment Terms:",
      key: "Accepted Payment Terms",
      options: [
        {
          label: "Net 14",
          value: "Net 14",
        },
        {
          label: "Net 30",
          value: "Net 30",
        },
        {
          label: "Net 60",
          value: "Net 60",
        },
        {
          label: "Net 90",
          value: "Net 90",
        },
        {
          label: "Consignment",
          value: "Consignment",
        },
        {
          label: "Trade Credit",
          value: "Trade Credit",
        },
        {
          label: "Cash On Delivery (COD)",
          value: "Cash On Delivery (COD)",
        },
      ],
      type: "multiselect",
    },

    {
      placeholder: "Unit THC (%):",
      key: "THC",
      value: 0,
      type: "percent",
    },
    {
      placeholder: <span className="required-field">Unit of Measure:</span>,
      key: "Unit",
      options: [
        {
          label: "Each",
          value: "Each",
        },
        {
          label: "Fluid Ounces",
          value: "Fluid Ounces",
        },
        {
          label: "Gallons",
          value: "Gallons",
        },
        {
          label: "Kilograms",
          value: "Kilograms",
        },
        {
          label: "Pounds",
          value: "Pounds",
        },
        {
          label: "Liters",
          value: "Liters",
        },
        {
          label: "Ounces",
          value: "Ounces",
        },
        {
          label: "Pints",
          value: "Pints",
        },
        {
          label: "Quarts",
          value: "Quarts",
        },
        {
          label: "Grams",
          value: "Grams",
        },
        {
          label: "Milligrams",
          value: "Milligrams",
        },
        {
          label: "Milliliters",
          value: "Milliliters",
        },
      ],

      type: "select",
    },
    {
      placeholder: "Unit CBD (%):",
      key: "CBD",
      value: 0,
      type: "percent",
    },
    {
      placeholder: <span className="required-field">Quantity:</span>,
      key: "Quantity",
      value: 0,
      type: "number",
    },
    {
      placeholder: "Grow Type:",
      key: "Grow Type",
      options: [
        {
          label: "Indoor",
          value: "Indoor",
        },
        {
          label: "Outdoor",
          value: "Outdoor",
        },
        {
          label: "Greenhouse",
          value: "Greenhouse",
        },
        {
          label: "Hydroponics",
          value: "Hydroponics",
        },
      ],
      type: "select",
    },
    // {
    //   placeholder: <span className="required-field">State:</span>,
    //   key: "State",
    //   value: "",
    //   type: "text",
    // },
    {
      placeholder: "Strain Type:",
      key: "Strain Type",
      options: [
        {
          label: "Indica",
          value: "Indica",
        },
        {
          label: "Sativa",
          value: "Sativa",
        },
        {
          label: "Hybrid",
          value: "Hybrid",
        },
      ],
      type: "select",
    },
    // {
    //   placeholder: "City:",
    //   key: "City",
    //   value: "",
    //   type: "text",
    // },

    {
      placeholder: "Sample Available:",
      key: "Sample Available",
      options: [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ],
      type: "select",
    },

    {
      placeholder: "Strain Effect:",
      key: "Strain Effect",
      options: [
        {
          label: "Happy",
          value: "Happy",
        },
        {
          label: "Relaxed",
          value: "Relaxed",
        },
        {
          label: "Euphoric",
          value: "Euphoric",
        },
        {
          label: "Uplifted",
          value: "Uplifted",
        },
        {
          label: "Sleepy",
          value: "Sleepy",
        },
        {
          label: "Energetic",
          value: "Energetic",
        },
      ],
      type: "multiselect",
    },
    {
      placeholder: "Lab Results:",
      key: "Lab Results",
      options: [
        {
          label: "Passed",
          value: "Passed",
        },
        {
          label: "Failed",
          value: "Failed",
        },
      ],
      type: "select",
    },
    {
      placeholder: "Scent:",
      key: "Scent",
      options: [
        {
          label: "Floral",
          value: "Floral",
        },
        {
          label: "Citrus",
          value: "Citrus",
        },
        {
          label: "Earthy",
          value: "Earthy",
        },
        {
          label: "Fruity",
          value: "Fruity",
        },
        {
          label: "Woody",
          value: "Woody",
        },
        {
          label: "Cheesy",
          value: "Cheesy",
        },
        {
          label: "Gassy",
          value: "Gassy",
        },
      ],
      type: "multiselect",
    },
    {
      placeholder: "Package Date:",
      key: "Package Date",
      options: [],
      type: "date",
    },

    {
      placeholder: <span className="required-field">Product Location:</span>,
      key: "Product Location",
      value: "",
      type: "location",
      cb: () => {},
    },
    {
      type: "upload",
    },
  ]);
  const [categorySelected, setCategorySelected] = useState("");
  const [categoryListData, setCategoryListData] = useState([]);
  const [open, setOpen] = useState(false);
  const [imageSelectedUrl, setImageSelectedUrl] = useState("");
  const [openCrop, setOpenCrop] = useState(false);
  const [photoURL, setPhotoURL] = useState("");
  const [file, setFile] = useState(null);

  const fetchProductData = async (id) => {
    try {
      const response = await restClient({
        method: "GET",
        url: `/user/products/${id}`,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      if (response.status !== 200)
        return toast.error(`${response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });

      const { data } = response;

      let newFormData = {
        product: {
          productName: data.data.product[0]["Product Name"],
          productPrice: data.data.product[0]["Rate"],
          productBrand: data.data.product[0].brand?._id,
        },
        description: data.data.product[0]["Product Detail"]
          ? data.data.product[0]["Product Detail"]
          : "",
      };

      let details = {};
      //this is to create details object for formData

      productDetailKeys.map((key) => {
        if (data?.data?.product[0][key]) {
          details[key] = data?.data?.product[0][key];
        }
      });

      if (data.data.product[0]["Category"]) {
        setCategorySelected(data.data.product[0].Category);
      }

      if (data.data.product[0]["mertcItemId"])
        setCategorySelected(data.data.product[0].Category);

      newFormData.details = details;
      setFormData(newFormData);
      setImagesList(
        data.data.product[0].images.map((item) => {
          return {
            src: item,
            file: {
              type: checkURLType(item),
            },
          };
        }),
      );
      setLabFile([{ name: data.data.product[0]["Lab File"] }]);
    } catch (error) {
      console.log(error);
    }
  };

  const getBrandList = async () => {
    try {
      const { data } = await restClient({
        method: "GET",
        url: `/user/brands/lists`,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      setProductBrands(data.data.brand);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategoryList = async () => {
    try {
      const { data } = await restClient({
        method: "GET",
        url: `/admin/categories`,
      });

      setCategoryListData(data.data);
      const categoryOptions = data.data.map((item) => {
        return { label: item.categoryName, value: item.categoryName };
      });

      setProductDetails((prev) => {
        return prev.map((item) => {
          if (item.key === "Category") item.options = [...categoryOptions];

          return item;
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addNewProduct = async () => {
    const FormDataNode = new FormData();

    FormDataNode.append("Product Name", formData.product.productName);
    formData.product.productBrand &&
      FormDataNode.append("brandId", formData.product.productBrand);
    formData.product.productPrice &&
      FormDataNode.append("Rate", formData.product.productPrice);
    formData.description &&
      FormDataNode.append("Product Detail", formData.description);

    if (!editState) {
      imagesList.forEach((item) => {
        FormDataNode.append("images", item.file);
      });
    }

    Object.keys(formData.details).map((item) => {
      if (
        item === "Sales Type" ||
        item === "Strain Effect" ||
        item === "Scent" ||
        item === "Accepted Payment Terms"
      ) {
        if (formData.details[item].length === 1)
          return FormDataNode.append(`${item}[]`, formData.details[item]);
        else if (formData.details[item].length > 1)
          return formData.details[item].map((value) => {
            return FormDataNode.append(`${item}`, value);
          });
      }

      return FormDataNode.append(item, formData.details[item]);
    });

    let newAddedImages = [];
    if (editState) {
      newAddedImages = imagesList.filter(
        (item) => !item.src.includes("weedx.s3.amazonaws.com"),
      );
    }

    labFile[0] && FormDataNode.append("Lab File", labFile[0]);

    try {
      setBtnDisabled(true);
      const response = await restClient({
        method: editState ? "PATCH" : "POST",
        url: editState ? `/user/products/${productId}` : `/user/products`,
        data: FormDataNode,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      if (response.status === 200) {
        toast.success(`${response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });

        if (formData.details.Status === "draft")
          navigate("/product-management", {
            state: { status: "draft" },
          });
        else if (editState) navigate("/product-management");
        else
          navigate(
            `/featured-product-payment/${response.data.data.product._id}`,
          );
      } else {
        toast.error(`${response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      if (response.status === 200 && editState && newAddedImages.length > 0) {
        const NewImageNode = new FormData();

        newAddedImages.forEach((item) => {
          NewImageNode.append("images", item.file);
        });

        const response = await restClient({
          method: "POST",
          url: `/user/add-images/products/${productId}`,
          data: NewImageNode,
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(`${error.response.data.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(null);
      setBtnDisabled(false);
    }
  };

  const deleteImageHandler = async () => {
    try {
      const response = await restClient({
        method: "POST",
        url: `/user/delete-image/products/${productId}`,
        data: { url: deleteImageSelected },
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      if (response.status === 200) {
        toast.success(`${response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.success(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log(error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (checkFileType(file) === "image") {
          setImageSelectedUrl(reader.result);
          setOpenCrop(true);
        }

        if (checkFileType(file) === "video") {
          setImagesList((prev) => [
            ...prev,
            { file, src: URL.createObjectURL(file) },
          ]);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const removeImageHandler = () => {
    setImagesList((prev) =>
      prev.filter((item) => {
        if (
          item.src === imageHovered &&
          item.src.includes("weedx.s3.amazonaws.com")
        )
          setDeleteSelected(item.src);

        return item.src !== imageHovered;
      }),
    );
  };

  const validateFormDataHandler = () => {
    const errors = validateFormData(formData);
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsLoading("save");
      addNewProduct();
    } else {
      setBtnDisabled(true);
      setShowErrors(true);
    }
  };

  useEffect(() => {
    if (!file || !photoURL || checkFileType(file) === "video") return;

    setImagesList((prev) => [...prev, { file, src: photoURL }]);
  }, [file, photoURL]);

  useEffect(() => {
    if (editState) fetchProductData(productId);
  }, [productId, editState]);

  useEffect(() => {
    if (deleteImageSelected) deleteImageHandler();
  }, [deleteImageSelected]);

  useEffect(() => {
    getBrandList();
    getCategoryList();
  }, []);

  useEffect(() => {
    if (!categorySelected || categoryListData.length === 0) return;

    const subCategoryOptions = categoryListData
      .filter((item) => item.categoryName === categorySelected)
      .map((item) => {
        return item.subCategory.map((item) => {
          return { label: item.subCategoryName, value: item.subCategoryName };
        });
      });

    setProductDetails((prev) => {
      return prev.map((item) => {
        if (item.key === "SubCategory")
          if (subCategoryOptions[0]) item.options = [...subCategoryOptions[0]];

        return item;
      });
    });
  }, [categorySelected, categoryListData]);

  useEffect(() => {
    if (!showErrors) return;

    const errors = validateFormData(formData);
    setErrors(errors);

    if (Object.keys(errors).length === 0) setBtnDisabled(false);
    else setBtnDisabled(true);
  }, [formData, showErrors]);

  return (
    <>
      <ToastContainer />
      {openCrop && (
        <CropEasy
          {...{
            photoURL: imageSelectedUrl,
            setPhotoURL,
            openCrop,
            setOpenCrop,
            setFile,
          }}
        />
      )}
      <Box
        sx={{
          height: { md: "100vh" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          overflow: "hidden",
          background: theme.palette.primary.dashboardBack,
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "inline-flex" },
            height: "100%",
            width: { xs: "75%", sm: "40%", md: "30%", lg: "20%" },
          }}
        >
          <SideBarLayout />
        </Box>
        <Box
          sx={{
            display: { md: "none" },
          }}
        >
          {open && <SideBarLayout setOpen={setOpen} />}
          <Grid
            item
            container
            sx={{
              padding: { xs: "1.5rem", sm: "1.5rem 2rem", lg: "0 10rem" },
              background: `url(${heroSubTabBannerGreen})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              display: { md: "none" },
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: { md: "none" },
                justifyContent: "space-between",
                alignItems: "center",
              }}
              container
            >
              <Link to={"/"}>
                <LogoWhite />
              </Link>
              <Box
                onClick={() => {
                  setOpen(true);
                  if (typeof window != "undefined" && window.document) {
                    document.body.style.overflow = "hidden";
                  }
                }}
              >
                <MenuIcon
                  sx={{ color: "white", width: "25px", height: "25px" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            padding: { xs: "30px 0px", md: "30px", lg: "80px 50px" },
            flexGrow: 1,
            width: "100%",
            overflowY: "auto",
          }}
        >
          <form>
            <Stack
              sx={{ display: "flex", flexDirection: "column", rowGap: "30px" }}
            >
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "center", md: "flex-start" },
                  rowGap: { xs: "30px", md: 0 },
                  justifyContent: { md: "space-between" },
                  columnGap: { md: "50px" },
                }}
              >
                <BoxWrapperV2
                  width={{ xs: "80%", lg: "50%" }}
                  padding={{ xs: "16px", lg: "24px" }}
                  heading=""
                >
                  {imagesList.length === 0 && (
                    <AddImagesContent handleImageChange={handleImageChange}>
                      <img
                        style={{ width: "100%", cursor: "pointer" }}
                        src={addImage}
                        alt="add_image"
                      />
                    </AddImagesContent>
                  )}

                  {imagesList.length > 0 && (
                    <Stack spacing={2}>
                      <Box
                        sx={{
                          height: { xs: "200px", md: "400px" },
                          position: "relative",
                        }}
                      >
                        {checkFileType(imagesList[0].file) === "image" && (
                          <>
                            <img
                              onMouseOver={(e) => setImageHovered(e.target.src)}
                              onMouseOut={() => setImageHovered("")}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                                opacity:
                                  imageHovered === imagesList[0].src ? 0.6 : 1,
                              }}
                              src={imagesList[0].src}
                              alt=""
                            />
                            {imageHovered === imagesList[0].src && (
                              <img
                                onClick={removeImageHandler}
                                src={deleteImage}
                                onMouseOver={(e) =>
                                  setImageHovered(imagesList[0].src)
                                }
                                style={{
                                  position: "absolute",
                                  zIndex: 1,
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%,-50%)",
                                  cursor: "pointer",
                                }}
                                alt=""
                              />
                            )}
                          </>
                        )}
                        {checkFileType(imagesList[0].file) === "video" && (
                          <Box
                            sx={{
                              width: "100%",
                              height: "100%",
                              position: "relative",
                            }}
                          >
                            <Box
                              onMouseOver={(e) => setImageHovered(e.target.src)}
                              onMouseOut={() => setImageHovered("")}
                              sx={{
                                width: "100%",
                                height: "100%",
                                opacity:
                                  imageHovered === imagesList[0].src ? 0.6 : 1,
                              }}
                            >
                              <ReactPlayer
                                url={imagesList[0].src}
                                width="100%"
                                height="100%"
                                controls={true}
                              />
                            </Box>
                            {imageHovered === imagesList[0].src && (
                              <img
                                src={deleteImage}
                                onMouseOver={(e) =>
                                  setImageHovered(imagesList[0].src)
                                }
                                onClick={removeImageHandler}
                                style={{
                                  position: "absolute",
                                  zIndex: 100,
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%,-50%)",
                                  cursor: "pointer",
                                }}
                                alt=""
                              />
                            )}
                          </Box>
                        )}
                      </Box>
                      <Stack direction={"row"} spacing={1}>
                        {imagesList.map((item, index) => {
                          if (index === 0) return null;

                          if (checkFileType(item.file) === "image")
                            return (
                              <ImageWrapper key={index + "key"}>
                                <Box
                                  sx={{
                                    position: "relative",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                >
                                  <img
                                    onMouseOver={(e) =>
                                      setImageHovered(e.target.src)
                                    }
                                    onMouseOut={() => setImageHovered("")}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "contain",
                                      opacity:
                                        imageHovered === item.src ? 0.6 : 1,
                                    }}
                                    src={item.src}
                                    alt=""
                                  />
                                  {imageHovered === item.src && (
                                    <img
                                      src={deleteImage}
                                      onClick={removeImageHandler}
                                      onMouseOver={(e) =>
                                        setImageHovered(item.src)
                                      }
                                      style={{
                                        position: "absolute",
                                        zIndex: 1,
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%,-50%)",
                                        cursor: "pointer",
                                      }}
                                      alt=""
                                    />
                                  )}
                                </Box>
                              </ImageWrapper>
                            );

                          if (checkFileType(item.file) === "video")
                            return (
                              <Box sx={{ position: "relative" }}>
                                <Box
                                  onMouseOver={(e) =>
                                    setImageHovered(e.target.src)
                                  }
                                  onMouseOut={() => setImageHovered("")}
                                  sx={{
                                    width: { xs: "60px", sm: "112px" },
                                    height: { xs: "60px", sm: "112px" },
                                    opacity:
                                      imageHovered === item.src ? 0.6 : 1,
                                  }}
                                >
                                  <ReactPlayer
                                    url={item.src}
                                    width="100%"
                                    height="100%"
                                    controls={true}
                                  />
                                </Box>
                                {imageHovered === item.src && (
                                  <img
                                    src={deleteImage}
                                    onMouseOver={(e) =>
                                      setImageHovered(item.src)
                                    }
                                    onClick={removeImageHandler}
                                    style={{
                                      position: "absolute",
                                      zIndex: 100,
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%,-50%)",
                                      cursor: "pointer",
                                    }}
                                    alt=""
                                  />
                                )}
                              </Box>
                            );
                        })}

                        {imagesList.length <= 4 && (
                          <Box>
                            <AddImagesContent
                              handleImageChange={handleImageChange}
                            >
                              <Box
                                width={{ xs: "60px", sm: "112px" }}
                                height={{ xs: "60px", sm: "112px" }}
                              >
                                <img
                                  height="100%"
                                  width="100%"
                                  onClick={handleImageChange}
                                  src={addSmallImage}
                                  alt="add_small_image"
                                />
                              </Box>
                            </AddImagesContent>
                          </Box>
                        )}
                      </Stack>
                    </Stack>
                  )}
                </BoxWrapperV2>
                <BoxWrapper
                  heading={"Product"}
                  width={{ xs: "80%", lg: "50%" }}
                  padding={{ xs: "16px", lg: "24px" }}
                >
                  {[
                    <ProductContent
                      productBrands={productBrands}
                      productData={formData.product}
                      setFormData={setFormData}
                      errors={errors}
                    />,
                  ]}
                </BoxWrapper>
              </Stack>

              <BoxWrapperV2
                heading={<span className="required-field">Description</span>}
                width={{ xs: "80%", md: "95%" }}
                padding={{ xs: "16px", lg: "24px" }}
              >
                <DescriptionContent
                  descriptionData={formData.description}
                  setFormData={setFormData}
                  errors={errors}
                />
              </BoxWrapperV2>

              <BoxWrapperV2
                bgcol="transparent"
                width={{ xs: "90%", md: "100%" }}
                padding="0"
                heading={"Details"}
              >
                <DetailsContent
                  data={productDetails}
                  detailsData={formData.details}
                  setFormData={setFormData}
                  setCategorySelected={setCategorySelected}
                  errors={errors}
                  labFile={labFile}
                  onDropzoneAreaChangeLab={onDropzoneAreaChangeLab}
                />
              </BoxWrapperV2>

              <Stack direction={"row"} justifyContent={"center"}>
                <SecondaryButton
                  onClick={() => {
                    if (!editState && !areAllFieldsNull(formData)) {
                      formData.details.Status = "draft";
                      setIsLoading("cancel");
                      addNewProduct();
                    } else navigate("/product-management");
                  }}
                  width={{ xs: "40%", sm: "20%" }}
                  isLoading={isLoading === "cancel" ? true : false}
                >
                  Cancel
                </SecondaryButton>
                <Box
                  sx={{
                    width: { xs: "40%", sm: "20%" },
                  }}
                >
                  <CustomPrimaryButton
                    onClick={(e) => {
                      e.preventDefault();
                      validateFormDataHandler();
                    }}
                    isLoading={isLoading === "save" ? true : false}
                    disabled={btnDisabled}
                  >
                    Save
                  </CustomPrimaryButton>
                </Box>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default AddNewProduct;
