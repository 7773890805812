import * as Yup from "yup";

const phoneRegExp = /^\(\d{3}\) \d{3}-\d{4}$/;

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  mobile: Yup.string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid")
    .min(14, "Phone number should be 10 characters long")
    .max(14, "Phone number should be 10 characters long"),
  businessName: Yup.string().required("Business name is required"),
  message: Yup.string().required("Message is required"),
});

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  businessName: "",
  message: "",
};
