import { Box, Stack } from "@mui/material";
import DatePickerField from "../../../../Components/Custom/DatePickerField";
import dayjs from "dayjs";

function CustomDatePicker({ placeholder, item, detailsData, setFormData }) {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      padding={"12px 16px"}
      sx={{
        borderRadius: "4px",
        bgcolor: "#fff",
      }}
    >
      <span style={{ color: "#87929E", fontSize: "14px", fontWeight: "300" }}>
        {placeholder}
      </span>
      <Box>
        <DatePickerField
          value={dayjs(detailsData[item.key])}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              details: {
                ...prev.details,
                [item.key]: dayjs(e).format("YYYY-MM-DD"),
              },
            }))
          }
        />
      </Box>
    </Stack>
  );
}

export default CustomDatePicker;
