import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Stack, Grid, CircularProgress, Box } from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { ToastContainer, toast } from "react-toastify";

import restClient from "../../../../../util/restClient";
import BoxWrapper from "../components/BoxWrapper";
import InputWrapper from "../components/InputWrapper";
import Features from "../components/Features";
import AddEditInput from "../helpers/AddEditInput";
import { businessInfo, socialAccounts } from "./Constants";
import SecondaryButton from "../../../../../Components/Custom/SecondaryButton";
import PrimaryButton from "../../../../../Components/Custom/PrimaryButton";
import PhotoContainer from "./PhotoContainer";
import errorConstants from "./ErrorConstants";
import InputField from "../../../../../Components/Custom/InputField";
import CustomPrimaryButton from "../../../../../Components/Custom/CustomPrimaryButton";

function About({ isLoader, editState, dispensaryData, setDispensary, updatedDispensaryLogo, updatedDispensaryImage }) {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userRegReducer?.userAuth);
  const [licenseValuesDropdown, setLicenseValuesDropdown] = useState([]);
  const [addDispensaryImageList, setAddDispensaryImageList] = useState([]);
  const [editDispensaryImageList, setEditDispensaryImageList] = useState([]);
  const [keyFeature, setKeyFeature] = useState("");
  const [updateLoader, setUpdateLoader] = useState(false);
  const [imageHovered, setImageHovered] = useState("");
  const [imageLoader, setImageLoader] = useState(false);
  const [licenseLoader, setLicenseLoader] = useState(false);
  const [errors, setErrors] = useState({});

  const fetchLicense = async () => {
    setLicenseLoader(true);
    try {
      const response = await restClient({
        method: "GET",
        url: `/user/license`,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      setLicenseValuesDropdown(response?.data?.data?.license);
    } catch (error) {
      console.log(error);
    } finally {
      setLicenseLoader(false);
    }
  };

  const handleDispensary = async (url, method, payload) => {
    businessInfo.forEach((item) => {
      payload.append(item.name, dispensaryData[item.name] || "");
    });

    socialAccounts.forEach((item) => {
      payload.append(item.name, dispensaryData[item.name] || "");
    });

    payload.append("aboutUs", dispensaryData.aboutUs || "");

    if (updatedDispensaryLogo) payload.append("logo", updatedDispensaryLogo);
    if (updatedDispensaryImage) payload.append("bannerImage", updatedDispensaryImage);

    if (dispensaryData.keyFeatures?.length === 0) payload.append("keyFeatures", "");
    else
      dispensaryData.keyFeatures?.forEach((item) => {
        payload.append("keyFeatures[]", item);
      });

    try {
      setUpdateLoader(true);
      const response = await restClient({
        method,
        url,
        data: payload,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      if (response.status === 200) {
        toast.success(`${response.data.message}`);
        navigate("/dispensary-management");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUpdateLoader(false);
    }
  };

  const dispensaryAddHandler = () => {
    const payload = new FormData();

    addDispensaryImageList.forEach((item) => {
      payload.append("images", item.file);
    });

    handleDispensary("/user/dispensaries", "POST", payload);
  };

  const dispensaryUpdateHandler = () => {
    const payload = new FormData();

    handleDispensary(`/user/dispensaries/${dispensaryData._id}`, "PATCH", payload);

    if (editDispensaryImageList.length > 0) editDispensaryImageUpdateHandler();
  };

  const editDispensaryImageUpdateHandler = async () => {
    const payload = new FormData();

    editDispensaryImageList.forEach((item) => {
      payload.append("images", item.file);
    });

    try {
      setImageLoader(true);
      await restClient({
        method: "POST",
        url: `/user/add-images/dispensaries/${dispensaryData._id}`,
        data: payload,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setImageLoader(false);
    }
  };

  const editDispensaryImageDeleteHandler = async () => {
    try {
      setImageLoader(true);
      const response = await restClient({
        method: "POST",
        url: `/user/delete-image/dispensaries/${dispensaryData._id}`,
        data: { url: imageHovered },
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      if (response.status === 200) toast.success(`${response.data.message}`);
    } catch (error) {
      console.log(error);
    } finally {
      setImageLoader(false);
    }
  };

  const onClickSaveHandler = () => {
    const errorObj = {};

    errorConstants.forEach((item) => {
      if (!dispensaryData[item.name]) {
        console.log(item.name);
        errorObj[item.name] = `${item.label} is required`;
      }
    });
    setErrors(errorObj);

    if (Object.keys(errorObj).length > 0)
      return window.scrollTo({
        top: "200",
        behavior: "smooth",
      });

    if (editState) dispensaryUpdateHandler();
    else dispensaryAddHandler();
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const errorObj = {};

      errorConstants.forEach((item) => {
        if (!dispensaryData[item.name]) {
          console.log(item.name);
          errorObj[item.name] = `${item.label} is required`;
        }
      });

      setErrors(errorObj);
    }
  }, [dispensaryData]);

  useEffect(() => {
    fetchLicense();
  }, []);

  return (
    <Stack spacing={4}>
      <ToastContainer />
      <BoxWrapper heading={"Business Info"}>
        {isLoader || licenseLoader ? (
          <Stack alignItems={"center"}>
            <CircularProgress />
          </Stack>
        ) : (
          <Grid sx={{ marginTop: "12px" }} container spacing={2}>
            {businessInfo.map((item, i) => (
              <Grid key={i} item xs={12} sm={6}>
                <InputWrapper label={item.label}>
                  <AddEditInput
                    item={item}
                    setDispensary={setDispensary}
                    dispensaryData={dispensaryData}
                    licenseValuesDropdown={licenseValuesDropdown}
                    error={errors[item.name]}
                  />
                </InputWrapper>
              </Grid>
            ))}
          </Grid>
        )}
      </BoxWrapper>

      <BoxWrapper heading={"Social accounts"}>
        {isLoader ? (
          <Stack alignItems={"center"}>
            <CircularProgress />
          </Stack>
        ) : (
          <Grid sx={{ marginTop: "12px" }} container spacing={2}>
            {socialAccounts.map((item, i) => (
              <Grid key={i} item xs={12} sm={6}>
                <InputWrapper label={item.label}>
                  <AddEditInput item={item} setDispensary={setDispensary} dispensaryData={dispensaryData} />
                </InputWrapper>
              </Grid>
            ))}
          </Grid>
        )}
      </BoxWrapper>

      <BoxWrapper heading={"About us"}>
        {isLoader ? (
          <Stack alignItems={"center"}>
            <CircularProgress />
          </Stack>
        ) : (
          <Box sx={{ marginTop: "12px", fontWeight: "300" }}>
            <p
              style={{
                fontSize: "14px",
                color: "#87929E",
                fontWeight: "300",
                marginBottom: "16px",
              }}
            >
              Give a brief description that highlights the unique features and benefits of your dispensary. Help customers understand what makes your
              dispensary special in a few sentences.
            </p>
            <BaseTextareaAutosize
              value={dispensaryData.aboutUs || ""}
              onChange={(e) => setDispensary((prev) => ({ ...prev, aboutUs: e.target.value }))}
              aria-label="empty textarea"
              style={{
                boxSizing: "border-box",
                padding: "16px",
                borderRadius: "4px",
                border: "1px solid #87929E",
                outline: "none",
                resize: "vertical",
                width: "100%",
              }}
              minRows={6}
              placeholder="Enter dispensary description"
            />
          </Box>
        )}
      </BoxWrapper>

      <BoxWrapper heading={"Key Features"}>
        {isLoader ? (
          <Stack alignItems={"center"}>
            <CircularProgress />
          </Stack>
        ) : (
          <Stack spacing={1} sx={{ marginTop: "12px" }}>
            <span
              style={{
                color: "#87929E",
                fontSize: "14px",
                fontWeight: "300",
              }}
            >
              Enhance your product listing by adding tags that showcase its unique features.
            </span>
            <Stack direction={"row"}>
              <InputField
                value={keyFeature}
                placeholder="Type your tags"
                onChange={(e) => {
                  setKeyFeature(e.target.value);
                }}
                borderRadius={"4px 0px 0px 4px"}
              />
              <Box sx={{ marginLeft: "-2px" }}>
                <PrimaryButton
                  height="40.12px"
                  borderRadius={"0px 4px 4px 0px"}
                  onClick={() => {
                    if (!keyFeature) return toast.error("Please enter key feature");

                    setDispensary((prev) => ({
                      ...prev,
                      keyFeatures: prev.keyFeatures?.length > 0 ? [...prev.keyFeatures, keyFeature] : [keyFeature],
                    }));
                    setKeyFeature("");
                  }}
                >
                  Add
                </PrimaryButton>
              </Box>
            </Stack>
            <Features setDispensary={setDispensary} features={dispensaryData?.keyFeatures || []} />
          </Stack>
        )}
      </BoxWrapper>

      <BoxWrapper heading={"Photos"}>
        {isLoader || imageLoader ? (
          <Stack alignItems={"center"}>
            <CircularProgress />
          </Stack>
        ) : (
          <PhotoContainer
            imageList={
              dispensaryData?.images?.map((src) => {
                return { src };
              }) || []
            }
            imageHovered={imageHovered}
            setImageHovered={setImageHovered}
            setDispensary={setDispensary}
            editState={editState}
            setAddDispensaryImageList={setAddDispensaryImageList}
            setEditDispensaryImageList={setEditDispensaryImageList}
            editDispensaryImageDeleteHandler={editDispensaryImageDeleteHandler}
          />
        )}
      </BoxWrapper>

      <Stack direction={"row"} spacing={3}>
        <SecondaryButton
          width="160px"
          onClick={() => {
            navigate("/dispensary-management");
          }}
        >
          Cancel
        </SecondaryButton>
        <Box width={"160px"}>
          <CustomPrimaryButton onClick={onClickSaveHandler} isLoading={updateLoader} disabled={updateLoader}>
            Save
          </CustomPrimaryButton>
        </Box>
      </Stack>
    </Stack>
  );
}

export default About;
