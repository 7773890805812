import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tab, Tabs, Stack } from "@mui/material";

import restClient from "../../../../util/restClient";
import Header from "./Header/Header";
import About from "./About/About";
import Reviews from "./Reviews/Reviews";

function AddEditDispensary({ editState }) {
  const { id } = useParams();
  const userData = useSelector((state) => state.userRegReducer?.userAuth);
  const [tabSelected, setTabSelected] = useState("description");
  const [dispensary, setDispensary] = useState({});
  const [updatedDispensaryLogo, setUpdatedDispensaryLogo] = useState("");
  const [updatedDispensaryImage, setUpdatedDispensaryImage] = useState("");
  const [loder, setLoder] = useState(false);

  const getDispensary = async () => {
    try {
      setLoder(true);
      const response = await restClient({
        method: "GET",
        url: `/user/dispensaries/${id}`,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      setDispensary(response?.data?.data?.dispensary || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoder(false);
    }
  };

  useEffect(() => {
    if (editState) getDispensary();
  }, []);

  return (
    <>
      <Stack>
        <Header
          editState={editState}
          dispensaryDetails={dispensary}
          setDispensary={setDispensary}
          setUpdatedDispensaryLogo={setUpdatedDispensaryLogo}
          setUpdatedDispensaryImage={setUpdatedDispensaryImage}
        />
        <Stack
          spacing={3}
          sx={{
            padding: {
              xs: "24px 16px",
              lg: "36px 100px",
            },
          }}
        >
          <Tabs
            value={tabSelected}
            onChange={(event, newValue) => {
              setTabSelected(newValue);
            }}
            variant="fullWidth"
            scrollButtons="auto"
            textColor="inherit"
            sx={{
              borderRadius: "4px",
              bgcolor: "white",
              "& .MuiTabs-indicator": {
                backgroundColor: "#F78B8B",
              },
            }}
            aria-label="scrollable auto tabs "
          >
            <Tab value="description" label="Description" />
            <Tab value="reviews" label="Reviews" />
          </Tabs>
          {tabSelected === "description" && (
            <About
              isLoader={loder}
              editState={editState}
              dispensaryData={dispensary}
              setDispensary={setDispensary}
              updatedDispensaryLogo={updatedDispensaryLogo}
              updatedDispensaryImage={updatedDispensaryImage}
            />
          )}
          {tabSelected === "reviews" && (
            <Reviews productOwnerID={dispensary.userId} />
          )}
        </Stack>
      </Stack>
    </>
  );
}

export default AddEditDispensary;
