import { Box, Stack } from "@mui/material";
import React from "react";

function InputWrapper({ label, children }) {
  return (
    <Stack spacing={1}>
      <span
        style={{
          color: "#212B36",
          fontSize: "12px",
          fontWeight: "500",
          margin: "0",
        }}
      >
        {label}
      </span>
      <Box sx={{ position: "relative" }}>{children}</Box>
    </Stack>
  );
}

export default InputWrapper;
