import * as React from "react"
const UploadIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={50}
    fill="none"
    {...props}
  >
    <path
      stroke="#2E3A48"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.667 34.335C8.777 33.945 1 31.068 1 22.668s6.222-11.278 9.333-11.667C10.723 7.891 13.6 1.668 22 1.668s11.278 6.222 11.667 9.333c3.11.39 9.333 3.267 9.333 11.667s-7.778 11.278-11.667 11.667m-9.333 14v-28m0 0-7 7m7-7 7 7"
    />
  </svg>
)
export default UploadIcon
