import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Redirect() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.redirectTo)
      navigate(location.state.redirectTo, { replace: false });
    else navigate("/marketplace");
  }, []);

  return (
    <div>
      Redirect to respective page on coming to {location.pathname} page!
    </div>
  );
}

export default Redirect;
