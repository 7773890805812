import { Grid, Box, Typography } from "@mui/material";
import theme from "../../../theme";
// import InputField from "../../../Components/Custom/InputField";
// import InputFieldLabel from "../../../Components/Custom/InnputFieldLabel";
//import Checkbox from "../../../Components/Custom/CheckBox";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import aboutMap from "../../../Assets/Images/aboutMap.png";
// import { useState } from "react";
// import { MuiTelInput } from "mui-tel-input";
//import { useNavigate } from "react-router-dom";
import InputFieldLabel from "../../../Components/Custom/InnputFieldLabel";
import InputField from "../../../Components/Custom/InputField";
//import { MuiTelInput } from "mui-tel-input";
//import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import {
  validationSchema,
  initialValues,
} from "../ContactUs/contactUsValidation";
import axios from "axios";
import restClient from "../../../util/restClient";

export default function GetInTouchSection() {
  const handleSendMessage = async (details) => {
    try {
      // const response = await axios.post(
      //   `https://staging.weedxhub.com/api/user/create-contact`,
      //   details
      // );
      const response = await restClient({
        method: "POST",
        url: "/user/create-contact",
        data: details,
      });
      if (response?.data?.success) {
        toast.success(`${response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`${response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      console.error(err.response);
      toast.error(`${err?.response?.data?.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log(values, "contact us values");
      handleSendMessage(values);
      resetForm({
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        businessName: "",
        message: "",
      });
    },
  });

  const formatPhoneNumber = (input) => {
    const numericInput = input.replace(/\D/g, "");
    if (!numericInput || isNaN(numericInput)) {
      return "";
    }
    return `(${numericInput.slice(0, 3)}) ${numericInput.slice(
      3,
      6
    )}-${numericInput.slice(6, 10)}`;
  };

  const handlePhoneChange = (event) => {
    const input = event.target.value;

    if (event.nativeEvent.inputType === "deleteContentBackward") {
      // Handle backspace key
      formik.setFieldValue("mobile", input);
      //field.onChange(event);
      return;
    }

    const formattedNumber = formatPhoneNumber(input);

    formik.setFieldValue("mobile", formattedNumber);
    //field.onChange(event);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} sx={{ height: { xs: "400px", sm: "auto" } }}>
          <img
            src={aboutMap}
            alt=""
            style={{ width: "100%", height: "100%", backgroundSize: "cover" }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            background: theme.palette.primary.white,
            padding: { xs: "1.5rem", sm: "2rem", lg: "5rem" },
          }}
        >
          <form>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", sm: "start" },
                height: "45px",
                textAlign: { xs: "center", sm: "left" },
                width: "100%",
              }}
            >
              <ToastContainer />
              <Typography
                sx={{
                  fontSize: { xs: "36px", sm: "30px", lg: "40px" },
                  textAlign: { xs: "center", sm: "left" },
                  fontWeight: "600",
                  color: theme.palette.primary.black,
                }}
              >
                Get In Touch!
              </Typography>
              <Box
                sx={{
                  borderTop: "4px solid #F78B8B",
                  width: "40px",
                  alignSelf: "flex-end",
                  marginLeft: "5px",
                  marginBottom: "10px",
                  display: { xs: "none", sm: "inline-flex" },
                }}
              ></Box>
            </Box>
            <Typography
              mt={1}
              sx={{
                color: theme.palette.primary.black,
                fontSize: { xs: "12px", lg: "16px" },
                fontWeight: "500",
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              Our friendly team would love to hear from you
            </Typography>
            <Box>
              <Box mt={2}>
                <InputFieldLabel
                  sx={{
                    color: theme.palette.primary.inputLabelColor,
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  First Name
                </InputFieldLabel>
                <InputField
                  background="white"
                  placeholder="Enter First Name"
                  name="firstName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  error={formik.errors.firstName ? true : false}
                />
              </Box>
              <Box mt={2}>
                <InputFieldLabel
                  sx={{
                    color: theme.palette.primary.inputLabelColor,
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  Last Name
                </InputFieldLabel>
                <InputField
                  background="white"
                  placeholder="Enter Last Name"
                  name="lastName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  error={formik.errors.lastName ? true : false}
                />
              </Box>
              <Box mt={2}>
                <InputFieldLabel
                  sx={{
                    color: theme.palette.primary.inputLabelColor,
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  Email Address
                </InputFieldLabel>
                <InputField
                  background="white"
                  placeholder="Enter Email Address"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.errors.email ? true : false}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="error">{formik.errors.email}</div>
                )}
              </Box>
              <Box mt={2}>
                <InputFieldLabel
                  sx={{
                    color: theme.palette.primary.inputLabelColor,
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  Phone Number
                </InputFieldLabel>
                <InputField
                  background="white"
                  placeholder="Enter Phone Number"
                  name="mobile"
                  type="text"
                  onChange={handlePhoneChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                  error={formik.errors.mobile ? true : false}
                />

                {/* <MuiTelInput
                value={phone}
                onChange={(value) => setPhone(value)}
                defaultCountry="US"
                flagSize="medium"
                style={{ width: "100%", padding: "0px", fontSize: "12px" }}
              /> */}
                {formik.touched.mobile && formik.errors.mobile && (
                  <div className="error">{formik.errors.mobile}</div>
                )}
              </Box>

              <Box mt={2}>
                <InputFieldLabel
                  sx={{
                    color: theme.palette.primary.inputLabelColor,
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  Business Name
                </InputFieldLabel>
                <InputField
                  background="white"
                  placeholder="Enter Business Name"
                  name="businessName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.businessName}
                  error={formik.errors.businessName ? true : false}
                />
              </Box>
              <Box mt={2}>
                <InputFieldLabel
                  sx={{
                    color: theme.palette.primary.inputLabelColor,
                    fontSize: "12px",
                    fontWeight: "500",
                  }}
                >
                  Message
                </InputFieldLabel>
                <InputField
                  placeholder="Write a Message"
                  name="message"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                  multiline
                  minRows={5}
                  maxRows={7}
                />
              </Box>
              {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: 2,
                mb: 2,
              }}
            >
              <Checkbox />
              <Typography
                sx={{
                  color: theme.palette.primary.black,
                  fontSize: { xs: "12px", md: theme.typography.fontSize },
                  fontWeight: "300",
                }}
              >
                You agree to our friendly privacy policy.
              </Typography>
            </Box> */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: { xs: "center" },
                  justifyContent: { xs: "center", sm: "start" },
                  my: 2,
                }}
              >
                <PrimaryButton
                  backgroundColor={theme.palette.primary.peachRed}
                  padding="0.7rem 2rem"
                  fontWeight="500"
                  onClick={formik.handleSubmit}
                >
                  Send Message
                </PrimaryButton>
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
    </>
  );
}
