import { Box, Grid, Stack } from "@mui/material";

function BoxWrapper({ heading, children, padding, width }) {
  return (
    <Box
      sx={{
        padding: padding || "24px",
        bgcolor: "#fff",
        borderRadius: "4px",
        width: width || "45%",
        height: "fit-content",
      }}
    >
      <h2
        style={{
          color: "#2E3A48",
          fontSize: "18px",
          fontWeight: "500",
          margin: "0",
        }}
      >
        {heading}
      </h2>
      <Stack sx={{ marginTop: "12px" }} spacing={2}>
        {children?.map((child, index) => child)}
      </Stack>
    </Box>
  );
}

export default BoxWrapper;
