import { Box, Stack } from "@mui/material";

function Features({ features, setDispensary }) {
  const colorArray = ["#24B314", "#FF9F43", "#179AFA", "#5D5FEF", "#FCD200"];
  const backgroundColorArray = [
    "#D9F7BE",
    "#FFF2D8",
    "#D9F7FF",
    "#E8E8FF",
    "#FFF7D9",
  ];

  return (
    <Stack direction={"row"} flexWrap={"wrap"} spacing={2}>
      {features?.map((feature, i) => (
        <Stack key={i} direction={"row"}>
          <Box
            key={i}
            sx={{
              padding: "4px 8px",
              textTransform: "uppercase",
              borderRadius: "4px 0 0 4px",
              fontSize: "12px",
              fontWeight: "500",
              color: colorArray[i % 5],
              backgroundColor: backgroundColorArray[i % 5],
            }}
          >
            {feature}
          </Box>
          <Box
            sx={{
              backgroundColor: colorArray[i % 5],
              color: backgroundColorArray[i % 5],
              padding: "0 8px",
              cursor: "pointer",
            }}
            onClick={() => {
              setDispensary((prev) => ({
                ...prev,
                keyFeatures: prev.keyFeatures.filter((f) => f !== feature),
              }));
            }}
          >
            X
          </Box>
        </Stack>
      ))}
    </Stack>
  );
}

export default Features;
