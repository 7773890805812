import { useEffect, useState } from "react";
import { Box, CircularProgress, Stack, useMediaQuery } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import CategoriesFilter from "./CategoriesFilter";

function Sidebar({
  categories,
  categoriesChecked,
  onClickHandler,
  loaderCategories,
  setCategoriesChecked,
}) {
  const mobile = useMediaQuery("(max-width: 1200px)");
  const [showCategories, setShowCategories] = useState(true);

  useEffect(() => {
    setShowCategories(!mobile);
  }, [mobile]);

  return (
    <Stack
      sx={{
        bgcolor: "white",
        padding: { lg: "0 16px" },
        minWidth: { lg: "280px" },
      }}
      spacing={2}
    >
      <Stack
        sx={{
          padding: "24px 16px",
          borderBottom: "1px solid  rgba(0, 0, 0, 0.05)",
        }}
        spacing={1}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <span style={{ fontSize: "18px", fontWeight: "500" }}>
            Categories
          </span>
          {mobile && (
            <>
              {showCategories ? (
                <ExpandLessIcon
                  onClick={() => setShowCategories((prev) => !prev)}
                />
              ) : (
                <ExpandMoreIcon
                  onClick={() => setShowCategories((prev) => !prev)}
                />
              )}
            </>
          )}
        </Stack>
        <Stack>
          {/* <Box>
            <span style={{ fontSize: "13px", color: "#596570" }}>
              {filterProductsLength} products chosen from{" "}
            </span>
            <span style={{ fontSize: "13px", fontWeight: "500" }}>
              {brandName}
            </span>
          </Box> */}
          <span
            style={{
              fontSize: "13px",
              fontWeight: "500",
              color: "#1B8A79",
              cursor: "pointer",
            }}
            onClick={() => setCategoriesChecked({})}
          >
            Clear Filters
          </span>
        </Stack>
      </Stack>
      {showCategories && (
        <Box>
          {loaderCategories ? (
            <Stack alignItems={"center"}>
              <CircularProgress />
            </Stack>
          ) : (
            <>
              {categories.map((category) => (
                <CategoriesFilter
                  key={category.label}
                  category={category}
                  categoriesChecked={categoriesChecked}
                  onClickHandler={onClickHandler}
                />
              ))}
            </>
          )}
        </Box>
      )}
    </Stack>
  );
}

export default Sidebar;
