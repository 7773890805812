import React, { useState } from "react";
import theme from "../../../../theme";
import tableColumns from "../Utils/columns";
import { CircularProgress, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import TableSort from "../../../../Components/Custom/TableSorting";
import { formatNumber, sortDataByField, removeQueryParameters } from "../../../../util/functions";
import { useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import ActionPopup from "../Components/ActionPopup";
function formatDate(date) {
  // Ensure the input is a valid Date object
  if (!(date instanceof Date) || isNaN(date)) {
    throw new Error("Invalid Date");
  }
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();

  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
}
export default function CustomTable({
  data,
  page,
  pageSize,
  onRowClick,
  isLoading,
  customClasses = "scrollbar",
  activeTab = "received",
  popupValues,
  handleDelete,
}) {
  const [tableSort, setTableSort] = useState({ id: "", order: "raw" });
  const [columns, setColumns] = useState(tableColumns);
  const [sortedData, setSortedData] = useState([]);

  const checkColumn = (column, value, rowData, index) => {
    if (column.id === "serialNumber") return index + 1 + (page - 1) * pageSize;
    if (column.formatNumber) return formatNumber(value);
    if (column.actions) {
      return (
        <div>
          <FaTrash
            style={{ color: "red" }}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(rowData);
            }}
          />
        </div>
      );
    }
    if ((column.id === "Package Date" || column.id === "startDate" || column.id == "date") && value !== undefined) return formatDate(new Date(value));

    if (column.id === "mertcItemId") {
      const source = value ? "Metrc" : "User";
      return source;
    }
    // if (column.actions) {
    // if (column.id === "trash") {
    //   return <FaTrash style={{ color: "red" }} />;
    // }
    // }

    return value;
  };
  useEffect(() => {
    if (activeTab === "received") {
      const pushBeforeIndex = (index, elements) => {
        setColumns((prevArr) => {
          const newArr = [...prevArr];
          newArr.splice(index, 0, ...elements);
          return newArr;
        });
      };
      const elementsToPush = [
        {
          id: "senderEmail",
          label: "Sender Email",
          sortable: true,
        },
        {
          id: "senderFullName",
          label: "Sender Name",
          sortable: true,
        },
      ];
      pushBeforeIndex(-1, elementsToPush);
    } else setColumns(tableColumns);
  }, [activeTab]);
  useEffect(() => {
    sortDataByField(data, tableSort, setSortedData);
  }, [data, tableSort]);
  return (
    <>
      <TableContainer
        className={customClasses}
        sx={{
          backgroundColor: theme.palette.primary.white,
        }}
      >
        <Table aria-label="sticky table">
          <TableHead sx={{ backgroundColor: theme.palette.primary.dashboardBack }}>
            <TableRow>
              {columns.map((column) => {
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                    }}
                    sx={{
                      fontWeight: "400",
                      fontSize: { xs: "10px" },
                      textTransform: "uppercase",
                      color: theme.palette.primary.black,
                      border: "none",
                      padding: { sm: "24px 12px" },
                      lineHeight: "15px",
                    }}
                  >
                    {!column.sortable && column.label}
                    {column.sortable && <TableSort head={column} span={column.label} tableSort={tableSort} setTableSort={setTableSort} />}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {!isLoading && (
            <TableBody>
              {sortedData.map((row, i) => {
                return (
                  <TableRow
                    onClick={() => {
                      if (onRowClick) onRowClick(row);
                    }}
                    key={"row" + i}
                    sx={
                      onRowClick
                        ? {
                            border: "1px solid #bdbdbd",
                            "&:hover": {
                              backgroundColor: "#f5f5f5",
                              cursor: "pointer",
                            },
                          }
                        : {}
                    }
                  >
                    {columns.map((column) => {
                      let value;
                      if (column.id === "Status") {
                        if (row.isFeatured === true) {
                          value = "featured";
                        } else {
                          value = row[column.id];
                        }
                      } else {
                        value = row[column.id];
                      }
                      return (
                        <TableCell
                          sx={{
                            color: theme.palette.primary.mediumGrey,
                            fontWeight: "400",
                            padding: { xs: "10px", lg: "24px 16px" },
                            fontSize: { xs: "10px", md: "12px", lg: "16px" },
                            border: "none",
                          }}
                          key={column.id}
                          align={column.align}
                        >
                          <Stack direction={"row"} alignItems={"center"}>
                            {column.logo && (
                              <img
                                src={removeQueryParameters(row[column.logo])}
                                alt="logo"
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  marginRight: "10px",
                                  borderRadius: "50%",
                                }}
                              />
                            )}
                            {checkColumn(column, value, row, i)}
                          </Stack>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              {/* <div ref={observerTarget}></div> */}
            </TableBody>
          )}
        </Table>
        {isLoading && (
          <Stack direction={"row"} justifyContent={"center"} sx={{ margin: "10px" }} minHeight={"300px"}>
            <CircularProgress />
          </Stack>
        )}
        {!isLoading && sortedData.length === 0 && (
          <Stack direction={"row"} justifyContent={"center"}>
            <p>No available messages at this time.</p>
          </Stack>
        )}
      </TableContainer>
    </>
  );
}
