import { Box, Grid, Typography } from "@mui/material";
import RealLife from "../../../Assets/Images/RealLifeLoveForWeedx.png";
import RealLifeMobile from "../../../Assets/Images/RealLifeLoveForWeedxMobile.png";
import theme from "../../../theme";

export default function RealLifeSection() {
  return (
    <>
      <Grid container mt={{ xs: 5, lg: 15 }}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: { xs: "none", sm: "inline-flex" },
              width: "100%",
              margin: "0rem 0.5rem",
            }}
          >
            <img src={`${RealLife}`} alt="" style={{ width: "100%" }} />
          </Box>
          <Box sx={{ width: "100%", display: { sm: "none" } }}>
            <img src={`${RealLifeMobile}`} alt="" style={{ width: "100%" }} />
          </Box>
        </Grid>
        <Grid item xs={12} display={"flex"} justifyContent={"center "}>
          <Typography
            sx={{
              fontSize: "18px",
              color: theme.palette.primary.mediumGrey,
              fontWeight: "400",
              textAlign: { xs: "center" },
              margin: { xs: "1rem 2rem", sm: "2rem" },
            }}
          >
            Unveiling the Dynamic Range of Markets We Serve
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
