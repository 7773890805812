import { Box, Typography } from "@mui/material";
import React from "react";
//import ChipTag from "../../../Components/Custom/Chip";
import theme from "../../../theme";

export default function BlogCard(props) {
  return (
    <Box
      sx={{
        background: theme.palette.primary.white,
        cursor: "pointer",
        marginRight: { sm: "1rem", lg: "1rem 2rem" },
      }}
      onClick={props.onClick}
    >
      <Box>
        <img
          src={props.blogViewImage}
          alt=""
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Box>
      <Box
        sx={{
          padding: { xs: "30px 15px 30px 30px", lg: "40px 30px 40px 60px" },
          background: theme.palette.primary.white,
        }}
      >
        <Typography
          sx={{
            color: theme.palette.primary.mediumGrey,
            fontSize: { xs: "36px", md: "40px" },
            fontWeight: "500",
            opacity: 0.2,
          }}
        >
          {props.time}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.primary.black,
            fontSize: { xs: "14px", md: "16px" },
            fontWeight: "500",
          }}
        >
          By {props.createdBy}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.primary.black,
            fontSize: { xs: "24px", md: theme.typography.fontSize32 },
            fontWeight: "600",
            lineHeight: "40px",
            mt: 1,
            mb: 2,
          }}
        >
          {props.title}
        </Typography>
        {/* <ChipTag label={"Indica"} /> */}
      </Box>
    </Box>
  );
}
