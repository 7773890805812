import { useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";

import BannerImage from "../../../../../Assets/Images/AddBrand/banner.svg";
import BannerLogo from "../../../../../Assets/Images/AddBrand/addLogo.svg";
import addImage from "../../../../../Assets/Images/AddBrand/addBanner.svg";
import addImageMobile from "../../../../../Assets/Images/AddProduct/addImageMobile.svg";
import CropEasy from "../../../../../Components/crop/CropEasy";

function Header({
  editState,
  dispensaryDetails,
  setDispensary,
  setUpdatedDispensaryLogo,
  setUpdatedDispensaryImage,
}) {
  const uploadImagesRef = useRef(null);
  const uploadLogoRef = useRef(null);
  const [imageSelectedUrl, setImageSelectedUrl] = useState("");
  const [openCropProfile, setOpenCropProfile] = useState(false);
  const [openCropBanner, setOpenCropBanner] = useState(false);
  const [profilePreview, setProfilePreview] = useState("");
  const [bannerPreview, setBannerPreview] = useState("");
  const [file, setFile] = useState(null);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        setImageSelectedUrl(reader.result);
        setOpenCropBanner(true);
      };
    }
  };

  const handleLogoChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        setImageSelectedUrl(reader.result);
        setOpenCropProfile(true);
      };
    }
  };

  useEffect(() => {
    if (file && bannerPreview) {
      setDispensary({
        ...dispensaryDetails,
        bannerImage: imageSelectedUrl,
      });

      setUpdatedDispensaryImage(file);
    }
  }, [bannerPreview]);

  useEffect(() => {
    if (file && profilePreview) {
      setDispensary({
        ...dispensaryDetails,
        logo: imageSelectedUrl,
      });

      setUpdatedDispensaryLogo(file);
    }
  }, [profilePreview]);

  return (
    <>
      {openCropProfile && (
        <CropEasy
          {...{
            photoURL: imageSelectedUrl,
            setPhotoURL: setProfilePreview,
            openCrop: openCropProfile,
            setOpenCrop: setOpenCropProfile,
            setFile: setFile,
          }}
        />
      )}
      {openCropBanner && (
        <CropEasy
          {...{
            photoURL: imageSelectedUrl,
            setPhotoURL: setBannerPreview,
            openCrop: openCropBanner,
            setOpenCrop: setOpenCropBanner,
            setFile,
            aspect: 16 / 5,
          }}
        />
      )}

      <input
        type="file"
        accept="image/*"
        onChange={handleLogoChange}
        style={{ display: "none" }}
        ref={uploadLogoRef}
      />
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: "none" }}
        ref={uploadImagesRef}
      />

      <Box sx={{ position: "relative" }}>
        <Box
          sx={{ maxHeight: { xs: "320px", md: "480px" }, overflow: "hidden" }}
        >
          <img
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            src={dispensaryDetails?.bannerImage || BannerImage}
            alt=""
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "0",
            left: { xs: "50%", md: "100px" },
            transform: { md: "translate(0,50%)", xs: "translate(-50%,50%)" },
            borderRadius: "50%",
            width: { xs: "120px", md: "180px" },
            height: { xs: "120px", md: "180px" },
            overflow: "hidden",
            cursor: "pointer",
          }}
          onClick={() => uploadLogoRef.current.click()}
        >
          <img
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            src={dispensaryDetails?.logo || BannerLogo}
            alt=""
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "24px",
            right: "24px",
            cursor: "pointer",
            display: { xs: "none", md: "block" },
            width: "200px",
            zIndex: 1,
          }}
          onClick={() => uploadImagesRef.current.click()}
        >
          <img
            style={{ width: "100%", objectFit: "cover" }}
            src={addImage}
            alt=""
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "12px",
            right: "12px",
            cursor: "pointer",
            display: { xs: "block", md: "none" },
            width: "36px",
            zIndex: 1,
            boxSizing: "border-box",
            padding: "8px 8px 4px 8px",
            bgcolor: "#00000098",
            borderRadius: "4px",
          }}
          onClick={() => uploadImagesRef.current.click()}
        >
          <img
            style={{ width: "100%", objectFit: "cover" }}
            src={addImageMobile}
            alt=""
          />
        </Box>
      </Box>
      <Stack
        spacing={{ xs: 1, md: 3 }}
        sx={{
          marginTop: { xs: "72px", md: "100px" },
          padding: { xs: "0 16px", md: "0 100px" },
        }}
      >
        <Box sx={{ fontSize: "24px", fontWeight: "500" }} component="span">
          {dispensaryDetails?.dispensaryName}
        </Box>
      </Stack>
    </>
  );
}

export default Header;
