import { Stack, Grid, CircularProgress, Box } from "@mui/material";

import BoxWrapper from "../components/BoxWrapper";
import InputWrapper from "../components/InputWrapper";
import { checkLabel } from "../helpers/labelChecker";

function About({ isLoader, productData }) {
  return (
    <Stack spacing={4}>
      <BoxWrapper heading={"Description"}>
        {isLoader ? (
          <Stack alignItems={"center"}>
            <CircularProgress />
          </Stack>
        ) : (
          <Box
            sx={{
              marginTop: "12px",
              fontWeight: "300",
            }}
          >
            {productData["Product Detail"] || ""}
          </Box>
        )}
      </BoxWrapper>
      {isLoader ? (
        <Stack alignItems={"center"}>
          <CircularProgress />
        </Stack>
      ) : (
        <Grid sx={{ boxSizing: "border-box" }} container>
          {[
            { name: "Category", label: "Category" },
            { name: "Package Date", label: "Package date" },
            { name: "SubCategory", label: "Subcategory" },
            { name: "Unit", label: "Unit of measure" },
            { name: "THC", label: "Unit THC (%)" },
            { name: "Quantity", label: "Quantity" },
            { name: "CBD", label: "Unit CBD (%)" },
            { name: "Package Date", label: "Package date" },
            { name: "Lab File", label: "Lab file" },
            { name: "Grow Type", label: "Grow type" },
            { name: "Product Location", label: "Location" },
            { name: "Strain Type", label: "Strain type" },
            { name: "Lab Results", label: "Lab Results" },
            { name: "Sample Available", label: "Sample available" },
            { name: "Strain Effect", label: "Strain effect" },
            { name: "Scent", label: "Scent" },
            { name: "Sales Type", label: "Sales type" },
            { name: "Accepted Payment Terms", label: "Accepted payment terms" },
          ].map((item, i) => (
            <Grid key={i} item xs={12} md={6}>
              <InputWrapper position={i} label={item.label}>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "#363636",
                  }}
                >
                  {checkLabel(item, productData)}
                </span>
              </InputWrapper>
            </Grid>
          ))}
        </Grid>
      )}
    </Stack>
  );
}

export default About;
