import React from 'react'
import TopHeader from '../Header/TopHeader';
import MenuBar from '../../../Components/Custom/MenuBar';
import LoginSmallBanner from '../../../Components/Custom/LoginSmallBanner';
import SmallFooter from '../SmallFooter/SmallFooter';
import SellerProfileBackImage from '../../../Assets/Images/sellerprofile.png'
import { Box, Grid, Typography } from '@mui/material';
import PrimaryHeading from '../../../Components/Custom/PrimaryHeading';
import theme from '../../../theme';
import ChipTag from '../../../Components/Custom/Chip';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AboutImage from '../../../Assets/Images/about.png'
import PrimaryButton from '../../../Components/Custom/PrimaryButton';

const CustomLink = styled(Link)(() => ({
    textDecoration: 'none !important',
    cursor: 'pointer'
}));

export default function AboutProfile() {
    const menus = [
        {
            label: 'Menu 1',
            items: ['Item 1-1', 'Item 1-2', 'Item 1-3'],
        },
        {
            label: 'Menu 2',
            items: ['Item 2-1', 'Item 2-2', 'Item 2-3'],
        },
    ];
    return (
        <>
            <TopHeader />
            <MenuBar menus={menus} />
            <LoginSmallBanner
                backImage={SellerProfileBackImage}
                HideProfile={'block'}
                title={'Breeze Canna'}
                location
                locationDes={'3340 8 Mile Rd, Detroit, MI 48234'}
                url
                urlDes={'www.breezecanna.com'}
                liecence
                liecenceDes={'AU-P-000157'}
            />
            <Grid container my={10}
                sx={{
                    padding: '0 10rem',
                }}
            >
                <Grid item xs={12}>
                    <Box sx={{
                        background: theme.palette.primary.white,
                        p: 4,
                        mt: 10,
                        mb: 3,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Typography sx={{
                            color: theme.palette.primary.black,
                            fontSize: theme.typography.fontSize
                        }}>
                            Key Features:
                        </Typography>
                        <ul style={{
                            listStyle: 'none',
                            display: 'flex',
                            margin: 0,
                            paddingLeft: 0
                        }}>
                            <li className='profileTags'>
                                <CustomLink>
                                    <ChipTag label={'Active'} />
                                </CustomLink>
                            </li>
                            <li className='profileTags'>
                                <CustomLink>
                                    <ChipTag label={'Active'} background={'rgba(255, 159, 67, 0.15)'} color={'#FF9F43'} />
                                </CustomLink>
                            </li>
                            <li className='profileTags'>
                                <CustomLink>
                                    <ChipTag label={'Active'} background={'rgba(23, 154, 250, 0.15)'} color={'#179AFA'} />
                                </CustomLink>
                            </li>
                        </ul>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        background: theme.palette.primary.white,
                        p: 4
                    }}>
                        <PrimaryHeading fontSize={'18px'} fontWeight={'500'}>
                            About Us
                        </PrimaryHeading>
                        <Typography sx={{
                            color: theme.palette.primary.inputTextColor,
                            fontSize: theme.typography.fontSize,
                            mt: 2
                        }}>
                            At BREEZE Canna, we believe cannabis users should have choices with their smoking experience. Switching from traditional cannabis to vaping should be a BREEZE. That is why we created a device convenient enough to fit your lifestyle.
                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam.
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{
                        background: theme.palette.primary.white,
                        p: 4,
                        mt: 3,
                        mb: 3
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <PrimaryHeading fontSize={'18px'} fontWeight={'500'}>
                                Photos
                            </PrimaryHeading>
                            <Link style={{
                                color: theme.palette.primary.peachRed
                            }}>
                                All Photos
                            </Link>
                        </Box>
                        <Box>
                            <ul style={{
                                listStyle: 'none',
                                display: 'flex',
                                paddingLeft: 0
                            }}>
                                <li className='socialMedia'>
                                    <CustomLink>
                                        <img src={`${AboutImage}`} />
                                    </CustomLink>
                                </li>
                                <li className='socialMedia'>
                                    <CustomLink>
                                        <img src={`${AboutImage}`} />
                                    </CustomLink>
                                </li>
                                <li className='socialMedia'>
                                    <CustomLink>
                                        <img src={`${AboutImage}`} />
                                    </CustomLink>
                                </li>
                                <li className='socialMedia'>
                                    <CustomLink>
                                        <img src={`${AboutImage}`} />
                                    </CustomLink>
                                </li>
                                <li className='socialMedia'>
                                    <CustomLink>
                                        <img src={`${AboutImage}`} />
                                    </CustomLink>
                                </li>
                                <li className='socialMedia'>
                                    <CustomLink>
                                        <img src={`${AboutImage}`} />
                                    </CustomLink>
                                </li>
                                <li className='socialMedia'>
                                    <CustomLink>
                                        <img src={`${AboutImage}`} />
                                    </CustomLink>
                                </li>
                                <li className='socialMedia'>
                                    <CustomLink>
                                        <img src={`${AboutImage}`} />
                                    </CustomLink>
                                </li>
                                <li className='socialMedia'>
                                    <CustomLink>
                                        <img src={`${AboutImage}`} />
                                    </CustomLink>
                                </li>
                                <li className='socialMedia'>
                                    <CustomLink>
                                        <img src={`${AboutImage}`} />
                                    </CustomLink>
                                </li>
                                <li className='socialMedia'>
                                    <CustomLink>
                                        <img src={`${AboutImage}`} />
                                    </CustomLink>
                                </li>
                                <li className='socialMedia'>
                                    <CustomLink>
                                        <img src={`${AboutImage}`} />
                                    </CustomLink>
                                </li>
                            </ul>
                        </Box>
                    </Box>
                    <PrimaryButton
                        backgroundColor={theme.palette.primary.peachRed}
                        color={theme.palette.primary.white}
                    >
                        View all products
                    </PrimaryButton>
                </Grid>

            </Grid>
            <SmallFooter />
        </>
    )
}
