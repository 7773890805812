// BasicTabs.js

import React, { useState } from "react";
//import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import theme from "../../theme";

export default function BasicTabs(props) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          display: props.display ? props.display : { xs: "none", sm: "flex" },
          justifyContent: "center",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.primary.peachRed,
              "& .MuiButtonBaseRoot": {
                background: "red !important",
              },
            },
          }}
        >
          {props.tabs.map((tab, index) => (
            <Tab
              sx={{
                background: theme.palette.primary.white,
                color: theme.palette.primary.inputTextColor,
                textTransform: "capitalize",
                width: props.width,
              }}
              label={tab.label}
              {...a11yProps(index)}
              key={index}
            />
          ))}
        </Tabs>
      </Box>
      {props.tabs.map((tab, index) => (
        <CustomTabPanel value={value} index={index} key={index}>
          {tab.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
}

// BasicTabs.propTypes = {
//   tabs: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string.isRequired,
//       content: "",
//     })
//   ).isRequired,
// };

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
