import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../../theme";
import styled from "styled-components";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import { ReactComponent as MasterCardIcon } from "../../../Assets/Icons/MasterCard.svg";
//import { ReactComponent as Visa } from "../../../Assets/Icons/Visa.svg";
//import { ReactComponent as Amex } from "../../../Assets/Icons/Amex.svg";
//import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddIcon from "@mui/icons-material/Add";
import Modal from "../../../Components/Custom/Modal";
import AddPaymentMethod from "./AddPaymentMethod/AddPaymentMethod";
import CancelSubscription from "./CancelSubscription/CancelSubscription";
import { useSelector } from "react-redux";
import restClient from "../../../util/restClient";
import PrimaryPopup from "./components/PrimaryPopup";

const Item = styled(Paper)(() => ({
  padding: "20px 20px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
  boxShadow: "none",
  margin: 6,
}));

export default function Membership(props) {
  const selectUserAuth = (state) => state.userRegReducer?.userAuth;
  const userData = useSelector(selectUserAuth);
  const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);
  const [cancelSub, setCancelSub] = useState("agree");
  const [cardDetails, setCardDetails] = useState([]);
  const [cancelSubModalOpen, setCancelSubModalOpen] = useState(false);
  const [isLoadingCards, setIsLoadingCards] = useState(false);
  const [paymentSchedule, setPaymentSchedule] = useState();
  const getCardDetails = async () => {
    try {
      setIsLoadingCards(true);
      // const response = await axios.get(
      //   "https://staging.weedxhub.com/api/user/get-customer-profile",
      //   { headers: { Authorization: `Bearer ${userData?.token}` } }
      // );
      const response = await restClient({
        method: "GET",
        url: "/user/get-customer-profile",
        headers: { Authorization: `Bearer ${userData?.token}` },
      });

      console.log(response, "get card details");
      let cardData = [];
      if (response?.data?.success) {
        const res = response?.data?.data?.subscription.profile?.paymentProfiles;
        setPaymentSchedule(response?.data?.data?.subscription?.paymentSchedule?.startDate);
        const customerProfileId = response?.data?.data?.subscription.profile?.customerProfileId;
        const defaultCustomerPaymentProfileId = response?.data?.data?.subscription?.defaultCustomerPaymentProfileId;

        for (let i = 0; i < res.length; i++) {
          let card = {};
          card = {
            ...res[i]?.payment?.creditCard,
            name: res[i]?.billTo?.firstName + " " + res[i]?.billTo?.lastName,
            customerPaymentProfileId: res[i]?.customerPaymentProfileId,
            customerProfileId,
            defaultCustomerPaymentProfileId: res[i]?.customerPaymentProfileId === defaultCustomerPaymentProfileId ? true : false,
          };
          cardData.push(card);
        }
      }

      setCardDetails([...cardData]);
    } catch (err) {
      console.log(err.response);
    } finally {
      setIsLoadingCards(false);
    }
  };
  function getNextMonthDate(inputDate) {
    // Parse the input date
    let date = new Date(inputDate);

    // Get the current year and month
    let year = date.getFullYear();
    let month = date.getMonth(); // 0-based month index

    // Increment the month
    month += 1;

    // If the incremented month exceeds December, roll over to the next year
    if (month > 11) {
      month = 0;
      year += 1;
    }

    // Set the new month and year while keeping the same day
    date.setFullYear(year);
    date.setMonth(month);

    return date.toLocaleDateString();
  }
  const handlePaymentOpenModal = () => {
    setPaymentMethodModalOpen(true);
  };

  const handlePaymentCloseModal = () => {
    setPaymentMethodModalOpen(false);
  };

  const handleCancelSubOpenModal = () => {
    setCancelSubModalOpen(true);
    setCancelSub("agree");
  };

  const handleCancelSubCloseModal = () => {
    setCancelSubModalOpen(false);
  };

  const cancelAccountSubscriptionAPI = async (password) => {
    try {
      // const response = await axios.post(
      //   `https://staging.weedxhub.com/api/user/delete-account`,
      //   { password: password },
      //   { headers: { Authorization: `Bearer ${userData?.token}` } }
      // );
      const response = await restClient({
        method: "POST",
        url: "/user/delete-account",
        data: { password: password },
        headers: { Authorization: `Bearer ${userData?.token}` },
      });
      console.log(response, "delete account api");
      return response;
    } catch (err) {
      console.log(err.response);
      return err.response;
    }
  };

  const onClickHandler = async (card, actionType) => {
    console.log(card, actionType, "card");

    let apiObject = {};

    if (actionType === "delete") apiObject = { url: "/user/customer-payment-profile", method: "DELETE" };

    if (actionType === "primary")
      apiObject = {
        url: "/user/change-default-payment-profile",
        method: "POST",
      };

    console.log(apiObject, "apiObject");

    try {
      setIsLoadingCards(true);
      const response = await restClient({
        ...apiObject,
        headers: { Authorization: `Bearer ${userData?.token}` },
        data: {
          customerProfileId: card.customerProfileId,
          customerPaymentProfileId: card.customerPaymentProfileId,
        },
      });

      if (response.status === 200) getCardDetails();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCardDetails();
  }, []);

  return (
    <>
      <Grid
        container
        sx={{
          height: { xs: "100%" },
          overflowY: { xs: "auto" },
          overflowX: "hidden",
        }}
      >
        <Grid item xs={12} sm={6} mt={4}>
          <Item
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: { xs: "100%", sm: "65%" },
              minHeight: "40vh",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: theme.palette.primary.inputLabelColor,
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                Pro Subscription
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.primary.peachRed,
                  fontSize: "40px",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                  columnGap: 0.5,
                }}
              >
                $198
                <span
                  style={{
                    color: theme.palette.primary.footerGrey,
                    fontSize: "16px",
                    fontWeight: 300,
                    marginTop: 12,
                  }}
                >
                  /mo
                </span>
              </Typography>

              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.footerGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  Start date
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.inputLabelColor,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  01/01/2024
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.footerGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  End date
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.inputLabelColor,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  01/01/2024
                </Typography>
              </Box> */}

              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                  mb: 2,
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.footerGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  Total occurrence
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.inputLabelColor,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  12
                </Typography>
              </Box> */}
            </Box>
            <Box>
              <PrimaryButton width={"100%"} backgroundColor={theme.palette.primary.peachRed} onClick={handleCancelSubOpenModal}>
                Cancel subscription
              </PrimaryButton>
              <Modal
                open={cancelSubModalOpen}
                handleClose={handleCancelSubCloseModal}
                headerText={
                  cancelSub === "agree" ? (
                    <>Are you sure you want to cancel your subscription?</>
                  ) : cancelSub === "confirmSub" ? (
                    <>Please enter your password</>
                  ) : null
                }
                maxWidth="xs"
                fullWidth
              >
                <CancelSubscription
                  cancelSub={cancelSub}
                  setCancelSub={setCancelSub}
                  handleDeleteAccount={cancelAccountSubscriptionAPI}
                  setOpen={handleCancelSubCloseModal}
                />
              </Modal>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} mt={{ xs: 10, sm: 4 }}>
          <Item
            sx={{
              background: theme.palette.primary.dashboardBack,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "",
              height: { xs: "75%", sm: "65%" },
              overflowY: "auto",
              minHeight: "40vh",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: theme.palette.primary.black,
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                Payment methods
              </Typography>
              <Typography
                sx={{
                  color: theme.palette.primary.footerGrey,
                  fontSize: theme.typography.fontSize,
                  display: "flex",
                  marginTop: 2,
                }}
              >
                Your card will be charged on
                <Typography
                  sx={{
                    color: theme.palette.primary.peachRed,
                    fontSize: theme.typography.fontSize,
                    pl: 1,
                  }}
                >
                  {paymentSchedule && getNextMonthDate(paymentSchedule)}
                </Typography>
              </Typography>
            </Box>
            {isLoadingCards ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  height: "55vh",
                  overflowY: "auto",
                }}
              >
                {cardDetails &&
                  cardDetails?.length > 0 &&
                  cardDetails?.map((card) => (
                    <Box sx={{ position: "relative" }}>
                      {card.defaultCustomerPaymentProfileId && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bgcolor: theme.palette.primary.main,
                            color: theme.palette.primary.white,
                            padding: "4px 8px",
                            borderRadius: "0px 0px 0px 4px",
                            fontSize: "12px",
                          }}
                        >
                          Primary
                        </Box>
                      )}
                      <Box
                        mt={3}
                        sx={{
                          background: theme.palette.primary.white,
                          p: 3,
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4}>
                            <MasterCardIcon />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              sx={{
                                color: theme.palette.primary.inputLabelColor,
                                fontSize: theme.typography.fontSize,
                                mb: 1,
                              }}
                            >
                              {card?.name}
                            </Typography>
                            <Typography
                              sx={{
                                color: theme.palette.primary.footerGrey,
                                fontSize: theme.typography.fontSize,
                                display: "flex",
                                mb: 1,
                              }}
                            >
                              {card?.cardNumber}
                            </Typography>
                            <Typography
                              sx={{
                                color: theme.palette.primary.footerGrey,
                                fontSize: theme.typography.fontSize,
                                display: "flex",
                                mb: 1,
                              }}
                            >
                              Expiry: {card?.expirationDate}
                            </Typography>
                          </Grid>
                          {!card.defaultCustomerPaymentProfileId && (
                            <Grid item xs={2} display={"flex"} justifyContent={"end"} alignItems={"start"}>
                              <PrimaryPopup
                                popupValues={[
                                  {
                                    label: "Make as Primary",
                                    action: "primary",
                                  },
                                  { label: "Delete Card", action: "delete" },
                                ]}
                                onClickHandler={onClickHandler}
                                card={card}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Box>
                  ))}
              </Box>
            )}
            <Box>
              <PrimaryButton margin={3} icon={<AddIcon />} width="100%" onClick={handlePaymentOpenModal}>
                Add payment method
              </PrimaryButton>

              <Modal
                noPadding
                open={paymentMethodModalOpen}
                handleClose={handlePaymentCloseModal}
                headerText={"Add payment method"}
                maxWidth="xs"
                fullWidth
              >
                <AddPaymentMethod handleClose={handlePaymentCloseModal} setCardDetails={setCardDetails} getCardDetails={getCardDetails} />
              </Modal>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </>
  );
}
