const areAllFieldsNull = (formData) =>
  Object.values(formData).every((field) => {
    if (typeof field === "object" && field !== null) {
      return Object.values(field).every(
        (subField) => subField === null || subField === "",
      );
    }
    return field === null || field === "";
  });

export default areAllFieldsNull;
