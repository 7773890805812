import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import LocationPin from "../../../Assets/Icons/location-pin.svg";
import MailOpened from "../../../Assets/Icons/mail-opened.svg";
import PhoneBox from "../../../Assets/Icons/phone-box.svg";
import theme from "../../../theme";

const Item = styled(Paper)(({ theme }) => ({
  boxShadow:
    "0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.04), 0px 0px 4px 4px rgba(0, 0, 0, 0.04)",
  textAlign: "center",
  height: "240px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
}));

function InfoSection() {
  return (
    <Grid
      container
      spacing={{ md: 3 }}
      sx={{
        padding: {
          xs: "2rem",
          sm: "2rem 7rem",
          md: "2rem 5rem",
          lg: "2rem 10rem",
        },
        rowGap: { xs: "1rem", lg: "0px" },
      }}
    >
      <Grid item xs={12} md={4}>
        <Item
          sx={{
            backgroundColor: theme.palette.primary.white,
            padding: { xs: "0px 20px", lg: "20px 56px" },
          }}
        >
          <img src={`${LocationPin}`} alt=""></img>
          <Typography
            sx={{
              color: theme.palette.primary.black,
              fontSize: "18px",
              fontWeight: "bold",
              marginTop: { xs: "15px", sm: "30px" },
            }}
          >
            Address
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.mediumGrey,
              fontSize: "14px",
              marginTop: { xs: "5px", sm: "10px" },
            }}
          >
            335 Haggerty Rd. 1027 Walled Lake, MI 48390
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={12} md={4}>
        <Item
          sx={{
            backgroundColor: theme.palette.primary.white,
            padding: { xs: "0px 20px", lg: "20px 56px" },
          }}
        >
          <img src={`${PhoneBox}`} alt=""></img>
          <Typography
            sx={{
              color: theme.palette.primary.black,
              fontSize: "18px",
              fontWeight: "bold",
              marginTop: { xs: "15px", sm: "30px" },
            }}
          >
            Phone
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.mediumGrey,
              fontSize: "14px",
              marginTop: { xs: "5px", sm: "10px" },
            }}
          >
            09:00 AM - 05:00 PM EST (Mon-Fri) For Sales, Technical or
            GeneralQuestions Please Call 877-42-WeedX
          </Typography>
        </Item>
      </Grid>
      <Grid item xs={12} md={4}>
        <Item
          sx={{
            backgroundColor: theme.palette.primary.white,
            padding: { xs: "0px 20px", lg: "20px 56px" },
          }}
        >
          <img src={`${MailOpened}`} alt=""></img>
          <Typography
            sx={{
              color: theme.palette.primary.black,
              fontSize: "18px",
              fontWeight: "bold",
              marginTop: { xs: "15px", sm: "30px" },
            }}
          >
            Email
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.mediumGrey,
              fontSize: "14px",
              marginTop: { xs: "5px", sm: "10px" },
            }}
          >
            info@weedxhub.com
          </Typography>
        </Item>
      </Grid>
    </Grid>
  );
}

export default InfoSection;
