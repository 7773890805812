import { Box, Grid } from "@mui/material";
import { Typography } from "@mui/material";
import Inventory from "../../../Assets/Icons/inventory.svg";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import SearchFilter from "../../../Assets/Icons/SearchFilter.svg";
import Connect from "../../../Assets/Icons/Connect.svg";
import MakeSales from "../../../Assets/Icons/MakeSales.svg";
import theme from "../../../theme";

const Item = styled(Paper)(({ theme }) => ({
  boxShadow:
    "0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.04), 0px 0px 4px 4px rgba(0, 0, 0, 0.04)",
  textAlign: "center",
  height: "240px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
}));

function HowItWorks() {
  return (
    <Box sx={{ padding: "1.5rem" }}>
      <Grid container sx={{ marginBottom: "3rem" }}>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          mt={{ lg: 4 }}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              fontSize: "28px",
              color: theme.palette.primary.black,
              fontWeight: "600",
            }}
          >
            How It Works
          </Typography>
          <Typography
            sx={{
              width: { sm: "60vw" },
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              fontSize: { xs: "14px", sm: "16px" },
              color: theme.palette.primary.mediumGrey,
            }}
          >
            Join a network and cultivate a community that generates sales for
            you. Unleash the genuine potential of a marketplace, and establish
            lasting value.
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={{ md: 3 }}
        sx={{
          padding: { sm: "0 10rem", md: "0rem", lg: "0 10rem" },
          rowGap: { xs: "1rem", lg: "0px" },
        }}
      >
        <Grid item xs={12} md={3}>
          <Item
            sx={{
              backgroundColor: theme.palette.primary.white,
              padding: { xs: "0px 20px", lg: "20px 56px" },
            }}
          >
            <img src={`${Inventory}`} alt=""></img>
            <Typography
              sx={{
                color: theme.palette.primary.black,
                fontSize: "18px",
                fontWeight: "bold",
                marginTop: { xs: "15px", sm: "30px" },
              }}
            >
              Inventory
            </Typography>
            <Typography
              sx={{
                color: theme.palette.primary.mediumGrey,
                fontSize: "14px",
                marginTop: { xs: "5px", sm: "10px" },
              }}
            >
              Easily sync your current or future inventory
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12} md={3}>
          <Item
            sx={{
              backgroundColor: theme.palette.primary.white,
              padding: { xs: "0px 20px", lg: "20px 56px" },
            }}
          >
            <img src={`${SearchFilter}`} alt=""></img>
            <Typography
              sx={{
                color: theme.palette.primary.black,
                fontSize: "18px",
                fontWeight: "bold",
                marginTop: { xs: "15px", sm: "30px" },
              }}
            >
              Search & Filter
            </Typography>
            <Typography
              sx={{
                color: theme.palette.primary.mediumGrey,
                fontSize: "14px",
                marginTop: { xs: "5px", sm: "10px" },
              }}
            >
              Search & Filter the largest wholesale catalog
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12} md={3}>
          <Item
            sx={{
              backgroundColor: theme.palette.primary.white,
              padding: { xs: "0px 20px", lg: "20px 56px" },
            }}
          >
            <img src={`${Connect}`} alt=""></img>
            <Typography
              sx={{
                color: theme.palette.primary.black,
                fontSize: "18px",
                fontWeight: "bold",
                marginTop: { xs: "15px", sm: "30px" },
              }}
            >
              Connect
            </Typography>
            <Typography
              sx={{
                color: theme.palette.primary.mediumGrey,
                fontSize: "14px",
                marginTop: { xs: "5px", sm: "10px" },
              }}
            >
              Connect & message on products
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={12} md={3}>
          <Item
            sx={{
              backgroundColor: theme.palette.primary.white,
              padding: { xs: "0px 20px", lg: "20px 56px" },
            }}
          >
            <img src={`${MakeSales}`} alt=""></img>
            <Typography
              sx={{
                color: theme.palette.primary.black,
                fontSize: "18px",
                fontWeight: "bold",
                marginTop: { xs: "15px", sm: "30px" },
              }}
            >
              Make sales
            </Typography>
            <Typography
              sx={{
                color: theme.palette.primary.mediumGrey,
                fontSize: "14px",
                marginTop: { xs: "5px", sm: "10px" },
              }}
            >
              Contact & make sales
            </Typography>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HowItWorks;
