// import styles from "components/common/CustomTable/TableSort/TableSort.module.css";
import { Stack } from "@mui/material";

import RawIcon from "../../Assets/Icons/Table/sortRaw.svg";
import AscendingIcon from "../../Assets/Icons/Table/sortAscending.svg";
import DescendingIcon from "../../Assets/Icons/Table/sortDescending.svg";

function TableSort({ head, span, tableSort, setTableSort }) {
  if (head.id === tableSort.id) {
    if (tableSort.order === "ascending")
      return (
        <Stack direction="row" alignItems="center">
          <span>{span}</span>
          <img
            style={{ marginTop: "-2.4px", cursor: "pointer" }}
            src={AscendingIcon}
            onClick={() => setTableSort({ id: head.id, order: "descending" })}
            alt="ascending icon"
          />
        </Stack>
      );

    if (tableSort.order === "descending")
      return (
        <Stack direction="row" alignItems="center">
          <span>{span}</span>
          <img
            src={DescendingIcon}
            style={{ marginTop: "-2.4px", cursor: "pointer" }}
            onClick={() => setTableSort({ id: head.id, order: "raw" })}
            alt="descending icon"
          />
        </Stack>
      );
  }

  return (
    <Stack direction="row" alignItems="center">
      <span>{span}</span>
      <img
        src={RawIcon}
        style={{ marginTop: "-2.4px", cursor: "pointer" }}
        onClick={() => {
          setTableSort({ id: head.id, order: "ascending" });
        }}
        alt="raw icon"
      />
    </Stack>
  );
}

export default TableSort;
