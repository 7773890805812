import { Box, Stack } from "@mui/material";

import icon from "../../../../Assets/Icons/SellerProfile/exclamatory.svg";
import PrimaryButton from "../../../../Components/Custom/PrimaryButton";

function LoginModal({ handleSubmit }) {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "0",
        width: "100%",
        height: "100%",
        backdropFilter: "blur(4px)",
        zIndex: "999",
      }}
    >
      <Stack
        alignItems={"center"}
        spacing={3}
        sx={{
          padding: "24px",
          width: { lg: "400px", xs: "300px" },
          margin: "200px auto",
          bgcolor: "#fff",
          boxShadow: "0px 32px 64px -12px rgba(16, 24, 40, 0.32)",
          borderRadius: "4px",
        }}
      >
        <img
          style={{
            width: "64px",
          }}
          src={icon}
          alt=""
        />
        <span
          style={{
            fontSize: "20px",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          Login Required
        </span>
        <span
          style={{
            fontSize: "14px",
            fontWeight: "300",
            color: "#596570",
          }}
        >
          Login for access to products.
        </span>
        <PrimaryButton onClick={handleSubmit} width="100%">
          Login
        </PrimaryButton>
      </Stack>
    </Box>
  );
}

export default LoginModal;
