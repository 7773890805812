import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "../../../../theme";
import { ReactComponent as TickIcon } from "../../../../Assets/Icons/TickIcon.svg";
import { ReactComponent as LogoWhite } from "../../../../Assets/Icons/LogoWhite.svg";
import { ReactComponent as Visa } from "../../../../Assets/Icons/Visa.svg";
import AuthImage from "../../../../Assets/Images/auth.png";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePickerField from "../../../../Components/Custom/DatePickerField";
import PrimaryButton from "../../../../Components/Custom/PrimaryButton";
import axios from "axios";
import { useSelector } from "react-redux";
import HamburgerMenu from "../../../../Components/Custom/HamburgerMenu";
import heroSubTabBannerGreen from "../../../../Assets/Images/heroSubTabBannerGreen.png";
import MenuIcon from "@mui/icons-material/Menu";
import restClient from "../../../../util/restClient";
import CustomPrimaryButton from "../../../../Components/Custom/CustomPrimaryButton";
import HttpsIcon from "@mui/icons-material/Https";

const FeaturedProductCard = () => {
  return (
    <Box
      sx={{
        position: { sm: "absolute" },
        top: { sm: "0%" },
        bottom: { sm: "0%" },
        left: { sm: "0%" },
        width: { sm: "50vw" },
        height: { sm: "100%" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "2rem",
      }}
    >
      <Box
        sx={{
          background: theme.palette.primary.dashboardBack,
          borderRadius: "10px",
          padding: { xs: 3, md: 5 },
          boxShadow:
            "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
          marginTop: { xs: "30px", md: "50px", lg: "0px" },
          maxWidth: { xs: "fit-content", sm: "auto" },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "32px", lg: "40px" },
            fontWeight: "600",
            color: theme.palette.primary.black,
          }}
        >
          Featured Product
        </Typography>

        <Typography
          sx={{
            fontSize: { xs: "50px", lg: "64px" },
            fontWeight: "700",
            color: theme.palette.primary.peachRed,
          }}
        >
          $99
          <span
            style={{
              fontSize: "20px",
              fontWeight: "400",
              color: theme.palette.primary.footerGrey,
            }}
          >
            / mo / per post
          </span>
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "12px", lg: "16px" },
            fontWeight: "300",
            color: theme.palette.primary.black,
          }}
        >
          Get a 600% more views and inquiries
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: { xs: "10px", sm: "5px", lg: "10px" },
            marginTop: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
            }}
          >
            <TickIcon />
            <Typography
              sx={{
                fontSize: { xs: "12px", lg: "16px" },
                fontWeight: "500",
                color: theme.palette.primary.mediumGrey,
              }}
            >
              Feature product on main page
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
            }}
          >
            <TickIcon />
            <Typography
              sx={{
                fontSize: { xs: "12px", lg: "16px" },
                fontWeight: "500",
                color: theme.palette.primary.mediumGrey,
              }}
            >
              Get more direct messages
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
            }}
          >
            <TickIcon />
            <Typography
              sx={{
                fontSize: { xs: "12px", lg: "16px" },
                fontWeight: "500",
                color: theme.palette.primary.mediumGrey,
              }}
            >
              Showcase product
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
            }}
          >
            <TickIcon />
            <Typography
              sx={{
                fontSize: { xs: "12px", lg: "16px" },
                fontWeight: "500",
                color: theme.palette.primary.mediumGrey,
              }}
            >
              Get in front of all dispensaries
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
            }}
          >
            <TickIcon />
            <Typography
              sx={{
                fontSize: { xs: "12px", lg: "16px" },
                fontWeight: "500",
                color: theme.palette.primary.mediumGrey,
              }}
            >
              Boost your brands
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
            }}
          >
            <TickIcon />
            <Typography
              sx={{
                fontSize: { xs: "12px", lg: "16px" },
                fontWeight: "500",
                color: theme.palette.primary.mediumGrey,
              }}
            >
              Unlocking success
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
            }}
          >
            <TickIcon />
            <Typography
              sx={{
                fontSize: { xs: "12px", lg: "16px" },
                fontWeight: "500",
                color: theme.palette.primary.mediumGrey,
              }}
            >
              No commission fees
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const FeaturedProductPayment = () => {
  const [cardDetails, setCardDetails] = useState([]);
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);
  const [formData, setFormData] = useState({
    startDate: "",
    customerProfileId: "",
    customerPaymentProfileId: "",
  });

  const selectUserAuth = (state) => state.userRegReducer?.userAuth;
  const userData = useSelector(selectUserAuth);
  const id = useParams().id;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  function formatDateToYYYYMMDD(date) {
    if (!(date instanceof Date)) {
      throw new Error("Input must be a Date object");
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  const handleDateChange = (date) => {
    const formattedDate = formatDateToYYYYMMDD(new Date(date));
    console.log(formattedDate);
    setFormData({ ...formData, startDate: formattedDate });
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await restClient({
        method: "POST",
        url: `/user/feature-post/${id}`,
        data: { data: formData },
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      console.log(response, "featured product");
      if (response?.data?.success) {
        navigate("/product-management", { state: { status: "featured" } });
      }
    } catch (err) {
      console.log(err.response);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getCardDetails = async () => {
      try {
        // const response = await axios.get(
        //   "https://staging.weedxhub.com/api/user/get-customer-profile",
        //   { headers: { Authorization: `Bearer ${userData?.token}` } },
        // );
        const response = await restClient({
          method: "GET",
          url: "/user/get-customer-profile",
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        });

        console.log(response, "subscriptions");
        let cardData = [];
        if (response?.data?.success) {
          setFormData({
            ...formData,
            customerProfileId:
              response?.data?.data?.paymentProfile?.customerProfileId,
          });
          const res =
            response?.data?.data?.subscription.profile?.paymentProfiles;
          const customerProfileId =
            response?.data?.data?.subscription.profile?.customerProfileId;
          const defaultCustomerPaymentProfileId =
            response?.data?.data?.subscription?.defaultCustomerPaymentProfileId;

          for (let i = 0; i < res.length; i++) {
            let card = {};
            card = {
              ...res[i]?.payment?.creditCard,
              name: res[i]?.billTo?.firstName + " " + res[i]?.billTo?.lastName,
              customerPaymentProfileId: res[i]?.customerPaymentProfileId,
              customerProfileId,
              defaultCustomerPaymentProfileId:
                res[i]?.customerPaymentProfileId ===
                defaultCustomerPaymentProfileId
                  ? true
                  : false,
            };
            cardData.push(card);
          }
        }

        setCardDetails([...cardData]);
      } catch (err) {
        console.log(err.response);
      }
    };
    getCardDetails();
  }, [userData?.token]);

  console.log(cardDetails, "cardDetails");
  console.log("formData", formData);
  return (
    <Grid container>
      {openHamburgerMenu && (
        <HamburgerMenu onClose={() => setOpenHamburgerMenu(false)} />
      )}
      <Grid
        item
        container
        sx={{
          padding: { xs: "1.5rem", sm: "0rem 3rem", lg: "0 10rem" },
          background: `url(${heroSubTabBannerGreen})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: { sm: "none" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
          container
        >
          <Link to="/">
            <LogoWhite />
          </Link>
          <Box onClick={() => setOpenHamburgerMenu(true)}>
            <MenuIcon sx={{ color: "white", width: "25px", height: "25px" }} />
          </Box>
        </Grid>
      </Grid>
      <ToastContainer />
      <Grid item xs={6} sx={{ display: { xs: "none", sm: "inline" } }}>
        <Box
          sx={{
            background: `url(${AuthImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            minHeight: "100vh",
            height: "100%",
            position: "relative",
            width: "100%",
            backgroundPositionY: "bottom",
          }}
        >
          <FeaturedProductCard />
          <Box sx={{ padding: 2, position: "absolute", top: 0, zIndex: 100 }}>
            <Link to="/">
              <LogoWhite />
            </Link>
          </Box>
          <Box sx={{ position: "absolute", bottom: 0, padding: 2 }}>
            <Typography
              sx={{
                color: theme.palette.primary.white,
                fontSize: { xs: "12px", lg: theme.typography.fontSize },
                fontWeight: "300",
              }}
            >
              © Copyright 2024, All Rights Reserved by WeedX
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          my: { xs: 3, lg: 5 },
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: { xs: "28px", sm: "24px", md: "32px" },
            color: theme.palette.primary.inputLabelColor,
          }}
        >
          Secure checkout
        </Typography>
        <Box sx={{ width: { xs: "90%", sm: "80%", lg: "70%" } }}>
          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: theme.palette.primary.inputLabelColor,
                mt: 2,
              }}
            >
              Marketing Service
            </Typography>
            <Box sx={{ ml: 2, mt: 1 }}>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: { xs: "14px", xl: "16px" },
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                &#8226; 30 days Featured Post on Marketplace
              </Typography>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: { xs: "14px", xl: "16px" },
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                &#8226; 2 Featured Marketing Emails
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 500,
                  color: theme.palette.primary.inputLabelColor,
                }}
              >
                Start date
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  mt: 1,
                }}
              >
                <DatePickerField onChange={handleDateChange} />
              </Box>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 500,
                color: theme.palette.primary.inputLabelColor,
              }}
            >
              Please click on payment method
            </Typography>

            {cardDetails && cardDetails?.length > 0 && (
              <Box
                className="scrollbar"
                sx={{ mt: 2, maxHeight: "100px", overflowY: "auto" }}
              >
                {cardDetails &&
                  cardDetails.length > 0 &&
                  cardDetails.map((card, index) => (
                    <Box sx={{ position: "relative" }}>
                      {card.defaultCustomerPaymentProfileId && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bgcolor: theme.palette.primary.main,
                            color: theme.palette.primary.white,
                            padding: "2px 6px",
                            borderRadius: "0px 0px 0px 4px",
                            fontSize: "12px",
                          }}
                        >
                          Primary
                        </Box>
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          columnGap: "15px",
                          background: "white",
                          borderRadius: "5px",
                          boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
                          py: 1,
                          px: 3,
                          mb: 1,
                          border:
                            card.customerPaymentProfileId ===
                            formData.customerPaymentProfileId
                              ? "2px solid"
                              : "none",
                          borderColor: theme.palette.primary.main,
                          cursor: "pointer",
                        }}
                        key={index}
                        onClick={() => {
                          console.log("hi");

                          setFormData({
                            ...formData,
                            customerPaymentProfileId:
                              card?.customerPaymentProfileId,
                            customerProfileId: card?.customerProfileId,
                          });
                        }}
                      >
                        <Visa />
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: { xs: "12px", sm: "10px", xl: "14px" },
                            color: theme.palette.primary.inputLabelColor,
                          }}
                        >
                          {card?.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: "12px", sm: "10px", xl: "14px" },
                            fontWeight: 300,
                            color: theme.palette.primary.mediumGrey,
                          }}
                        >
                          {card?.cardNumber}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: "12px", sm: "10px", xl: "14px" },
                            fontWeight: 300,
                            color: theme.palette.primary.mediumGrey,
                          }}
                        >
                          Expiry: {card?.expirationDate}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
              </Box>
            )}
            <Box
              sx={{
                background: "white",
                borderRadius: "3px",
                p: 3,
                my: 2,
                display: "flex",
                flexDirection: "column",
                rowGap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "12px", xl: "14px" },
                    fontWeight: 300,
                    color: theme.palette.primary.mediumGrey,
                  }}
                >
                  Marketing Fee
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "12px", xl: "14px" },
                    fontWeight: 300,
                    color: theme.palette.primary.inputLabelColor,
                  }}
                >
                  $96
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "12px", xl: "14px" },
                    fontWeight: 300,
                    color: theme.palette.primary.mediumGrey,
                  }}
                >
                  Platform Fee
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "12px", xl: "14px" },
                    fontWeight: 300,
                    color: theme.palette.primary.inputLabelColor,
                  }}
                >
                  $3
                </Typography>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "12px", xl: "14px" },
                    fontWeight: 300,
                    color: theme.palette.primary.mediumGrey,
                  }}
                >
                  Total Amount
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "12px", xl: "14px" },
                    fontWeight: 300,
                    color: theme.palette.primary.peachRed,
                  }}
                >
                  $99
                </Typography>
              </Box>
            </Box>
            <CustomPrimaryButton
              isLoading={isLoading}
              onClick={handleSubmit}
              disabled={
                formData.customerPaymentProfileId === "" ||
                formData.startDate === "" ||
                isLoading
              }
            >
              Confirm Payment
            </CustomPrimaryButton>
            <Stack
              marginTop={"10px"}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              spacing={0.6}
              sx={{ color: "#555555", fontSize: "14px", fontWeight: "500" }}
            >
              <HttpsIcon sx={{ fontSize: "16px" }} />
              <Box>SSL Secure Payment</Box>
            </Stack>
            <FeaturedProductCard />
            <Typography
              sx={{
                fontSize: { xs: "12px", xl: "14px" },
                fontWeight: 500,
                color: theme.palette.primary.black,
                textAlign: "center",
                mt: 2,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/product-management");
              }}
            >
              Skip
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FeaturedProductPayment;
