import React from "react";
//import { ReactComponent as ListViewIcon } from "../../Assets/Icons/ListView.svg";
//import { ReactComponent as GridViewIcon } from "../../Assets/Icons/GridView.svg";
import { Grid } from "@mui/material";
import theme from "../../theme";
import SearchInputWithIcon from "./SearchInput";

export default function InnerPageTopBar(props) {
  return (
    <Grid
      container
      my={5}
      sx={{
        padding: props.padding ? props.padding : { xs: "0rem", lg: "0 10rem" },
        width: props.width ? props.width : "inherit",
      }}
    >
      <Grid
        container
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          background: theme.palette.primary.white,
          padding: "0 10px",
          justifyContent: "space-between",
        }}
      >
        {/* <Grid
            item
            xs={2}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: theme.palette.primary.black,
                fontSize: theme.typography.fontSize,
                fontWeight: "500",
              }}
            >
              Row per page:
            </Typography>
            <Box>
              <select
                name="PageDropDown"
                id="PageDropDown"
                onChange={handlePageSizeChange}
                style={{
                  fontSize: "14px",
                  fontWeight: "300",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                <option value={2}>2</option>
                <option value={4}>4</option>
                <option value={6}>6</option>
                <option value={8}>8</option>
                <option value={10}>10</option>
              </select>
            </Box>
          </Grid> */}
        <Grid
          item
          xs={10}
          sx={{
            padding: "5px",
          }}
        >
          <SearchInputWithIcon
            placeholder={
              props.placeholder ? props.placeholder : "Search (Ctrl+/)"
            }
            callbackFunction={props.getSearchResults}
          />
        </Grid>
        {/* <Grid item xs={2} display={"flex"} justifyContent={"flex-end"}>
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.03)",
                cursor: "pointer",
                borderRadius: "5px",
              }}
            >
              <Box
                sx={{
                  padding: "10px",
                }}
              >
                <ListViewIcon />
              </Box>
              <Box
                sx={{
                  padding: "10px",
                }}
              >
                <GridViewIcon />
              </Box>
            </Box>
          </Grid> */}
      </Grid>
    </Grid>
  );
}
