import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import PrimaryHeading from "../../../Components/Custom/PrimaryHeading";
import LoginImage from "../../../Assets/Images/Login.png";
import theme from "../../../theme";
import { Link } from "react-router-dom";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import { ReactComponent as EmailImage } from "../../../Assets/Icons/EmailIcon.svg";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  resendAsyncOTP,
  verifyAsyncOTP,
} from "../SignUpIntegration/userRegSlice";
import { useParams } from "react-router-dom";
import HamburgerMenu from "../../../Components/Custom/HamburgerMenu";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as LogoWhite } from "../../../Assets/Icons/LogoWhite.svg";
import heroSubTabBanner from "../../../Assets/Images/heroSubTabBanner.png";

export default function VerifyOTP(props) {
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);
  const [otp, setOtp] = useState("");
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectUserAuth = (state) => state.userRegReducer?.userAuth;
  const userData = useSelector(selectUserAuth);

  console.log(userData, "");

  const handleSubmit = async () => {
    dispatch(verifyAsyncOTP({ OTP: otp, token: token })).then((res) => {
      console.log("then", res);
      if (res.payload.success) {
        toast.success(`${res.payload.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          navigate("/resetPassword");
        }, 1000);
      } else {
        toast.error(`${res.payload.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const resendOTP = async (email) => {
    dispatch(resendAsyncOTP({ email: email })).then((res) => {
      console.log("then", res);
      if (res.payload.success) {
        toast.success(`${res.payload.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`${res.payload.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  return (
    <>
      <Grid container>
        <ToastContainer />
        {openHamburgerMenu && (
          <HamburgerMenu onClose={() => setOpenHamburgerMenu(false)} />
        )}
        <Grid
          item
          container
          sx={{
            padding: { xs: "1.5rem", sm: "0rem 3rem", lg: "0 10rem" },
            background: `url(${heroSubTabBanner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: { sm: "none" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
            container
          >
            <Link to={"/"}>
              <LogoWhite />
            </Link>
            <Box onClick={() => setOpenHamburgerMenu(true)}>
              <MenuIcon
                sx={{ color: "white", width: "25px", height: "25px" }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={6} sx={{ display: { xs: "none", sm: "inline" } }}>
          <Box
            sx={{
              background: `url(${
                props.backImage ? props.backImage : LoginImage
              })`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "100vh",
              position: "relative",
              width: "100%",
              backgroundPositionY: "bottom",
            }}
          >
            <Box sx={{ padding: 2, position: "absolute", top: 0, zIndex: 100 }}>
              <Link to={"/"}>
                <LogoWhite />
              </Link>
            </Box>
            <Box sx={{ position: "absolute", bottom: 0, padding: 2 }}>
              <Typography
                sx={{
                  color: theme.palette.primary.white,
                  fontSize: { xs: "12px", lg: theme.typography.fontSize },
                  fontWeight: "300",
                }}
              >
                © Copyright 2024, All Rights Reserved by WeedX
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            overflow: "auto",
          }}
        >
          {/* <Button
            sx={{
              padding: "30px",
              textTransform: "capitalize",
            }}
          >
            <ArrowLeft />
            <Typography
              pl={1}
              sx={{
                color: theme.palette.primary.black,
              }}
            >
              Go back
            </Typography>
          </Button> */}
          <Box
            sx={{
              px: { xs: 3, sm: 5, md: 10, lg: 25 },
              py: 5,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              // width: '100%',
              height: "80vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box mb={2}>
                <EmailImage />
              </Box>
              <PrimaryHeading
                color={theme.palette.primary.inputLabelColor}
                fontSize={{ xs: "28px" }}
                fontWeight="600"
              >
                Check your email
              </PrimaryHeading>
              <Typography
                sx={{
                  color: theme.palette.primary.mediumGrey,
                  fontSize: "14px",
                  mb: 3,
                  mt: { xs: 1, lg: 2 },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                Enter code from email{" "}
                <strong>{userData?.sendData?.userEmail}</strong> we just sent
                you. If you don’t see it, check spam
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                // renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={"otp-input"}
                isInputNum={true} // Allow only numbers in input
                shouldAutoFocus={true} // Autofocus the first input
              />
            </Box>

            <Box
              sx={{
                width: "100%",
              }}
            >
              <PrimaryButton
                text="Verify Email"
                width="100%"
                margin={4}
                onClick={() => handleSubmit()}
                //   onClick={() => setForgotPassword("newPassword")}
              >
                Verify email
              </PrimaryButton>

              <Box display={"flex"} justifyContent={"center"} mt={3}>
                <Typography
                  sx={{
                    color: theme.palette.primary.black,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  Didn’t receive the email?
                  <Link
                    style={{
                      color: theme.palette.primary.main,
                      paddingLeft: "10px",
                      textDecoration: "none",
                    }}
                    onClick={() => resendOTP(userData?.sendData?.userEmail)}
                  >
                    Click to resend
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
