import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";

import WriteReview from "./WriteReview/WriteReview";
import Ratings from "./Ratings/Ratings";
import CustomerReview from "./CustomerReview/CustomerReview";
import LoginModal from "../components/LoginModal";
import restClient from "../../../../../util/restClient";

function Reviews({ productOwnerID }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useSelector((state) => state.userRegReducer?.userAuth);
  const [isLoader, setIsLoader] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const getReviews = async () => {
    try {
      setIsLoader(true);
      const response = await restClient({
        method: "GET",
        url: `/user/review/dispensaries/${id}`,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      setReviews(response?.data?.data?.reviews || []);
      setStatistics(response?.data?.data?.statistics || {});
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    getReviews();
  }, []);

  useEffect(() => {
    if (!userData.authorized) setOpenLoginModal(true);
    else setOpenLoginModal(false);
  }, [userData]);

  if (!id) return <div>Dispensary not found. Add a dispensary first !!</div>;

  return (
    <Stack sx={{ position: "relative" }} spacing={{ xs: 2, lg: 3 }}>
      {openLoginModal && (
        <LoginModal
          handleSubmit={() =>
            navigate("/login", {
              state: { redirectTo: location.pathname },
              replace: false,
            })
          }
        />
      )}
      {userData?.user?._id !== productOwnerID && (
        <WriteReview getReviews={getReviews} />
      )}
      <Ratings statistics={statistics} isLoading={isLoader} />
      <CustomerReview
        reviews={reviews}
        getReviews={getReviews}
        isLoading={isLoader}
        productOwnerID={productOwnerID}
      />
    </Stack>
  );
}

export default Reviews;
