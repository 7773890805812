import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { userPostReq, makeHttpRequest, userPatchReq } from "./APIFunction";
import apiRoutes from "../../../Routes/apiRoute";

// adding user Reg part 1
export const addAsyncData = createAsyncThunk("user/addData", async (data) => {
  try {
    const response = await userPostReq(apiRoutes.createUser, data);
    //localStorage.setItem("jwt-access-token", response?.data?.token);
    return response;
  } catch (error) {
    return error.response.data;
  }
});

// Verify OTP
export const verifyAsyncOTP = createAsyncThunk("user/verifyOtp", async (data) => {
  try {
    const response = await makeHttpRequest("POST", apiRoutes.verifyOtp, data);
    console.log(response, "response verify otp");
    return response;
  } catch (error) {
    return error?.response?.data;
  }
});

// Resend OTP
export const resendAsyncOTP = createAsyncThunk("user/resendOtp", async (data) => {
  try {
    const response = await makeHttpRequest("POST", apiRoutes.resendOtp, data);
    return response;
  } catch (error) {
    return error.response.data;
  }
});

// Login User
export const userAsyncLogin = createAsyncThunk("user/userLogin", async (data) => {
  try {
    const response = await userPostReq(apiRoutes.userLogin, data);
    //localStorage.setItem("jwt-access-token", response?.data?.token);
    return response;
  } catch (error) {
    return error.response.data;
  }
});

// User Update Information
export const userUpdateAsync = createAsyncThunk("user/userUpdate", async (data) => {
  try {
    const formData = new FormData();
    console.log(data);
    if (data.file instanceof Blob) {
      formData.append("userImage", data.file);
    }
    formData.append("fullname", data.fullname);
    formData.append("phone", data.phone);
    const response = await userPatchReq(apiRoutes.userUpdate, formData);
    //localStorage.setItem("jwt-access-token", response?.token);
    return response;
  } catch (error) {
    return error.response.data;
  }
});

// Create an async thunk for updating data
export const updateAsyncData = createAsyncThunk("user/updateData", async (data) => {
  console.log(data, "update data called");
  try {
    const response = await makeHttpRequest("PUT", `${apiRoutes.userAdditionDetail}?authorized=${data.authorized}`, data?.data);
    console.log(response, "update data response");
    return response;
  } catch (error) {
    console.log(error?.response?.data, "update data response");
    return error.response.data;
  }
});
export const updateCompanyData = createAsyncThunk("user/updateCompanyData", async (data) => {
  console.log(data, "update data called");
  try {
    const response = await makeHttpRequest("POST", `${apiRoutes.userCompanyDetails}?authorized=${data.authorized}`, data?.data);
    console.log(response, "update data response");
    return response;
  } catch (error) {
    console.log(error?.response?.data, "update data response");
    return error.response.data;
  }
});

// Login User
export const userAsyncForgetPassword = createAsyncThunk("user/forgetPassword", async (data) => {
  try {
    const response = await userPostReq(apiRoutes.forgetPasswordReq, data);
    return response;
  } catch (error) {
    return error.response.data;
  }
});

// Login User
export const userAsyncConfirmPassword = createAsyncThunk("user/confirmPassword", async (data) => {
  try {
    const response = await makeHttpRequest("POST", apiRoutes.changeForgetPassword, data);
    return response;
  } catch (error) {
    return error.response.data;
  }
});

// Create the data slice
const userAuthOneSlice = createSlice({
  name: "user",
  initialState: {
    userAuth: {},
    loading: false,
    error: null,
  },
  reducers: {
    resetUser: (state) => {
      state.userAuth = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addAsyncData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addAsyncData.fulfilled, (state, action) => {
        if (action.payload.success === false) {
          state.loading = false;
          state.error = action.payload.message;
        } else {
          state.loading = false;
          state.userAuth = action.payload.data;
        }
      })
      .addCase(addAsyncData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateCompanyData.fulfilled, (state, action) => {
        if (action.payload.success === false) {
          state.loading = false;
          state.error = action.payload.message;
        } else {
          state.loading = false;
          state.userAuth = action.payload.data;
        }
      })
      .addCase(updateCompanyData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(verifyAsyncOTP.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyAsyncOTP.fulfilled, (state, action) => {
        if (action?.payload?.success === false) {
          state.loading = false;
          state.error = action.payload.message;
        }
      })
      .addCase(verifyAsyncOTP.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateAsyncData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAsyncData.fulfilled, (state, action) => {
        if (action.payload.success === false) {
          state.loading = false;
          state.error = action.payload.message;
        } else if (action.payload.success === true) {
          state.loading = false;
          state.userAuth = action.payload.data;
        }
      })
      .addCase(updateAsyncData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(userAsyncLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userAsyncLogin.fulfilled, (state, action) => {
        if (action.payload.success === false) {
          state.loading = false;
          state.error = action.payload.message;
        } else if (action.payload.success === true) {
          state.loading = false;
          state.userAuth = action.payload.data;
        }
      })
      .addCase(userAsyncLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(userUpdateAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userUpdateAsync.fulfilled, (state, action) => {
        if (action.payload.success === false) {
          state.loading = false;
          state.error = action.payload.message;
        } else if (action.payload.success === true) {
          state.loading = false;
          state.userAuth = action.payload.data;
        }
      })
      .addCase(userUpdateAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(userAsyncForgetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userAsyncForgetPassword.fulfilled, (state, action) => {
        if (action.payload.success === false) {
          state.loading = false;
          state.error = action.payload.message;
        } else if (action.payload.success === true) {
          state.loading = false;
          state.userAuth = action.payload.data;
        }
      })
      .addCase(userAsyncForgetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(userAsyncConfirmPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userAsyncConfirmPassword.fulfilled, (state, action) => {
        if (action.payload.success === false) {
          state.loading = false;
          state.error = action.payload.message;
        } else if (action.payload.success === true) {
          state.loading = false;
        }
      })
      .addCase(userAsyncConfirmPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetUser } = userAuthOneSlice.actions;
export const authReducer = userAuthOneSlice.reducer;
