import { useEffect, useState } from "react";

export const useDebounce = (value) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    let timeoutId = setTimeout(() => {
      //console.log("calling the api");
      setDebouncedValue(value);
    }, 500);
    return () => {
      //console.log("clearing the timer");
      clearTimeout(timeoutId);
    };
  }, [value]);

  return debouncedValue;
};
