import { Box, Grid, Typography } from "@mui/material";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import theme from "../../../theme";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as LogoWhite } from "../../../Assets/Icons/LogoWhite.svg";
import { useState } from "react";
import HamburgerMenu from "../../../Components/Custom/HamburgerMenu";
import TypewriterEffect from "../../../Components/Custom/TypewriterEffect";
import heroImage from "../../../Assets/Images/heroImage.png";

export default function BannerSection() {
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      {openHamburgerMenu && (
        <HamburgerMenu onClose={() => setOpenHamburgerMenu(false)} />
      )}
      <Grid
        container
        sx={{
          padding: { xs: "1.5rem", sm: "0rem 3rem", lg: "0 10rem" },
          background: "linear-gradient(90deg, #FFA078 0%, #FF7B88 100%)",
          pb: 7,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: { sm: "none" },
            justifyContent: "space-between",
            maxHeight: "40px",
          }}
          container
        >
          <Link to={"/"}>
            <LogoWhite />
          </Link>
          <Box onClick={() => setOpenHamburgerMenu(true)}>
            <MenuIcon sx={{ color: "white", width: "25px", height: "25px" }} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "start" },
            padding: { xs: "5rem 0rem", sm: "0rem" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              columnGap: "4px",
            }}
          >
            <TypewriterEffect dataText={["Discover, Connect, Exchange"]} />
          </Box>
          <Typography
            sx={{
              fontSize: { xs: "48px", sm: "55px", md: "80px" },
              fontWeight: theme.typography.heading2.fontWeight,
              color: theme.palette.primary.white,
            }}
          >
            marketplace
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "14px", sm: theme.typography.heading2.fontSize },
              color: theme.palette.primary.white,
              fontWeight: "300",
              mb: 5,
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            Access & source licensed wholesale cannabis products.
          </Typography>
          <PrimaryButton
            width={{ xs: "150px", md: "180px" }}
            backgroundColor={"white"}
            padding={{ xs: "5px 0px", md: "10px 0px" }}
            textTransform="none"
            color={"black"}
            onClick={() => navigate("/signup")}
          >
            Sign Up
          </PrimaryButton>
        </Grid>
        <Grid item xs={6} sx={{ display: { xs: "none", sm: "inline-grid" } }}>
          <img src={heroImage} alt="" />
        </Grid>
      </Grid>
    </>
  );
}
