import React, { useCallback, useEffect, useRef, useState } from "react";
import TopHeader from "../Header/TopHeader";
import MenuBar from "../../../Components/Custom/MenuBar";
import SmallBanner from "../../../Components/Custom/SmalBanner";
import SmallFooter from "../SmallFooter/SmallFooter";
import InnerPageTopBar from "../../../Components/Custom/InnerPageTopBar";
import BlogCard from "./BlogCard";
import { Grid } from "@mui/material";
//import BasicPagination from "../../../Components/Custom/Pagination";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import restClient from "../../../util/restClient";

const formatDate = (enteredDate) => {
  const date = new Date(enteredDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${month}.${day}.${year.toString().slice(2)}`;
};

let currentPage = 1;
let pageSize = 20;

if (window.innerWidth < 600) {
  pageSize = 20;
} else if (window.innerWidth < 1536) {
  pageSize = 16;
} else {
  pageSize = 20;
}

export default function Blog() {
  const [blogs, setBlogs] = useState([]);
  //const [pageCount, setPageCount] = useState(0);
  //const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const observerTarget = useRef(null);

  console.log(observerTarget, "observerTarget");
  console.log(currentPage, "currentPage");
  const navigate = useNavigate();

  const getBlogs = useCallback(async (keyword = "") => {
    setIsLoading(true);
    try {
      // const response=await restClient({
      //   method:'GET',
      //   url:`/user/blog?page=${currentPage}&pageSize=${pageSize}&title=${keyword}`
      // })
      const response = await restClient({
        method: "GET",
        url: `/user/blog?page=${currentPage}&pageSize=${pageSize}&title=${keyword}`,
      });
      console.log(response, "blog response");
      if (response?.status === 200) {
        setBlogs([...response.data.data.blogs]);
        //setPageCount(Math.ceil(response?.data.data.total / pageSize));
        setIsLoading(false);
        pageSize += 10;
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          getBlogs();
        }
      },
      { threshold: 1 }
    );

    let currentTarget = observerTarget.current;

    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [observerTarget, getBlogs]);

  // const handlePageChange = (event, page) => {
  //   setCurrentPage(page);
  // };
  console.log(blogs, "blogs");
  return (
    <>
      <TopHeader />
      <MenuBar isVisible={false} />
      <SmallBanner breadcrumbsMainTitle={"Blog"} breadcrumbsSmallTitle={"Blog"} />
      <Grid
        container
        sx={{
          padding: { xs: "1.5rem", lg: "0 10rem" },
        }}
      >
        <Grid item xs={12}>
          <InnerPageTopBar width="100%" placeholder="Search Blogs" getSearchResults={getBlogs} />
        </Grid>
        {blogs &&
          blogs.length > 0 &&
          blogs?.map((blog) => (
            <Grid key={blog?._id} item xs={12} sm={6} xl={4} sx={{ margin: "1rem 0rem" }}>
              <BlogCard
                blogViewImage={blog?.bannerImage}
                time={formatDate(blog?.time)}
                createdBy={blog?.createdBy}
                title={blog?.title}
                onClick={() => navigate(`/blog-details/${blog?._id}`)}
              />
            </Grid>
          ))}
      </Grid>

      <Grid
        container
        sx={{
          padding: { xs: "1.5rem", lg: "0 10rem" },
          my: { xs: 1, sm: 3, lg: 5 },
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <BasicPagination
            handlePageChange={handlePageChange}
            count={pageCount}
            currentPage={currentPage}
          /> */}
        </Grid>
      </Grid>
      {isLoading && <p style={{ textAlign: "center" }}>Loading...</p>}
      <div ref={observerTarget}></div>
      <SmallFooter />
    </>
  );
}
