import { Button } from "@mui/material";

function SecondaryButton({ onClick, children }) {
  return (
    <Button
      sx={{
        color: "#1B8A79",
        border: "2px solid #1B8A79",

        "&:hover": {
          color: "#1B8A79",
          border: "2px solid #1B8A79",
        },
      }}
      onClick={onClick}
      variant="outlined"
    >
      {children}
    </Button>
  );
}

export default SecondaryButton;
