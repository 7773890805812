const validateFormData = (formData) => {
  let errors = {};
  if (!formData.product.productName)
    errors.productName = "Product Name is required *";

  if (!formData.product.productPrice)
    errors.productPrice = "Product Price is required *";

  if (!formData.product.productBrand)
    errors.productBrand = "Product Brand is required *";

  if (!formData.description)
    errors.description = "Product Description is required *";

  if (!formData.details.Category) errors.Category = "Category is required *";

  if (!formData.details.SubCategory)
    errors.SubCategory = "SubCategory is required *";

  if (!formData.details.Quantity) errors.Quantity = "Quantity is required *";

  // if (!formData.details.State) errors.State = "State is required *";

  // if (!formData.details.Unit) errors.Unit = "Unit is required *";
  if (!formData.details["Product Location"])
    errors["Product Location"] = "Product Location is required *";

  return errors;
};

export default validateFormData;
