import { useState } from "react";
import { Stack, CircularProgress, Box } from "@mui/material";

import BoxWrapper from "../components/BoxWrapper";
import InputWrapper from "../components/InputWrapper";
import Features from "../components/Features";
import ImageModal from "../../../../Components/Custom/ImageModal";

function About({ isLoader, brandData }) {
  const [imageSelected, setImageSelected] = useState("");
  return (
    <>
      {Boolean(imageSelected) && (
        <ImageModal
          imageSelected={imageSelected}
          closeModal={() => setImageSelected("")}
        />
      )}
      <Stack spacing={4}>
        <BoxWrapper heading={"Business Info"}>
          {isLoader ? (
            <Stack alignItems={"center"}>
              <CircularProgress />
            </Stack>
          ) : (
            <Stack sx={{ marginTop: "12px" }} spacing={1.8}>
              {[
                { name: "Brand Name", label: "Business Name" },
                { name: "State License", label: "License" },
                { name: "Brand Location", label: "Address" },
                { name: "Website", label: "Website" },
                { name: "email", label: "Email" },
                { name: "phoneNumber", label: "Phone Number" },
              ].map((item, i) => (
                <Box key={i}>
                  <InputWrapper label={item.label}>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "300",
                        color: "#363636",
                      }}
                    >
                      {brandData[item.name] || ""}
                    </span>
                  </InputWrapper>
                </Box>
              ))}
            </Stack>
          )}
        </BoxWrapper>

        <BoxWrapper heading={"About us"}>
          {isLoader ? (
            <Stack alignItems={"center"}>
              <CircularProgress />
            </Stack>
          ) : (
            <Box sx={{ marginTop: "12px", fontWeight: "300" }}>
              {brandData["About us"] || ""}
            </Box>
          )}
        </BoxWrapper>
        <BoxWrapper heading={"Key Features"}>
          {isLoader ? (
            <Stack alignItems={"center"}>
              <CircularProgress />
            </Stack>
          ) : (
            <Box sx={{ marginTop: "12px" }}>
              <Features features={brandData?.keyFeatures || []} />
            </Box>
          )}
        </BoxWrapper>
        <BoxWrapper heading={"Photos"}>
          {isLoader ? (
            <Stack alignItems={"center"}>
              <CircularProgress />
            </Stack>
          ) : (
            <Box sx={{ marginTop: "12px" }}>
              {brandData.images?.length > 0 && (
                <Stack sx={{ padding: "8px 0" }} direction={"row"} spacing={1}>
                  {brandData.images.map((item, index) => {
                    return (
                      <Box
                        width={{ xs: "60px", sm: "112px" }}
                        height={{ xs: "60px", sm: "112px" }}
                        sx={{
                          overflow: "hidden",
                          position: "relative",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                        key={index + "key"}
                        onClick={() => setImageSelected(item)}
                      >
                        <img style={{ width: "100%" }} src={item} alt="" />
                      </Box>
                    );
                  })}
                </Stack>
              )}
            </Box>
          )}
        </BoxWrapper>
      </Stack>
    </>
  );
}

export default About;
