const variableType = (variable) => {
  return typeof variable;
};

export const stringTruncate = (str, maxLength) => {
  const endChar = "…"; // character to insert into the center of the result

  if (str.length <= maxLength) return str;

  return str.substr(0, maxLength) + endChar;
};

export const removeQueryParameters = (imageUrl) => {
  // Check if the URL contains a query string
  if (imageUrl?.includes("?")) {
    // Split the URL into the base URL and the query string
    const [baseUrl, queryString] = imageUrl.split("?");

    // Create a new URL without the query string
    const newImageUrl = baseUrl;

    return newImageUrl;
  } else {
    // If there are no query parameters, return the original URL as is
    return imageUrl;
  }
};

export const formatNumber = (value) => {
  if (!value) return "-";

  const formattedValue = new Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(value);

  return formattedValue;
};

export const sortDataByField = (tableData, tableSort, setSortedData) => {
  const field = tableSort.id;

  if (tableSort.order === "raw") setSortedData(tableData);

  if (tableSort.order === "ascending") {
    setSortedData(
      tableData?.slice().sort((a, b) => {
        if (variableType(a[field]) === "number") return a[field] - b[field];

        if (variableType(a[field]) === "string")
          return a[field]?.localeCompare(b[field]);
      }),
    );
  }

  if (tableSort.order === "descending")
    setSortedData(
      tableData?.slice().sort((a, b) => {
        if (variableType(a[field]) === "number") return b[field] - a[field];

        if (variableType(a[field]) === "string")
          return b[field]?.localeCompare(a[field]);
      }),
    );
};
