import { Grid, Typography } from "@mui/material";
import theme from "../../../theme";
import MarketPlace from "../../../Assets/Images/marketplace.png";

export default function MarketPlaceSection() {
  return (
    <>
      <Grid
        container
        sx={{
          padding: { xs: "1.5rem", sm: "2rem", lg: "5rem" },
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{ padding: { sm: "0px 1rem 0px 0px", lg: "0px 3rem 0px 0px" } }}
        >
          <Typography
            sx={{
              fontSize: { xs: "36px", sm: "30px", lg: "40px" },
              textAlign: { xs: "center", sm: "left" },
              fontWeight: "600",
              color: theme.palette.primary.black,
            }}
          >
            The Fastest Growing Marketplace
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: "14px",
                sm: "12px",
                lg: theme.typography.fontSize16,
              },
              color: theme.palette.primary.mediumGrey,
              mt: { xs: 2, lg: 4 },
              display: "flex",
              alignItems: "center",
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            At Weed X, we are passionate about empowering cannabis professionals
            and driving the industry forward. Through our innovative features,
            we aim to provide a comprehensive platform that encompasses product
            visibility, social networking, and financial transparency. Join us
            today and become a part of a thriving community that is shaping the
            future of the cannabis industry. Together, let's grow, connect, and
            succeed!
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img src={`${MarketPlace}`} alt="" style={{ width: "100%" }} />
        </Grid>
      </Grid>
    </>
  );
}
