export const checkURLType = (url) => {
  const imageRegex = /\.(jpg|jpeg|png|gif|bmp|blob)$/i;
  const videoRegex = /\.(mp4|avi|mov|wmv|flv)$/i;

  if (imageRegex.test(url)) return "image";
  if (videoRegex.test(url)) return "video";

  return "image";
};

export const checkFileType = (file) => {
  if (file?.type?.includes("image")) return "image";
  if (file?.type?.includes("video")) return "video";
};
