import { useState, useRef, useEffect } from "react";
import { Box } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Popup from "../../../../Components/Custom/Popup";
import theme from "../../../../theme";

function ActionPopup({
  popUpIcon = null,
  hoverColor = "",
  popupValues,
  id,
  status = "",
}) {
  const popupRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef]);

  return (
    <Box>
      <Box ref={popupRef} sx={{ position: "relative" }}>
        {popUpIcon?.icon ? (
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowPopup((prev) => !prev);
            }}
          >
            {popUpIcon.icon}
          </div>
        ) : (
          <MoreVertIcon
            sx={{
              fontSize: "20px",
              color: theme.palette.primary.black,
              cursor: "pointer",
              "&:hover": {
                color: theme.palette.primary.main,
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              setShowPopup((prev) => !prev);
            }}
          />
        )}

        {showPopup && (
          <Popup>
            {popupValues &&
              popupValues?.length > 0 &&
              popupValues?.map((value, index) => (
                <span
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (status && status === "draft") {
                      value?.callbackFunction(id, "draft");
                    } else value?.callbackFunction(id);
                    setShowPopup(false);
                  }}
                  onMouseOver={() => setHoveredIndex(index)}
                  onMouseOut={() => setHoveredIndex(null)}
                  style={{
                    cursor: "pointer",
                    fontSize: "12px",
                    fontWeight: "400",
                    color:
                      hoveredIndex === index
                        ? theme.palette.primary.white
                        : theme.palette.primary.black,
                    backgroundColor:
                      hoveredIndex === index
                        ? hoverColor
                          ? hoverColor
                          : "#1B8A79"
                        : "#FFFFFF",
                    width: "100%",
                    margin: "0px",
                    padding: "6px 0",
                    textAlign: "left",
                  }}
                >
                  <span
                    style={{
                      marginLeft: "2rem",
                    }}
                  >
                    {value?.title}
                  </span>
                </span>
              ))}
          </Popup>
        )}
      </Box>
    </Box>
  );
}

export default ActionPopup;
