export const getCurrentTime = () => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();

  const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  const ampm = hours >= 12 ? "PM" : "AM";

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};
