import React, { useCallback, useEffect, useRef, useState } from "react";
import TopHeader from "../Header/TopHeader";
import MenuBar from "../../../Components/Custom/MenuBar";
import SmallBanner from "../../../Components/Custom/SmalBanner";
import SmallFooter from "../SmallFooter/SmallFooter";
import InnerPageTopBar from "../../../Components/Custom/InnerPageTopBar";
import { Grid } from "@mui/material";
//import BasicPagination from "../../../Components/Custom/Pagination";
import BrandBox from "../PublicBrands/BrandBox";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import restClient from "../../../util/restClient";
import NoImage from "../../../Assets/Images/NoImage.svg";

let currentPage = 1;
let defaultPageSize = 20;

if (window.innerWidth < 600) {
  defaultPageSize = 20;
} else if (window.innerWidth < 1536) {
  defaultPageSize = 16;
} else {
  defaultPageSize = 20;
}
let pageSize = defaultPageSize;
export default function Dispensaries() {
  const [dispensaries, setDispensaries] = useState([]);
  //const [pageCount, setPageCount] = useState(0);
  //const [currentPage, setCurrentPage] = useState(0);
  // const [pageSize, setPageSize] = useState(defaultPageSize);
  const [isLoading, setIsLoading] = useState(false);
  const [allowInfiniteScroll, setAllowInfiniteScroll] = useState(true);
  const observerTarget = useRef(null);

  console.log(observerTarget, "observerTarget");
  console.log(currentPage, "currentPage");

  const navigate = useNavigate();

  const getDispensaries = useCallback(async (keyword = "") => {
    let url;
    if (keyword)
      url = `/admin/scrap/dispensaries?page=${currentPage}&pageSize=${pageSize}&name=${keyword}`;
    else
      url = `/admin/scrap/dispensaries?page=${currentPage}&pageSize=${pageSize}`;

    setIsLoading(true);
    try {
      // const response = await axios.get(
      //   `https://staging.weedxhub.com/api/admin/scrap/dispensaries?page=${currentPage}&pageSize=${pageSize}&name=${keyword}`
      // );
      const response = await restClient({
        method: "GET",
        url: url,
      });
      console.log(response, "dispensaries response");
      if (response?.status === 200) {
        if (response?.data?.data?.documents?.length < 16) {
          setAllowInfiniteScroll(false);
        } else {
          setAllowInfiniteScroll(true);
        }
        const newDispensaries = [...response?.data?.data?.documents];
        setDispensaries(newDispensaries);
        //setPageCount(Math.ceil(response?.data.data.total / pageSize));
        setIsLoading(false);
        pageSize = pageSize + 10;
        // setPageSize((prev) => prev + 10);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }, []);

  // useEffect(() => {
  //   getDispensaries();
  // }, [getDispensaries]);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       if (entries[0].isIntersecting) {
  //         getDispensaries();
  //       }
  //     },
  //     { threshold: 1 }
  //   );

  //   let currentTarget = observerTarget.current;

  //   if (currentTarget) {
  //     observer.observe(currentTarget);
  //   }

  //   return () => {
  //     if (currentTarget) {
  //       observer.unobserve(currentTarget);
  //     }
  //   };
  // }, [observerTarget, getDispensaries]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        getDispensaries();
      }
    });

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [getDispensaries]);

  // const handlePageChange = (event, page) => {
  //   setCurrentPage(page);
  // };

  // const getSearchResults = async (keyword) => {
  //   try {
  //     const response = await axios.get(
  //       `https://staging.weedxhub.com/api/admin/scrap/dispensaries?page=1&pageSize=10&name=${keyword}`
  //     );
  //     console.log(response, "search results response api");
  //     if (response) {
  //       setDispensaries({ ...response?.data?.data?.documents });
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <>
      <TopHeader />
      <MenuBar isVisible={false} />
      <SmallBanner
        breadcrumbsMainTitle={"Dispensaries"}
        breadcrumbsSmallTitle={"Dispensaries"}
      />
      <Grid
        container
        sx={{
          padding: { xs: "1.5rem", lg: "0 5rem" },
          my: { xs: 1, sm: 3, lg: 5 },
        }}
      >
        <Grid item xs={12}>
          <InnerPageTopBar
            getSearchResults={getDispensaries}
            width="100%"
            placeholder="Search Dispensaries"
          />
        </Grid>
        {dispensaries &&
          dispensaries?.length > 0 &&
          dispensaries?.map((dispensary) => (
            <Grid
              key={dispensary?._id}
              item
              xs={6}
              sm={3}
              xl={2.4}
              sx={{ margin: "0.5rem 0rem" }}
            >
              <BrandBox
                imageUrl={dispensary?.["logo"] || NoImage}
                title={dispensary?.["dispensaryName"] || "Dispensary Name"}
                onClick={() =>
                  navigate(`/dispensary-detail/${dispensary?._id}`)
                }
              />
            </Grid>
          ))}
      </Grid>

      {/* <Grid
        container
        sx={{
          padding: { xs: "1.5rem", lg: "0 10rem" },
          my: { xs: 1, sm: 3, lg: 5 },
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <BasicPagination
            handlePageChange={handlePageChange}
            count={pageCount}
            currentPage={currentPage}
          />
        </Grid>
      </Grid> */}
      {isLoading && <p style={{ textAlign: "center" }}>Loading...</p>}
      {allowInfiniteScroll && <div ref={observerTarget}></div>}
      <SmallFooter />
    </>
  );
}
