import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Stack } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import SideBarLayout from "../../SharedComponents/SideBarLayout";
import heroSubTabBannerGreen from "../../Assets/Images/heroSubTabBannerGreen.png";
import { ReactComponent as LogoWhite } from "../../Assets/Icons/LogoWhite.svg";

function LoginResponsiveSidebar({ children }) {
  const [open, setOpen] = useState(false);

  return (
    <Stack direction={{ md: "row", xs: "column" }}>
      <Box
        sx={{
          display: { xs: "none", md: "inline-flex" },
          width: { xs: "75%", sm: "40%", md: "30%", lg: "20%" },
          height: "100vh",
          position: "fixed",
        }}
      >
        <SideBarLayout />
      </Box>
      <Box sx={{ display: { md: "none" } }}>
        {open && <SideBarLayout setOpen={setOpen} />}
        <Grid
          item
          container
          sx={{
            padding: { xs: "1.5rem", sm: "1.5rem 2rem", lg: "0 10rem" },
            background: `url(${heroSubTabBannerGreen})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            display: { md: "none" },
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: { md: "none" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
            container
          >
            <Link to={"/"}>
              <LogoWhite />
            </Link>
            <Box
              sx={{ cursor: "pointer", display: { md: "none" } }}
              onClick={() => {
                setOpen(true);
                document.body.style.overflow = "hidden";
              }}
            >
              <MenuIcon
                sx={{ color: "white", width: "25px", height: "25px" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          marginLeft: { md: "30%", lg: "20%" },
        }}
      >
        {children}
      </Box>
    </Stack>
  );
}

export default LoginResponsiveSidebar;
