import { useState } from "react";
import { Box } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Popup from "../../../../Components/Custom/Popup";
import theme from "../../../../theme";

function ActionPopup() {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <Box sx={{ position: "relative" }}>
      <MoreVertIcon
        sx={{
          fontSize: "20px",
          color: theme.palette.primary.black,
          cursor: "pointer",
          "&:hover": {
            color: theme.palette.primary.main,
          },
        }}
        onClick={() => setShowPopup((prev) => !prev)}
      />
      {showPopup && (
        <Popup>
          <span>Edit</span>
          <span>Delete</span>
          <span>Activate</span>
          <span>Inactivate</span>
        </Popup>
      )}
    </Box>
  );
}

export default ActionPopup;
