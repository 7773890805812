import InputField from "../../../../Components/Custom/InputField";
import InputWrapper from "./InputWrapper";
import InputDropDown from "../../../../Components/Custom/InputDropdown";
import { Box, Stack, Grid } from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import PrimaryButton from "../../../../Components/Custom/PrimaryButton";
import { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import InputFieldLocation from "../../../../Components/Custom/InputFieldLocation";

export const BusinessInfoContent = ({ onChangeHandler, licenseData = [], setLicenseValue, brandData, error }) => {
  return (
    <Grid
      sx={{
        marginTop: "12px",
      }}
      container
      spacing={2}
    >
      <Grid item xs={12} sm={6}>
        <InputWrapper label={"Business Name"} required={true}>
          <InputField
            placeholder="Enter business name"
            onChange={onChangeHandler}
            name="businessName"
            value={brandData.businessName}
            error={error.businessName}
          ></InputField>
        </InputWrapper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputWrapper label={"License"} required={true}>
          <InputDropDown
            options={[...licenseData]}
            width={"100%"}
            onChange={setLicenseValue}
            value={brandData.license}
            name="license"
            error={error.license}
          ></InputDropDown>
        </InputWrapper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputWrapper label={"Address"} required={true}>
          <InputFieldLocation
            apiKey={process.env.REACT_APP_GOOGLE}
            background="white"
            placeholder="Enter your Facility Address"
            name={`address`}
            value={brandData?.address}
            onPlaceSelected={(place) => {
              onChangeHandler({
                target: {
                  name: "address",
                  value: place?.formatted_address,
                },
              });
            }}
          />
        </InputWrapper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputWrapper label={"Website"}>
          <InputField placeholder="Enter website address " onChange={onChangeHandler} name="website" value={brandData.website}></InputField>
        </InputWrapper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputWrapper label={"Email"}>
          <InputField placeholder="Enter email address " onChange={onChangeHandler} name="businessEmail" value={brandData.businessEmail}></InputField>
        </InputWrapper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputWrapper label={"Phone number"}>
          <InputField placeholder="Enter phone number" onChange={onChangeHandler} name="phoneNo" value={brandData.phoneNo}></InputField>
        </InputWrapper>
      </Grid>
    </Grid>
  );
};

export const SocialAccountContent = ({ onChangeHandler, brandData }) => {
  return (
    <Grid
      sx={{
        marginTop: "12px",
      }}
      container
      spacing={2}
    >
      <Grid item xs={12} sm={6}>
        <InputWrapper label={"Facebook"}>
          <InputField placeholder="Enter Facebook URL" onChange={onChangeHandler} name="facebook" value={brandData.facebook}></InputField>
        </InputWrapper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputWrapper label={"Instagram"}>
          <InputField placeholder="Enter Instagram URL" onChange={onChangeHandler} name="instagram" value={brandData.instagram}></InputField>
        </InputWrapper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputWrapper label={"Twitter"}>
          <InputField placeholder="Enter Twitter handle URL" onChange={onChangeHandler} name="twitter" value={brandData.twitter}></InputField>
        </InputWrapper>
      </Grid>
    </Grid>
  );
};

export const AboutUsContent = ({ onChangeHandler, aboutUsData }) => {
  return (
    <Grid
      sx={{
        marginTop: "12px",
      }}
      container
      spacing={2}
    >
      <Grid item xs={12} md={6}>
        <Stack>
          <span
            style={{
              color: "#87929E",
              fontSize: "14px",
              fontWeight: "300",
              margin: "0 0 16px 0",
            }}
          >
            Give a brief description that highlights the unique features and benefits of your brand. Help customers understand what makes your brand
            special in a few sentences.
          </span>
          <BaseTextareaAutosize
            aria-label="empty textarea"
            style={{
              padding: "16px",
              borderRadius: "4px",
              border: "1px solid #87929E",
              outline: "none",
              resize: "vertical",
            }}
            minRows={3}
            name="aboutUs"
            value={aboutUsData}
            placeholder="Enter brand description"
            onChange={onChangeHandler}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export const KeyFeaturesContent = ({ onChangeHandler, keyFeaturesList, deleteKeyTagHandler }) => {
  const [keyFeature, setKeyFeature] = useState();

  return (
    <Grid
      sx={{
        marginTop: "12px",
      }}
      container
      spacing={2}
    >
      <Grid item xs={12} md={6}>
        <Stack>
          <span
            style={{
              color: "#87929E",
              fontSize: "14px",
              fontWeight: "300",
              margin: "0 0 16px 0",
            }}
          >
            Enhance your product listing by adding tags that showcase its unique features.
          </span>
          <Stack direction={"row"} sx={{ height: "102%" }}>
            <InputField
              placeholder="Type your tags"
              value={keyFeature}
              onChange={(e) => {
                setKeyFeature(e.target.value);
              }}
              borderRadius={"4px 0px 0px 4px"}
            ></InputField>
            <PrimaryButton
              onClick={() => {
                onChangeHandler(keyFeature);
                setKeyFeature("");
              }}
              borderRadius={"0px 4px 4px 0px"}
              height="40px"
            >
              Add
            </PrimaryButton>
          </Stack>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              marginTop: "16px",
              flexWrap: "wrap",
            }}
          >
            {keyFeaturesList.map((key, index) => {
              return (
                <span
                  key={index}
                  style={{
                    backgroundColor: "#8d98ff1a",
                    fontSize: "14px",
                    borderRadius: "4px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    padding: "4px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyItems: "center",
                    color: " #5D5FEF",
                    // fontWeight: "500",
                  }}
                >
                  {key}
                  <CloseIcon
                    onClick={() => {
                      deleteKeyTagHandler(index);
                    }}
                    fontSize="small"
                  />
                </span>
              );
            })}
          </div>
        </Stack>
      </Grid>
    </Grid>
  );
};
