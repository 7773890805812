import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import ReactPlayer from "react-player";

import Features from "../../components/Features";
import SecondaryButton from "../../components/SecondaryButton";
import NoImage from "../../../../../Assets/Images/NoImage.svg";
import { stringTruncate } from "../../../../../util/functions";
import { checkURLType } from "../../../../Admin/ProductManagement/helpers/checkFileType";
import featureTag from "../../../../../Assets/Images/featureTag.svg";

function Product({ product }) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        bgcolor: "white",
        margin: "0 24px 24px 0",
        cursor: "pointer",
        border: "1px solid #E0E0E0",
        borderRadius: "4px",
        position: "relative",
      }}
      onClick={() => navigate(`/product-overview/${product._id}`)}
    >
      {product.isFeatured && (
        <Box sx={{ position: "absolute" }}>
          <img src={featureTag} alt="" />
        </Box>
      )}
      <Box sx={{ height: { md: "280px", xs: "200px" }, overflow: "hidden" }}>
        {checkURLType(product.image[0]) === "image" && (
          <img
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
            src={product.image[0] || NoImage}
            alt=""
          />
        )}
        {checkURLType(product.image[0]) === "video" && (
          <ReactPlayer
            url={product.image[0]}
            width="100%"
            height="100%"
            controls={true}
          />
        )}
      </Box>
      <div
        style={{
          padding: "16px",
          display: "grid",
          gridTemplateRows: "repeat(6, 1fr)",
        }}
      >
        <Features features={product.salesType} />
        <Box sx={{ fontWeight: "500" }}>
          {stringTruncate(product.name, 40) || ""}
        </Box>
        <span
          style={{
            textTransform: "uppercase",
            color: "#87929E",
            fontWeight: "300",
            fontSize: "13px",
          }}
        >
          {product.brandName || " "}
        </span>
        <Box
          style={{
            fontWeight: "300",
            fontSize: "13px",
          }}
        >
          <span style={{ fontWeight: "500" }}>Available: </span>
          <span>{product.quantity || "Message For Quantity"}</span>
        </Box>
        <Box
          style={{
            fontWeight: "300",
            fontSize: "13px",
          }}
        >
          <span style={{ fontWeight: "500" }}>Unit: </span>
          <span>{product.unit || "Unavailable"}</span>
        </Box>
        <SecondaryButton>
          {product.price ? "$" : ""}
          {product.price || "Message For Price"}
        </SecondaryButton>
      </div>
    </Box>
  );
}

export default Product;
