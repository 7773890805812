const formatPhoneNumber = (event, input) => {
  if (event?.nativeEvent.inputType === "deleteContentBackward") return input;

  const numericInput = input.replace(/\D/g, "");
  if (!numericInput || isNaN(numericInput)) return "";

  return `(${numericInput.slice(0, 3)}) ${numericInput.slice(
    3,
    6,
  )}-${numericInput.slice(6, 10)}`;
};

export default formatPhoneNumber;
