export const businessInfo = [
  {
    name: "dispensaryName",
    label: <span className="required-field">Business Name</span>,
    placeholder: "Enter business name",
  },
  {
    name: "stateLicense",
    label: <span className="required-field">License</span>,
    placeholder: "Enter license number",
  },
  {
    name: "dispensaryUrl",
    label: "Website",
    placeholder: "Enter website url",
  },
  {
    name: "email",
    label: "Email",
    placeholder: "Enter email address",
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    placeholder: "Enter  phone number",
  },
  {
    name: "location",
    label: <span className="required-field">Location</span>,
    placeholder: "Enter location address",
  },
];

export const socialAccounts = [
  { name: "fbLink", label: "Facebook", placeholder: "Enter Facebook URL" },
  {
    name: "instagramLink",
    label: "Instagram",
    placeholder: "Enter Instagram URL",
  },
  {
    name: "twitterLink",
    label: "Twitter",
    placeholder: "Enter Twitter Handle URL",
  },
];
