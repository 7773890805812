import * as React from "react";
const CameraProfileIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#192E2B"
        fillRule="evenodd"
        d="M16.432 3.761h-1.728l-.576-1.152c-.34-.67-.516-1.152-1.152-1.152H6.064c-.636 0-.847.55-1.152 1.152l-.576 1.152H2.608A2.304 2.304 0 0 0 .304 6.065v9.216a2.304 2.304 0 0 0 2.304 2.304h13.824a2.304 2.304 0 0 0 2.304-2.304V6.065a2.304 2.304 0 0 0-2.304-2.304ZM9.52 15.281a4.608 4.608 0 1 1 0-9.216 4.608 4.608 0 0 1 0 9.216Zm0-8.064a3.457 3.457 0 1 0 .001 6.913 3.457 3.457 0 0 0 0-6.913Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.304.305h18.432v18.432H.304z" />
      </clipPath>
    </defs>
  </svg>
);
export default CameraProfileIcon;
