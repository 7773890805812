import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import AuthImage from "../../../Assets/Images/auth.png";
import PrimaryHeading from "../../../Components/Custom/PrimaryHeading";
import { Link } from "react-router-dom";
import theme from "../../../theme";
import Step1Content from "./Step1Content";
import Step2Content from "./Step2Content";
import Step3Content from "./Step3Content";
//import Step4Content from "./Step4Content";
import Step5Content from "./Step5Content";
import Step6Content from "./Step6Content";
//import { ReactComponent as ArrowLeft } from "../../../Assets/Icons/LeftArrow.svg";
import HamburgerMenu from "../../../Components/Custom/HamburgerMenu";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as LogoWhite } from "../../../Assets/Icons/LogoWhite.svg";
import heroSubTabBanner from "../../../Assets/Images/heroSubTabBanner.png";
import { ToastContainer } from "react-toastify";
//import Button from "@mui/material/Button";

const stepContent = ["User Info", "Confirm your email", "Facility Metrc API keys", "Order confirmation"];

export default function SignUp({ activeStep, setActiveStep, handleNext, handleBack, backImage }) {
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);

  console.log(activeStep, "active steo");

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Step1Content
            handleNext={handleNext}
            activeStep={activeStep}
            //steps={steps}
          />
        );
      case 1:
        return (
          // <Step2Content
          //   handleNext={handleNext}
          //   activeStep={activeStep}
          //   //steps={steps}
          // />
          <Step3Content
            handleNext={handleNext}
            activeStep={activeStep}
            //steps={steps}
          />
        );
      case 2:
        return (
          // <Step3Content
          //   handleNext={handleNext}
          //   activeStep={activeStep}
          //   //steps={steps}
          // />
          <Step5Content
            handleNext={handleNext}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            //steps={steps}
          />
        );
      case 3:
        return (
          // <Step5Content
          //   handleNext={handleNext}
          //   activeStep={activeStep}
          //   //steps={steps}
          // />
          <Step6Content
            handleNext={handleNext}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            //steps={steps}
          />
        );
      case 4:
        return (
          <Step6Content
            handleNext={handleNext}
            activeStep={activeStep}
            //steps={steps}
          />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <>
      <Grid container sx={{}}>
        <ToastContainer />

        {openHamburgerMenu && <HamburgerMenu onClose={() => setOpenHamburgerMenu(false)} />}
        <Grid
          item
          container
          sx={{
            padding: { xs: "1.5rem", sm: "0rem 3rem", lg: "0 10rem" },
            background: `url(${heroSubTabBanner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: { sm: "none" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
            container
          >
            <Link to="/">
              <LogoWhite />
            </Link>
            <Box onClick={() => setOpenHamburgerMenu(true)}>
              <MenuIcon sx={{ color: "white", width: "25px", height: "25px" }} />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={6} sx={{ display: { xs: "none", sm: "inline" } }}>
          <Box
            sx={{
              background: `url(${backImage ? backImage : AuthImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              minHeight: "100vh",
              height: "100%",
              position: "relative",
              width: "100%",
              backgroundPositionY: "bottom",
            }}
          >
            <Box sx={{ padding: 2, position: "absolute", top: 0, zIndex: 100 }}>
              <Link to="/">
                <LogoWhite />
              </Link>
            </Box>
            <Box sx={{ position: "absolute", bottom: 0, padding: 2 }}>
              <Typography
                sx={{
                  color: theme.palette.primary.white,
                  fontSize: { xs: "12px", lg: theme.typography.fontSize },
                  fontWeight: "300",
                }}
              >
                © Copyright 2024, All Rights Reserved by WeedX
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            overflow: "auto",
            display: "flex",
            alignItems: { sm: "center" },
            flexDirection: "column",
            justifyContent: { sm: "center" },
          }}
        >
          {/* <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{
              padding: "30px",
              textTransform: "capitalize",
              alignSelf: "start",
            }}
          >
            <ArrowLeft />
            <Typography
              pl={1}
              sx={{
                color: theme.palette.primary.black,
              }}
            >
              Go back
            </Typography>
          </Button> */}

          <Box
            sx={{
              px: { xs: 3, sm: 5, md: 10 },
              py: 5,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Box>
              <PrimaryHeading color={theme.palette.primary.inputLabelColor} fontSize={{ xs: "28px" }} fontWeight="600" textAlign="center">
                Registration
              </PrimaryHeading>
              <Typography
                sx={{
                  color: theme.palette.primary.mediumGrey,
                  fontSize: theme.typography.fontSize16,
                  mb: 3,
                  textAlign: "center",
                }}
              >
                {stepContent[activeStep]}
              </Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
              }}
            >
              {getStepContent(activeStep)}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
