import React, { useState } from "react";
import theme from "../../theme";
import { Accordion, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactComponent as FAQSIcon } from "../../Assets/Icons/FaqsIcon.svg";
import { ReactComponent as FAQSIconRed } from "../../Assets/Icons/FaqsIconRed.svg";
import AccordionDetails from "@mui/material/AccordionDetails";

function CustomAccordion(props) {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div
      style={{
        boxShadow:
          "0px 8px 8px -4px rgba(0, 0, 0, 0.03), 0px 20px 24px -4px rgba(0, 0, 0, 0.04), 0px 0px 4px 4px rgba(0, 0, 0, 0.04)",
        background: theme.palette.primary.white,
        borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
      }}
    >
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{
          boxShadow: "none !important",
          border: "none !important",
          borderLeft: expanded ? "4px solid #F78B8B !important" : "",
          borderRadius: props.borderRadius,
        }}
      >
        <AccordionSummary
          sx={{
            padding: "0px 32px",
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ marginRight: "10px", marginTop: "1.5px" }}>
            {expanded ? <FAQSIconRed /> : <FAQSIcon />}
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary.black,
              fontSize: { xs: "14px", lg: theme.typography.fontSize16 },
              fontWeight: "500",
            }}
          >
            {props.accordionTitle}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              color: theme.palette.primary.mediumGreyColor,
              fontSize: theme.typography.fontSize,
              fontWeight: "300",
              marginLeft: "48px",
              marginTop: "-5px",
            }}
          >
            {props.accordionDetail}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default CustomAccordion;
