import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import theme from "../../../theme";
import styled from "styled-components";
import { ReactComponent as TickIcon } from "../../../Assets/Icons/TickIcon.svg";
import { ReactComponent as TrialIcon } from "../../../Assets/Icons/TrialIcon.svg";
import { ReactComponent as LoginIcon } from "../../../Assets/Icons/LoginIconWhite.svg";
import SellerDashboard from "../../../Assets/Images/SellerDashboard.png";
import SellerDashboardTwo from "../../../Assets/Images/SellerDashTwo.png";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import { useNavigate } from "react-router-dom";

const CustomListItem = styled(ListItem)(({ theme }) => ({
  padding: "0 !important",
  paddingBottom: "10px !important",
}));

const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "46px !important",
}));

export default function GrowerSection() {
  const navigate = useNavigate();

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ display: { md: "none" } }}>
          <img style={{ width: "100%" }} src={SellerDashboard} alt="" />
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            padding: { xs: "3rem 1.5rem", lg: "3rem" },
            background: theme.palette.primary.black,
            marginTop: { xs: "-7px", md: "0px" },
            display: { sm: "flex" },
            flexDirection: { sm: "column" },
            justifyContent: { sm: "center", md: "start" },
            alignItems: { sm: "center", md: "start" },
            height: { md: "65vh", lg: "70vh" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "25px", lg: "40px" },
              fontWeight: "600",
              color: "white",
              lineHeight: { xs: "35px", lg: "50px" },
            }}
          >
            For Growers Processors & Wholesalers
          </Typography>

          <List sx={{ my: 1 }}>
            <CustomListItem>
              <CustomListItemIcon>
                <TickIcon />
              </CustomListItemIcon>
              <ListItemText
                secondary={
                  <Typography
                    sx={{
                      color: "#C6CED9",
                      fontSize: { xs: "14px", lg: "16px" },
                      fontWeight: "300",
                    }}
                  >
                    Receive higher dollar amount per lb/unit
                  </Typography>
                }
              />
            </CustomListItem>

            <CustomListItem>
              <CustomListItemIcon>
                <TickIcon />
              </CustomListItemIcon>
              <ListItemText
                secondary={
                  <Typography
                    sx={{
                      color: "#C6CED9",
                      fontSize: { xs: "14px", lg: "16px" },
                      fontWeight: "300",
                    }}
                  >
                    Wider reach & more distribution channels
                  </Typography>
                }
              />
            </CustomListItem>

            <CustomListItem>
              <CustomListItemIcon>
                <TickIcon />
              </CustomListItemIcon>
              <ListItemText
                secondary={
                  <Typography
                    sx={{
                      color: "#C6CED9",
                      fontSize: { xs: "14px", lg: "16px" },
                      fontWeight: "300",
                    }}
                  >
                    Brand & reputation building
                  </Typography>
                }
              />
            </CustomListItem>

            <CustomListItem>
              <CustomListItemIcon>
                <TickIcon />
              </CustomListItemIcon>
              <ListItemText
                secondary={
                  <Typography
                    sx={{
                      color: "#C6CED9",
                      fontSize: { xs: "14px", lg: "16px" },
                      fontWeight: "300",
                    }}
                  >
                    Convenient tool for sales reps
                  </Typography>
                }
              />
            </CustomListItem>

            <CustomListItem>
              <CustomListItemIcon>
                <TickIcon />
              </CustomListItemIcon>
              <ListItemText
                secondary={
                  <Typography
                    sx={{
                      color: "#C6CED9",
                      fontSize: { xs: "14px", lg: "16px" },
                      fontWeight: "300",
                    }}
                  >
                    No commission fees
                  </Typography>
                }
              />
            </CustomListItem>
          </List>
          <PrimaryButton onClick={() => navigate("/signup")}>
            Create Store
          </PrimaryButton>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            height: { md: "70vh", lg: "75vh" },
            display: { xs: "none", md: "inline-flex" },
            marginTop: { md: "-5vh" },
          }}
        >
          <img
            style={{ width: "100%", height: "100%" }}
            src={SellerDashboard}
            alt=""
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            height: { md: "60vh", xl: "80vh" },
            //display: { xs: "none", md: "inline-flex" },
          }}
        >
          <img
            style={{ width: "100%", height: "100%" }}
            src={SellerDashboardTwo}
            alt=""
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            padding: { xs: "2rem 1.5rem", lg: "2rem" },
            background: theme.palette.primary.white,
            marginTop: { xs: "-7px", md: "0px" },
            display: { sm: "flex" },
            flexDirection: { sm: "column" },
            justifyContent: { sm: "center", md: "start" },
            alignItems: { sm: "center", md: "start" },
            height: { md: "60vh", xl: "80vh" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "25px", lg: "40px" },
              fontWeight: "600",
              color: theme.palette.primary.black,
              lineHeight: { xs: "35px", lg: "50px" },
            }}
          >
            For Dispensaries
          </Typography>

          <List sx={{ my: 1 }}>
            <CustomListItem>
              <CustomListItemIcon>
                <TickIcon />
              </CustomListItemIcon>
              <ListItemText
                secondary={
                  <Typography
                    sx={{
                      color: "#596570",
                      fontSize: { xs: "14px", lg: "16px" },
                      fontWeight: "300",
                    }}
                  >
                    Access to emerging brands & suppliers
                  </Typography>
                }
              />
            </CustomListItem>

            <CustomListItem>
              <CustomListItemIcon>
                <TickIcon />
              </CustomListItemIcon>
              <ListItemText
                secondary={
                  <Typography
                    sx={{
                      color: "#596570",
                      fontSize: { xs: "14px", lg: "16px" },
                      fontWeight: "300",
                    }}
                  >
                    24/7 Access to detailed product info
                  </Typography>
                }
              />
            </CustomListItem>

            <CustomListItem>
              <CustomListItemIcon>
                <TickIcon />
              </CustomListItemIcon>
              <ListItemText
                secondary={
                  <Typography
                    sx={{
                      color: "#596570",
                      fontSize: { xs: "14px", lg: "16px" },
                      fontWeight: "300",
                    }}
                  >
                    Easy communications
                  </Typography>
                }
              />
            </CustomListItem>

            <CustomListItem>
              <CustomListItemIcon>
                <TickIcon />
              </CustomListItemIcon>
              <ListItemText
                secondary={
                  <Typography
                    sx={{
                      color: "#596570",
                      fontSize: { xs: "14px", lg: "16px" },
                      fontWeight: "300",
                    }}
                  >
                    No commission fees
                  </Typography>
                }
              />
            </CustomListItem>
          </List>
          <PrimaryButton
            onClick={() => navigate("/signup")}
            icon={<LoginIcon />}
          >
            Claim Account Free
          </PrimaryButton>
        </Grid>
      </Grid>
    </>
  );
}
