import { Box, Stack } from "@mui/material";

import SortCont from "./SortCont";
import SearchInputWithIcon from "../../../../../Components/Custom/SearchInput";

function SortSearch({ sortValue, setSortValue, getProducts }) {
  return (
    <Stack
      sx={{ bgcolor: "white", padding: { xs: "16px", lg: "16px 32px" } }}
      direction={"row"}
      alignItems={"center"}
      spacing={2}
    >
      <SortCont sortValue={sortValue} setSortValue={setSortValue} />
      <Box sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            border: "1px solid lightgrey",
            borderRadius: "4px",
            padding: "2px 4px",
          }}
        >
          <SearchInputWithIcon
            placeholder="Search products"
            callbackFunction={getProducts}
          />
        </Box>
      </Box>
    </Stack>
  );
}

export default SortSearch;
