import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../App.css";
import AboutUs from "../Features/LandingPages/AboutUs/AboutUs";
import MainPage from "../Features/LandingPages/HomePage/MainPage";
import FAQs from "../Features/LandingPages/FAQs/Faqs";
import Pricing from "../Features/LandingPages/Pricing/Pricing";
import PublicBrands from "../Features/LandingPages/PublicBrands/PublicBrands";
import Dispensaries from "../Features/LandingPages/Dispensaries/Dispensaries";
import Blog from "../Features/LandingPages/Blog/Blog";
import AboutProfile from "../Features/LandingPages/AboutProfile/AboutProfile";
import SignUp from "../Features/Auth/SignUp/SignUp";
import Login from "../Features/Auth/Login/Login";
import ForgotPassword from "../Features/Auth/Login/ForgotPassword";
import StorefrontSettings from "../Features/Admin/StorefrontSettings/StorefrontSettings";
import VerifyOTP from "../Features/Auth/Login/VerifyOTP";
import ConfirmPassword from "../Features/Auth/Login/ConfirmPassword";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import BrandManagement from "../Features/Admin/BrandManagement/BrandManagement";
import ProductManagement from "../Features/Admin/ProductManagement/ProductManagement";
import TermsAndConditions from "../Features/LandingPages/TermsAndConditions/TermsAndConditions";
import BlogDetail from "../Features/LandingPages/Blog/BlogDetail";
import ScrollToTop from "../Components/ScrollToTop";
import ContactUs from "../Features/LandingPages/ContactUs/ContactUs";
import { useState } from "react";
import AddNewBrand from "../Features/Admin/BrandManagement/AddNewBrand";
import AddNewProduct from "../Features/Admin/ProductManagement/AddNewProduct";
import FeaturedProductPayment from "../Features/Admin/ProductManagement/FeatureProductPayment/FeaturedProductPayment";
import SellerProfile from "../Features/LandingPages/SellerProfile/SellerProfile";
import Marketplace from "../Features/LandingPages/Marketplace/Marketplace";
import ProductOverview from "../Features/LandingPages/Marketplace/ProductOverview";
import DispensaryMangement from "../Features/LandingPages/Dispensary/Management/DispensaryMangement";
import AddEditDispensary from "../Features/LandingPages/Dispensary/AddEditDispensary/AddEditDispensary";
import DispensaryDetail from "../Features/LandingPages/Dispensary/DispensaryDetail/DispensaryDetail";
import Redirect from "./Redirect";
import Messages from "../Features/LandingPages/Messaging/Messages";
import Inbox from "../Features/Admin/Inbox/Inbox";
import UnAuthProductOverview from "../Features/LandingPages/Marketplace/UnAuthProductOverview";
import PrivacyPolicy from "../Features/LandingPages/Marketplace/PrivacyPolicy/PrivacyPolicy";

function ReactRouting() {
  const [activeStep, setActiveStep] = useState(0);

  console.log(activeStep, "active step");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const selectUserAuth = (state) => state.userRegReducer?.userAuth;
  const userData = useSelector(selectUserAuth);
  console.log(userData?.authorized, "userData");
  function isAuthenticated() {
    if (userData?.authorized === "true" || userData.authorized === true) {
      console.log(true);
      return true;
    }
    return false;
  }

  console.log(typeof userData?.authorized, "authorized", isAuthenticated(), "value");
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/inbox" element={<Inbox />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/t&c" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/dispensaries" element={<Dispensaries />} />
            <Route path="/dispensary-detail/:id" element={<DispensaryDetail />} />
            {/* <Route path="/dispensary/:id" element={<Dispensary />} /> */}
            <Route path="/brands" element={<PublicBrands />} />
            {/* <Route path="/brand-details/:userName" element={<BrandDetails />} /> */}
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog-details/:id" element={<BlogDetail />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/resetPassword/:token" element={<ConfirmPassword />} />
            <Route path="/verify-OTP/:token" element={<VerifyOTP />} />
            <Route path="/profile" element={!isAuthenticated() ? <AboutProfile /> : <Navigate to="/login" />} />
            <Route
              path="/signup"
              element={
                !isAuthenticated() ? (
                  <SignUp activeStep={activeStep} setActiveStep={setActiveStep} handleBack={handleBack} handleNext={handleNext} />
                ) : (
                  <Navigate to="/my-account" />
                )
              }
            />
            <Route
              path="/login"
              element={
                !isAuthenticated() ? (
                  <Login activeStep={activeStep} setActiveStep={setActiveStep} handleBack={handleBack} handleNext={handleNext} />
                ) : (
                  <Redirect />
                )
              }
            />
            {/* <Route
              path="/my-account"
              element={
                isAuthenticated() ? (
                  <StorefrontSettings />
                ) : (
                  <Navigate to="/login" />
                )
              }
            /> */}
            <Route path="/my-account" element={<StorefrontSettings />} />
            <Route
              path="/brand-management"
              element={
                // isAuthenticated() ? (
                <BrandManagement />
                // ) : (
                //   <Navigate to="/login" />
                // )
              }
            />
            <Route
              path="/add-new-brand"
              element={
                // isAuthenticated() ? (
                <AddNewBrand />
                // ) : (
                //   <Navigate to="/login" />
                // )
              }
            />
            <Route
              path="/edit-brand/:id"
              element={
                // isAuthenticated() ? (
                <AddNewBrand editState={true} />
                // ) : (
                //   <Navigate to="/login" />
                // )
              }
            />
            <Route
              path="/product-management"
              element={
                // isAuthenticated() ? (
                <ProductManagement />
                // ) : (
                //   <Navigate to="/login" />
                // )
              }
            />
            <Route
              path="/add-new-product"
              element={
                // isAuthenticated() ? (
                <AddNewProduct />
                // ) : (
                //   <Navigate to="/login" />
                // )
              }
            />
            <Route
              path="/edit-product/:id"
              element={
                // isAuthenticated() ? (
                <AddNewProduct editState={true} />
                // ) : (
                //   <Navigate to="/login" />
                // )
              }
            />
            <Route path="/product-overview/view/:productID" element={<UnAuthProductOverview />} />
            <Route path="/product-overview/:productID" element={isAuthenticated() ? <ProductOverview /> : <Navigate to="/login" />} />

            <Route path="/featured-product-payment/:id" element={<FeaturedProductPayment />} />
            <Route path="/brand-details/:brandID" element={<SellerProfile />} />
            <Route path="/marketplace" element={<Marketplace />} />
            <Route path="/dispensary-management" element={<DispensaryMangement />} />
            <Route path="/add-new-dispensary" element={<AddEditDispensary />} />
            <Route path="/edit-dispensary/:id" element={<AddEditDispensary editState={true} />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default ReactRouting;
