import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import LogoWhite from "../Assets/Images/LogoWhite.png";
import { ReactComponent as MarketPlaceIcon } from "../Assets/Icons/Marketplace.svg";
import { ReactComponent as BrandIcon } from "../Assets/Icons/Brand.svg";
import { ReactComponent as ForumIcon } from "../Assets/Icons/Forum.svg";
import { ReactComponent as BlogIcon } from "../Assets/Icons/Blog.svg";
import { ReactComponent as ContactUsIcon } from "../Assets/Icons/Contactus.svg";
import { ReactComponent as InboxIcon } from "../Assets/Icons/Inbox.svg";
import { ReactComponent as BellIcon } from "../Assets/Icons/BellIcon.svg";
import { ReactComponent as FaqIcon } from "../Assets/Icons/Faqdashboard.svg";
import { ReactComponent as UserIcon } from "../Assets/Icons/User.svg";
import { ReactComponent as BrandsManagement } from "../Assets/Icons/BrandManagement.svg";
import { ReactComponent as DispensaryManagement } from "../Assets/Icons/DispensaryManagement.svg";
import { ReactComponent as ProductManagement } from "../Assets/Icons/ProductManagement.svg";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as CircleDot } from "../Assets/Icons/CircleDot.svg";
import theme from "../theme";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ActionPopup from "../Features/Admin/BrandManagement/components/ActionPopup";

const SideBarLayout = ({ setOpen }) => {
  const selectUserAuth = (state) => state.userRegReducer?.userAuth;
  const userData = useSelector(selectUserAuth);
  const navigate = useNavigate();
  const location = useLocation();
  const [optionHover, setOptionHover] = useState("");

  const getColor = (path) => {
    if (location.pathname === path) return theme.palette.inputLabelColor;
    if (optionHover === path) return "#ffffff";
    return theme.palette.primary.footerGrey;
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.footerBg,
        height: "100%",
        width: "100%",
        position: { xs: "absolute", md: "static" },
        right: 0,
        zIndex: 100,
        overflowY: "auto",
        minWidth: "max-content",
      }}
      className="scrollbar"
    >
      <Box
        sx={{
          height: "85%",
          padding: { xs: "1.5rem", lg: "2rem" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src={LogoWhite}
            alt=""
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/");
              document.body.style.overflow = "auto";
            }}
          />
          <Box
            onClick={() => {
              if (setOpen) {
                setOpen(false);
                document.body.style.overflow = "auto";
              }
            }}
            sx={{ display: { md: "none" }, cursor: "pointer" }}
          >
            <CircleDot />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
            margin: "1rem 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: { xs: "10px", lg: "12px" },
              borderTop: `0.5px solid rgba(255, 255, 255, 0.1)`,
              borderBottom: `0.5px solid rgba(255, 255, 255, 0.1)`,
              padding: { xs: "1rem 0rem", lg: "1rem 0rem" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "10px",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
                backgroundColor: location.pathname === "/marketplace" ? theme.palette.primary.white : "transparent",
              }}
              onClick={() => {
                navigate("/marketplace");
                document.body.style.overflow = "auto";
              }}
              onMouseEnter={() => {
                setOptionHover("/marketplace");
              }}
              onMouseLeave={() => {
                setOptionHover("");
              }}
            >
              <MarketPlaceIcon />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  color: getColor("/marketplace"),
                }}
              >
                Marketplace
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "10px",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
                backgroundColor: location.pathname === "/dispensaries" ? theme.palette.primary.white : "transparent",
              }}
              onClick={() => {
                navigate("/dispensaries");
                document.body.style.overflow = "auto";
              }}
              onMouseEnter={() => {
                setOptionHover("/dispensaries");
              }}
              onMouseLeave={() => {
                setOptionHover("");
              }}
            >
              <UserIcon />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  color: getColor("/dispensaries"),
                }}
              >
                Dispensaries
              </Typography>
            </Box>
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "10px",
                backgroundColor: location.pathname === "/brands" ? theme.palette.primary.white : "transparent",
                padding: "10px",
                borderRadius: "10px",
              }}
              onClick={() => {
                navigate("/brands");
                document.body.style.overflow = "auto";
              }}
              onMouseEnter={() => {
                setOptionHover("/brands");
              }}
              onMouseLeave={() => {
                setOptionHover("");
              }}
            >
              <BrandIcon />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  color: getColor("/brands"),
                }}
              >
                Brands
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: { xs: "10px", lg: "12px" },
              borderBottom: `0.5px solid rgba(255, 255, 255, 0.1)`,
              padding: { xs: "1rem 0rem", lg: "1rem 0rem" },
            }}
          >
            {(userData?.user?.role === "BOTH" || userData?.user?.role === "BUYER") && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "10px",
                  padding: "10px",
                  borderRadius: "10px",
                  cursor: "pointer",
                  backgroundColor: location.pathname === "/dispensary-management" ? theme.palette.primary.white : "transparent",
                }}
                onClick={() => {
                  navigate("/dispensary-management");
                  document.body.style.overflow = "auto";
                }}
                onMouseEnter={() => {
                  setOptionHover("/dispensary-management");
                }}
                onMouseLeave={() => {
                  setOptionHover("");
                }}
              >
                <DispensaryManagement />
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color: getColor("/dispensary-management"),
                  }}
                >
                  Dispensary Management
                </Typography>
              </Box>
            )}

            {(userData?.user?.role === "BOTH" || userData?.user?.role === "SELLER") && (
              <Box
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "10px",
                  backgroundColor: location.pathname === "/brand-management" ? theme.palette.primary.white : "transparent",
                  padding: "10px",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  navigate("/brand-management");
                  document.body.style.overflow = "auto";
                }}
                onMouseEnter={() => {
                  setOptionHover("/brand-management");
                }}
                onMouseLeave={() => {
                  setOptionHover("");
                }}
              >
                <BrandsManagement />
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color: getColor("/brand-management"),
                  }}
                >
                  Brand Management
                </Typography>
              </Box>
            )}
            {(userData?.user?.role === "BOTH" || userData?.user?.role === "SELLER") && (
              <Box
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "10px",
                  backgroundColor: location.pathname === "/product-management" ? theme.palette.primary.white : "transparent",
                  padding: "10px",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  navigate("/product-management");
                  document.body.style.overflow = "auto";
                }}
                onMouseEnter={() => {
                  setOptionHover("/product-management");
                }}
                onMouseLeave={() => {
                  setOptionHover("");
                }}
              >
                <ProductManagement />
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color: getColor("/product-management"),
                  }}
                >
                  Product Management
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: { xs: "10px", lg: "12px" },
              padding: { xs: "1rem 0rem", lg: "1rem 0rem" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "10px",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/blog");
                document.body.style.overflow = "auto";
              }}
              onMouseEnter={() => {
                setOptionHover("/blog");
              }}
              onMouseLeave={() => {
                setOptionHover("");
              }}
            >
              <BlogIcon />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  color: getColor("/blog"),
                }}
              >
                Blog
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "10px",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/contact");
                document.body.style.overflow = "auto";
              }}
              onMouseEnter={() => {
                setOptionHover("/contact");
              }}
              onMouseLeave={() => {
                setOptionHover("");
              }}
            >
              <ContactUsIcon />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  color: getColor("/contact"),
                }}
              >
                Contact Us
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "10px",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/faqs");
                document.body.style.overflow = "auto";
              }}
              onMouseEnter={() => {
                setOptionHover("/faqs");
              }}
              onMouseLeave={() => {
                setOptionHover("");
              }}
            >
              <FaqIcon />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  color: getColor("/faqs"),
                }}
              >
                FAQ
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: { xs: "15px", lg: "20px" },
              borderTop: `0.5px solid rgba(255, 255, 255, 0.1)`,
              borderBottom: `0.5px solid rgba(255, 255, 255, 0.1)`,
              padding: "1rem 0rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "10px",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
                backgroundColor: location.pathname === "/inbox" ? theme.palette.primary.white : "transparent",
              }}
              onMouseEnter={() => {
                setOptionHover("/inbox");
              }}
              onMouseLeave={() => {
                setOptionHover("");
              }}
              onClick={() => {
                navigate("/inbox");
                document.body.style.overflow = "auto";
              }}
            >
              <InboxIcon />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  color: getColor("/inbox"),
                }}
              >
                Messages
              </Typography>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "10px",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onMouseEnter={() => {
                setOptionHover("/notifications");
              }}
              onMouseLeave={() => {
                setOptionHover("");
              }}
            >
              <BellIcon />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  color: getColor("/notifications"),
                }}
              >
                Notification
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "10px",
                padding: "10px",
                borderRadius: "10px",
                cursor: "pointer",
              }}
              onMouseEnter={() => {
                setOptionHover("/forum");
              }}
              onMouseLeave={() => {
                setOptionHover("");
              }}
            >
              <ForumIcon />
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  color: getColor("/forum"),
                }}
              >
                Forum
              </Typography>
            </Box> */}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              py: 2,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: theme.palette.primary.white,
                  fontWeight: 500,
                }}
              >
                {userData?.user?.fullname}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: theme.palette.primary.footerGrey,
                }}
              >
                {userData?.user?.email}
              </Typography>
            </Box>
            {/* <KeyboardArrowDownIcon
              onClick={() => {
                //open drop down option
              }}
              sx={{
                color: theme.palette.primary.black,
                cursor: "pointer",
                background: theme.palette.primary.white,
                borderRadius: "50%",
                width: 16,
                height: 16,
                marginTop: 1,
              }}
            /> */}
            <ActionPopup
              popUpIcon={{
                icon: (
                  <KeyboardArrowDownIcon
                    sx={{
                      color: theme.palette.primary.black,
                      cursor: "pointer",
                      background: theme.palette.primary.white,
                      borderRadius: "50%",
                      width: 16,
                      height: 16,
                      marginTop: 1,
                    }}
                  />
                ),
              }}
              popupValues={[
                {
                  title: "My Account",
                  callbackFunction: () => {
                    navigate("/my-account");
                    document.body.style.overflow = "auto";
                  },
                },
                {
                  title: "Logout",
                  callbackFunction: () => {
                    sessionStorage.removeItem("persist:root");
                    navigate("/");
                    document.body.style.overflow = "auto";
                    window.location.reload(true);
                  },
                },
              ]}
              hoverColor={"#f78b8b"}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SideBarLayout;
