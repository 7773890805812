import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import theme from "../../theme";
import { Box } from "@mui/material";

export default function InputDropDown({
  defaultValue = "",
  options,
  width,
  placeholder,
  onChange,
  value,
  error,
}) {
  const [age, setAge] = React.useState(value ? value : defaultValue);

  const handleChange = (event) => {
    setAge(event.target.value);
    onChange(event.target.value);
  };
  console.log(value, age);
  return (
    <FormControl
      sx={{ marginTop: "1px", minWidth: 120, width: width }}
      size="small"
    >
      <Select
        labelId="demo-select-small-label"
        placeholder={placeholder}
        id="demo-select-small"
        value={value ? value : age}
        onChange={handleChange}
        sx={{
          color: theme.palette.primary.inputTextColor,
          fontSize: theme.typography.fontSize,
          padding: "2px",
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      {error !== null && (
        <Box
          sx={{
            color: "red",
            fontSize: "12px",
            fontWeight: "400",
            marginTop: "5px",
          }}
        >
          {error}
        </Box>
      )}
    </FormControl>
  );
}
