import MenuBar from "../../../Components/Custom/MenuBar";
import SmallBanner from "../../../Components/Custom/SmalBanner";
import TopHeader from "../Header/TopHeader";
import GrowerSection from "../HomePage/GowerSection";
import HowItWorks from "../HomePage/HowItWorks";
import SmallFooter from "../SmallFooter/SmallFooter";
import GetInTouchSection from "./GetInTouchSection";
import MarketPlaceSection from "./MarketPlaceSection";
import WhatWeDoSection from "./WhatWeDoSection";

export default function AboutUs() {
  return (
    <>
      <TopHeader />
      <MenuBar isVisible={false} />
      <SmallBanner
        breadcrumbsMainTitle={"About Us"}
        breadcrumbsSmallTitle={"About Us"}
      />
      <WhatWeDoSection />
      <HowItWorks />
      <GrowerSection />
      <MarketPlaceSection />
      <GetInTouchSection />
      <SmallFooter />
    </>
  );
}
