import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";

import restClient from "../../../../../util/restClient";
import StarRating from "../../components/StarRating";
import PrimaryButton from "../../../../../Components/Custom/PrimaryButton";
import AddImagePopup from "./AddImagePopup";
import styles from "./WriteReview.module.css";

function WriteReview({ getReviews }) {
  const { brandID } = useParams();
  const userData = useSelector((state) => state.userRegReducer?.userAuth);
  const [starRatingValue, setStarRatingValue] = useState(0);
  const [description, setDescription] = useState("");
  const [attachImagePopup, setAttachImagePopup] = useState(false);
  const [imagesList, setImagesList] = useState([]);
  const [isLoader, setIsLoader] = useState(false);

  const resetData = () => {
    setStarRatingValue("");
    setDescription("");
    setImagesList([]);
  };

  const handleWriteReview = async () => {
    const payload = new FormData();
    payload.append("rating", starRatingValue);
    payload.append("description", description);
    imagesList.forEach((item) => {
      payload.append("images", item.file);
    });

    try {
      setIsLoader(true);
      const response = await restClient({
        method: "POST",
        url: `/user/review/brand/${brandID}`,
        data: payload,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      if (response.status === 200) {
        resetData();
        getReviews();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoader(false);
    }
  };

  return (
    <>
      <AddImagePopup
        open={attachImagePopup}
        handleClose={() => setAttachImagePopup(false)}
        imagesList={imagesList}
        setImagesList={setImagesList}
      />
      <Stack spacing={2} className={styles.container}>
        <span className={styles.heading}>Write a Review</span>
        <Box>
          <StarRating
            value={starRatingValue}
            setValue={setStarRatingValue}
            size="large"
            name="simple-controlled"
          />
        </Box>
        <Stack spacing={1}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <span style={{ fontWeight: "500" }}>Description </span>
            <em
              style={
                description.length === 500
                  ? { color: "red", fontSize: "12px" }
                  : { fontSize: "12px" }
              }
            >
              (Max 500 words)
            </em>
          </Stack>
          <BaseTextareaAutosize
            aria-label="empty textarea"
            style={{
              padding: "16px",
              borderRadius: "4px",
              border: "1px solid #87929E",
              outline: "none",
            }}
            minRows={6}
            placeholder="Enter review here"
            value={description}
            onChange={(e) => {
              if (e.target.value.length <= 500) setDescription(e.target.value);
            }}
          />
        </Stack>
        {imagesList.length > 0 && (
          <Stack sx={{ padding: "8px 0" }} direction={"row"} spacing={1}>
            {imagesList.map((item, index) => {
              return (
                <Box
                  width={{ xs: "60px", sm: "112px" }}
                  height={{ xs: "60px", sm: "112px" }}
                  sx={{
                    overflow: "hidden",
                    position: "relative",
                    borderRadius: "4px",
                  }}
                  key={index + "key"}
                >
                  <img style={{ width: "100%" }} src={item.src} alt="" />
                </Box>
              );
            })}
          </Stack>
        )}
        <Stack
          direction={{
            xs: "column",
            lg: "row",
          }}
          spacing={2}
          alignItems={"center"}
        >
          <span
            style={{ cursor: "pointer", fontSize: "13px", fontWeight: "500" }}
            onClick={() => setAttachImagePopup(true)}
          >
            Attach Image
          </span>
          <PrimaryButton
            onClick={handleWriteReview}
            disabled={
              starRatingValue === "" || description.length === 0 ? true : false
            }
            isLoading={isLoader}
          >
            Submit Review
          </PrimaryButton>
        </Stack>
      </Stack>
    </>
  );
}

export default WriteReview;
