import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PrimaryHeading from "../../../Components/Custom/PrimaryHeading";
import theme from "../../../theme";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import { ReactComponent as ArrowSignUp } from "../../../Assets/Icons/ArrowSignUp.svg";
import { useDispatch, useSelector } from "react-redux";
import { userAsyncPayment } from "../SignUpIntegration/subscripSlice";
import { ToastContainer, toast } from "react-toastify";
import { resetUser } from "../SignUpIntegration/userRegSlice";
import { resetPayment } from "../SignUpIntegration/subscripSlice";
//import localStorage from "redux-persist/es/storage";
import { useNavigate } from "react-router-dom";

function formatDate(dateString) {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

export default function Step6Content(props) {
  const dispatch = useDispatch();
  const [subscription, setSubscription] = useState("sendCode");
  const selectUserPayment = (state) => state.userPaymentReducer?.userSubs;
  const selectUserAuth = (state) => state.userRegReducer?.userAuth;
  const userData = useSelector(selectUserPayment);
  const userReg = useSelector(selectUserAuth);
  console.log("userData", userData);
  console.log("userReg", userReg);

  const navigate = useNavigate();

  const formattedStartDate = formatDate(userData?.currentDate);
  const formattedEndDate = formatDate(userData?.endDate);

  const handleConfirm = async () => {
    dispatch(userAsyncPayment(userData?.data)).then((res) => {
      console.log("then", res);
      if (res?.payload?.success) {
        toast.success(`${res?.payload?.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          setSubscription("confirmSub");
        }, 1000);
      } else {
        toast.error(`${res?.payload?.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  useEffect(() => {
    if (subscription === "confirmSub") {
      dispatch(resetUser());
      dispatch(resetPayment());
      sessionStorage.removeItem("persist:root");
    }
  }, [subscription, dispatch]);

  return (
    <>
      <ToastContainer />

      {subscription === "sendCode" && (
        <>
          <Box
            sx={{
              width: { xs: "85%", sm: "100%" },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          >
            <Box
              sx={{
                background: theme.palette.primary.white,
                p: 3,
                width: "100%",
              }}
            >
              <PrimaryHeading fontSize={"16px"}>Summary</PrimaryHeading>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  Order number
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  {userData?.orderNumber}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  Subscription
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  Pro Subscription WeedX
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  Start date
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  {formattedStartDate}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  End date
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  {formattedEndDate}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                background: theme.palette.primary.white,
                p: 3,
                margin: "10px",
                width: "100%",
              }}
            >
              <PrimaryHeading fontSize={"16px"}>Payment details</PrimaryHeading>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  Customer
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  #{userReg?.user?._id}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  Payment method
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  {userData?.cardDetail} ****
                  {userData?.data?.data?.cardNumber.slice(-4)} Exp:
                  {userData?.data?.data?.expDate}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 1,
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  Total
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  150
                </Typography>
              </Box>
            </Box>

            <PrimaryButton
              text="Next"
              width="100%"
              margin="20px"
              onClick={() => {
                handleConfirm();
              }}
            >
              {console.log(props.handleNext)}
              {props.steps && props.activeStep === props.steps.length - 1 ? "Finish" : "Next"}
            </PrimaryButton>
          </Box>
        </>
      )}

      {subscription === "confirmSub" && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              my: 2,
            }}
          >
            <Box mb={2}>
              <ArrowSignUp />
            </Box>
            <PrimaryHeading fontSize={"20px"}>Subscription purchased</PrimaryHeading>

            <Typography
              sx={{
                color: theme.palette.primary.black,
                fontSize: theme.typography.fontSize,
              }}
            >
              Thank you! Please login to start using.
            </Typography>
          </Box>

          <PrimaryButton
            text="Got it!"
            width="100%"
            margin="20px"
            // onClick={props.handleNext}
            onClick={() => {
              props.setActiveStep(0);
              navigate("/login");
              // window.location.reload();
            }}
          >
            Login
          </PrimaryButton>
        </>
      )}
    </>
  );
}
