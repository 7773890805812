import * as Yup from "yup";

export const validationSchemaFP = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const initialValuesFP = {
  email: "",
};

export const validationSchemaCP = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const initialValuesCP = {
  password: "",
  confirmPassword: "",
};
