import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import PrimaryHeading from "../../../Components/Custom/PrimaryHeading";
import LoginImage from "../../../Assets/Images/Login.png";
// import { ReactComponent as ArrowLeft } from "../../../Assets/Icons/LeftArrow.svg";
import theme from "../../../theme";
import InputFieldLabel from "../../../Components/Custom/InnputFieldLabel";
import InputField from "../../../Components/Custom/InputField";
import { Link } from "react-router-dom";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import { ReactComponent as RefreshImage } from "../../../Assets/Icons/RefreshIcon.svg";
import { ReactComponent as EmailImage } from "../../../Assets/Icons/EmailIcon.svg";
// import { ReactComponent as NewPasswordIcon } from "../../../Assets/Icons/GearIcon.svg";
// import { ReactComponent as Completed } from "../../../Assets/Icons/Complete.svg";
// import InputPassword from "../../../Components/Custom/InputPassword";
// import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as LogoWhite } from "../../../Assets/Icons/LogoWhite.svg";
import heroSubTabBanner from "../../../Assets/Images/heroSubTabBanner.png";
import {
  validationSchemaFP,
  initialValuesFP,
} from "../LoginIntegration/ForgetPasswordValidations";
import {
  resendAsyncOTP,
  userAsyncForgetPassword,
} from "../SignUpIntegration/userRegSlice";
import { useNavigate } from "react-router-dom";
import HamburgerMenu from "../../../Components/Custom/HamburgerMenu";

export default function ForgotPassword(props) {
  //const [forgotPassword, setForgotPassword] = useState("checkInbox");
  const [forgotPassword, setForgotPassword] = useState("forgotPassword");
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);
  //const [otp, setOtp] = useState("");

  const selectUserAuth = (state) => state.userRegReducer?.userAuth;
  const userData = useSelector(selectUserAuth);
  const showUserDetail = userData?.sendData;
  console.log(userData, "userData");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialValuesFP,
    validationSchema: validationSchemaFP,
    onSubmit: (values) => {
      dispatch(userAsyncForgetPassword(values)).then((res) => {
        console.log("then", res);
        if (res.payload.success) {
          toast.success(`${res.payload.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          // setTimeout(() => {
          //   setForgotPassword("checkInbox");
          // }, 3000);
          //navigate(`/verify-OTP/${showUserDetail?.token}`);
        } else {
          toast.error(`${res.payload.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    },
  });

  const resendOTP = async (email) => {
    dispatch(resendAsyncOTP({ email: email })).then((res) => {
      console.log("then", res);
      if (res.payload.success) {
        toast.success(`${res.payload.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(`${res.payload.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleReset = () => {
    navigate(`/verify-OTP/${showUserDetail?.token}`);
  };

  return (
    <>
      <Grid container>
        <ToastContainer />
        {openHamburgerMenu && (
          <HamburgerMenu onClose={() => setOpenHamburgerMenu(false)} />
        )}
        <Grid
          item
          container
          sx={{
            padding: { xs: "1.5rem", sm: "0rem 3rem", lg: "0 10rem" },
            background: `url(${heroSubTabBanner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: { sm: "none" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
            container
          >
            <Link to={"/"}>
              <LogoWhite />
            </Link>
            <Box onClick={() => setOpenHamburgerMenu(true)}>
              <MenuIcon
                sx={{ color: "white", width: "25px", height: "25px" }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={6} sx={{ display: { xs: "none", sm: "inline" } }}>
          <Box
            sx={{
              background: `url(${
                props.backImage ? props.backImage : LoginImage
              })`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "100vh",
              position: "relative",
              width: "100%",
              backgroundPositionY: "bottom",
            }}
          >
            <Box sx={{ padding: 2, position: "absolute", top: 0, zIndex: 100 }}>
              <Link to={"/"}>
                <LogoWhite />
              </Link>
            </Box>
            <Box sx={{ position: "absolute", bottom: 0, padding: 2 }}>
              <Typography
                sx={{
                  color: theme.palette.primary.white,
                  fontSize: { xs: "12px", lg: theme.typography.fontSize },
                  fontWeight: "300",
                }}
              >
                © Copyright 2024, All Rights Reserved by WeedX
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            overflow: "auto",
          }}
        >
          {/* <Button
            sx={{
              padding: "30px",
              textTransform: "capitalize",
            }}
          >
            <ArrowLeft />
            <Typography
              pl={1}
              sx={{
                color: theme.palette.primary.black,
              }}
            >
              Go back
            </Typography>
          </Button> */}

          {forgotPassword === "forgotPassword" && (
            <>
              <Box
                sx={{
                  px: { xs: 3, sm: 5, md: 10, lg: 25 },
                  py: 5,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  // width: '100%',
                  height: "80vh",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Box mb={2}>
                    <RefreshImage />
                  </Box>
                  <PrimaryHeading
                    color={theme.palette.primary.inputLabelColor}
                    fontSize={{ xs: "28px" }}
                    fontWeight="600"
                  >
                    Forgot password?
                  </PrimaryHeading>
                  <Typography
                    sx={{
                      color: theme.palette.primary.mediumGrey,
                      fontSize: "14px",
                      textAlign: "center",
                      mb: 3,
                      mt: { xs: 1, sm: 2 },
                    }}
                  >
                    No worries, we’ll send you reset instructions.
                  </Typography>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <div>
                    <InputFieldLabel>Email</InputFieldLabel>
                    <InputField
                      background="white"
                      placeholder="Enter your Email"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      error={formik.errors.email ? true : false}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="error">{formik.errors.email}</div>
                    )}
                  </div>

                  <PrimaryButton
                    width="100%"
                    margin={4}
                    onClick={formik.handleSubmit}
                  >
                    Reset password
                  </PrimaryButton>

                  <Box display={"flex"} justifyContent={"center"} mt={3}>
                    <Typography
                      sx={{
                        color: theme.palette.primary.mediumGrey,
                        fontSize: theme.typography.fontSize,
                      }}
                    >
                      Already have an account?
                      <Link
                        style={{
                          color: theme.palette.primary.main,
                          paddingLeft: "10px",
                          textDecoration: "none",
                        }}
                        to="/login"
                      >
                        Login
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </>
          )}

          {forgotPassword === "checkInbox" && (
            <>
              <Box
                sx={{
                  px: { xs: 3, sm: 5, md: 10, lg: 25 },
                  py: 5,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  // width: '100%',
                  height: "80vh",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Box mb={2}>
                    <EmailImage />
                  </Box>
                  <PrimaryHeading
                    color={theme.palette.primary.inputLabelColor}
                    fontSize={{ xs: "28px" }}
                    fontWeight="600"
                  >
                    Check your inbox
                  </PrimaryHeading>
                  <Box
                    sx={{
                      mt: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.primary.mediumGrey,
                      }}
                    >
                      We sent a verification link & code to
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: theme.typography.heading1.fontWeight,
                        color: theme.palette.primary.mediumGrey,
                      }}
                    >
                      {showUserDetail?.userEmail}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <PrimaryButton width="100%" margin={3} onClick={handleReset}>
                    Enter code manually
                  </PrimaryButton>

                  <Box display={"flex"} justifyContent={"center"} mt={3}>
                    <Typography
                      sx={{
                        color: theme.palette.primary.black,
                        fontSize: theme.typography.fontSize,
                      }}
                    >
                      Didn’t receive the email?
                      <Link
                        style={{
                          color: theme.palette.primary.main,
                          paddingLeft: "10px",
                          textDecoration: "none",
                        }}
                        onClick={() => resendOTP(showUserDetail?.userEmail)}
                      >
                        Click to resend
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}
