import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Box, Link, Typography } from "@mui/material";
import theme from "../../../theme";
import { ReactComponent as ArrowLeftSlider } from "../../../Assets/Icons/ProductArrowLeft.svg";
import { ReactComponent as ArrowRightSlider } from "../../../Assets/Icons/ProductArrowRight.svg";
// import ProductImageOne from "../../../Assets/Images/ProductOne.png";
// import ProductImageTwo from "../../../Assets/Images/ProductTwo.png";
// import ProductImageThree from "../../../Assets/Images/ProductThree.png";
// import ChipTag from "../../../Components/Custom/Chip";
import { ReactComponent as RightArrow } from "../../../Assets/Icons/RightProductArrow.svg";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import restClient from "../../../util/restClient";

let PAGE_SIZE = 1000; //just to make sure that we get all the blogs in one go

export default function ProductsSection() {
  const [blogs, setBlogs] = useState([]);

  const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const getBlogs = useCallback(async (keyword = "") => {
    try {
      // const response = await axios.get(
      //   `https://staging.weedxhub.com/api/user/blog?page=${1}&pageSize=${PAGE_SIZE}&title=${keyword}`
      // );
      const response=await restClient({
        method:"GET",
        url:`/user/blog?page=${1}&pageSize=${PAGE_SIZE}&title=${keyword}`
      })
      console.log(response, "blog response");
      if (response?.status === 200) {
        setBlogs([...response.data.data.blogs]);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getBlogs();
  }, [getBlogs]);

  const formatDate = (enteredDate) => {
    const date = new Date(enteredDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${month}.${day}.${year.toString().slice(2)}`;
  };

  console.log(blogs, "blogs");

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    // const {
    //   carouselState: { currentSlide },
    // } = rest;

    return (
      <>
        <Box
          sx={{
            position: "absolute",
            top: { xs: "15%", sm: "20%", md: "25%", lg: "20%" },
            right: { xs: "5%", lg: "3%" },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: { xs: "90%", lg: "94%" },
          }}
        >
          <Box
            onClick={() => previous()}
            sx={{
              width: "40px",
              height: "40px",
              background: theme.palette.primary.white,
              borderRadius: "128px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ArrowLeftSlider />
          </Box>
          <Box
            sx={{
              width: "40px",
              height: "40px",
              background: theme.palette.primary.white,
              borderRadius: "128px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => next()}
          >
            <ArrowRightSlider />
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <Box
        mt={{ xs: 5, lg: 14 }}
        sx={{
          position: "relative",
        }}
      >
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          renderButtonGroupOutside={false}
          customButtonGroup={<ButtonGroup />}
          arrows={false}
        >
          {blogs &&
            blogs?.length > 0 &&
            blogs?.map((blog) => (
              <Box
                key={blog?._id}
                sx={{
                  width: "100%",
                  backgroundColor: theme.palette.primary.white,
                  height: "100%",
                }}
              >
                {blog?.bannerImage && (
                  <Box>
                    <img src={blog?.bannerImage} width={"100%"} alt="" />
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: { xs: "center", lg: "start" },
                    alignItems: { xs: "center", lg: "flex-start" },
                    padding: { xs: "3rem", lg: "30px 30px" },
                  }}
                >
                  {blog?.time && (
                    <Typography
                      sx={{
                        color: theme.palette.primary.mediumGrey,
                        fontSize: "48px",
                        fontWeight: theme.typography.heading1.fontWeight,
                        opacity: "0.2",
                      }}
                    >
                      {formatDate(blog?.time)}
                    </Typography>
                  )}
                  {blog?.createdBy && (
                    <Typography
                      sx={{
                        color: theme.palette.primary.black,
                        fontSize: theme.typography.fontSize,
                        mt: 3,
                      }}
                    >
                      By {blog?.createdBy}
                    </Typography>
                  )}
                  {blog?.title && (
                    <Typography
                      sx={{
                        color: theme.palette.primary.black,
                        fontSize: theme.typography.fontSize32,
                        fontWeight: theme.typography.heading1.fontWeight,
                        lineHeight: "44px",
                        mt: 1,
                        mb: 2,
                        textAlign: { xs: "center", lg: "left" },
                      }}
                    >
                      {blog?.title.slice(0, 80)}...
                    </Typography>
                  )}

                  {blog?.introduction && (
                    <Typography
                      sx={{
                        color: theme.palette.primary.mediumGrey,
                        fontSize: theme.typography.fontSize16,
                        lineHeight: "28px",
                        mt: 2,
                        mb: 2,
                        textAlign: { xs: "center", lg: "left" },
                      }}
                    >
                      {blog?.introduction.slice(0, 200)}...
                    </Typography>
                  )}

                  <Link
                    sx={{
                      textDecoration: "none",
                      color: theme.palette.primary.black,
                      fontSize: theme.typography.fontSize,
                      fontWeight: theme.typography.heading1.fontWeight,
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate(`/blog-details/${blog?._id}`)}
                  >
                    Read More
                    <Box
                      ml={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <RightArrow />
                    </Box>
                  </Link>
                </Box>
              </Box>
            ))}
        </Carousel>
      </Box>
    </>
  );
}
