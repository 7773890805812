import React, { useEffect, useRef, useState } from "react";
import TopHeader from "../Header/TopHeader";
import MenuBar from "../../../Components/Custom/MenuBar";
import SmallBanner from "../../../Components/Custom/SmalBanner";
import { Grid } from "@mui/material";
import SmallFooter from "../SmallFooter/SmallFooter";
//import BasicPagination from "../../../Components/Custom/Pagination";
import BrandBox from "./BrandBox";
import InnerPageTopBar from "../../../Components/Custom/InnerPageTopBar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCallback } from "react";
import restClient from "../../../util/restClient";

let currentPage = 1;
let defaultPageSize = 20;

if (window.innerWidth < 600) {
  defaultPageSize = 20;
} else if (window.innerWidth < 1536) {
  defaultPageSize = 16;
} else {
  defaultPageSize = 20;
}

let pageSize = defaultPageSize;
export default function PublicBrands() {
  const [publicBrands, setPublicBrands] = useState([]);
  // const [pageSize, setPageSize] = useState(defaultPageSize);
  //const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [allowInfiniteScroll, setAllowInfiniteScroll] = useState(true);
  const observerTarget = useRef(null);

  const navigate = useNavigate();

  const getPublicBrands = useCallback(async (keyword = "") => {
    try {
      let url;
      if (keyword)
        url = `/admin/scrap/brands?page=${currentPage}&pageSize=${pageSize}&name=${keyword}`;
      else url = `/admin/scrap/brands?page=${currentPage}&pageSize=${pageSize}`;
      setIsLoading(true);

      const response = await restClient({
        method: "GET",
        url: url,
      });

      console.log(response, "public brands response");
      if (response?.status === 200) {
        const newPublicBrands = [...response.data.data.documents];
        // setPublicBrands([...response.data.data.documents]);
        console.log(
          "checking infinite scroll",
          newPublicBrands.length,
          pageSize,
        );
        if (newPublicBrands.length < 16) {
          setAllowInfiniteScroll(false);
        } else {
          setAllowInfiniteScroll(true);
        }
        setPublicBrands(newPublicBrands);
        //setPageCount(Math.ceil(response?.data.data.total / pageSize));
        // setPageSize((prev) => prev + 10);

        console.log("searching", searchValue, newPublicBrands, pageSize);
        pageSize += 10;

        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }, []);

  // console.log("searching", searchValue);
  console.log(publicBrands, "publicBrand response");
  console.log("searching 2", searchValue, pageSize);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        getPublicBrands();
      }
    });

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [getPublicBrands]);

  // const handlePageChange = (event, page) => {
  //   setCurrentPage(page);
  // };

  return (
    <>
      <TopHeader />
      <MenuBar isVisible={false} />
      <SmallBanner
        breadcrumbsMainTitle={"Brands"}
        breadcrumbsSmallTitle={"Brands"}
      />
      <Grid
        container
        sx={{
          padding: { xs: "1.5rem", lg: "0 5rem" },
          my: { xs: 1, sm: 3, lg: 5 },
        }}
      >
        <Grid item xs={12}>
          <InnerPageTopBar
            getSearchResults={getPublicBrands}
            width="100%"
            placeholder="Search Brands"
          />
        </Grid>

        {publicBrands &&
          publicBrands.length > 0 &&
          publicBrands.map((brand) => (
            <Grid
              key={brand?._id}
              item
              xs={6}
              sm={3}
              xl={2.4}
              sx={{ margin: "0.5rem 0rem" }}
            >
              <BrandBox
                imageUrl={
                  brand?.["Brand Logo"]
                    ? brand?.["Brand Logo"]
                    : brand?.["Profile Image"]
                }
                title={brand?.["Brand Name"]}
                onClick={() => navigate(`/brand-details/${brand?.username}`)}
              />
            </Grid>
          ))}
      </Grid>

      {/* <Grid
        container
        sx={{
          padding: { xs: "1.5rem", lg: "0 10rem" },
          my: { xs: 1, sm: 3, lg: 5 },
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <BasicPagination
            handlePageChange={handlePageChange}
            count={pageCount}
            currentPage={currentPage}
          />
        </Grid>
      </Grid> */}
      {isLoading && <p style={{ textAlign: "center" }}>Loading...</p>}
      {allowInfiniteScroll && <div ref={observerTarget}></div>}
      <SmallFooter />
    </>
  );
}
