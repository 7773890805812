import { Box, Stack } from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import CustomPrimaryButton from "./CustomPrimaryButton";
import { useEffect } from "react";
import CustomSecondaryButton from "./CustomSecondaryButton";

function DeleteModal({
  handleSubmit,
  handleClose,
  headingText,
  subHeadingText,
  isLoading,
}) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        zIndex: "999",
        bgcolor: "rgba(0, 0, 0, 0.6)",
      }}
      onClick={handleClose}
    >
      <Stack
        alignItems={"center"}
        spacing={3}
        sx={{
          padding: "24px",
          width: { lg: "400px", xs: "300px" },
          margin: "200px auto",
          bgcolor: "#fff",
          boxShadow: "0px 32px 64px -12px rgba(16, 24, 40, 0.32)",
          borderRadius: "4px",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            height: "40px",
            width: "40px",
            bgcolor: "#ffe6e6",
            borderRadius: "50%",
          }}
        >
          <DeleteIcon sx={{ color: "#F78B8B", cursor: "pointer" }} />
        </Stack>
        <span
          style={{
            fontSize: "20px",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {headingText}
        </span>
        <span
          style={{
            fontSize: "14px",
            fontWeight: "300",
            color: "#596570",
          }}
        >
          {subHeadingText}
        </span>
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Box sx={{ width: "100%" }}>
            <CustomSecondaryButton onClick={handleClose}>
              Cancel
            </CustomSecondaryButton>
          </Box>
          <Box sx={{ width: "100%" }}>
            <CustomPrimaryButton
              backgroundColor="#F78B8B"
              color="white"
              onClick={handleSubmit}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Delete
            </CustomPrimaryButton>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

export default DeleteModal;
