import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
    heading1: {
      fontSize: "28px",
      fontWeight: "600",
    },
    heading2: {
      fontSize: "18px",
      fontWeight: "600",
    },
    heading3: {
      fontSize: "16px",
      fontWeight: "regular",
    },
    extra_small: 12,
    small_font: 13,
    fontSize: 14,
    fontSize16: 16,
    fontSize20: 20,
    fontSize32: 32,
    fontSize45: 45,
    fontWeight500: "500",
  },
  palette: {
    primary: {
      main: "#1B8A79",
      lightGrey: "#0000000D",
      black: "#2E3A48",
      inputTextColor: "#87929E",
      mediumGrey: "#596570",
      white: "#FFFFFF",
      peachRed: "#F78B8B",
      darkGreen: "#24B314",
      footerGrey: "#A3ACBA",
      inputLabelColor: "#212B36",
      footerBg: "#292F4C",
      dashboardBack: "#F9F9FC",

      table: {
        table_header: "#F0F4F8",
        table_row: "#F8FAFC",
      },
      btn: {
        secondary: "#EFF2F4",
      },
    },
  },
  shapes: {
    borderLeft: "2px solid #1B8A79",
    borderRadius: "15px",
    primaryBtnBorderRadius: "8px",
    dropDownBorderRadius: "12px",
    inputFieldLabel: "12px",
  },
  fonts: {
    primary: "'Poppins', sans-serif;",
  },
  table: {
    borderRadius: "12px",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
