import React, { useEffect, useState } from "react";
import restClient from "../../../../util/restClient";
import { ToastContainer, toast } from "react-toastify";
import { Box, Grid, Typography } from "@mui/material";
import SmallBanner from "../../../../Components/Custom/SmalBanner";
import MenuBar from "../../../../Components/Custom/MenuBar";
import TopHeader from "../../Header/TopHeader";
import theme from "../../../../theme";

const PrivacyPolicy = () => {
  const [privacyPolicyData, setPrivacyPolicyData] = useState([]);
  const getPrivacyPolicy = async () => {
    try {
      const response = await restClient({
        method: "GET",
        url: `/admin/privacy-policy`,
      });
      console.log(response, "response");
      if (response?.data?.success) {
        setPrivacyPolicyData(response?.data?.data[0]);
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    getPrivacyPolicy();
  }, []);
  return (
    <>
      <ToastContainer />
      <TopHeader />
      <MenuBar />
      <SmallBanner breadcrumbsMainTitle={"Privacy Policy & Terms of Use"} breadcrumbsSmallTitle={"Privacy Policy & Terms of Use"} />
      <Grid
        container
        sx={{
          justifyContent: "center",
          my: { xs: 4, sm: 7 },
          padding: { lg: "0rem 10rem" },
        }}
      >
        {/* <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="text-justify" dangerouslySetInnerHTML={{ __html: privacyPolicyData?.htmlPolicyParagraph }}></div>
            </div>
          </div>
        </div> */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              color: theme.palette.primary.mediumGrey,
              fontSize: { xs: "12px", sm: "16px" },
              fontWeight: "300",
              marginX: { xs: "8rem", lg: "1rem" },
            }}
            dangerouslySetInnerHTML={{ __html: privacyPolicyData?.htmlPolicyParagraph }}
          ></Typography>
        </Box>
      </Grid>
    </>
  );
};

export default PrivacyPolicy;
