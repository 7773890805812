import { useState } from "react";
import Box from "@mui/material/Box";
import SideBarLayout from "../../../SharedComponents/SideBarLayout";
import { Grid, Typography } from "@mui/material";
import theme from "../../../theme";
import BasicTabs from "../../../Components/Custom/Tabs";
import UserInfo from "./UserInfo";
import MetricApiKeys from "./MetricApiKeys";
import PrivacyAndPassword from "./PrivacyAndPassword";
import Membership from "./Membership";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as LogoWhite } from "../../../Assets/Icons/LogoWhite.svg";
import heroSubTabBannerGreen from "../../../Assets/Images/heroSubTabBannerGreen.png";

export default function StorefrontSettings(props) {
  const [open, setOpen] = useState(false);

  const selectUserAuth = (state) => state.userRegReducer?.userAuth;
  const userData = useSelector(selectUserAuth);
  console.log(userData, "userData");

  const tabs = [
    {
      label: "User info",
      content: <UserInfo />,
    },
    {
      label: "Metric API keys",
      content: <MetricApiKeys />,
    },
    {
      label: "Privacy & Password",
      content: <PrivacyAndPassword />,
    },

    ...(userData?.user?.role === "SELLER" || userData?.user?.role === "BOTH"
      ? [
          {
            label: "Membership",
            content: <Membership />,
          },
        ]
      : []),
  ];

  return (
    <Box
      sx={{
        height: { md: "100vh" },
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        overflow: "hidden",
        background: theme.palette.primary.dashboardBack,
      }}
    >
      <Box
        sx={{
          display: { xs: "none", md: "inline-flex" },
          height: "100%",
          width: { xs: "75%", sm: "40%", md: "30%", lg: "20%" },
        }}
      >
        <SideBarLayout />
      </Box>
      <Box
        sx={{
          display: { md: "none" },
        }}
      >
        {open && <SideBarLayout setOpen={setOpen} />}
        <Grid
          item
          container
          sx={{
            padding: { xs: "1.5rem", sm: "1.5rem 2rem", lg: "0 10rem" },
            background: `url(${heroSubTabBannerGreen})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            display: { md: "none" },
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: { md: "none" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
            container
          >
            <Link to={"/"}>
              <LogoWhite />
            </Link>
            <Box
              onClick={() => {
                setOpen(true);
                document.body.style.overflow = "hidden";
              }}
            >
              <MenuIcon sx={{ color: "white", width: "25px", height: "25px" }} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          width: { md: "70%", lg: "80%" },
        }}
      >
        <Box
          sx={{
            background: "white",
            height: "90%",
            overflowY: "hidden",
            padding: { xs: "1rem", md: "2rem" },
            borderRadius: "5px",
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 600,
              color: theme.palette.primary.black,
            }}
          >
            My account
          </Typography>
          <Box
            sx={{
              marginTop: "1rem",
              height: "100%",
            }}
          >
            <BasicTabs tabs={tabs} display="flex" width={{ lg: "220px" }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
