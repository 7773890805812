import { Box, Stack } from "@mui/material";
import React from "react";

export function ChatComponentSender({ chat }) {
  return (
    <Stack
      spacing={2}
      direction="column"
      sx={{
        boxSizing: "border-box",
        padding: "10px",
        borderRadius: "4px",
        bgcolor: "#e0f2f1",
        color: "#2E3A48",
        maxWidth: "80%",
        fontSize: "14px",
        position: "relative",
      }}
    >
      <Box sx={{ marginRight: "80px !important" }}>{chat.message}</Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "4px",
          right: "8px",
          color: "#1B8A79",
        }}
      >
        {chat.time}
      </Box>
    </Stack>
  );
}

export function ChatComponentReciever({ chat }) {
  return (
    <Stack
      spacing={2}
      direction="column"
      sx={{
        boxSizing: "border-box",
        padding: "10px",
        borderRadius: "4px",
        bgcolor: "#f5f5f5",
        color: "#2E3A48",
        maxWidth: "80%",
        fontSize: "14px",
        position: "relative",
      }}
    >
      <Box sx={{ marginRight: "80px !important" }}>{chat.message}</Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "4px",
          right: "8px",
          color: "#A3ACBA",
        }}
      >
        {chat.time}
      </Box>
    </Stack>
  );
}
