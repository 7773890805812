import React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { Box } from "@mui/material";

const TextFieldInputBase = styled(TextField)(({ theme, background, margin, padding }) => ({
  background: background || "transparent",
  // color: theme.palette.primary.green_dark,
  width: "100%",
  //border: "1px solid lightGrey",
  // borderRadius: "5px",
  // margin: margin || "",
  boxShadow: 0,
  // "& .MuiInput-root:hover": {
  //   "& before": {

  //     borderBottom: '0 !important'
  //   }

  // },
  "& .MuiInputBase-input": {
    width: "100%",
    borderRadius: "5px",
    fontSize: "14px",
    padding: padding ? padding : "10px",
    boxShadow: 0,
    "& hover": {
      border: "none !important",
    },
  },
  // "& .MuiInput-root:before": {
  //   borderBottom: "0",
  // },
  // "& .MuiInput-root:after": {
  //   borderBottom: "0",
  // },
  // "& .Mui-focused": {
  //   color: "rgba(0, 0, 0, 0.87)",
  //   borderBottom: 0,
  //   boxShadow: 0,
  // },
}));

const InputField = (props) => {
  const { background, placeholder, name, onChange, onBlur, value, type, maxLength, borderRadius, error, ...otherProps } = props;

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <TextFieldInputBase
        InputLabelProps={{
          sx: {
            [`&.${inputLabelClasses.shrink}`]: {
              color: "#5D953C !important",
            },
          },
        }}
        variant="outlined"
        size="standard"
        sx={{
          background: background ? background : "transparent",
          borderRadius: borderRadius ? borderRadius : "4px",
          fontSize: "14px",
        }}
        {...otherProps}
        onChange={onChange}
        value={props.value}
        name={name}
        type={props.type ? props.type : "text"}
        placeholder={placeholder}
        InputProps={props.inputProps}
        onBlur={onBlur}
        maxLength={maxLength}
        required={props.required}
      />
      {error !== null && (
        <Box
          sx={{
            color: "red",
            fontSize: "12px",
            fontWeight: "400",
            marginTop: "5px",
          }}
        >
          {error}
        </Box>
      )}
    </Box>
  );
};

export default InputField;
