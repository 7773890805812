import React from "react";
import ReactRouting from "./Routes/reactRouting";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { useRef } from "react";

function App() {
  const tawkMessengerRef = useRef();

  const handleMinimize = () => {
    tawkMessengerRef.current.minimize();
  };

  return (
    <>
      {/* <button onClick={handleMinimize}> Minimize the Chat </button> */}

      <TawkMessengerReact propertyId="65df617a8d261e1b5f66a30b" widgetId="1hno99hhj" ref={tawkMessengerRef} />

      <ReactRouting />
    </>
  );
}

export default App;
