import { Box, Stack } from "@mui/material";

import UploadIcon from "../../../../../Assets/Icons/UploadIcon";
import deleteImage from "../../../../../Assets/Images/AddProduct/deleteImage.svg";
import addSmallImage from "../../../../../Assets/Images/AddProduct/addSmallImage.svg";
import CropEasy from "../../../../../Components/crop/CropEasy";
import { useEffect, useState } from "react";

const AddImagesContent = ({ handleImageChange, children }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <label
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          opacity: 0,
          zIndex: 1,
          cursor: "pointer",
        }}
        htmlFor="upload-images"
      >
        Upload
      </label>
      <input
        id="upload-images"
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
      {children}
    </Box>
  );
};

const ImageWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        width: { xs: "60px", sm: "112px" },
        height: { xs: "60px", sm: "112px" },
        borderRadius: "4px",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {children}
    </Box>
  );
};

function PhotoContainer({
  imageList,
  imageHovered,
  setImageHovered,
  setDispensary,
  editState,
  setAddDispensaryImageList,
  setEditDispensaryImageList,
  editDispensaryImageDeleteHandler,
}) {
  const [openCrop, setOpenCrop] = useState(false);
  const [imageSelectedUrl, setImageSelectedUrl] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [file, setFile] = useState(null);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        setImageSelectedUrl(reader.result);
        setOpenCrop(true);
      };
    }
  };

  const removeImageHandler = () => {
    setDispensary((prev) => {
      const images = prev.images.filter((item) => item !== imageHovered);

      return { ...prev, images };
    });

    if (!editState)
      setAddDispensaryImageList((prev) =>
        prev.filter((item) => item.src !== imageHovered),
      );

    if (editState) {
      if (imageHovered.includes("weedx.s3.amazonaws.com"))
        editDispensaryImageDeleteHandler();

      setEditDispensaryImageList((prev) =>
        prev.filter((item) => item.src !== imageHovered),
      );
    }
  };

  useEffect(() => {
    if (file && photoURL) {
      setDispensary((prev) => {
        if (!prev.images || prev.images.length === 0) {
          return { ...prev, images: [photoURL] };
        }

        return { ...prev, images: [...prev.images, photoURL] };
      });

      if (!editState)
        setAddDispensaryImageList((prev) => [...prev, { file, src: photoURL }]);

      if (editState)
        setEditDispensaryImageList((prev) => [
          ...prev,
          { file, src: photoURL },
        ]);
    }
  }, [photoURL, file]);

  return (
    <>
      {openCrop && (
        <CropEasy
          {...{
            photoURL: imageSelectedUrl,
            setPhotoURL: setPhotoURL,
            openCrop: openCrop,
            setOpenCrop: setOpenCrop,
            setFile: setFile,
          }}
        />
      )}
      <Box sx={{ marginTop: "12px" }}>
        {imageList.length > 0 && (
          <p
            style={{
              margin: "0px",
              marginBottom: "5px",
              color: "#87929E",
              fontSize: "0.8rem",
            }}
          >
            10 pictures max
          </p>
        )}
        {imageList.length === 0 && (
          <AddImagesContent handleImageChange={handleImageChange}>
            <Box
              sx={{
                backgroundColor: "#F9F9FC",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                padding: { xs: "20px", sm: "50px" },
                gap: "2px",
                cursor: "pointer",
              }}
            >
              <UploadIcon />
              <Box sx={{ fontSize: { xs: "0.6rem", sm: "1.2rem" } }}>
                Drop image(s) here or click to{" "}
                <span style={{ color: "#F78B8B" }}>upload</span>
              </Box>
              <Box
                sx={{
                  fontWeight: "300",
                  color: "#87929E",
                  fontSize: { xs: "0.6rem", sm: "1.2rem" },
                }}
              >
                You can add up to files, 10 max
              </Box>
            </Box>
          </AddImagesContent>
        )}

        {imageList.length > 0 && (
          <Stack direction={"row"} spacing={1}>
            {imageList.map((item, index) => {
              return (
                <ImageWrapper key={index + "key"}>
                  <Box>
                    <img
                      onMouseOver={(e) => setImageHovered(e.target.src)}
                      onMouseOut={() => setImageHovered("")}
                      style={{
                        width: "100%",
                        height: "100%",
                        opacity: imageHovered === item.src ? 0.6 : 1,
                        objectFit: "fill",
                      }}
                      src={item.src}
                      alt=""
                    />
                    {imageHovered === item.src && (
                      <img
                        src={deleteImage}
                        onClick={removeImageHandler}
                        onMouseOver={(e) => setImageHovered(item.src)}
                        style={{
                          position: "absolute",
                          zIndex: 1,
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                          cursor: "pointer",
                        }}
                        alt=""
                      />
                    )}
                  </Box>
                </ImageWrapper>
              );
            })}
            {imageList.length <= 4 && (
              <Box>
                <AddImagesContent handleImageChange={handleImageChange}>
                  <Box
                    width={{ xs: "60px", sm: "120px" }}
                    height={{ xs: "60px", sm: "120px" }}
                  >
                    <img
                      height="100%"
                      width="100%"
                      onClick={handleImageChange}
                      src={addSmallImage}
                      alt="add_small_image"
                    />
                  </Box>
                </AddImagesContent>
              </Box>
            )}
          </Stack>
        )}
      </Box>
    </>
  );
}

export default PhotoContainer;
