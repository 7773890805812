import { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery, Tab, Tabs, Stack, Box, Grid, CircularProgress } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import restClient from "../../../util/restClient";
import About from "./About/About";
import Reviews from "./Reviews/Reviews";
import Header from "./Header/Header";
import SideBarLayout from "../../../SharedComponents/SideBarLayout";
import heroSubTabBannerGreen from "../../../Assets/Images/heroSubTabBannerGreen.png";
import { ReactComponent as LogoWhite } from "../../../Assets/Icons/LogoWhite.svg";
import StarRating from "./components/StarRating";
import { checkURLType } from "../../Admin/ProductManagement/helpers/checkFileType";
import NoImage from "../../../Assets/Images/NoImage.svg";
import ReactImageMagnify from "react-image-magnify";
import ReactPlayer from "react-player";
import { formatDate } from "./helpers/dateFormatter";
import TopHeader from "../Header/TopHeader";
import theme from "../../../theme";
function ProductOverview() {
  const { productID } = useParams();
  const navigate = useNavigate();
  const tabVariant = useMediaQuery("(max-width: 450px)") ? "scrollable" : "fullWidth";
  const [tabSelected, setTabSelected] = useState("description");
  const [productData, setProductData] = useState({});
  const [reviewsData, setReviewsData] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const getProductData = async () => {
    try {
      setIsLoader(true);
      const response = await restClient({
        method: "GET",
        url: `/user/products/${productID}`,
      });

      setProductData(response?.data?.data?.product[0] || {});
      setReviewsData(response?.data?.data?.statistics[0] || {});
      setImagePreview(response?.data?.data?.product[0]?.images[0] || NoImage);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    getProductData();
  }, []);

  return (
    <>
      <TopHeader />
      <Stack
        // sx={{
        //   maxHeight: "90vh",
        //   height: "100%",
        //   overflowY: "scroll",
        // }}
        direction={{ xs: "column", md: "row" }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "inline-flex" },
            height: "100vh",
            width: { sm: "40%", md: "30%", lg: "20%" },
            position: "fixed",
          }}
        >
          {/* <SideBarLayout /> */}
        </Box>
        <Box sx={{ display: { sm: "none" } }}>
          {/* {open && <SideBarLayout setOpen={setOpen} />} */}
          <Grid
            item
            container
            sx={{
              padding: { xs: "1.5rem", sm: "1.5rem 2rem", lg: "0 10rem" },
              background: `url(${heroSubTabBannerGreen})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              display: { md: "none" },
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: { md: "none" },
                justifyContent: "space-between",
                alignItems: "center",
              }}
              container
            >
              <Link to={"/"}>
                <LogoWhite />
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            // marginLeft: userData?.authorized ? { md: "30%", lg: "20%" } : {},
          }}
        >
          <Grid
            sx={{
              padding: {
                xs: "16px",
                lg: "36px",
              },
              boxSizing: "border-box",
            }}
            spacing={{ xs: 2, lg: 6 }}
            container
          >
            {isLoader ? (
              <Grid item xs={12}>
                <Stack minHeight={"200px"} alignItems={"center"} justifyContent={"center"}>
                  <CircularProgress />
                </Stack>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <Stack spacing={2}>
                    {checkURLType(imagePreview) === "image" && (
                      <>
                        <Stack
                          alignItems={"center"}
                          sx={{
                            borderRadius: "4px",
                            bgcolor: "white",
                            border: "1px solid #dddddd",
                            display: { xs: "none", md: "flex" },
                          }}
                        >
                          <Box
                            sx={{
                              width: { lg: "400px" },
                              height: { lg: "400px" },
                            }}
                          >
                            <ReactImageMagnify
                              {...{
                                smallImage: {
                                  alt: "Product image",
                                  isFluidWidth: true,
                                  src: imagePreview,
                                },
                                largeImage: {
                                  src: imagePreview,
                                  width: 1400,
                                  height: 1400,
                                },
                                className: "magnify_image_cont",
                                imageClassName: "small_magnify_image",
                                lensStyle: { backgroundColor: "rgba(0,0,0,.6)" },
                              }}
                            />
                          </Box>
                        </Stack>
                        <Box
                          sx={{
                            width: "100%",
                            height: { xs: "200px", lg: "400px" },
                            borderRadius: "4px",
                            bgcolor: "white",
                            border: "1px solid #dddddd",
                            display: { xs: "block", md: "none" },
                          }}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                            src={imagePreview}
                            alt=""
                          />
                        </Box>
                      </>
                    )}
                    {checkURLType(imagePreview) === "video" && (
                      <Box sx={{ height: { xs: "200px", lg: "400px" } }}>
                        <ReactPlayer url={imagePreview} width="100%" height="100%" controls={true} />
                      </Box>
                    )}

                    {productData?.images?.length > 1 && (
                      <Stack sx={{ padding: "8px 0" }} direction={"row"} spacing={2}>
                        {productData?.images?.map((item, index) => {
                          if (checkURLType(item) === "image")
                            return (
                              <Box
                                width={{ xs: "60px", sm: "120px" }}
                                height={{ xs: "60px", sm: "120px" }}
                                sx={{
                                  overflow: "hidden",
                                  position: "relative",
                                  borderRadius: "4px",
                                  cursor: "pointer",
                                }}
                                key={index + "key"}
                                onClick={() => setImagePreview(item)}
                              >
                                <img
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                  src={item}
                                  alt=""
                                />
                              </Box>
                            );

                          if (checkURLType(item) === "video")
                            return (
                              <Box
                                width={{ xs: "60px", sm: "120px" }}
                                height={{ xs: "60px", sm: "120px" }}
                                sx={{
                                  overflow: "hidden",
                                  position: "relative",
                                  borderRadius: "4px",
                                }}
                                key={index + "key"}
                              >
                                <ReactPlayer url={item} width="100%" height="100%" controls={true} />
                              </Box>
                            );
                        })}
                      </Stack>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={2}>
                    <Stack>
                      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <span style={{ fontSize: "24px", fontWeight: "500" }}>{productData["Product Name"] || ""}</span>
                        {/* Remove edit and delete icons */}
                      </Stack>
                      <span style={{ fontWeight: "300" }}>
                        ${productData["Rate"]}/{productData["Unit"]}
                      </span>
                    </Stack>
                    <Stack spacing={1} direction={"row"} alignItems={"center"}>
                      <StarRating size="small" name="read-only" value={reviewsData?.averageRating || 0} behavior="readOnly" />
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "300",
                          color: "#87929E",
                        }}
                      >
                        {reviewsData?.averageRating || 0}
                      </span>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "300",
                          color: "#87929E",
                        }}
                      >
                        {reviewsData?.totalReviews || 0} Reviews
                      </span>
                    </Stack>
                    <Box
                      sx={{
                        padding: "12px",
                        bgcolor: "white",
                        border: "1px solid #dddddd",
                      }}
                    >
                      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        {/* Display brand and user information */}
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                          <Box
                            sx={{
                              height: "64px",
                              width: "64px",
                              borderRadius: "50%",
                              overflow: "hidden",
                            }}
                          >
                            <img style={{ width: "100%" }} src={productData?.brand?.["Brand Logo"] || NoImage} alt="" />
                          </Box>
                          <Stack>
                            <span
                              style={{
                                fontWeight: "500",
                                textDecoration: "underline",
                                color: "#1B8A79",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                navigate(`/brand-details/${productData?.brand?.username}`);
                              }}
                            >
                              {productData?.brand?.["Brand Name"] || ""}
                            </span>
                            <span style={{ fontWeight: "400" }}>{productData?.user?.fullname || ""}</span>
                            <span style={{ fontWeight: "300", fontSize: "14px" }}>Joined WeedX in {formatDate(productData?.user?.createdAt)}</span>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Box>
                  </Stack>
                </Grid>
              </>
            )}
          </Grid>
          {/* <Header isLoader={isLoader} productData={productData} reviewsData={reviewsData} setProductData={setProductData} /> */}
          <Stack
            spacing={3}
            sx={{
              padding: {
                xs: "16px",
                lg: "36px",
              },
            }}
          >
            <Tabs
              value={tabSelected}
              onChange={(event, newValue) => {
                setTabSelected(newValue);
              }}
              variant={tabVariant}
              scrollButtons="auto"
              textColor="inherit"
              sx={{
                borderRadius: "4px",
                bgcolor: "white",
                "& .MuiTabs-indicator": {
                  backgroundColor: "#F78B8B",
                },
              }}
              aria-label="scrollable auto tabs "
            >
              <Tab value="description" label="Description" />
              <Tab value="reviews" label="Reviews" />
            </Tabs>
            {tabSelected === "description" && <About isLoader={isLoader} productData={productData} />}
            {tabSelected === "reviews" && <Reviews productOwnerID={productData.userId} />}
          </Stack>
        </Box>
      </Stack>
    </>
  );
}

export default ProductOverview;
