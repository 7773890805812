import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const StyledCheckbox = styled(Checkbox)((props) => ({
  paddingLeft: "0 !important",
  background: props.background ? props.background : "white",
  "& .MuiSvgIcon-root": {
    fill: "#1B8A79 !important",
  },
}));

export default function CustomCheckbox({ checked, onChange, background }) {
  return (
    <>
      <StyledCheckbox
        {...label}
        checked={checked}
        onChange={onChange}
        background={background}
      />
    </>
  );
}
