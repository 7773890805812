import { Grid, Link } from "@mui/material";
import PrimaryHeading from "../../../Components/Custom/PrimaryHeading";
import theme from "../../../theme";
import CustomAccordion from "../../../Components/Custom/Accordion";
import { useNavigate } from "react-router-dom";
import restClient from "../../../util/restClient";
import { useEffect, useState } from "react";

export default function FrequentlyQuestionSection() {
  const navigate = useNavigate();

  const [faqs, setFaqs] = useState([]);

  const getAllFaqs = async (keyword = "") => {
    try {
      // const response = await axios.get(
      //   `https://staging.weedxhub.com/api/user/faq-info?&name=${keyword}`
      // );
      const response = await restClient({
        method: "GET",
        url: `/user/faq-info?&name=${keyword}`,
      });

      if (response) {
        setFaqs([...response?.data?.data]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllFaqs();
  }, []);

  return (
    <>
      <Grid
        container
        sx={{
          padding: { xs: "1.5rem", lg: "0 10rem" },
          mt: { xs: 0, lg: 12 },
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: { md: "space-between" },
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              padding: { xs: "0rem 3rem", md: "0rem" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <PrimaryHeading>Frequently asked questions</PrimaryHeading>
            <Link
              sx={{
                color: theme.palette.primary.main,
                fontSize: theme.typography.fontSize,
                textDecoration: "underline",
                margin: { xs: "1rem 0rem", md: "0rem" },
              }}
              onClick={() => navigate("/faqs")}
            >
              See All FAQs
            </Link>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6} pl={{ md: 2 }} mt={{ md: 4 }}>
            {faqs &&
              faqs?.length > 0 &&
              faqs
                .slice(0, faqs.length / 2)
                .map((faq, index) => (
                  <CustomAccordion
                    key={faq._id}
                    accordionTitle={faq?.question}
                    accordionDetail={faq?.answer}
                    borderRadius={
                      index === 0
                        ? "4px 4px 0px 0px !important"
                        : index === faqs.length - 1
                        ? "0px 0px 4px 4px !important"
                        : ""
                    }
                  />
                ))}
          </Grid>
          <Grid item xs={12} md={6} pl={{ md: 2 }} mt={{ md: 4 }}>
            {faqs &&
              faqs?.length > 0 &&
              faqs
                .slice(faqs.length / 2)
                .map((faq, index) => (
                  <CustomAccordion
                    key={faq._id}
                    accordionTitle={faq?.question}
                    accordionDetail={faq?.answer}
                    borderRadius={
                      index === 0
                        ? "4px 4px 0px 0px !important"
                        : index === faqs.length - 1
                        ? "0px 0px 4px 4px !important"
                        : ""
                    }
                  />
                ))}
          </Grid>
          {/* <Grid item xs={12} md={6} pl={{ md: 2 }} mt={{ md: 4 }}>
            <CustomAccordion
              accordionTitle={"How does this help me as dispensary?"}
              accordionDetail={
                "It is a way for Sellers to purchase advertising space on featured product page and also get a access on our weekly email marketing."
              }
              borderRadius="4px 4px 0px 0px !important"
            />
            <CustomAccordion
              accordionTitle={
                "Is a state sponsored cannabis license required to register?"
              }
              accordionDetail={
                "It is a way for Sellers to purchase advertising space on featured product page and also get a access on our weekly email marketing."
              }
            />
            <CustomAccordion
              accordionTitle={"How does this help me as a grower?"}
              accordionDetail={
                "It is a way for Sellers to purchase advertising space on featured product page and also get a access on our weekly email marketing."
              }
              borderRadius="0px 0px 4px 4px !important"
            />
          </Grid> */}
        </Grid>
      </Grid>
    </>
  );
}
