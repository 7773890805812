import * as Yup from "yup";

export const validateMetrcSchema = Yup.object().shape({
  companyName: Yup.string().required("Company name is required"),
  metrcAPIKey: Yup.string().required("Metrc API key is required"),
  facilityAddress: Yup.string().required("Facility address is required"),
});

export const initialValues = {
  companyName: "",
  metrcAPIKey: "",
  facilityAddress: "",
};
