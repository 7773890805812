import { Box, Grid, Stack } from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import { default as React, useState, useEffect, useRef } from "react";
import banner from "../../../Assets/Images/AddBrand/banner.svg";
import addBanner from "../../../Assets/Images/AddBrand/addBanner.svg";
import addLogo from "../../../Assets/Images/AddBrand/addLogo.svg";
import BoxWrapper from "./components/BoxWrapper";
import BoxWrapperV2 from "./components/BoxWrapperV2";
import { AboutUsContent, KeyFeaturesContent, BusinessInfoContent, SocialAccountContent } from "./components/boxContent";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import SecondaryButton from "../../../Components/Custom/SecondaryButton";
import { Key } from "@mui/icons-material";
import CameraProfileIcon from "../../../Assets/Icons/CameraProfileIcon";
import { useSelector } from "react-redux";
import restClient from "../../../util/restClient";
import { Link, useNavigate, useParams } from "react-router-dom";
import CameraIcon from "../../../Assets/Icons/CameraIcon";
import addImage from "../../../Assets/Images/AddProduct/addImage.svg";
import deleteImage from "../../../Assets/Images/AddProduct/deleteImage.svg";
import addSmallImage from "../../../Assets/Images/AddProduct/addSmallImage.svg";
import { AddImagesContent, ImageWrapper } from "../ProductManagement/components/boxContent";
import theme from "../../../theme";
import SideBarLayout from "../../../SharedComponents/SideBarLayout";
import { ReactComponent as LogoWhite } from "../../../Assets/Icons/LogoWhite.svg";
import heroSubTabBannerGreen from "../../../Assets/Images/heroSubTabBannerGreen.png";
import MenuIcon from "@mui/icons-material/Menu";
import UploadIcon from "../../../Assets/Icons/UploadIcon";
import BrandLogo from "../../../Assets/Icons/BrandLogo.png";
import { ToastContainer, toast } from "react-toastify";
import CropEasy from "../../../Components/crop/CropEasy";
import CustomPrimaryButton from "../../../Components/Custom/CustomPrimaryButton";

export default function AddNewBrand({ editState = false }) {
  let { id } = useParams();
  const userData = useSelector((state) => state.userRegReducer?.userAuth);
  const navigate = useNavigate();

  const [fileList, setFileList] = useState([]);
  const [bannerImage, setBannerImage] = useState();
  const [profileImage, setProfileImage] = useState();
  const [profilePreview, setProfilePreview] = useState();
  const [bannerPreview, setBannerPreview] = useState();
  const [imageHovered, setImageHovered] = useState("");
  const [open, setOpen] = useState(false);
  const [licenseData, setLicenseData] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const [brandData, setBrandData] = useState({
    businessName: "",
    businessEmail: "",
    phoneNo: "",
    website: "",
    address: "",
    facebook: "",
    instagram: "",
    twitter: "",
    aboutUs: "",
    license: "",
    keyFeatures: [],
  });
  const [errorMessages, setErrorMessages] = useState({
    businessName: null,
    address: null,
    license: null,
    profileImage: null,
  });

  const [openCropProfile, setOpenCropProfile] = useState(false);
  const [openCropBanner, setOpenCropBanner] = useState(false);
  const [imageSelectedUrl, setImageSelectedUrl] = useState("");
  const [openCropImages, setOpenCropImages] = useState(false);
  const [imagesFile, setImagesFile] = useState(null);
  const [cropImageURL, setCropImageURL] = useState("");

  const bannerRef = useRef();
  const profileRef = useRef();

  const formatPhoneNumber = (input) => {
    const numericInput = input.replace(/\D/g, "");
    if (!numericInput || isNaN(numericInput)) {
      return "";
    }
    return `(${numericInput.slice(0, 3)}) ${numericInput.slice(3, 6)}-${numericInput.slice(6, 10)}`;
  };

  const onChangeHandler = (e) => {
    console.log(e.target.name, e.target.value);
    if (e.target.name === "businessName" || e.target.name === "address") {
      if (errorMessages[e.target.name] !== null) {
        if (e.target.value !== "") {
          setErrorMessages((prev) => ({
            ...prev,
            [e.target.name]: "",
          }));
        } else {
          const errMsg =
            e.target.name === "license"
              ? "License is required"
              : e.target.name === "businessName"
              ? "Business Name is required"
              : "Location is required";
          setErrorMessages((prev) => ({
            ...prev,
            [e.target.name]: errMsg,
          }));
        }
      }
    }
    if (e.target.name === "phoneNo") {
      if (e.nativeEvent.inputType === "deleteContentBackward") {
        // Handle backspace key
        setBrandData({ ...brandData, [e.target.name]: e.target.value });
        return;
      }
      const formattedNumber = formatPhoneNumber(e.target.value);
      setBrandData({ ...brandData, [e.target.name]: formattedNumber });
    } else {
      setBrandData({ ...brandData, [e.target.name]: e.target.value });
    }
  };

  const addKeyFeatureHandler = (value) => {
    const newKeyFeatures = [...brandData.keyFeatures];
    const allKeys = value.split(",");
    allKeys.forEach((key) => {
      if (key !== "") newKeyFeatures.push(key);
    });
    setBrandData({ ...brandData, keyFeatures: newKeyFeatures });
  };

  const bannerUploadHandler = () => {
    bannerRef.current.click();
  };

  const profileUploadHandler = () => {
    profileRef.current.click();
  };

  const handleSubmit = async () => {
    let errors = 0;
    if (brandData.businessName === "") {
      setErrorMessages((prev) => ({
        ...prev,
        businessName: "Business Name is required",
      }));
      errors++;
    }
    if (brandData.address === "") {
      setErrorMessages((prev) => ({
        ...prev,
        address: "Location is required",
      }));
      errors++;
    }

    if (brandData.license === "") {
      setErrorMessages((prev) => ({
        ...prev,
        license: "License is required",
      }));
      errors++;
    }
    if (!editState && !profileImage) {
      setErrorMessages((prev) => ({
        ...prev,
        profileImage: "Profile Image is required",
      }));
      errors++;
    }
    if (errors > 0)
      return window.scrollTo({
        top: "100",
        behavior: "smooth",
      });

    const formData = new FormData();
    if (!editState)
      for (let i = 0; i < fileList.length; i++) {
        formData.append("images", fileList[i].file);
      }
    if (bannerImage) formData.append("Banner Image", bannerImage);
    if (profileImage) formData.append("Brand Logo", profileImage);
    formData.append("Brand Name", brandData.businessName);
    formData.append("State License", brandData.license);
    formData.append("Brand Location", brandData.address);
    formData.append("email", brandData.businessEmail);
    formData.append("phoneNumber", brandData.phoneNo);
    formData.append("Website", brandData.website);
    formData.append("FB Link", brandData.facebook);
    formData.append("IG Link", brandData.instagram);
    formData.append("Twitter Link", brandData.twitter);
    formData.append("About us", brandData.aboutUs);
    // if (brandData.keyFeatures.length === 0)
    //   formData.append("keyFeatures[]", []);
    if (brandData.keyFeatures.length === 1) formData.append("keyFeatures[]", brandData.keyFeatures[0]);
    else if (brandData.keyFeatures.length > 1)
      for (let i = 0; i < brandData.keyFeatures.length; i++) formData.append("keyFeatures", brandData.keyFeatures[i]);

    setIsLoading("save");

    try {
      const response = await restClient({
        method: editState ? "PATCH" : "POST",
        url: editState ? `/user/brands/${id}` : `/user/brands`,
        data: formData,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      console.log(response);
      navigate("/brand-management");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(null);
    }
  };

  const fetchLicense = async () => {
    setIsLoading(true);
    try {
      const response = await restClient({
        method: "GET",
        url: `/user/license`,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      setLicenseData(response?.data?.data?.license);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageEditUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("images", file);

      const response = await restClient({
        method: "POST",
        url: `/user/add-images/brands/${id}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      toast.success("Image uploaded Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });

      return response;
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log(error);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setOpenCropImages(true);
        setImageSelectedUrl(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const removeImageHandler = async () => {
    if (editState) {
      try {
        const url = fileList.find((item) => item.src === imageHovered).src;
        console.log(url, "url");

        const response = await restClient({
          method: "POST",
          url: `/user/delete-image/brands/${id}`,
          data: { url },
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        });
        console.log(response);
        setFileList((prev) => prev.filter((item) => item.src !== url));
        toast.success("Image deleted Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } catch (error) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log(error);
      }
    } else setFileList((prev) => prev.filter((item) => item.src !== imageHovered));
  };

  useEffect(() => {
    fetchLicense();
  }, []);

  const fetchBrandData = async (id) => {
    setIsLoading(true);
    try {
      const response = await restClient({
        method: "GET",
        url: `/user/brands/${id}`,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const newData = response?.data?.data?.brand;

      const newBrandData = {
        businessName: newData["Brand Name"] ? newData["Brand Name"] : "",
        businessEmail: newData["email"] ? newData["email"] : "",
        phoneNo: newData["phoneNumber"] ? newData["phoneNumber"] : "",
        website: newData["Website"] ? newData["Website"] : "",
        address: newData["Brand Location"] ? newData["Brand Location"] : "",
        facebook: newData["FB Link"] ? newData["FB Link"] : "",
        instagram: newData["IG Link"] ? newData["IG Link"] : "",
        twitter: newData["Twitter Link"] ? newData["Twitter Link"] : "",
        aboutUs: newData["About us"] ? newData["About us"] : "",
        license: newData["State License"] ? newData["State License"] : "",
        keyFeatures: newData["keyFeatures"] ? newData["keyFeatures"] : [],
      };

      setBrandData(newBrandData);
      setBannerPreview(newData["Banner Image"]);
      setProfilePreview(newData["Brand Logo"]);

      const newFileList = response?.data?.data?.brand.images.map((item) => ({
        src: item,
      }));
      setFileList(newFileList);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (editState === true) {
      fetchBrandData(id);
    }
  }, [id]);

  useEffect(() => {
    if (!cropImageURL || !imagesFile) return;

    if (editState) {
      handleImageEditUpload(imagesFile).then((response) => {
        if (response.status === 200) {
          const newFileList = response.data.data.document.images.map((item) => ({
            src: item,
          }));
          setFileList(newFileList);
        }
      });
    } else setFileList((prev) => [...prev, { file: imagesFile, src: cropImageURL }]);
  }, [cropImageURL, imagesFile]);

  return (
    <>
      <ToastContainer />
      {openCropProfile && (
        <CropEasy
          {...{
            photoURL: imageSelectedUrl,
            setPhotoURL: setProfilePreview,
            openCrop: openCropProfile,
            setOpenCrop: setOpenCropProfile,
            setFile: setProfileImage,
          }}
        />
      )}
      {openCropBanner && (
        <CropEasy
          {...{
            photoURL: imageSelectedUrl,
            setPhotoURL: setBannerPreview,
            openCrop: openCropBanner,
            setOpenCrop: setOpenCropBanner,
            setFile: setBannerImage,
            aspect: 16 / 5,
          }}
        />
      )}
      {openCropImages && (
        <CropEasy
          {...{
            photoURL: imageSelectedUrl,
            setPhotoURL: setCropImageURL,
            openCrop: openCropImages,
            setOpenCrop: setOpenCropImages,
            setFile: setImagesFile,
          }}
        />
      )}
      <Box
        sx={{
          height: { md: "100vh" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          overflow: "hidden",
          background: theme.palette.primary.dashboardBack,
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "inline-flex" },
            height: "100%",
            width: { xs: "75%", sm: "40%", md: "30%", lg: "20%" },
          }}
        >
          <SideBarLayout />
        </Box>
        <Box
          sx={{
            display: { md: "none" },
          }}
        >
          {open && <SideBarLayout setOpen={setOpen} />}
          <Grid
            item
            container
            sx={{
              padding: { xs: "1.5rem", sm: "1.5rem 2rem", lg: "0 10rem" },
              background: `url(${heroSubTabBannerGreen})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              display: { md: "none" },
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: { md: "none" },
                justifyContent: "space-between",
                alignItems: "center",
              }}
              container
            >
              <Link to={"/"}>
                <LogoWhite />
              </Link>
              <Box
                onClick={() => {
                  setOpen(true);
                  if (typeof window != "undefined" && window.document) {
                    document.body.style.overflow = "hidden";
                  }
                }}
              >
                <MenuIcon sx={{ color: "white", width: "25px", height: "25px" }} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            // padding: { xs: "30px 0px", md: "30px", lg: "80px 50px" },
            flexGrow: 1,
            width: { md: "70%", lg: "80%" },
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              width: "100%",
              position: "relative",
              maxHeight: { xs: "320px", md: "480px" },
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              src={bannerPreview ? bannerPreview : banner}
              alt="banner"
            />
            <input
              name="profile"
              id="file"
              ref={bannerRef}
              type="file"
              hidden
              onChange={(e) => {
                setImageSelectedUrl(URL.createObjectURL(e.target.files[0]));
                setOpenCropBanner(true);
              }}
            />
            <Box
              sx={{
                backgroundColor: "#000000",
                opacity: "0.7",
                position: "absolute",
                height: { xs: "25px", md: "30px" },
                width: { xs: "25px", md: "150px" },
                // padding: { xs: "5px", sm: "8px" },
                paddingTop: { xs: "4px", md: "8px" },
                paddingBottom: { xs: "4px", md: "8px" },
                paddingLeft: { xs: "4px", md: "10px" },
                paddingRight: { xs: "4px", md: "10px" },
                zIndex: 1,
                bottom: "20px",
                borderRadius: { xs: "10%", md: "3px" },
                right: "15px",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                // gap: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={bannerUploadHandler}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <CameraIcon />
                <Box
                  sx={{
                    fontSize: "12px",
                    display: { md: "block", xs: "none" },
                    color: "white",
                  }}
                >
                  Add Cover Photo
                </Box>
              </Box>
              <Box
                sx={{
                  fontSize: "10px",
                  display: { md: "block", xs: "none" },
                  color: "white",
                }}
              >
                (16:5)
              </Box>
            </Box>
            <Box
              sx={{
                border: "2px solid #E0E0E0",
                backgroundColor: "#D9D9D9",
                width: { sm: "150px", xs: "100px" },
                height: { sm: "150px", xs: "100px" },
                borderRadius: "50%",
                position: "absolute",
                zIndex: 1,
                bottom: "0",
                right: "50%",
                transform: "translate(50%,50%)",
                cursor: "pointer",
              }}
            >
              <img
                src={profilePreview ? profilePreview : BrandLogo}
                alt="profile"
                style={{
                  objectFit: "contain",
                  height: profilePreview ? "100%" : "60%",
                  width: profilePreview ? "100%" : "60%",
                  position: profilePreview ? "static" : "absolute",
                  bottom: "18%",
                  right: "20%",
                  borderRadius: "50%",
                }}
              />
              <span
                style={{
                  color: "red",
                  marginLeft: "2px",
                  position: "absolute",
                  top: "63%",
                  left: "98%",
                }}
              >
                *
              </span>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#F2F2F2",
                  borderRadius: "50%",
                  padding: "10px",
                  position: "absolute",
                  height: "20px",
                  width: "20px",
                  zIndex: 1,
                  bottom: "18%",
                  right: "12%",
                  transform: "translate(50%,50%)",
                  cursor: "pointer",
                }}
                onClick={profileUploadHandler}
              >
                <input
                  name="profile"
                  id="file"
                  ref={profileRef}
                  type="file"
                  hidden
                  onChange={(e) => {
                    setErrorMessages((prev) => ({
                      ...prev,
                      profileImage: "",
                    }));
                    setImageSelectedUrl(URL.createObjectURL(e.target.files[0]));
                    setOpenCropProfile(true);
                  }}
                />
                <CameraProfileIcon />
              </Box>
              {errorMessages.profileImage !== null && errorMessages.profileImage !== "" && (
                <Box
                  sx={{
                    color: "red",
                    fontSize: { xs: "8px", sm: "12px" },
                    fontWeight: "400",
                    marginTop: "5px",
                    position: "absolute",
                    top: "100%",
                    left: { xs: "10%", sm: "0%" },
                  }}
                >
                  {errorMessages.profileImage}
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              padding: { xs: "80px 30px", sm: "120px 40px", md: "120px 100px" },
            }}
          >
            <form>
              <Stack spacing={4}>
                <BoxWrapper heading={"Business Info"}>
                  <BusinessInfoContent
                    onChangeHandler={onChangeHandler}
                    licenseData={licenseData}
                    brandData={brandData}
                    error={errorMessages}
                    setLicenseValue={(value) => {
                      if (value !== "") {
                        setErrorMessages((prev) => ({
                          ...prev,
                          license: "",
                        }));
                      }
                      setBrandData({ ...brandData, license: value });
                    }}
                  />
                </BoxWrapper>

                <BoxWrapper heading={"Social accounts"}>
                  <SocialAccountContent onChangeHandler={onChangeHandler} brandData={brandData} />
                </BoxWrapper>

                <BoxWrapperV2 heading={"About us"}>
                  <AboutUsContent onChangeHandler={onChangeHandler} aboutUsData={brandData.aboutUs} />
                </BoxWrapperV2>
                <BoxWrapperV2 heading={"Key Features"}>
                  <KeyFeaturesContent
                    onChangeHandler={addKeyFeatureHandler}
                    keyFeaturesList={brandData.keyFeatures}
                    deleteKeyTagHandler={(index) => {
                      const newKeyFeatures = brandData.keyFeatures.filter((key, index2) => index !== index2);
                      setBrandData({
                        ...brandData,
                        keyFeatures: newKeyFeatures,
                      });
                    }}
                  />
                </BoxWrapperV2>

                <BoxWrapperV2 padding={{ xs: "16px", lg: "24px" }} heading={fileList.length > 0 ? "Visuals" : ""}>
                  {fileList.length > 0 && (
                    <p
                      style={{
                        margin: "0px",
                        marginBottom: "5px",
                        color: "#87929E",
                        fontSize: "0.8rem",
                      }}
                    >
                      10 pictures max
                    </p>
                  )}
                  {fileList.length === 0 && (
                    <AddImagesContent handleImageChange={handleImageChange}>
                      <Box
                        sx={{
                          backgroundColor: "#F9F9FC",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "5px",
                          padding: { xs: "20px", sm: "50px" },
                          gap: "2px",
                          cursor: "pointer",
                        }}
                      >
                        <UploadIcon />
                        <Box sx={{ fontSize: { xs: "0.6rem", sm: "1.2rem" } }}>
                          Drop image(s) here or click to <span style={{ color: "#F78B8B" }}>upload</span>
                        </Box>
                        <Box
                          sx={{
                            fontWeight: "300",
                            color: "#87929E",
                            fontSize: { xs: "0.6rem", sm: "1.2rem" },
                          }}
                        >
                          You can add up to files, 10 max
                        </Box>
                      </Box>
                    </AddImagesContent>
                  )}

                  {fileList.length > 0 && (
                    <Stack spacing={2}>
                      <Stack direction={"row"} spacing={1}>
                        {fileList.map((item, index) => {
                          return (
                            <ImageWrapper key={index + "key"}>
                              <Box
                                sx={{
                                  position: "relative",
                                  width: "120px",
                                  height: "120px",
                                }}
                              >
                                <img
                                  onMouseOver={(e) => setImageHovered(e.target.src)}
                                  onMouseOut={() => setImageHovered("")}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    opacity: imageHovered === item.src ? 0.6 : 1,
                                    objectFit: "fill",
                                  }}
                                  src={item.src}
                                  alt=""
                                />
                                {imageHovered === item.src && (
                                  <img
                                    src={deleteImage}
                                    onClick={removeImageHandler}
                                    onMouseOver={(e) => setImageHovered(item.src)}
                                    style={{
                                      position: "absolute",
                                      zIndex: 1,
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%,-50%)",
                                      cursor: "pointer",
                                    }}
                                    alt=""
                                  />
                                )}
                              </Box>
                            </ImageWrapper>
                          );
                        })}
                        {fileList.length <= 4 && (
                          <Box>
                            <AddImagesContent handleImageChange={handleImageChange}>
                              <Box width={{ xs: "60px", sm: "112px" }} height={{ xs: "60px", sm: "112px" }}>
                                <img height="100%" width="100%" onClick={handleImageChange} src={addSmallImage} alt="add_small_image" />
                              </Box>
                            </AddImagesContent>
                          </Box>
                        )}
                      </Stack>
                    </Stack>
                  )}
                </BoxWrapperV2>
                <Stack
                  direction={"row"}
                  sx={{
                    width: { xs: "100%", sm: "50%" },
                  }}
                >
                  <SecondaryButton
                    width={{ xs: "50%", sm: "30%" }}
                    onClick={() => {
                      navigate("/brand-management");
                    }}
                  >
                    Cancel
                  </SecondaryButton>
                  <Box sx={{ width: { xs: "50%", sm: "30%" } }}>
                    <CustomPrimaryButton
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      isLoading={isLoading === "save" ? true : false}
                      disabled={isLoading}
                    >
                      Save
                    </CustomPrimaryButton>
                  </Box>
                </Stack>
              </Stack>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
}
