import React, { useState } from "react";
import PrimaryHeading from "../../../Components/Custom/PrimaryHeading";
import { Box, Grid, Typography } from "@mui/material";
import theme from "../../../theme";
import { useDispatch, useSelector } from "react-redux";
import InputFieldLabel from "../../../Components/Custom/InnputFieldLabel";
import InputField from "../../../Components/Custom/InputField";
import InputFieldLocation from "../../../Components/Custom/InputFieldLocation";
import { useFormik } from "formik";
import { validateMetrcSchema, initialValues, validationCardSchema } from "./StoreFrontValidations/storeFrontSettingValidations";
import { updateAsyncData } from "../../Auth/SignUpIntegration/userRegSlice";
import { ToastContainer, toast } from "react-toastify";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import AddIcon from "@mui/icons-material/Add";
import Modal from "../../../Components/Custom/Modal";
import SecondaryButton from "../../../Components/Custom/SecondaryButton";
import BasicTabs from "../../../Components/Custom/Tabs";
import { ReactComponent as TickIcon } from "../../../Assets/Icons/TickIcon.svg";
import { ReactComponent as CrossIcon } from "../../../Assets/Icons/cross.svg";
import { checkAsyncCard, userAsyncPayment } from "../../Auth/SignUpIntegration/subscripSlice";
import { ReactComponent as Completed } from "../../../Assets/Icons/Complete.svg";
import CustomPrimaryButton from "../../../Components/Custom/CustomPrimaryButton";
import AmericanExpress from "../../../Assets/Images/AmericanExpress.png";
import UnionPay from "../../../Assets/Images/UnionPay.png";
import MasterCard from "../../../Assets/Images/MasterCard.png";
import Visa from "../../../Assets/Images/Visa.png";
export default function MetricApiKeys() {
  const selectUserAuth = (state) => state.userRegReducer?.userAuth;
  const selectUserPayment = (state) => state.userPaymentReducer?.userSubs;

  const userData = useSelector(selectUserAuth);
  const userPaymentData = useSelector(selectUserPayment);

  const [openModal, setOpenModal] = useState("");

  const handleCloseModal = () => {};
  const handleOpenModal = (type) => {
    setOpenModal(type);
  };
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Box
        sx={{
          overflowY: "auto",
          mt: 3,
        }}
      >
        <ToastContainer />
        {openModal !== "" && (
          <Modal noPadding open={openModal} handleClose={handleCloseModal} fullWidth maxWidth="xs">
            {openModal === "form" && <AddMetrcKeyForm handleOpenModal={handleOpenModal} loading={loading} setLoading={setLoading} />}
            {openModal === "plan-detail" && <PlanInformation handleOpenModal={handleOpenModal} />}
            {openModal === "payment" && <CheckoutForm handleOpenModal={handleOpenModal} userPaymentData={userPaymentData} />}
            {openModal === "status" && <SubscriptionStatus handleOpenModal={handleOpenModal} />}
          </Modal>
        )}

        <Box
          sx={{
            overflowY: "auto",
            maxHeight: { xs: "50vh", md: "50vh" },
            marginVertical: { sm: 5 },
          }}
        >
          {userData?.user?.companyDetail?.map((companyData, index) => (
            <Box
              sx={{
                background: theme.palette.primary.dashboardBack,
                p: { xs: 2, sm: 3 },
                borderRadius: "4px",
                mt: { xs: 1, sm: 4 },
                overflowX: "auto",
              }}
              key={index}
            >
              <PrimaryHeading fontSize={"16px"} textTransform="capitalize">
                {companyData?.companyName}
              </PrimaryHeading>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: "space-between",
                  mt: 1,
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  License Name
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: { xs: "12px", sm: theme.typography.fontSize },
                  }}
                >
                  {companyData?.metrcApiKeyAuth}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", sm: "row" },
                  mt: 1,
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  Address
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: { xs: "12px", sm: theme.typography.fontSize },
                  }}
                >
                  {companyData?.facilityAddress}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", sm: "row" },
                  mt: 1,
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  License Number
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: { xs: "12px", sm: theme.typography.fontSize },
                  }}
                >
                  {companyData?.licenseNumber}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", sm: "row" },
                  mt: 1,
                }}
              >
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize,
                  }}
                >
                  Type
                </Typography>
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: { xs: "12px", sm: theme.typography.fontSize },
                  }}
                >
                  {companyData?.licenseType}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: { xs: "2%", md: "8%" },
          }}
        >
          <PrimaryButton icon={<AddIcon />} onClick={() => handleOpenModal("form")}>
            Add license
          </PrimaryButton>
        </Box>
      </Box>
    </>
  );
}

const AddMetrcKeyForm = ({ handleOpenModal, loading, setLoading }) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues,
    validationSchema: validateMetrcSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      dispatch(updateAsyncData({ data: values, authorized: true })).then((res) => {
        if (res?.payload?.success) {
          if (res?.payload?.data?.user?.payment !== "PAID") {
            handleOpenModal("plan-detail");
          } else {
            handleOpenModal("status");
          }
          toast.success(`License Added Successfully`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
          resetForm();
        } else {
          toast.error(`Metrc API key not found, Please check key and try again`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
          resetForm();
        }
      });
    },
  });

  return (
    <form>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "1.5rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            color: theme.palette.primary.inputLabelColor,
          }}
        >
          Add another license
        </Typography>
        <Box>
          <InputFieldLabel>Company name</InputFieldLabel>
          <InputField
            background="white"
            placeholder="Enter your Facility/Company name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.companyName}
            name={`companyName`}
          />
          {formik.touched?.companyName && formik.errors?.companyName && <div className="error">{formik.errors.companyName}</div>}
        </Box>
        <Box>
          <InputFieldLabel>Metric API key</InputFieldLabel>
          <InputField
            background="white"
            placeholder="Enter your Metric API key"
            name={`metrcAPIKey`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.metrcAPIKey}
          />
          {formik.touched?.metrcAPIKey && formik.errors?.metrcAPIKey && <div className="error">{formik.errors.metrcAPIKey}</div>}
        </Box>

        <Box>
          <InputFieldLabel>Facility address</InputFieldLabel>
          <InputFieldLocation
            apiKey={process.env.REACT_APP_GOOGLE}
            background="white"
            placeholder="Enter your Facility Address"
            name={`facilityAddress`}
            value={formik?.values?.facilityAddress}
            onPlaceSelected={(place) => {
              formik.setFieldValue(`facilityAddress`, place?.formatted_address);
            }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched?.facilityAddress && formik.errors?.facilityAddress && <div className="error">{formik.errors.facilityAddress}</div>}
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", marginTop: 3 }}>
        <SecondaryButton width="100%" onClick={() => handleOpenModal("")}>
          Cancel
        </SecondaryButton>
        <Box sx={{ width: "100%" }}>
          <CustomPrimaryButton onClick={formik.handleSubmit} isLoading={loading} disabled={loading}>
            Add
          </CustomPrimaryButton>
        </Box>
      </Box>
    </form>
  );
};

const PlanInformation = ({ handleOpenModal }) => {
  const tabs = [
    {
      label: "Monthly",
      content: <Monthly handleOpenModal={handleOpenModal} />,
    },
    // {
    //   label: "Annual",
    //   content: <Annual handleOpenModal={handleOpenModal} />,
    // },
  ];

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 600,
              color: theme.palette.primary.inputLabelColor,
            }}
          >
            Choose Plan
          </Typography>
          <Box sx={{ cursor: "pointer" }} onClick={() => handleOpenModal("")}>
            <CrossIcon />
          </Box>
        </Box>
        <BasicTabs tabs={tabs} width="150px" />
      </Box>
    </>
  );
};

const Monthly = ({ handleOpenModal }) => {
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.dashboardBack,
          padding: 3,
          display: "flex",
          flexDirection: "column",
          rowGap: "0.8rem",
          margin: "1.3rem 0",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 300,
            color: theme.palette.primary.black,
          }}
        >
          Get a 80% Sales Increase with WeedX
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <TickIcon height="20px" />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            Post Products or Brands for sale
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <TickIcon height="20px" />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            Unlimited posts and brands
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <TickIcon height="20px" />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            Messaging & File sharing
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <TickIcon height="20px" />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            View competition prices
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <TickIcon height="20px" />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            View industry credit of dispensaries
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <TickIcon height="20px" />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            No commission fees
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <TickIcon height="20px" />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            Sort & Filter by sellers & brands
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <TickIcon height="20px" />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            Full Profile Customization
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <TickIcon height="20px" />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            Robust Admin Tools
          </Typography>
        </Box>
      </Box>
      <PrimaryButton width="100%" fontSize="14px" fontWeight="500" onClick={() => handleOpenModal("payment")}>
        $198 per month
      </PrimaryButton>
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: 400,
          color: theme.palette.primary.inputTextColor,
          textAlign: "center",
          margin: "5px 0",
        }}
      >
        You can cancel anytime
      </Typography>
    </Box>
  );
};

const Annual = ({ handleOpenModal }) => {
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.dashboardBack,
          padding: 3,
          display: "flex",
          flexDirection: "column",
          rowGap: "0.8rem",
          margin: "1.3rem 0",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 300,
            color: theme.palette.primary.black,
          }}
        >
          Get a 80% Sales Increase with WeedX
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <TickIcon height="20px" />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            Post Products or Brands for sale
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <TickIcon height="20px" />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            Unlimited posts and brands
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <TickIcon height="20px" />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            Messaging & File sharing
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <TickIcon height="20px" />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            View competition prices
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <TickIcon height="20px" />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            View industry credit of dispensaries
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <TickIcon height="20px" />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            No commission fees
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <TickIcon height="20px" />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            Sort & Filter by sellers & brands
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <TickIcon height="20px" />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            Full Profile Customization
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
          }}
        >
          <TickIcon height="20px" />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            Robust Admin Tools
          </Typography>
        </Box>
      </Box>
      <PrimaryButton width="100%" fontSize="14px" fontWeight="500" onClick={() => handleOpenModal("payment")}>
        Buy for 1 year for $1988
      </PrimaryButton>
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: 400,
          color: theme.palette.primary.inputTextColor,
          textAlign: "center",
          margin: "5px 0",
        }}
      >
        You can cancel anytime
      </Typography>
    </Box>
  );
};

const CheckoutForm = ({ handleOpenModal, userPaymentData }) => {
  const dispatch = useDispatch();
  console.log(userPaymentData, "test2");
  const formatExpDate = (input) => {
    let expDateInput = input.replace(/\D/g, ""); // Remove non-numeric characters

    // Format the CVV to MM/YY
    if (expDateInput.length > 2) {
      expDateInput = `${expDateInput.slice(0, 2)}/${expDateInput.slice(2, 4)}`;
    }

    return expDateInput;
  };

  const handleExpDateChange = (event) => {
    const input = event.target.value;

    if (event.nativeEvent.inputType === "deleteContentBackward") {
      // Handle backspace key
      formik.setFieldValue("expDate", input);
      //field.onChange(event);
      return;
    }

    const formattedDate = formatExpDate(input);

    formik.setFieldValue("expDate", formattedDate);
    //field.onChange(event);
  };

  const makePaymentAPI = async () => {
    const cardData = {
      data: {
        cardNumber: formik.values.cardNumber,
        expDate: formik.values.expDate,
        nameOnCard: formik.values.nameOnCard,
        secureCode: formik.values.secureCode,
      },
    };
    dispatch(userAsyncPayment(cardData)).then((res) => {
      console.log(cardData, "payment data");
      console.log("then", res);
      if (res?.payload?.success) {
        toast.success(`${res?.payload?.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleOpenModal("status");
      } else {
        toast.error(`${res?.payload?.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      cardNumber: "",
      expDate: "",
      nameOnCard: "",
      secureCode: "",
    },
    validationSchema: validationCardSchema,
    onSubmit: (values) => {
      dispatch(checkAsyncCard({ data: values })).then((res) => {
        console.log("then", res);
        if (res.payload.success) {
          // toast.success(`${res.payload.message}`, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          makePaymentAPI(userPaymentData?.data);
        } else {
          toast.error(`${res.payload.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    },
  });

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            color: theme.palette.primary.inputLabelColor,
          }}
        >
          Checkout
        </Typography>
        <Box sx={{ cursor: "pointer" }} onClick={() => handleOpenModal("")}>
          <CrossIcon />
        </Box>
      </Box>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          rowGap: "20px",
        }}
      >
        <Grid item xs={12}>
          <img src="https://verify.authorize.net/anetseal/images/secure90x72.gif" alt="authorize.net logo" />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sx={{
            marginBottom: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={3} lg={2}>
            <img
              src={AmericanExpress}
              // src="https://www.authorize.net/_jcr_content/root/container/responsivegrid/contentwell_copy_cop/contentWell/columncontrol/par2/contentwell_67198405/contentWell/contentwell/contentWell/imagecontainer/images/contentwell_929033021/contentWell/image_copy_202153645.img.png/1700067762940.png"
              alt="american-express"
              width="70px"
            />
          </Grid>

          <Grid item xs={3} lg={2}>
            <img
              src={UnionPay}
              // src="https://www.authorize.net/_jcr_content/root/container/responsivegrid/contentwell_copy_cop/contentWell/columncontrol/par2/contentwell_67198405/contentWell/contentwell_copy/contentWell/imagecontainer/images/contentwell_copy/contentWell/image_copy.img.png/1700067746976.png"
              alt="union-pay"
              width="70px"
            />
          </Grid>

          <Grid item xs={3} lg={2}>
            <img
              src={Visa}
              // src="https://www.authorize.net/_jcr_content/root/container/responsivegrid/contentwell_copy_cop/contentWell/columncontrol/par2/contentwell_67198405/contentWell/contentwell_copy/contentWell/imagecontainer/images/contentwell_copy_1558768459/contentWell/image_1738459615_cop.img.png/1700087749780.png"
              alt="visa"
              width="70px"
            />
          </Grid>

          <Grid item xs={3} lg={2}>
            <img
              src={MasterCard}
              // src="https://www.authorize.net/_jcr_content/root/container/responsivegrid/contentwell_copy_cop/contentWell/columncontrol/par2/contentwell_67198405/contentWell/contentwell_copy/contentWell/imagecontainer/images/contentwell_copy_117/contentWell/image_copy_656778450.img.png/1700067764762.png"
              alt="mastercard"
              width="70px"
            />
          </Grid>
        </Grid>
      </Grid>
      <form>
        <div>
          <InputFieldLabel margin="1.5vw">Name on card</InputFieldLabel>
          <InputField
            background="white"
            placeholder="Sean Thomas"
            name="nameOnCard"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.nameOnCard}
          />
          {formik.touched.nameOnCard && formik.errors.nameOnCard && <div className="error">{formik.errors.nameOnCard}</div>}
        </div>
        <div>
          <InputFieldLabel margin="1.5vw">Card number</InputFieldLabel>
          <InputField
            background="white"
            placeholder="1421 5235 1251 1512"
            name="cardNumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cardNumber}
          />
          {formik.touched.cardNumber && formik.errors.cardNumber && <div className="error">{formik.errors.cardNumber}</div>}
        </div>
        <div>
          <InputFieldLabel margin="1.5vw">Expiration date</InputFieldLabel>
          <InputField
            background="white"
            placeholder="12/24"
            name="expDate"
            onChange={handleExpDateChange}
            onBlur={formik.handleBlur}
            value={formik.values.expDate}
          />
          {formik.touched.expDate && formik.errors.expDate && <div className="error">{formik.errors.expDate}</div>}
        </div>

        <div>
          <InputFieldLabel margin="1.5vw">CVV</InputFieldLabel>
          <InputField
            background="white"
            placeholder="* * *"
            name="secureCode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.secureCode}
          />
          {formik.touched.secureCode && formik.errors.secureCode && <div className="error">{formik.errors.secureCode}</div>}
        </div>
        <PrimaryButton width="100%" fontSize="14px" fontWeight="500" margin="1rem" onClick={formik.handleSubmit}>
          Continue
        </PrimaryButton>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            color: theme.palette.primary.inputTextColor,
            textAlign: "center",
            margin: "10px 0",
          }}
        >
          You can cancel anytime
        </Typography>
      </form>
    </Box>
  );
};

const SubscriptionStatus = ({ handleOpenModal }) => {
  return (
    <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
      <Box mb={2}>
        <Completed />
      </Box>
      <PrimaryHeading fontSize={"20px"}>License Added</PrimaryHeading>
      <Typography
        sx={{
          color: theme.palette.primary.mediumGrey,
          fontSize: theme.typography.fontSize,
          mt: 1,
          mb: 2,
        }}
      >
        Thank you! Your license has been added successfully.
      </Typography>
      <PrimaryButton onClick={() => handleOpenModal("")} width={"100%"}>
        Got It!
      </PrimaryButton>
    </Box>
  );
};
