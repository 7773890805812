import ActionPopup from "../../../Features/Admin/ProductManagement/components/ActionPopup";

const columns = [
  {
    id: "serialNumber",
    label: "",
  },

  {
    id: "Product Name",
    label: "name",
    sortable: true,
  },
  {
    id: "State License",
    label: "associated licence",
  },
  {
    id: "Brand Name",
    label: "brand",
    sortable: true,
  },
  {
    id: "mertcItemId",
    label: "product source",
  },

  {
    id: "views_count",
    label: "views",
    formatNumber: true,
    sortable: true,
  },
  {
    id: "Rate",
    label: "price",
    formatNumber: true,
    sortable: true,
  },
  {
    id: "Package Date",
    label: "post date",
    sortable: true,
  },
  {
    id: "Status",
    label: "status",
    status: true,
    sortable: true,
  },
  {
    id: "actions",
    align: "right",
    actions: true,
    popup: <ActionPopup />,
  },
];

export default columns;
