import * as React from "react";
const CameraIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M16.827 2.341H15.1l-.576-1.152C14.184.52 14.007.037 13.371.037H6.46c-.636 0-.846.55-1.152 1.152l-.576 1.152H3.003A2.304 2.304 0 0 0 .7 4.645v9.216a2.304 2.304 0 0 0 2.304 2.304h13.824a2.304 2.304 0 0 0 2.304-2.304V4.645a2.304 2.304 0 0 0-2.304-2.304Zm-6.912 11.52a4.608 4.608 0 1 1 0-9.216 4.608 4.608 0 0 1 0 9.216Zm0-8.064a3.457 3.457 0 1 0 .001 6.913 3.457 3.457 0 0 0 0-6.913Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CameraIcon;
