import { useState } from "react";
import { Box, CircularProgress, Stack } from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ReplyIcon from "@mui/icons-material/Reply";
import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import { useSelector } from "react-redux";

import StarRating from "../../components/StarRating";
import { formatDateDifference } from "../../helpers/dateFormatter";
import restClient from "../../../../../../util/restClient";

function UserReviewCont({ review, getReviews, productOwnerID }) {
  const userData = useSelector((state) => state.userRegReducer?.userAuth);
  const [onReply, setOnReply] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isLoaderReply, setIsLoaderReply] = useState(false);

  const handleToggleHelpful = async () => {
    try {
      setIsLoader(true);
      const response = await restClient({
        method: "POST",
        url: `/user/review/dispensaries/helpful/${review._id}`,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      if (response.status === 200) {
        getReviews();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoader(false);
    }
  };

  const handleReply = async () => {
    try {
      setIsLoaderReply(true);
      const response = await restClient({
        method: "POST",
        url: `/user/review/dispensaries/reply/${review._id}`,
        data: {
          answer: replyText,
        },
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      if (response.status === 200) {
        getReviews();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoaderReply(false);
    }
  };

  return (
    <Box
      sx={{
        padding: {
          xs: "0 16px 24px 16px",
          lg: "0 32px 24px 32px",
        },
        borderBottom: "1px solid #0000000D",
      }}
    >
      <Stack
        spacing={1}
        sx={
          onReply
            ? {
                borderRadius: "8px",
                borderLeft: "4px solid #1B8A79",
                padding: "12px 20px",
                bgcolor: "#F8F8F8",
                transition: "all 0.3s ease",
              }
            : {
                transition: "all 0.3s ease",
              }
        }
      >
        <Stack>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <span
              style={{
                fontWeight: "500",
                color: onReply ? "#1B8A79" : "#2E3A48",
                fontSize: "14px",
              }}
            >
              {review.userInfo.fullname}
            </span>
            <span
              style={{ fontWeight: "400", color: "#596570", fontSize: "12px" }}
            >
              {formatDateDifference(review.createdAt)}
            </span>
          </Stack>
          <StarRating
            value={review.rating}
            size={"small"}
            name={"read-only"}
            behavior={"readOnly"}
          />
        </Stack>
        <span style={{ color: "#596570", fontWeight: "300" }}>
          {review.description}
        </span>
        {review.images.length > 0 && (
          <Stack sx={{ padding: "8px 0" }} direction={"row"} spacing={1}>
            {review.images.map((item, index) => {
              return (
                <Box
                  width={{ xs: "60px", sm: "112px" }}
                  height={{ xs: "60px", sm: "112px" }}
                  sx={{
                    overflow: "hidden",
                    position: "relative",
                    borderRadius: "4px",
                  }}
                  key={index + "key"}
                >
                  <img style={{ width: "100%" }} src={item} alt="" />
                </Box>
              );
            })}
          </Stack>
        )}
        <Box>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              spacing={1}
              sx={{ cursor: "pointer" }}
            >
              {!review.helpfulFlag ? (
                <ThumbUpAltOutlinedIcon
                  sx={{
                    color: "#212B36",
                    fontSize: "16px",
                  }}
                  onClick={handleToggleHelpful}
                />
              ) : (
                <ThumbUpAltIcon
                  sx={{
                    color: "#212B36",
                    fontSize: "16px",
                  }}
                  onClick={handleToggleHelpful}
                />
              )}
              <span style={{ fontWeight: "500", fontSize: "12px" }}>
                Helpful ({review.helpfulCount})
              </span>
              {isLoader && <CircularProgress size={16} />}
            </Stack>
            {!review.reply[0]?.answer &&
              userData?.user?._id === productOwnerID && (
                <Stack
                  sx={{ cursor: "pointer" }}
                  onClick={() => setOnReply((prev) => !prev)}
                  direction={"row"}
                  spacing={1}
                  alignItems={"center"}
                >
                  {onReply ? (
                    <CancelIcon
                      sx={{
                        color: "#949494",
                        fontSize: "16px",
                      }}
                    />
                  ) : (
                    <ReplyIcon
                      sx={{
                        color: "#949494",
                        fontSize: "16px",
                      }}
                    />
                  )}
                  <span style={{ fontWeight: "500", fontSize: "12px" }}>
                    {onReply ? "Cancel Reply" : "Reply"}
                  </span>
                </Stack>
              )}
          </Stack>
          {review.reply[0]?.answer && (
            <Box
              sx={{
                borderRadius: "8px",
                borderLeft: "4px solid #1B8A79",
                padding: "12px 16px",
                bgcolor: "#F8F8F8",
                marginTop: "12px",
              }}
            >
              <Stack spacing={1}>
                <span
                  style={{
                    fontWeight: "400",
                    color: "#596570",
                    fontSize: "12px",
                  }}
                >
                  {formatDateDifference(review.reply[0]?.createdAt)}
                </span>
                <span style={{ color: "#596570", fontWeight: "300" }}>
                  {review.reply[0]?.answer}
                </span>
              </Stack>
            </Box>
          )}
        </Box>
      </Stack>
      {onReply && (
        <Stack
          direction={"row"}
          sx={{ padding: "24px 0 0 0" }}
          spacing={2}
          alignItems={"center"}
        >
          <input
            style={{
              flexGrow: "1",
              border: "none",
              outline: "none",
              fontWeight: "300",
              color: "#212B36",
            }}
            placeholder="Type your reply here"
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
            type="text"
          />
          {isLoaderReply && <CircularProgress size={16} />}
          {replyText.length > 0 && (
            <SendIcon
              onClick={handleReply}
              sx={{ cursor: "pointer", color: "#1B8A79" }}
            />
          )}
        </Stack>
      )}
    </Box>
  );
}

export default UserReviewCont;
