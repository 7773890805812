import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../../theme";
import InputFieldLabel from "../../../Components/Custom/InnputFieldLabel";
import InputPassword from "../../../Components/Custom/InputPassword";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import {
  validationSchemaCP,
  initialValuesCP,
} from "./StoreFrontValidations/privacyAndPasswordValidation";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import Modal from "../../../Components/Custom/Modal";
import CancelSubscription from "./CancelSubscription/CancelSubscription";
import restClient from "../../../util/restClient";

export default function PrivacyAndPassword() {
  const selectUserAuth = (state) => state.userRegReducer?.userAuth;
  const userData = useSelector(selectUserAuth);

  const [cancelSubModalOpen, setCancelSubModalOpen] = useState(false);
  const [cancelSub, setCancelSub] = useState("agree");

  const handleCancelSubOpenModal = () => {
    setCancelSubModalOpen(true);
    setCancelSub("agree");
  };

  const handleCancelSubCloseModal = () => {
    setCancelSubModalOpen(false);
  };

  const formik = useFormik({
    initialValues: initialValuesCP,
    validationSchema: validationSchemaCP,
    onSubmit: async (values, { resetForm }) => {
      try {
        // const response = await axios.post(
        //   `https://staging.weedxhub.com/api/user/change-password`,
        //   { password: values.oldPassword, newPassword: values.password },
        //   { headers: { Authorization: `Bearer ${userData?.token}` } }
        // );
        const response = await restClient({
          method: "POST",
          url: "/user/change-password",
          data: {
            password: values.oldPassword,
            newPassword: values.password,
          },
          headers: { Authorization: `Bearer ${userData?.token}` },
        });
        console.log(response, "response");
        if (response.data.success) {
          toast.success(`${response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(`${response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (err) {
        toast.error(`${err.response.data.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      resetForm();
    },
  });

  console.log(userData?.token);

  const deleteAccountAPI = async (password) => {
    console.log(password);
    try {
      // const response = await axios.post(
      //   `https://staging.weedxhub.com/api/user/delete-account`,
      //   { password: password },
      //   { headers: { Authorization: `Bearer ${userData?.token}` } }
      // );
      const response = await restClient({
        method: "POST",
        url: "/user/delete-account",
        data: {
          password: password,
        },
        headers: { Authorization: `Bearer ${userData?.token}` },
      });
      console.log(response, "delete account api");
      return response;
    } catch (err) {
      console.log(err.response);
      return err.response;
    }
  };

  return (
    <>
      {/* <Box
        display="flex"
        alignItems="center"
        sx={{
          background: theme.palette.primary.dashboardBack,
          px: 3,
          py: 0.5,
          borderRadius: "4px",
          mt: 4,
        }}
      >
        <CustomCheckbox background="transparent" />
        <Typography
          sx={{
            color: theme.palette.primary.mediumGrey,
            fontSize: theme.typography.fontSize,
          }}
        >
          Hide Email and phone number from public profile
        </Typography>
      </Box> */}
      <Box
        sx={{
          my: 3,
          height: { md: "60vh" },
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Typography
          sx={{
            fontSize: { md: "20px", lg: "24px" },
            fontWeight: 400,
            color: theme.palette.primary.inputLabelColor,
          }}
        >
          Change Password
        </Typography>
        <ToastContainer />
        <form>
          <Box
            sx={{
              mt: 1,
              display: "flex",
              flexDirection: "column",
              rowGap: "1rem",
            }}
          >
            <div>
              <InputFieldLabel>Password</InputFieldLabel>
              <InputPassword
                placeholder="Enter your password"
                name="oldPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.oldPassword}
                error={formik.errors.oldPassword ? true : false}
                background="white"
              />
            </div>

            <div>
              <InputFieldLabel>New Password</InputFieldLabel>
              <InputPassword
                placeholder="Enter your new password"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={formik.errors.password ? true : false}
                background="white"
              />
            </div>
            <div>
              <InputFieldLabel>Confirm Password</InputFieldLabel>
              <InputPassword
                placeholder="Enter your confirm password"
                name="confirmPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                error={formik.errors.confirmPassword ? true : false}
                background="white"
              />
            </div>
          </Box>
          <PrimaryButton margin={3} onClick={formik.handleSubmit}>
            Save Changes
          </PrimaryButton>
        </form>
        {userData?.user?.role !== "SELLER" && (
          <Box mt={3}>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: theme.palette.primary.inputLabelColor,
              }}
            >
              Delete Account
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 300,
                color: theme.palette.primary.inputLabelColor,
              }}
              my={1}
            >
              The account will no longer be available, and all data in the
              account will be permanently deleted
            </Typography>
            <PrimaryButton
              fontSize="14px"
              fontWeight={500}
              onClick={handleCancelSubOpenModal}
            >
              Delete Account
            </PrimaryButton>
            <Modal
              open={cancelSubModalOpen}
              handleClose={handleCancelSubCloseModal}
              headerText={
                cancelSub === "agree" ? (
                  <>Are you sure you want to delete your account?</>
                ) : cancelSub === "confirmSub" ? (
                  <>Please enter your password</>
                ) : null
              }
              fullWidth
              maxWidth="xs"
            >
              <CancelSubscription
                cancelSub={cancelSub}
                setCancelSub={setCancelSub}
                subHeading="Are you sure your want to permanently delete your account? This cannot be undone."
                tertiaryHeading="Enter your password to Delete your Account"
                statusText={{
                  heading: "Account Deleted",
                  description: "Your account has been deleted",
                }}
                handleDeleteAccount={deleteAccountAPI}
                setOpen={handleCancelSubCloseModal}
              />
            </Modal>
          </Box>
        )}
      </Box>
    </>
  );
}
