import { Box, Stack } from "@mui/material";
import React from "react";

function InputWrapper({ label, children, required }) {
  return (
    <Stack spacing={1}>
      <span
        style={{
          color: "#212B36",
          fontSize: "12px",
          fontWeight: "500",
          margin: "0",
        }}
      >
        {label}
        {required && (
          <span
            style={{
              color: "red",
              marginLeft: "2px",
            }}
          >
            *
          </span>
        )}
      </span>
      <Box>{children}</Box>
    </Stack>
  );
}

export default InputWrapper;
