import MenuBar from "../../../Components/Custom/MenuBar";
import MainLogo from "../../../Assets/Images/smallFooterLogo.png";
import { Grid, Link, Typography } from "@mui/material";
import styled from "styled-components";
import theme from "../../../theme";
//import { ReactComponent as Twitter } from "../../../Assets/Icons/TwitterWhite.svg";
//import { ReactComponent as Instagram } from "../../../Assets/Icons/InstaWhite.svg";
import FaceBookSvG from "../../../Assets/Icons/Facebook";
//import GithubSVG from "../../../Assets/Icons/Github";

const CustomLink = styled(Link)(() => ({
  textDecoration: "none !important",
  cursor: "pointer",
}));

export default function SmallFooter() {
  return (
    <>
      <Grid
        container
        sx={{
          padding: { xs: "1rem", lg: " 1rem 3rem" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          rowGap: { xs: "20px", sm: "0px" },
          background: theme.palette.primary.main,
        }}
      >
        <Grid item xs={7} sm={1.5} lg={2}>
          <img src={`${MainLogo}`} alt="" style={{ width: "100%" }} />
        </Grid>
        <Grid item xs={12} sm={8.5} lg={8}>
          <MenuBar
            marginTop={"0"}
            borderTop={"0"}
            padding={"0"}
            textColor="white"
            backgroundColor="#1B8A79"
            isVisible={true}
          />
        </Grid>
        <Grid item xs={12} sm={2} lg={2}>
          <ul
            style={{
              listStyle: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: 0,
            }}
          >
            {/* <li className="socialMedia">
              <CustomLink>
                <Twitter
                  sx={{
                    fill: "red",
                  }}
                />
              </CustomLink>
            </li> */}
            <li
              className="socialMedia"
              onClick={() =>
                window.open(
                  "https://www.facebook.com/profile.php?id=100091293528796&mibextid=LQQJ4d",
                  "_blank"
                )
              }
            >
              <CustomLink>
                <FaceBookSvG fillColor={"white"} width={"20"} height={"20"} />
              </CustomLink>
            </li>
            {/* <li className="socialMedia">
              <CustomLink>
                <Instagram />
              </CustomLink>
            </li>
            <li className="socialMedia">
              <CustomLink>
                <GithubSVG fillColor={"white"} width={"20"} height={"20"} />
              </CustomLink>
            </li> */}
          </ul>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            background: theme.palette.primary.footerBg,
            display: "flex",
            justifyContent: "center",
            py: 3,
          }}
        >
          <Typography
            sx={{
              color: theme.palette.primary.white,
              fontSize: { xs: "12px", sm: theme.typography.fontSize },
              fontWeight: "300",
            }}
          >
            © Copyright 2024, All Rights Reserved by WeedX
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
