import { Box, Stack } from "@mui/material";

function InputWrapper({ label, children, errors, keyValue }) {
  return (
    <Stack>
      <span
        style={{
          color: "#212B36",
          fontSize: "12px",
          fontWeight: "500",
          marginBottom: "8px",
        }}
      >
        {label}
      </span>
      <Box>{children}</Box>
      <Box
        sx={{
          fontSize: "12px",
          marginTop: "2px",
          color: "#FF0000",
        }}
        component={"span"}
      >
        {errors?.[keyValue]}
      </Box>
    </Stack>
  );
}

export default InputWrapper;
