import { useState } from "react";
import { Box, Stack } from "@mui/material";

import sendIcon from "../../../../../Assets/Icons/messageBox/sendMessage.svg";
import messageIcon from "../../../../../Assets/Icons/messageBox/message.svg";
import attachIcon from "../../../../../Assets/Icons/messageBox/fileAttach.svg";

function MessageBox({ onClickSendMessage }) {
  const [message, setMessage] = useState("");

  return (
    <Box sx={{ marginTop: "auto", padding: "20px" }}>
      <Stack
        direction={"row"}
        sx={{
          backgroundColor: "#fafafa",
          borderRadius: "4px",
          alignItems: "center",
          height: "40px",
          border: "1px solid #E0E0E0",
        }}
      >
        <input
          style={{
            width: "100%",
            border: "none",
            outline: "none",
            backgroundColor: "#fafafa",
            color: "#2E3A48",
            padding: "0 20px",
            fontFamily: "inherit",
          }}
          placeholder={"Type your message"}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (message !== "") onClickSendMessage(message);
              setMessage("");
            }
          }}
        />
        <Box
          sx={{
            width: "2px",
            height: "24px",
            backgroundColor: "#E0E0E0",
          }}
        />
        <Stack direction={"row"} spacing={2} sx={{ padding: "0 20px" }}>
          <img src={messageIcon} alt="" />
          <img src={attachIcon} alt="" />
        </Stack>
        <Stack
          onClick={() => {
            if (message !== "") onClickSendMessage(message);
            setMessage("");
          }}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            bgcolor: "#1B8A79",
            height: "100%",
            borderRadius: "0 4px 4px 0",
            cursor: "pointer",
            width: "60px",
          }}
        >
          <img src={sendIcon} alt="" />
        </Stack>
      </Stack>
    </Box>
  );
}

export default MessageBox;
