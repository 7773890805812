import { Box } from "@mui/material";
import React from "react";
import InputFieldLabel from "../../../Components/Custom/InnputFieldLabel";
import InputField from "../../../Components/Custom/InputField";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import {
  validateMetrcSchema,
  initialValues,
} from "./StoreFrontValidations/UserInfoValidation";
import { useDispatch, useSelector } from "react-redux";
import { updateAsyncData } from "../../Auth/SignUpIntegration/userRegSlice";
import InputFieldLocation from "../../../Components/Custom/InputFieldLocation";
export default function UserInfo() {
  const dispatch = useDispatch();
  const selectUserAuth = (state) => state.userRegReducer?.userAuth;
  const userData = useSelector(selectUserAuth);
  console.log(userData, "userData");

  const formik = useFormik({
    initialValues,
    validationSchema: validateMetrcSchema,
    onSubmit: (values, { setValues, setTouched, setErrors }) => {
      console.log("values", values);
    },
  });

  // const formatPhoneNumber = (input) => {
  //   const numericInput = input.replace(/\D/g, "");
  //   if (!numericInput || isNaN(numericInput)) {
  //     return "";
  //   }
  //   return `(${numericInput.slice(0, 3)}) ${numericInput.slice(
  //     3,
  //     6
  //   )}-${numericInput.slice(6, 10)}`;
  // };

  // const handlePhoneChange = (event) => {
  //   const input = event.target.value;

  //   if (event.nativeEvent.inputType === "deleteContentBackward") {
  //     // Handle backspace key
  //     formik.setFieldValue("phone", input);
  //     //field.onChange(event);
  //     return;
  //   }

  //   const formattedNumber = formatPhoneNumber(input);

  //   formik.setFieldValue("phone", formattedNumber);
  //   //field.onChange(event);
  // };

  return (
    <>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <Box
          mt={3}
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
            rowGap: "1rem",
            height: { md: "60vh" },
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div>
            <InputFieldLabel>Full name</InputFieldLabel>
            <InputField
              background="white"
              placeholder="Melanie Fielder"
              value={userData?.user?.fullname}
              name="fullName"
              error={formik.errors?.firstName ? true : false}
              disabled
            />
            {formik.touched?.firstName && formik.errors?.firstName && (
              <div className="error">{formik.errors.firstName}</div>
            )}
          </div>

          <div>
            <InputFieldLabel>Email</InputFieldLabel>
            <InputField
              background="white"
              placeholder="melaniefielder@example.com"
              value={userData?.user?.email}
              name="email"
              error={formik.errors?.email ? true : false}
              disabled
            />
            {formik.touched?.email && formik.errors?.email && (
              <div className="error">{formik.errors.email}</div>
            )}
          </div>

          <div>
            <InputFieldLabel>Phone</InputFieldLabel>
            <InputField
              background="white"
              placeholder="229-407-3969"
              name="phone"
              type="text"
              value={userData?.user?.phone}
              error={formik.errors.phone ? true : false}
              disabled
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className="error">{formik.errors.phone}</div>
            )}
          </div>

          <div>
            <InputFieldLabel>Facility/Company name</InputFieldLabel>
            <InputField
              background="white"
              placeholder="Puff Canna"
              name="companyName"
              value={userData?.user?.companyDetail[0]?.companyName}
              error={formik.errors?.companyName ? true : false}
              disabled
            />
            {formik.touched?.companyName && formik.errors?.companyName && (
              <div className="error">{formik.errors?.companyName}</div>
            )}
          </div>

          <div>
            <InputFieldLabel>Facility address</InputFieldLabel>
            <InputFieldLocation
              apiKey={process.env.REACT_APP_GOOGLE}
              background="white"
              placeholder="225 s Wilson dr Detroit MI, 49333"
              name="facilityAddress"
              onPlaceSelected={(place) => {
                formik.setFieldValue(
                  `facilityAddress`,
                  place?.formatted_address
                );
                console.log("place", place);
              }}
              error={formik.errors?.facilityAddress ? true : false}
              value={userData?.user?.companyDetail[0]?.facilityAddress}
              disabled
            />
            {formik.touched?.facilityAddress &&
              formik.errors?.facilityAddress && (
                <div className="error">{formik.errors?.facilityAddress}</div>
              )}
          </div>
        </Box>
      </form>
    </>
  );
}
