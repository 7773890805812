import { Rating } from "@mui/material";

function StarRating({ size, name, behavior, value, setValue }) {
  return (
    <Rating
      name={name}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      size={size}
      readOnly={behavior === "readOnly"}
    />
  );
}

export default StarRating;
