import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  cardNumber: Yup.string()
    .required("Card Number is required")
    .matches(/^\d{16}$/, "Card Number must be 16 digits"),
  expDate: Yup.string()
    .matches(
      /^(0[1-9]|1[0-2])\/\d{2}$/,
      "Invalid expiration date format (MM/YY)"
    )
    .required("Expiration Date is required"),
  nameOnCard: Yup.string().required("Name on Card is required"),
  secureCode: Yup.string()
    .matches(/^\d{3}$/, "Invalid security code format (3 digits)")
    .required("Security Code is required"),
});

export const initialValues = {
  cardNumber: "",
  expDate: "",
  nameOnCard: "",
  secureCode: "",
};
