const apiRoutes = {
  // USER Auth Routes
  // Signup ==================
  createUser: "api/user/signup",
  verifyOtp: "api/user/verifyOTP",
  resendOtp: "api/user/resendOTP",
  userCompanyDetails: "api/user/company-details",
  userAdditionDetail: "api/user/authWithMetrc",

  // Login ===================
  userLogin: "api/user/login",
  userUpdate: "api/user/update-user-info",

  // ForgetPassword Flow
  forgetPasswordReq: "api/user/forget-password",
  changeForgetPassword: "api/user/change-forget-password",

  // Payment Subscription
  subscriptionRoute: "api/user/payment-subscription",

  // BRAND Route
  getPublicBrand: "api/user/get-public-brands",

  // FAQs
  getFAQs: "api/admin/get-Faq",
};

export default apiRoutes;
