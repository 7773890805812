import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery, Tab, Tabs, Stack, Box, Grid } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import restClient from "../../../util/restClient";
import About from "./About/About";
import Reviews from "./Reviews/Reviews";
import Header from "./Header/Header";
import SideBarLayout from "../../../SharedComponents/SideBarLayout";
import heroSubTabBannerGreen from "../../../Assets/Images/heroSubTabBannerGreen.png";
import { ReactComponent as LogoWhite } from "../../../Assets/Icons/LogoWhite.svg";

function ProductOverview() {
  const { productID } = useParams();
  const userData = useSelector((state) => state.userRegReducer?.userAuth);
  const tabVariant = useMediaQuery("(max-width: 450px)")
    ? "scrollable"
    : "fullWidth";
  const [tabSelected, setTabSelected] = useState("description");
  const [productData, setProductData] = useState({});
  const [reviewsData, setReviewsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const getProductData = async () => {
    try {
      setIsLoader(true);
      const response = await restClient({
        method: "GET",
        url: `/user/products/${productID}`,
      });

      setProductData(response?.data?.data?.product[0] || {});
      setReviewsData(response?.data?.data?.statistics[0] || {});
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoader(false);
    }
  };

  useEffect(() => {
    getProductData();
  }, []);

  return (
    <Stack direction={{ xs: "column", md: "row" }}>
      <Box
        sx={{
          display: { xs: "none", md: "inline-flex" },
          height: "100vh",
          width: { sm: "40%", md: "30%", lg: "20%" },
          position: "fixed",
        }}
      >
        <SideBarLayout />
      </Box>
      <Box sx={{ display: { md: "none" } }}>
        {open && <SideBarLayout setOpen={setOpen} />}
        <Grid
          item
          container
          sx={{
            padding: { xs: "1.5rem", sm: "1.5rem 2rem", lg: "0 10rem" },
            background: `url(${heroSubTabBannerGreen})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            display: { md: "none" },
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: { md: "none" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
            container
          >
            <Link to={"/"}>
              <LogoWhite />
            </Link>
            <Box
              sx={{ cursor: "pointer", display: { md: "none" } }}
              onClick={() => {
                setOpen(true);
                document.body.style.overflow = "hidden";
              }}
            >
              <MenuIcon
                sx={{ color: "white", width: "25px", height: "25px" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          marginLeft: userData?.authorized ? { md: "30%", lg: "20%" } : {},
        }}
      >
        <Header
          isLoader={isLoader}
          productData={productData}
          reviewsData={reviewsData}
          setProductData={setProductData}
        />
        <Stack
          spacing={3}
          sx={{
            padding: {
              xs: "16px",
              lg: "36px",
            },
          }}
        >
          <Tabs
            value={tabSelected}
            onChange={(event, newValue) => {
              setTabSelected(newValue);
            }}
            variant={tabVariant}
            scrollButtons="auto"
            textColor="inherit"
            sx={{
              borderRadius: "4px",
              bgcolor: "white",
              "& .MuiTabs-indicator": {
                backgroundColor: "#F78B8B",
              },
            }}
            aria-label="scrollable auto tabs "
          >
            <Tab value="description" label="Description" />
            <Tab value="reviews" label="Reviews" />
          </Tabs>
          {tabSelected === "description" && (
            <About isLoader={isLoader} productData={productData} />
          )}
          {tabSelected === "reviews" && (
            <Reviews productOwnerID={productData.userId} />
          )}
        </Stack>
      </Box>
    </Stack>
  );
}

export default ProductOverview;
