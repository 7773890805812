


import React from 'react';

const FaceBookSvG = (props) => {
  return (
    <svg width={(props.width) ? props.width : "24"} height={(props.height) ? props.width : "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.397 20.9972V12.8012H16.162L16.573 9.59217H13.397V7.54817C13.397 6.62217 13.655 5.98817 14.984 5.98817H16.668V3.12717C15.8486 3.03936 15.025 2.99696 14.201 3.00017C11.757 3.00017 10.079 4.49217 10.079 7.23117V9.58617H7.332V12.7952H10.085V20.9972H13.397Z" fill={props.fillColor ? props.fillColor : "#2E3A48"} />
    </svg>
  );
};

export default FaceBookSvG;