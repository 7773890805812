import { Grid } from "@mui/material";
import TopHeader from "../Header/TopHeader";
import MenuBar from "../../../Components/Custom/MenuBar";
import HowItWorks from "./HowItWorks";
import BuildSection from "./BuildSection";
import StatesSection from "./StatesSection";
import GrowerSection from "./GowerSection";
import CreateShopSection from "./CreateShopSection";
import Plans from "./Plans";
import FeatureBrands from "./FeaturedBrands";
import FrequentlyQuestionSection from "./FrequentlyQuestionSection";
import RealLifeSection from "./RealLifeSection";
import ProductsSection from "./ProductsSection";
import Footer from "../Footer/Footer";
//import MapAndListViewSection from "./MapAndListView";
import BannerSection from "./BannerSection";
import theme from "../../../theme";

function MainPage() {
  return (
    <>
      <Grid
        sx={{
          overflow: "hidden",
          backgroundColor: theme.palette.primary.dashboardBack,
        }}
      >
        <TopHeader />
        <MenuBar isVisible={false} />
        <BannerSection />
        <HowItWorks />
        <FeatureBrands />
        <BuildSection />
        <StatesSection />
        <GrowerSection />
        <CreateShopSection />
        <Plans />
        <FrequentlyQuestionSection />
        <RealLifeSection />
        {/* <MapAndListViewSection /> */}
        <ProductsSection />
        <Footer />
      </Grid>
    </>
  );
}

export default MainPage;
