import { Stack } from "@mui/material";
import SortPopup from "../../components/SortPopup";

function SortCont({ sortValue, setSortValue }) {
  const onClickHandler = (value) => {
    setSortValue(value.label);
  };

  return (
    <Stack
      sx={{
        padding: {
          xs: "16px",
          lg: "16px 32px",
        },
        borderBottom: "1px solid #0000000D",
      }}
      direction={"row"}
      spacing={1}
    >
      <span style={{ fontWeight: "500" }}>Sort by:</span>
      <span style={{ fontWeight: "300", color: "#596570" }}>{sortValue}</span>
      <SortPopup
        popupValues={[
          { label: "Latest" },
          { label: "Most Helpful" },
          { label: "Highest Stars" },
          { label: "Lowest Stars" },
        ]}
        onClickHandler={onClickHandler}
      />
    </Stack>
  );
}

export default SortCont;
