import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InputFieldLabel from "../../../Components/Custom/InnputFieldLabel";
import InputField from "../../../Components/Custom/InputField";
import BasicTabs from "../../../Components/Custom/Tabs";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as TickIcon } from "../../../Assets/Icons/TickIcon.svg";
import { ReactComponent as ArrowSignUp } from "../../../Assets/Icons/ArrowSignUp.svg";
import { validationSchema, initialValues } from "../SignUpIntegration/subscripValidation";
import { checkAsyncCard, userAsyncPayment } from "../SignUpIntegration/subscripSlice";
import theme from "../../../theme";
import { useNavigate } from "react-router-dom";
import Modal from "../../../Components/Custom/Modal";
import PrimaryHeading from "../../../Components/Custom/PrimaryHeading";
import { ReactComponent as Completed } from "../../../Assets/Icons/Complete.svg";
import HttpsIcon from "@mui/icons-material/Https";
import AmericanExpress from "../../../Assets/Images/AmericanExpress.png";
import UnionPay from "../../../Assets/Images/UnionPay.png";
import Visa from "../../../Assets/Images/Visa.png";
import MasterCard from "../../../Assets/Images/MasterCard.png";

function Monthly(props) {
  const formatExpDate = (input) => {
    let expDateInput = input.replace(/\D/g, ""); // Remove non-numeric characters

    // Format the CVV to MM/YY
    if (expDateInput.length > 2) {
      expDateInput = `${expDateInput.slice(0, 2)}/${expDateInput.slice(2, 4)}`;
    }

    return expDateInput;
  };

  const handleExpDateChange = (event) => {
    const input = event.target.value;

    if (event.nativeEvent.inputType === "deleteContentBackward") {
      // Handle backspace key
      props.formik.setFieldValue("expDate", input);
      //field.onChange(event);
      return;
    }

    const formattedDate = formatExpDate(input);

    props.formik.setFieldValue("expDate", formattedDate);
    //field.onChange(event);
  };

  return (
    <>
      <ToastContainer />

      <Box
        sx={{
          position: { sm: "absolute" },
          top: { sm: "0%" },
          bottom: { sm: "0%" },
          left: { sm: "0%" },
          width: { sm: "50vw" },
          height: { sm: "100%" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "2rem",
        }}
      >
        <Box
          sx={{
            background: theme.palette.primary.dashboardBack,
            borderRadius: "10px",
            padding: { xs: 3, md: 5 },
            boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
            marginTop: { xs: "30px", md: "50px", lg: "0px" },
            maxWidth: { xs: "fit-content", sm: "auto" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "32px", lg: "40px" },
              fontWeight: "600",
              color: theme.palette.primary.black,
            }}
          >
            Pro
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "20px", lg: "28px" },
              fontWeight: "600",
              color: theme.palette.primary.black,
            }}
          >
            Subscription
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "50px", lg: "64px" },
              fontWeight: "700",
              color: theme.palette.primary.peachRed,
            }}
          >
            $198
            <span
              style={{
                fontSize: "20px",
                fontWeight: "400",
                color: theme.palette.primary.footerGrey,
              }}
            >
              /mo
            </span>
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "12px", lg: "16px" },
              fontWeight: "300",
              color: theme.palette.primary.black,
            }}
          >
            Get a 80% Sales Increase with WeedX
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: { xs: "10px", sm: "5px", lg: "10px" },
              marginTop: "20px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <TickIcon />
              <Typography
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                  fontWeight: "500",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                Post Products or Brands for sale
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <TickIcon />
              <Typography
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                  fontWeight: "500",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                Unlimited posts and brands
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <TickIcon />
              <Typography
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                  fontWeight: "500",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                Messaging & File sharing
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <TickIcon />
              <Typography
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                  fontWeight: "500",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                View competition prices
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <TickIcon />
              <Typography
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                  fontWeight: "500",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                View industry credit of dispensaries
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <TickIcon />
              <Typography
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                  fontWeight: "500",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                No commission fees
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <TickIcon />
              <Typography
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                  fontWeight: "500",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                Sort & Filter by sellers & brands
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <TickIcon />
              <Typography
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                  fontWeight: "500",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                Full Profile Customization
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <TickIcon />
              <Typography
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                  fontWeight: "500",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                Robust Admin Tools
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <form>
          <div>
            <InputFieldLabel margin="1.5vw">Name on card</InputFieldLabel>
            <InputField
              background="white"
              placeholder="Sean Thomas"
              name="nameOnCard"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.nameOnCard}
            />
            {props.formik.touched.nameOnCard && props.formik.errors.nameOnCard && <div className="error">{props.formik.errors.nameOnCard}</div>}
          </div>
          <div>
            <InputFieldLabel margin="1.5vw">Card number</InputFieldLabel>
            <InputField
              background="white"
              placeholder="1421 5235 1251 1512"
              name="cardNumber"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.cardNumber}
            />
            {props.formik.touched.cardNumber && props.formik.errors.cardNumber && <div className="error">{props.formik.errors.cardNumber}</div>}
          </div>
          <div>
            <InputFieldLabel margin="1.5vw">Expiration date</InputFieldLabel>
            <InputField
              background="white"
              placeholder="12/24"
              name="expDate"
              onChange={handleExpDateChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.expDate}
            />
            {props.formik.touched.expDate && props.formik.errors.expDate && <div className="error">{props.formik.errors.expDate}</div>}
          </div>

          <div>
            <InputFieldLabel margin="1.5vw">CVV</InputFieldLabel>
            <InputField
              background="white"
              placeholder=""
              name="secureCode"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.secureCode}
            />
            {props.formik.touched.secureCode && props.formik.errors.secureCode && <div className="error">{props.formik.errors.secureCode}</div>}
          </div>
        </form>
      </Box>
    </>
  );
}

function Annual(props) {
  return (
    <>
      <ToastContainer />

      <Box
        sx={{
          position: { sm: "absolute" },
          top: { sm: "0%" },
          bottom: { sm: "0%" },
          left: { sm: "0%" },
          width: { sm: "50vw" },
          height: { sm: "100%" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "1.5rem",
        }}
      >
        <Box
          sx={{
            background: theme.palette.primary.dashboardBack,
            borderRadius: "10px",
            padding: { xs: 3, md: 5 },
            boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
            marginTop: { xs: "30px", md: "50px", lg: "0px" },
            maxWidth: { xs: "fit-content", sm: "auto" },
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "32px", lg: "40px" },
              fontWeight: "600",
              color: theme.palette.primary.black,
            }}
          >
            Pro
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "20px", lg: "28px" },
              fontWeight: "600",
              color: theme.palette.primary.black,
            }}
          >
            Subscription
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "50px", lg: "64px" },
              fontWeight: "700",
              color: theme.palette.primary.peachRed,
            }}
          >
            $1998
            <span
              style={{
                fontSize: "20px",
                fontWeight: "400",
                color: theme.palette.primary.footerGrey,
              }}
            >
              /yr
            </span>
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "12px", lg: "16px" },
              fontWeight: "300",
              color: theme.palette.primary.black,
            }}
          >
            Get a 80% Sales Increase with WeedX
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: { xs: "10px", sm: "5px", lg: "10px" },
              marginTop: "20px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <TickIcon />
              <Typography
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                  fontWeight: "500",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                Post Products or Brands for sale
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <TickIcon />
              <Typography
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                  fontWeight: "500",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                Unlimited posts and brands
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <TickIcon />
              <Typography
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                  fontWeight: "500",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                Messaging & File sharing
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <TickIcon />
              <Typography
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                  fontWeight: "500",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                View competition prices
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <TickIcon />
              <Typography
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                  fontWeight: "500",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                View industry credit of dispensaries
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <TickIcon />
              <Typography
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                  fontWeight: "500",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                No commission fees
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <TickIcon />
              <Typography
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                  fontWeight: "500",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                Sort & Filter by sellers & brands
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <TickIcon />
              <Typography
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                  fontWeight: "500",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                Full Profile Customization
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
              <TickIcon />
              <Typography
                sx={{
                  fontSize: { xs: "12px", lg: "16px" },
                  fontWeight: "500",
                  color: theme.palette.primary.mediumGrey,
                }}
              >
                Robust Admin Tools
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <form>
          <div>
            <InputFieldLabel margin="1.5vw">Card number</InputFieldLabel>
            <InputField
              background="white"
              placeholder="1421 5235 1251 1512"
              name="cardNumber"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.cardNumber}
            />
            {props.formik.touched.cardNumber && props.formik.errors.cardNumber && <div className="error">{props.formik.errors.cardNumber}</div>}
          </div>
          <div>
            <InputFieldLabel margin="1.5vw">Expiration date</InputFieldLabel>
            <InputField
              background="white"
              placeholder="12/24"
              name="expDate"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.expDate}
            />
            {props.formik.touched.expDate && props.formik.errors.expDate && <div className="error">{props.formik.errors.expDate}</div>}
          </div>
          <div>
            <InputFieldLabel margin="1.5vw">Name on card</InputFieldLabel>
            <InputField
              background="white"
              placeholder="Sean Thomas"
              name="nameOnCard"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.nameOnCard}
            />
            {props.formik.touched.nameOnCard && props.formik.errors.nameOnCard && <div className="error">{props.formik.errors.nameOnCard}</div>}
          </div>
          <div>
            <InputFieldLabel margin="1.5vw">Secure code</InputFieldLabel>
            <InputField
              background="white"
              placeholder=""
              name="secureCode"
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              value={props.formik.values.secureCode}
            />
            {props.formik.touched.secureCode && props.formik.errors.secureCode && <div className="error">{props.formik.errors.secureCode}</div>}
          </div>
        </form>
      </Box>
    </>
  );
}

export default function Step5Content(props) {
  const selectUserAuth = (state) => state.userRegReducer?.userAuth;
  const userData = useSelector(selectUserAuth);
  const selectUserPayment = (state) => state.userPaymentReducer?.userSubs;
  const userPaymentData = useSelector(selectUserPayment);
  const showUserDetail = userData?.user;
  console.log(showUserDetail, "======");
  const [openModal, setOpenModal] = useState("");

  console.log(userPaymentData, "userPaymentData step 5");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(checkAsyncCard({ data: values })).then((res) => {
        console.log("then", res);
        if (res.payload.success) {
          // toast.success(`${res.payload.message}`, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          dispatch(userAsyncPayment({ data: { ...values } })).then((res) => {
            console.log("then", res);

            if (res?.payload?.success) {
              toast.success(`${res?.payload?.message}`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              setOpenModal(true);
            } else {
              toast.error(`${res?.payload?.message}`, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          });
          // props.handleNext();
        } else {
          toast.error(`${res.payload.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
    },
  });

  const tabs = [
    {
      label: "Monthly",
      content: <Monthly formik={formik} />,
    },
    // {
    //   label: "Annual",
    //   content: <Annual formik={formik} />,
    // },
  ];

  return (
    <>
      {openModal && <SubscriptionStatusModal openModal={openModal} setOpenModal={setOpenModal} setActiveStep={props.setActiveStep} />}

      {showUserDetail?.role !== "BUYER" && (
        <>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              rowGap: "20px",
            }}
          >
            <Grid item xs={12}>
              {/* <img src="https://verify.authorize.net/anetseal/images/secure90x72.gif" alt="authorize.net logo" /> */}
              {/* <div class="AuthorizeNetSeal">
                <Helmet>
                  <script type="text/javascript" language="javascript">
                    var ANS_customer_id="6bc8faee-4df5-4548-a2a6-ddd74e81a065";
                  </script>
                  <script
                    type="text/javascript"
                    language="javascript"
                    src="//verify.authorize.net:443/anetseal/seal.js"
                  ></script>
                </Helmet>
              </div> */}
            </Grid>

            <AuthorizeNetSeal />

            <Stack direction={"row"} sx={{ marginBottom: "20px" }} justifyContent={"space-between"}>
              <Grid item xs={3} lg={2}>
                <img
                  src={AmericanExpress}
                  // src="https://www.authorize.net/_jcr_content/root/container/responsivegrid/contentwell_copy_cop/contentWell/columncontrol/par2/contentwell_67198405/contentWell/contentwell/contentWell/imagecontainer/images/contentwell_929033021/contentWell/image_copy_202153645.img.png/1700067762940.png"
                  alt="american-express"
                  width="70px"
                />
              </Grid>

              <Grid item xs={3} lg={2}>
                <img
                  src={UnionPay}
                  // src="https://www.authorize.net/_jcr_content/root/container/responsivegrid/contentwell_copy_cop/contentWell/columncontrol/par2/contentwell_67198405/contentWell/contentwell_copy/contentWell/imagecontainer/images/contentwell_copy/contentWell/image_copy.img.png/1700067746976.png"
                  alt="union-pay"
                  width="70px"
                />
              </Grid>

              <Grid item xs={3} lg={2}>
                <img
                  src={Visa}
                  // src="https://www.authorize.net/_jcr_content/root/container/responsivegrid/contentwell_copy_cop/contentWell/columncontrol/par2/contentwell_67198405/contentWell/contentwell_copy/contentWell/imagecontainer/images/contentwell_copy_1558768459/contentWell/image_1738459615_cop.img.png/1700087749780.png"
                  alt="visa"
                  width="70px"
                />
              </Grid>

              <Grid item xs={3} lg={2}>
                <img
                  src={MasterCard}
                  // src="https://www.authorize.net/_jcr_content/root/container/responsivegrid/contentwell_copy_cop/contentWell/columncontrol/par2/contentwell_67198405/contentWell/contentwell_copy/contentWell/imagecontainer/images/contentwell_copy_117/contentWell/image_copy_656778450.img.png/1700067764762.png"
                  alt="mastercard"
                  width="70px"
                />
              </Grid>
            </Stack>
          </Grid>

          <BasicTabs tabs={tabs} width={"15vw !important"} display={{ xs: "flex", sm: "flex" }} />
          <PrimaryButton
            text="Next"
            width="100%"
            margin="20px"
            // onClick={props.handleNext}
            onClick={formik.handleSubmit}
          >
            Confirm Payment
          </PrimaryButton>
          <Stack
            marginTop={"10px"}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            spacing={0.6}
            sx={{ color: "#555555", fontSize: "14px", fontWeight: "500" }}
          >
            <HttpsIcon sx={{ fontSize: "16px" }} />
            <Box>SSL Secure Payment</Box>
          </Stack>
        </>
      )}
      {showUserDetail?.role === "BUYER" && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              my: 2,
            }}
          >
            <Box mb={2}>
              <ArrowSignUp />
            </Box>

            <Typography
              sx={{
                color: theme.palette.primary.black,
                fontSize: theme.typography.fontSize,
              }}
            >
              Thank you! Please go to dashboard to start using.
            </Typography>
          </Box>

          <PrimaryButton
            text="Got it!"
            width="100%"
            marginTop="20px"
            // onClick={props.handleNext}
            onClick={() => {
              props.setActiveStep(0);
              navigate("/login");
              // window.location.reload();
            }}
          >
            Login
          </PrimaryButton>
        </>
      )}
    </>
  );
}
const AuthorizeNetSeal = () => {
  useEffect(() => {
    const loadAuthorizeNetSeal = async () => {
      const script1 = document.createElement("script");
      script1.type = "text/javascript";
      script1.innerText = `var ANS_customer_id="6bc8faee-4df5-4548-a2a6-ddd74e81a065";`;

      const script2 = document.createElement("script");
      script2.type = "text/javascript";
      script2.src = "https://verify.authorize.net:443/anetseal/seal.js";

      document.getElementsByClassName("AuthorizeNetSeal")[0].appendChild(script1);
      document.getElementsByClassName("AuthorizeNetSeal")[0].appendChild(script2);
    };

    loadAuthorizeNetSeal();
  }, []);

  return <div className="AuthorizeNetSeal"></div>;
};
const SubscriptionStatusModal = ({ setOpenModal, openModal, setActiveStep }) => {
  const navigate = useNavigate();
  return (
    <Modal noPadding open={openModal} handleClose={() => setOpenModal(false)} fullWidth maxWidth="xs">
      <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
        <Box mb={2}>
          <Completed />
        </Box>
        <PrimaryHeading fontSize={"20px"}>Subscription purchased</PrimaryHeading>
        <Typography
          sx={{
            color: theme.palette.primary.mediumGrey,
            fontSize: theme.typography.fontSize,
            mt: 1,
            mb: 2,
          }}
        >
          Thank you! Please login to start using.
        </Typography>
        <PrimaryButton
          onClick={() => {
            //setOpenModal(false);
            sessionStorage.removeItem("persist:root");
            setActiveStep(0);
            navigate("/login");
            // window.location.reload();
          }}
          width={"100%"}
        >
          Got It!
        </PrimaryButton>
      </Box>
    </Modal>
  );
};
