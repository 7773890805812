import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PrimaryHeading from "../../../Components/Custom/PrimaryHeading";
import LoginImage from "../../../Assets/Images/Login.png";
//import { ReactComponent as ArrowLeft } from "../../../Assets/Icons/LeftArrow.svg";
import InputFieldLabel from "../../../Components/Custom/InnputFieldLabel";
import { ReactComponent as NewPasswordIcon } from "../../../Assets/Icons/GearIcon.svg";
import InputPassword from "../../../Components/Custom/InputPassword";
import { ReactComponent as Completed } from "../../../Assets/Icons/Complete.svg";
import theme from "../../../theme";
import { Link, useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import {
  validationSchemaCP,
  initialValuesCP,
} from "../LoginIntegration/ForgetPasswordValidations";
import {
  resetUser,
  //userAsyncConfirmPassword,
} from "../SignUpIntegration/userRegSlice";
import HamburgerMenu from "../../../Components/Custom/HamburgerMenu";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as LogoWhite } from "../../../Assets/Icons/LogoWhite.svg";
import heroSubTabBanner from "../../../Assets/Images/heroSubTabBanner.png";
import axios from "axios";
import restClient from "../../../util/restClient";

export default function ConfirmPassword(props) {
  const [forgotPassword, setForgotPassword] = useState("newPassword");
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);

  const selectUserAuth = (state) => state.userRegReducer?.userAuth;
  const userData = useSelector(selectUserAuth);

  console.log(userData, "userData");

  const { token } = useParams();
  const navigate = useNavigate();

  //const token = userData?.sendData?.token;

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValuesCP,
    validationSchema: validationSchemaCP,
    onSubmit: async (values) => {
      try {
        // const response = await axios.post(
        //   `https://staging.weedxhub.com/api/user/change-forget-password`,
        //   { password: values.password },
        //   { headers: { Authorization: `Bearer ${token}` } }
        // );
        const response = await restClient({
          method: "POST",
          url: "/user/change-forget-password",
          data: { password: values.password },
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data.success) {
          toast.success(`${response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeout(() => {
            setForgotPassword("resetCompleted");
          }, 1000);
        } else {
          toast.error(`${response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (err) {
        toast.error(`${err.response.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      // dispatch(userAsyncConfirmPassword({ password: values?.password })).then(
      //   (res) => {
      //     console.log("then", res);
      //     if (res.payload.success) {
      //       toast.success(`${res.payload.message}`, {
      //         position: toast.POSITION.TOP_RIGHT,
      //       });
      //       setTimeout(() => {
      //         setForgotPassword("resetCompleted");
      //       }, 1000);
      //     } else {
      //       toast.error(`${res.payload.message}`, {
      //         position: toast.POSITION.TOP_RIGHT,
      //       });
      //     }
      //   }
      // );
    },
  });

  useEffect(() => {
    if (forgotPassword === "resetCompleted") {
      dispatch(resetUser());
      sessionStorage.removeItem("persist:root");
    }
  }, [forgotPassword, dispatch]);

  return (
    <>
      <Grid container>
        <ToastContainer />
        {openHamburgerMenu && (
          <HamburgerMenu onClose={() => setOpenHamburgerMenu(false)} />
        )}
        <Grid
          item
          container
          sx={{
            padding: { xs: "1.5rem", sm: "0rem 3rem", lg: "0 10rem" },
            background: `url(${heroSubTabBanner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: { sm: "none" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
            container
          >
            <Link to={"/"}>
              <LogoWhite />
            </Link>
            <Box onClick={() => setOpenHamburgerMenu(true)}>
              <MenuIcon
                sx={{ color: "white", width: "25px", height: "25px" }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={6} sx={{ display: { xs: "none", sm: "inline" } }}>
          <Box
            sx={{
              background: `url(${
                props.backImage ? props.backImage : LoginImage
              })`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "100vh",
              position: "relative",
              width: "100%",
              backgroundPositionY: "bottom",
            }}
          >
            <Box sx={{ padding: 2, position: "absolute", top: 0, zIndex: 100 }}>
              <Link to={"/"}>
                <LogoWhite />
              </Link>
            </Box>
            <Box sx={{ position: "absolute", bottom: 0, padding: 2 }}>
              <Typography
                sx={{
                  color: theme.palette.primary.white,
                  fontSize: { xs: "12px", lg: theme.typography.fontSize },
                  fontWeight: "300",
                }}
              >
                © Copyright 2024, All Rights Reserved by WeedX
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            overflow: "auto",
          }}
        >
          {/* <Button
            sx={{
              padding: "30px",
              textTransform: "capitalize",
            }}
          >
            <ArrowLeft />
            <Typography
              pl={1}
              sx={{
                color: theme.palette.primary.black,
              }}
            >
              Go back
            </Typography>
          </Button> */}
          {forgotPassword === "newPassword" && (
            <Box
              sx={{
                px: { xs: 3, sm: 5, md: 10, lg: 25 },
                py: 5,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                // width: '100%',
                height: "80vh",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box mb={2}>
                  <NewPasswordIcon />
                </Box>
                <PrimaryHeading
                  color={theme.palette.primary.inputLabelColor}
                  fontSize={{ xs: "28px" }}
                  fontWeight="600"
                  textAlign="center"
                >
                  Set new password
                </PrimaryHeading>
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize16,
                    mb: 3,
                    mt: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  Your new password must be different to previously used
                  passwords.
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "100%",
                }}
              >
                <div>
                  <InputFieldLabel margin="1.5vw">Password</InputFieldLabel>
                  <InputPassword
                    placeholder="Enter your password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    error={formik.errors.password}
                    background="white"
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="error">{formik.errors.password}</div>
                  )}
                </div>
                <div>
                  <InputFieldLabel margin="1.5vw">
                    Confirm Password
                  </InputFieldLabel>
                  <InputPassword
                    placeholder="Enter your confirm password"
                    name="confirmPassword"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    error={formik.errors.confirmPassword}
                    background="white"
                  />
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword && (
                      <div className="error">
                        {formik.errors.confirmPassword}
                      </div>
                    )}
                </div>
              </Box>

              <Box
                sx={{
                  width: "100%",
                }}
              >
                <PrimaryButton
                  text="Set new password"
                  width="100%"
                  margin={3}
                  onClick={formik.handleSubmit}
                  fontWeight="500"
                  fontSize="14px"
                  textTransform="none"
                >
                  Set new password
                </PrimaryButton>

                <Box display={"flex"} justifyContent={"center"} mt={3}>
                  <Link
                    style={{
                      color: theme.palette.primary.main,
                      paddingLeft: "10px",
                      textDecoration: "none",
                      fontSize: theme.typography.fontSize,
                    }}
                  >
                    Back to Login
                  </Link>
                </Box>
              </Box>
            </Box>
          )}

          {forgotPassword === "resetCompleted" && (
            <Box
              sx={{
                px: { xs: 3, sm: 5, md: 10, lg: 25 },
                py: 5,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                // width: '100%',
                height: "80vh",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box mb={2}>
                  <Completed />
                </Box>
                <PrimaryHeading
                  color={theme.palette.primary.inputLabelColor}
                  fontSize={{ xs: "28px" }}
                  fontWeight="600"
                  textAlign="center"
                >
                  Password reset completed
                </PrimaryHeading>
                <Typography
                  sx={{
                    color: theme.palette.primary.mediumGrey,
                    fontSize: theme.typography.fontSize16,
                    mb: 3,
                    mt: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  Now, you can log in to your account with the new password.
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "100%",
                }}
              >
                <PrimaryButton
                  text="Got It!"
                  width="100%"
                  margin={3}
                  fontWeight="500"
                  fontSize="14px"
                  textTransform="none"
                  onClick={() => navigate("/login")}
                >
                  Got It!
                </PrimaryButton>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
}
