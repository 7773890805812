import * as React from "react"
const CopyIconClicked = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#1B8A79"
      fillOpacity={0.35}
      d="M11.43 1.5H8.51c-1.324 0-2.372 0-3.192.11-.844.115-1.527.355-2.066.896-.54.54-.778 1.227-.892 2.074-.11.824-.11 1.876-.11 3.204v4.379c0 1.13.69 2.1 1.67 2.506-.05-.682-.05-1.639-.05-2.435V8.477c0-.961 0-1.79.089-2.453.095-.71.31-1.392.86-1.945.55-.552 1.229-.768 1.937-.863.66-.09 1.486-.09 2.443-.09H11.5c.958 0 1.78 0 2.441.09A2.701 2.701 0 0 0 11.43 1.5Z"
    />
    <path
      fill="#1B8A79"
      stroke="#1B8A79"
      strokeWidth={0.5}
      d="M5.272 6.202c-.076.57-.077 1.317-.077 2.346v3.615c0 1.03 0 1.776.077 2.346.076.563.22.916.483 1.18.262.263.613.408 1.174.484.567.077 1.311.077 2.336.077h2.16c1.026 0 1.77 0 2.337-.077.56-.076.911-.221 1.174-.484.262-.264.407-.617.482-1.18.077-.57.077-1.317.077-2.346V8.548c0-1.03 0-1.776-.077-2.346-.075-.563-.22-.916-.482-1.18l-9.664 1.18Zm0 0c.076-.563.22-.916.483-1.18m-.483 1.18.483-1.18m0 0c.262-.263.613-.408 1.174-.484m-1.174.485 1.174-.485m0 0c.567-.077 1.311-.077 2.336-.077m-2.336.077 2.336-.077m0 0h2.16m-2.16 0h2.16m0 0c1.026 0 1.77 0 2.337.077m-2.337-.077 2.337.077m0 0c.56.076.911.221 1.174.485l-1.174-.485Z"
    />
  </svg>
)
export default CopyIconClicked
