import { useState } from "react";
import { Box, Typography, styled } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import TrendingDownRoundedIcon from "@mui/icons-material/TrendingDownRounded";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";

import CopyIcon from "../../../../../Assets/Icons/CopyIcon";
import CopyIconClicked from "../../../../../Assets/Icons/CopyIconClicked";
import NoImage from "../../../../../Assets/Images/NoImage.svg";
import ActionPopup from "../../../../Admin/BrandManagement/components/ActionPopup";
import Constants from "../../../../../Routes/constantRoute";
import theme from "../../../../../theme";

function DispensaryCard(props) {
  const [copyClicked, setCopyClicked] = useState(false);

  const frontendUrl = Constants.FRONTEND_URL;
  const commonStyles = {
    fontSize: "12px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
  };

  const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme, backgroundColor, textColor }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        padding: "20px",
        backgroundColor: backgroundColor,
        color: textColor,
        ...commonStyles,
      },
    })
  );

  const handleCopyClick = () => {
    setCopyClicked(true);
    navigator.clipboard.writeText(`https://weedxhub.com/dispensary-detail/${props.brand._id || ""}`);
    setTimeout(() => {
      setCopyClicked(false);
    }, 1000);
  };

  const getPercentageChange = (type) => {
    const lastWeek = props.brand[type]?.lastWeek || 0;
    const lastLastWeek = props.brand[type]?.lastLastWeek || 0;

    const percentageChange = ((lastWeek - lastLastWeek) / lastLastWeek) * 100;
    const roundedPercentage = Math.abs(percentageChange).toFixed(0);

    let per =
      type === "totalViews"
        ? lastWeek > lastLastWeek
          ? roundedPercentage
          : -roundedPercentage
        : lastWeek > lastLastWeek
        ? roundedPercentage
        : -roundedPercentage;

    // Check for NaN and convert to 0
    per = isNaN(per) ? 0 : per;

    if (lastLastWeek === 0 && lastWeek > 0) per = 100;

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: "5px",
          background: `${per >= 0 ? "rgba(27, 138, 121, 0.08)" : "rgba(247, 139, 139, 0.08)"}`,
          padding: "5px",
          borderRadius: "2px",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            color: per >= 0 ? theme.palette.primary.main : theme.palette.primary.peachRed,
          }}
        >
          {per >= 0 ? `+${per}%` : `${per}%`}
        </Typography>
        {per >= 0 ? (
          <TrendingUpRoundedIcon
            sx={{
              color: theme.palette.primary.main,
              width: "16px",
              height: "16px",
            }}
          />
        ) : (
          <TrendingDownRoundedIcon
            sx={{
              color: "#F78B8B",
              width: "16px",
              height: "16px",
            }}
          />
        )}
      </Box>
    );
  };

  const uniquePer = getPercentageChange("uniqueViews");
  const totalPer = getPercentageChange("totalViews");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: "24px",
        bgcolor: "white",
        padding: "24px",
        borderRadius: "4px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            columnGap: "4px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              color: theme.palette.primary.black,
              fontWeight: 400,
            }}
          >
            How to grow your stats
          </Typography>
          <CustomTooltip
            title="Share your brand URL to your website, social media pages, & emails
            to get more views. More views = higher sales."
            arrow
            placement="top"
            backgroundColor="#ffffff"
            textColor="#2E3A48"
          >
            <HelpOutlineRoundedIcon
              sx={{
                fontSize: "16px",
                cursor: "pointer",
                color: "#2E3A48",
              }}
            />
          </CustomTooltip>
        </Box>
        <ActionPopup popupValues={props.popupValues} id={props.brand._id} />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          columnGap: "12px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "64px", xl: "100px" },
            height: { xs: "64px", xl: "100px" },
          }}
        >
          <img
            src={props.brand.logo || NoImage}
            alt="banner logo"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "50%",
              border: "1px solid #E0E0E0",
            }}
          />
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.primary.black,
            }}
          >
            {props.brand.dispensaryName}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 300,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            {props.brand.stateLicense}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box textAlign={"center"}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              color: theme.palette.primary.black,
            }}
          >
            Total Views
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            Past 7 days
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
              marginTop: "5px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: theme.palette.primary.black,
              }}
            >
              {props.brand?.totalViews?.lastWeek > 10 ? props.brand?.totalViews?.lastWeek : `0${props.brand?.totalViews?.lastWeek}` || 0}
            </Typography>
            {totalPer}
          </Box>
        </Box>
        <Box textAlign={"center"}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              color: theme.palette.primary.black,
            }}
          >
            Unique Visitors
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              color: theme.palette.primary.mediumGrey,
            }}
          >
            Past 7 days
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
              marginTop: "5px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: theme.palette.primary.black,
              }}
            >
              {props.brand?.uniqueViews?.lastWeek > 10 ? props.brand?.uniqueViews?.lastWeek : `0${props.brand?.uniqueViews?.lastWeek}` || 0}
            </Typography>

            {uniquePer}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "5px",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            color: theme.palette.primary.mediumGrey,
          }}
        >
          YOUR DISPENSARY LINK
        </Typography>
        <Box sx={{ display: "flex", maxWidth: "80%" }}>
          <Box
            sx={{
              border: "1px solid #EEEEEE",
              padding: "5px 10px",
              borderRadius: "4px 0px 0px 4px",
              width: "100%",
            }}
          >
            <Typography
              noWrap
              sx={{
                fontSize: "12px",
                color: theme.palette.primary.black,
                fontWeight: 400,
              }}
            >
              {`${frontendUrl}dispensary-details/${props.brand._id || ""}`}
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "6px",
              backgroundColor: "#1b8a7917",
              cursor: "pointer",
              display: "flex",
              justifySelf: "flex-end",
              borderRadius: "0px 4px 4px 0px",
            }}
            onClick={() => {
              handleCopyClick();
            }}
          >
            {copyClicked === true ? <CopyIconClicked /> : <CopyIcon />}
          </Box>
        </Box>
        <Typography
          sx={{
            fontSize: "12px",
            color: theme.palette.primary.mediumGrey,
            fontWeight: 400,
          }}
        >
          Copy & share your storefront link everywhere{" "}
        </Typography>
      </Box>
    </Box>
  );
}

export default DispensaryCard;
