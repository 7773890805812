import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import TopHeader from "../Header/TopHeader";
import MenuBar from "../../../Components/Custom/MenuBar";
import SmallBanner from "../../../Components/Custom/SmalBanner";
import SearchInputWithIcon from "../../../Components/Custom/SearchInput";
import theme from "../../../theme";
import CustomAccordion from "../../../Components/Custom/Accordion";
import SmallFooter from "../SmallFooter/SmallFooter";
import restClient from "../../../util/restClient";

function FAQs() {
  const [faqs, setFaqs] = useState([]);

  const getAllFaqs = async (keyword = "") => {
    try {
      // const response = await axios.get(
      //   `https://staging.weedxhub.com/api/user/faq-info?&name=${keyword}`
      // );
      const response = await restClient({
        method: "GET",
        url: `/admin/get-Faq?&name=${keyword}`,
      });

      if (response) {
        setFaqs([...response?.data?.data]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllFaqs();
  }, []);

  console.log(faqs, "faqs");
  return (
    <>
      <TopHeader />
      <MenuBar isVisible={false} />
      <SmallBanner
        breadcrumbsMainTitle={"FAQs"}
        breadcrumbsSmallTitle={"FAQs"}
      />
      <Grid
        container
        sx={{
          padding: { lg: "0 10rem" },
          my: { xs: 5, lg: 10 },
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={10}
          lg={8}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <SearchInputWithIcon
            placeholder={"Search FAQs"}
            background={theme.palette.primary.white}
            callbackFunction={getAllFaqs}
            padding={"10px"}
          />
        </Grid>
        <Grid item xs={10} lg={8} mt={{ xs: 5, lg: 10 }}>
          {faqs &&
            faqs?.length > 0 &&
            faqs.map((faq, index) => (
              <CustomAccordion
                key={faq._id}
                accordionTitle={faq?.question}
                accordionDetail={faq?.answer}
                borderRadius={
                  index === 0
                    ? "4px 4px 0px 0px !important"
                    : index === faqs.length - 1
                    ? "0px 0px 4px 4px !important"
                    : ""
                }
              />
            ))}
        </Grid>
      </Grid>

      <SmallFooter />
    </>
  );
}

export default FAQs;
