import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from "@mui/material";

import SortSearch from "./SortSearch/SortSearch";
import Product from "./Product/Product";
import Sidebar from "./Sidebar/Sidebar";
import restClient from "../../../../util/restClient";
import LoginModal from "../components/LoginModal";

function Products({ brandName }) {
  const location = useLocation();
  const userData = useSelector((state) => state.userRegReducer?.userAuth);
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [sortValue, setSortValue] = useState({});
  const [categoriesChecked, setCategoriesChecked] = useState({});
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [loaderCategories, setLoaderCategories] = useState(false);
  const [loaderProducts, setLoaderProducts] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [categoriesMain, setCategoriesMain] = useState([]);
  const [categoriesOthers, setCategoriesOthers] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);

  const onClickHandler = (event, category, subCategory) => {
    if (subCategory === "All") {
      const checked = event.target.checked;

      if (!checked)
        setCategoriesChecked((prevState) => {
          const nextState = { ...prevState };
          delete nextState[category];

          return nextState;
        });
      else
        setCategoriesChecked((prevState) => ({
          ...prevState,
          [category]: {
            subCategories: categories.find((item) => item.label === category)
              .subCategories,
            checkedAll: true,
          },
        }));
    } else {
      const checked = event.target.checked;

      if (!checked)
        setCategoriesChecked((prevState) => {
          if (prevState[category]?.subCategories.length === 1) {
            const nextState = { ...prevState };
            delete nextState[category];

            return nextState;
          }

          return {
            ...prevState,
            [category]: {
              subCategories: prevState[category].subCategories.filter(
                (item) => item !== subCategory,
              ),
              checkedAll: false,
            },
          };
        });
      else
        setCategoriesChecked((prevState) => ({
          ...prevState,
          [category]: {
            subCategories: [
              ...(prevState[category]?.subCategories || []),
              subCategory,
            ],
            checkedAll:
              prevState[category]?.subCategories.length + 1 ===
              categories.find((item) => item.label === category).subCategories
                .length
                ? true
                : false,
          },
        }));
    }
  };

  const getCategories = async () => {
    try {
      setLoaderCategories(true);
      const response = await restClient({
        method: "GET",
        url: `/user/filterCategories`,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      setCategoriesMain(response?.data?.data?.categories || []);
      setCategoriesOthers(response?.data?.data?.others || []);

      const sortedCategories = [
        ...response?.data?.data?.categories,
        ...response?.data?.data?.others,
      ].sort((a, b) => a.label.localeCompare(b.label));

      setCategories(sortedCategories || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoaderCategories(false);
    }
  };

  const getProducts = useCallback(
    async (keyword) => {
      const filterFormData = () => {
        let formData = {
          subCategory: [],
        };

        if (keyword) formData.name = keyword;
        formData = { ...formData, ...sortValue };

        Object.keys(categoriesChecked).forEach((category) => {
          if (categoriesMain.find((item) => item.label === category)) {
            formData.subCategory = [
              ...formData.subCategory,
              ...categoriesChecked[category].subCategories,
            ];
          }

          if (categoriesOthers.find((item) => item.label === category)) {
            formData[category] = categoriesChecked[category].subCategories;
          }
        });

        if (formData.subCategory.length === 0) delete formData.subCategory;
        return formData;
      };

      try {
        setLoaderProducts(true);
        const response = await restClient({
          method: "POST",
          url: `/user/products/all?page=${page}&limit=${pageSize}`,
          data: filterFormData(),
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        });

        setTotalProducts(response?.data?.data?.totalProducts || 0);
        setProducts(response?.data?.data?.products || []);
      } catch (error) {
        console.log(error);
      } finally {
        setLoaderProducts(false);
      }
    },
    [
      userData?.token,
      categoriesChecked,
      categoriesMain,
      categoriesOthers,
      page,
      sortValue,
      pageSize,
    ],
  );

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    getProducts();
  }, [page, categoriesChecked, sortValue, pageSize]);

  useEffect(() => {
    setPage(1);
  }, [categoriesChecked, sortValue, pageSize]);

  useEffect(() => {
    if (!userData.authorized) setOpenLoginModal(true);
    else setOpenLoginModal(false);
  }, [userData]);

  return (
    <Stack
      sx={{ position: "relative" }}
      spacing={3}
      direction={{ xs: "column", lg: "row" }}
    >
      {openLoginModal && (
        <LoginModal
          handleSubmit={() =>
            navigate("/login", {
              state: { redirectTo: location.pathname },
              replace: false,
            })
          }
        />
      )}
      <Sidebar
        categories={categories}
        categoriesChecked={categoriesChecked}
        onClickHandler={onClickHandler}
        loaderCategories={loaderCategories}
        setCategoriesChecked={setCategoriesChecked}
      />
      <Stack spacing={3} flexGrow={1} sx={{ paddingLeft: { lg: "300px" } }}>
        <SortSearch
          sortValue={sortValue}
          setSortValue={setSortValue}
          getProducts={getProducts}
        />
        {loaderProducts ? (
          <Stack
            sx={{ minHeight: "400px" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <Grid container>
            {products.map((product, index) => {
              return (
                <Grid item key={index} xs={12} md={6} lg={4}>
                  <Product product={product} />
                </Grid>
              );
            })}
          </Grid>
        )}
        {products.length === 0 && !loaderProducts && (
          <Stack
            sx={{ minHeight: "400px" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <span style={{ fontSize: "18px", fontWeight: "500" }}>
              No available products at this time
            </span>
          </Stack>
        )}
        {products.length !== 0 && (
          <Stack
            direction="row"
            sx={{ padding: "10px" }}
            spacing={4}
            justifyContent={"flex-end"}
          >
            <Stack direction="row" spacing={2} alignItems={"center"}>
              <span
                style={{
                  fontSize: "14px",
                  color: "#363636",
                }}
              >
                Products per page:
              </span>
              <Select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(e.target.value);
                  setPage(1);
                }}
                variant="standard"
                color="success"
              >
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={27}>27</MenuItem>
                <MenuItem value={54}>54</MenuItem>
              </Select>
            </Stack>
            <Pagination
              color="success"
              count={Math.ceil(totalProducts / pageSize)}
              page={page}
              onChange={(e, value) => {
                setPage(value);
              }}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

export default Products;
