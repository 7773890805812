import { formatDate } from "./dateFormatter";

const labelArr = [
  "Strain effect",
  "Scent",
  "Sales type",
  "Accepted payment terms",
];

export const checkLabel = (item, productData) => {
  if (labelArr.includes(item.label))
    return productData?.[item.name]?.filter(Boolean).join(", ");

  if (item.label === "Package date")
    return formatDate(productData?.[item.name]);

  if (item.label === "Sample available")
    return productData?.[item.name] ? "Yes" : "No";

  if (item.label === "Lab file") {
    if (!productData?.[item.name]) return "No file";

    return (
      <span
        style={{
          color: "#1B8A79",
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={() =>
          window.open(productData?.[item.name], "_blank", "noreferrer")
        }
      >
        Lab File
      </span>
    );
  }

  return productData?.[item.name];
};
