import { useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";

import Header from "./Header";
import { ChatComponentReciever, ChatComponentSender } from "./ChatComponent";
import MessageBox from "./MessageBox";
import { getCurrentTime } from "../../helpers/dateConverter";

const dummmyChatList = [
  {
    message: "Thanks for your interest, I'll provide you full list ASAP",
    time: "03:59 PM",
    sender: "sender",
  },
  {
    message:
      "Hi, I hope you are doing well, Id like to see what products you have for sale.",
    time: "04:30 PM",
    sender: "receiver",
  },
  {
    message:
      "Yea I'm well, Thank you, i am very happy for this. Please take a look at our storefront.",
    time: "10:30 AM",
    sender: "sender",
  },
];

function ActiveChat() {
  const activeChatComponent = useRef();
  const chatListComponent = useRef();
  const headerComponent = useRef();
  const messageComponent = useRef();
  const [chatList, setChatList] = useState(dummmyChatList);

  useEffect(() => {
    const calculateChatListComponentHeight = () => {
      if (activeChatComponent.current && chatListComponent.current) {
        const activeChatComponentHeight =
          activeChatComponent.current.clientHeight;
        const headerHeight = headerComponent.current.clientHeight;
        const messageComponentHeight = messageComponent.current.clientHeight;

        const newHeight =
          activeChatComponentHeight -
          headerHeight -
          messageComponentHeight -
          40;

        chatListComponent.current.style.height = `${newHeight}px`;
      }
    };

    calculateChatListComponentHeight();

    window.addEventListener("resize", calculateChatListComponentHeight);

    return () => {
      window.removeEventListener("resize", calculateChatListComponentHeight);
    };
  }, []);

  useEffect(() => {
    const scrollToBottom = () => {
      if (chatListComponent.current) {
        chatListComponent.current.scrollTop =
          chatListComponent.current.scrollHeight;
      }
    };

    scrollToBottom();
  }, [chatList]);

  return (
    <Stack ref={activeChatComponent} sx={{ width: "100%" }}>
      <Box
        ref={headerComponent}
        sx={{
          position: "sticky",
          top: "0",
          zIndex: 1000,
        }}
      >
        <Header />
      </Box>
      <Stack
        className="scrollbar"
        overflow={"auto"}
        sx={{ padding: "20px" }}
        spacing={2}
        ref={chatListComponent}
      >
        {chatList.map((chat, index) => (
          <Stack
            key={index}
            direction={"row"}
            justifyContent={
              chat.sender === "sender" ? "flex-end" : "flex-start"
            }
          >
            {chat.sender === "sender" && (
              <ChatComponentSender key={index} chat={chat} />
            )}
            {chat.sender === "receiver" && (
              <ChatComponentReciever key={index} chat={chat} />
            )}
          </Stack>
        ))}
      </Stack>
      <Box ref={messageComponent}>
        <MessageBox
          onClickSendMessage={(message) => {
            setChatList([
              ...chatList,
              {
                message: message,
                time: getCurrentTime(),
                sender: "sender",
              },
            ]);
          }}
        />
      </Box>
    </Stack>
  );
}

export default ActiveChat;
