import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as RoundTick } from "../../../Assets/Icons/RoundTick.svg";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import theme from "../../../theme";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Plans() {
  const [selected, setSelected] = useState("dispensary");

  const navigate = useNavigate();

  return (
    <>
      <Grid container sx={{ display: { lg: "none" }, padding: "1.5rem" }}>
        <Grid
          container
          item
          sx={{ border: "1px solid #F78B8B", borderRadius: "5px" }}
        >
          <Grid item xs={6}>
            <PrimaryButton
              backgroundColor={
                selected === "dispensary" ? "#F78B8B" : "transparent"
              }
              color={selected === "dispensary" ? "white" : "#F78B8B"}
              fontSize="14px"
              fontWeight="500"
              width="100%"
              borderRadius="3px 0px 0px 3px"
              padding="8px"
              onClick={() => setSelected("dispensary")}
            >
              Dispensary
            </PrimaryButton>
          </Grid>
          <Grid item xs={6}>
            <PrimaryButton
              backgroundColor={
                selected === "supplier" ? "#F78B8B" : "transparent"
              }
              color={selected === "supplier" ? "white" : "#F78B8B"}
              fontSize="14px"
              fontWeight="500"
              width="100%"
              padding="8px"
              borderRadius="0px 3px 3px 0px"
              onClick={() => setSelected("supplier")}
            >
              Supplier
            </PrimaryButton>
          </Grid>
        </Grid>

        <Grid
          item
          container
          sx={{
            margin: "2rem 0rem",
            borderRadius: "5px",
            boxShadow:
              "0px 8px 8px -4px #00000008, 0px 20px 24px -4px #0000000A, 0px 0px 4px 4px #0000000A",
          }}
        >
          <Grid
            item
            xs={7}
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.3)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "500",
                color: theme.palette.primary.black,
              }}
            >
              Compare Plans
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "300",
                color: theme.palette.primary.mediumGrey,
              }}
            >
              Choose your workspace plan according to your organisational plan
            </Typography>
          </Grid>

          <Grid
            item
            xs={5}
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: theme.palette.primary.black,
              }}
            >
              {selected === "dispensary" ? "Dispensary" : "Supplier"}
            </Typography>
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "600",
                color: theme.palette.primary.black,
                display: "flex",
                alignItems: "center",
              }}
            >
              {selected === "dispensary" ? "Free" : "$198"}
              {selected === "supplier" && (
                <span
                  style={{
                    fontSize: "10px",
                    fontWeight: "300",
                    color: theme.palette.primary.black,
                    marginTop: "2px",
                  }}
                >
                  /Month
                </span>
              )}
            </Typography>
            <PrimaryButton
              onClick={() => navigate("/signup")}
              backgroundColor="#F78B8B"
              fontSize="12px"
              fontWeight="300"
              width="100%"
              padding="3px"
            >
              {selected === "dispensary" ? "Claim Account" : "Create Store"}
            </PrimaryButton>
          </Grid>

          <Grid
            item
            xs={7}
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.3)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: theme.palette.primary.black,
              }}
            >
              View all products
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RoundTick />
          </Grid>
          <Grid
            item
            xs={7}
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.3)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: theme.palette.primary.black,
              }}
            >
              Verify facility profile
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RoundTick />
          </Grid>
          <Grid
            item
            xs={7}
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.3)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: theme.palette.primary.black,
              }}
            >
              Message & file sharing
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RoundTick />
          </Grid>
          <Grid
            item
            xs={7}
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.3)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: theme.palette.primary.black,
              }}
            >
              Search sellers & brands
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RoundTick />
          </Grid>
          <Grid
            item
            xs={7}
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.3)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: theme.palette.primary.black,
              }}
            >
              No commission fees
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RoundTick />
          </Grid>
          <Grid
            item
            xs={7}
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.3)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: theme.palette.primary.black,
              }}
            >
              Integrated with METRC directly
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RoundTick />
          </Grid>
          <Grid
            item
            xs={7}
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.3)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: theme.palette.primary.black,
              }}
            >
              24/7 customer support
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RoundTick />
          </Grid>
          <Grid
            item
            xs={7}
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.3)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: theme.palette.primary.black,
              }}
            >
              Sync products from METRC
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {selected === "supplier" && <RoundTick />}
          </Grid>
          <Grid
            item
            xs={7}
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.3)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: theme.palette.primary.black,
              }}
            >
              Unlimited products or brand uploads
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {selected === "supplier" && <RoundTick />}
          </Grid>
          <Grid
            item
            xs={7}
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.3)",
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: theme.palette.primary.black,
              }}
            >
              Analytics and reporting
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {selected === "supplier" && <RoundTick />}
          </Grid>
          <Grid
            item
            xs={7}
            sx={{
              borderRight: "1px solid rgba(0, 0, 0, 0.3)",
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: theme.palette.primary.black,
              }}
            >
              Advertise on Weed X
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              padding: { xs: "0.7rem", sm: "1rem 2rem" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {selected === "supplier" && <RoundTick />}
          </Grid>
        </Grid>
      </Grid>

      {/************UI FOR BREAKPOINTS STARTING FROM lg: 1200px */}
      <Box
        sx={{
          display: { xs: "none", lg: "flex" },
          justifyContent: "center",
          alignItems: "center",

          margin: "3rem auto",
        }}
      >
        <div className="main">
          <table className="price-table">
            <tbody>
              <tr>
                <td
                  style={{
                    width: "33.333%",
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.primary.black,
                      fontSize: "24px",
                      fontWeight: "500",
                    }}
                  >
                    Compare Plans
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.primary.mediumGrey,
                      fontSize: theme.typography.fontSize,
                      fontWeight: "300",
                      marginTop: "5px",
                    }}
                  >
                    Choose your workspace plan according to your organisational
                    plan
                  </Typography>
                </td>
                <td
                  style={{
                    width: "33.333%",
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.primary.black,
                      fontSize: theme.typography.fontSize,
                      fontWeight: "500",
                    }}
                  >
                    Dispensary
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.primary.black,
                      fontSize: "40px",
                      fontWeight: "600",
                    }}
                  >
                    Free
                  </Typography>
                  <PrimaryButton
                    backgroundColor={`${theme.palette.primary.peachRed}`}
                    width="200px"
                    fontSize="14px"
                    fontWeight="500"
                    onClick={() => navigate("/signup")}
                  >
                    Claim Account
                  </PrimaryButton>
                </td>
                <td
                  style={{
                    padding: "40px",
                    width: "33.333%",
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.primary.black,
                      fontSize: theme.typography.fontSize,
                      fontWeight: "500",
                    }}
                  >
                    Supplier
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.primary.black,
                      fontSize: "40px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "baseline",
                      justifyContent: "center",
                    }}
                  >
                    $198
                    <span
                      style={{
                        fontSize: theme.typography.fontSize,
                        color: theme.palette.primary.mediumGrey,
                        fontWeight: "300",
                        pl: 1,
                      }}
                    >
                      /Month
                    </span>
                  </Typography>
                  <PrimaryButton
                    backgroundColor={`${theme.palette.primary.peachRed}`}
                    width="200px"
                    fontWeight="500"
                    fontSize="14px"
                    onClick={() => navigate("/signup")}
                  >
                    Create Store
                  </PrimaryButton>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: theme.palette.primary.black,
                  }}
                >
                  View all products
                </td>
                <td>
                  <RoundTick />
                </td>
                <td>
                  <RoundTick />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: theme.palette.primary.black,
                  }}
                >
                  Verify facility profile
                </td>
                <td>
                  <RoundTick />
                </td>
                <td>
                  <RoundTick />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: theme.palette.primary.black,
                  }}
                >
                  Message & file sharing
                </td>
                <td>
                  <RoundTick />
                </td>
                <td>
                  <RoundTick />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: theme.palette.primary.black,
                  }}
                >
                  Search sellers & brands
                </td>
                <td>
                  <RoundTick />
                </td>
                <td>
                  <RoundTick />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: theme.palette.primary.black,
                  }}
                >
                  No commission fees
                </td>
                <td>
                  <RoundTick />
                </td>
                <td>
                  <RoundTick />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: theme.palette.primary.black,
                  }}
                >
                  Integrated with METRC directly
                </td>
                <td>
                  <RoundTick />
                </td>
                <td>
                  <RoundTick />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: theme.palette.primary.black,
                  }}
                >
                  24/7 customer support
                </td>
                <td>
                  <RoundTick />
                </td>
                <td>
                  <RoundTick />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: theme.palette.primary.black,
                  }}
                >
                  Sync products from METRC
                </td>
                <td></td>
                <td>
                  <RoundTick />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: theme.palette.primary.black,
                  }}
                >
                  Unlimited products or brand uploads
                </td>
                <td></td>
                <td>
                  <RoundTick />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: theme.palette.primary.black,
                  }}
                >
                  Analytics and reporting
                </td>
                <td></td>
                <td>
                  <RoundTick />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    color: theme.palette.primary.black,
                  }}
                >
                  Advertise on Weed X
                </td>
                <td></td>
                <td>
                  <RoundTick />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Box>
    </>
  );
}
