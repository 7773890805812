import { Box, Stack } from "@mui/material";

function CustomInput({ placeholder, item, detailsData, setFormData, errors }) {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      padding={"16px"}
      sx={{
        borderRadius: "4px",
        bgcolor: "#fff",
      }}
      spacing={1}
    >
      <span style={{ color: "#87929E", fontSize: "14px", fontWeight: "300" }}>
        {placeholder}
      </span>
      <Stack alignItems={"flex-end"}>
        <Box sx={{ width: { xs: "140px", md: "160px", lg: "200px" } }}>
          <input
            type={item.type}
            min="0"
            value={detailsData[item.key]}
            placeholder="Type here"
            style={{
              border: "none",
              outline: "none",
              fontSize: "14px",
              color: "#87929E",
              width: "90%",
              borderBottom: "1px solid #E6E6E6",
            }}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                details: { ...prev.details, [item.key]: e.target.value },
              }))
            }
          />
          {item.type === "percent" && (
            <span style={{ fontSize: "12px" }}>%</span>
          )}
        </Box>
        <Box
          sx={{
            fontSize: "12px",
            marginTop: "4px",
            color: "#FF0000",
          }}
          component={"span"}
        >
          {errors?.[item.key]}
        </Box>
      </Stack>
    </Stack>
  );
}

export default CustomInput;
