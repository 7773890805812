import { Box, Stack } from "@mui/material";

function Features({ features }) {
  const colorArray = ["#24B314", "#FF9F43", "#179AFA", "#5D5FEF", "#FCD200"];
  const backgroundColorArray = [
    "#D9F7BE",
    "#FFF2D8",
    "#D9F7FF",
    "#E8E8FF",
    "#FFF7D9",
  ];

  return (
    <Stack direction={"row"} flexWrap={"wrap"}>
      {features?.filter(Boolean)?.map((feature, i) => (
        <Box
          key={i}
          sx={{
            boxSizing: "border-box",
            padding: "4px",
            textTransform: "uppercase",
            borderRadius: "4px",
            fontSize: "12px",
            fontWeight: "500",
            color: colorArray[i % 5],
            backgroundColor: backgroundColorArray[i % 5],
            margin: "0 4px 4px 0",
            height: "26px",
          }}
        >
          {feature}
        </Box>
      ))}
    </Stack>
  );
}

export default Features;
