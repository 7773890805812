import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import theme from "../../theme";
import SecondaryButton from "./SecondaryButton";
import PrimaryButton from "./PrimaryButton";
import CustomPrimaryButton from "../../Components/Custom/CustomPrimaryButton";
export default function Modal(props) {
  return (
    <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" {...props}>
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          fontSize: "20px",
          fontWeight: theme.typography.heading1.fontWeight,
        }}
      >
        {props.headerText}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{props.children}</DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          padding: props.noPadding || "4px 24px 20px",
        }}
      >
        {props.leftButton && (
          <>
            <SecondaryButton onClick={props.handleClose && props.handleClose} width={"100%"}>
              {props.leftButton}
            </SecondaryButton>
          </>
        )}
        {props.rightButton && (
          <>
            <CustomPrimaryButton onClick={props.handleSubmit} isLoading={props.isLoading} disabled={props.isLoading ? true : false}>
              {props.rightButton}
            </CustomPrimaryButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
