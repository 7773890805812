import { Box, CircularProgress, Stack } from "@mui/material";
import { useState } from "react";
import SortCont from "./SortCont";
import UserReviewCont from "./UserReviewCont";
import SecondaryButton from "../../components/SecondaryButton";

function CustomerReview({ reviews, getReviews, isLoading, brandOwnerId }) {
  const [sortValue, setSortValue] = useState("Latest");
  const [limit, setLimit] = useState(3);

  return (
    <Stack sx={{ bgcolor: "white" }} spacing={3}>
      <SortCont sortValue={sortValue} setSortValue={setSortValue} />
      <Box
        sx={{
          fontSize: "18px",
          fontWeight: "500",
          padding: {
            xs: "0 16px",
            lg: "0 32px",
          },
        }}
        component={"span"}
      >
        Customer Reviews
      </Box>
      {isLoading ? (
        <Box alignSelf={"center"}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {reviews
            .sort((a, b) => {
              if (sortValue === "Latest")
                return new Date(b.createdAt) - new Date(a.createdAt);
              if (sortValue === "Most Helpful")
                return b.helpfulCount - a.helpfulCount;
              if (sortValue === "Highest Stars") return b.rating - a.rating;
              if (sortValue === "Lowest Stars") return a.rating - b.rating;

              return new Date(b.createdAt) - new Date(a.createdAt);
            })
            .slice(0, limit)
            .map((review) => (
              <UserReviewCont
                key={review._id}
                review={review}
                getReviews={getReviews}
                brandOwnerId={brandOwnerId}
              />
            ))}
        </>
      )}
      <Box sx={{ alignSelf: "flex-end", padding: "0 32px 32px 0" }}>
        {limit === reviews.length ? (
          <SecondaryButton onClick={() => setLimit(3)}>
            Hide Reviews
          </SecondaryButton>
        ) : (
          <SecondaryButton onClick={() => setLimit(reviews.length)}>
            View all Reviews
          </SecondaryButton>
        )}
      </Box>
    </Stack>
  );
}

export default CustomerReview;
