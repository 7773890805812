import Button from "@mui/material/Button";
import theme from "../../theme";
import { CircularProgress } from "@mui/material";

function SecondaryButton(props) {
  return (
    <>
      <Button
        component="label"
        variant="outlined"
        startIcon={props.icon}
        onClick={props.onClick}
        disabled={props.disabled || props.isLoading}
        sx={{
          border: props.bordersColor || "1px solid" + theme.palette.primary.main,
          color: props.color || theme.palette.primary.main,
          textTransform: props.textTransform ? props.textTransform : "capitalize",
          mr: 2,
          width: props.width || "auto",
          padding: props.padding || "",
          boxShadow: "none !important",
          marginTop: props.margin || "",
          marginLeft: props.marginLeft || "",
          background: props.background || "",
          "&:hover": {
            backgroundColor: "inherit",
            boxShadow: "none !important",
            borderColor: props.bordersColor || theme.palette.primary.main,
          },
          cursor: "pointer",
          fontSize: props.fontSize || "14px",
        }}
      >
        {props.children}
        {props.isLoading && (
          <CircularProgress
            size={"1.2rem"}
            sx={{
              color: theme.palette.primary.main,
              position: "relative",
              left: "10px",
            }}
          />
        )}
      </Button>
    </>
  );
}

export default SecondaryButton;
