import { Box } from "@mui/material";
import React from "react";

function Header() {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        padding: "20px 36px",
        fontSize: { md: "24px", xs: "18px" },
        fontWeight: 500,
      }}
    >
      Messages
    </Box>
  );
}

export default Header;
