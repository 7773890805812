import { Stack } from "@mui/material";
import SortPopup from "../../components/SortPopup";

function SortCont({ sortValue, setSortValue }) {
  const onClickHandler = (item) => {
    switch (item.label) {
      case "A-Z":
        setSortValue({
          sortOrder: "asc",
        });
        break;

      case "Z-A":
        setSortValue({
          sortOrder: "desc",
        });
        break;

      case "Price: Low to High":
        setSortValue({
          sortByRate: "asc",
        });
        break;

      case "Price: High to Low":
        setSortValue({
          sortByRate: "desc",
        });
        break;

      case "Featured":
        setSortValue({
          isFeatured: true,
        });
        break;

      default:
        setSortValue({});
    }
  };

  const getSortLabel = (sortValue) => {
    if (sortValue.sortOrder === "asc") return "A-Z";
    if (sortValue.sortOrder === "desc") return "Z-A";
    if (sortValue.sortByRate === "asc") return "Price: Low to High";
    if (sortValue.sortByRate === "desc") return "Price: High to Low";
    if (sortValue.isFeatured) return "Featured";

    return "";
  };

  return (
    <Stack direction={"row"} spacing={1}>
      <span style={{ fontWeight: "500" }}>Sort by:</span>
      <span style={{ fontWeight: "300", color: "#596570" }}>
        {getSortLabel(sortValue)}
      </span>
      <SortPopup
        popupValues={[
          { label: "A-Z", value: "asc" },
          { label: "Z-A", value: "desc" },
          { label: "Price: Low to High", value: "asc" },
          { label: "Price: High to Low", value: "desc" },
          { label: "Featured", value: true },
        ]}
        onClickHandler={onClickHandler}
      />
    </Stack>
  );
}

export default SortCont;
