import { Stack } from "@mui/material";

function Popup({ children, customStyles }) {
  return (
    <Stack
      spacing={2}
      sx={{
        width: "128px",
        padding: "4px",
        border: "1px solid lightgrey",
        borderRadius: "1px",
        position: "absolute",
        top: "50%",
        right: "100% ",
        backgroundColor: "#FFFFFF",
        alignItems: "center",
        zIndex: 1000,
        boxShadow: "0px 10px 10px 0px rgba(0, 0, 0, 0.1)",
        fontWeight: "400",
        ...customStyles,
      }}
    >
      {children}
    </Stack>
  );
}

export default Popup;
