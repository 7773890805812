import { Box, Stack } from "@mui/material";
import React from "react";
const getColor = (status) => {
  switch (status) {
    case "all":
      return {
        bgcolor: "#212B36",
        color: "#fff",
      };

    case "received":
      return {
        color: "#EE4C1A",
        bgcolor: "#FFECEB",
      };

    case "sent":
      return {
        color: "#24B314",
        bgcolor: "#E6FFED",
      };

    case "draft":
      return {
        color: "#596570",
        bgcolor: "#F5F6F7",
      };

    case "featured":
      return {
        color: "#3323EE",
        bgcolor: "#ECEAFF",
      };

    default:
      return {};
  }
};
export default function Filter({ data, isActive, setIsActive, messagesCount }) {
  return (
    <Stack direction="row" spacing={{ xs: 1, sm: 3 }} sx={{ width: "100%", justifyContent: "flex-start" }}>
      {["sent", "received"].map((item, i) => {
        return (
          <Stack
            key={i}
            direction="row"
            spacing={1}
            alignItems={"center"}
            sx={{
              borderBottom: item === isActive ? "2px solid #F78B8B" : "2px solid transparent",
              padding: "16px 0",
              cursor: "pointer",
            }}
            onClick={() => setIsActive(item)}
          >
            <Box
              component={"span"}
              sx={{
                fontWeight: "500",
                fontSize: { xs: "10px", sm: "14px" },
                color: item === isActive ? "#212B36" : "#87929E",
                textTransform: "capitalize",
                "&:hover": {
                  color: "#212B36",
                },
              }}
            >
              {item}
            </Box>
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              component={"span"}
              sx={{
                width: { xs: "12px", sm: "18px" },
                height: { xs: "12px", sm: "18px" },
                borderRadius: "50%",
                fontSize: { xs: "8px", sm: "11px" },
                fontWeight: "500",
                ...getColor(item),
              }}
            >
              {/* {getItemNumber(data, item)} */}
              {messagesCount[item]}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}
