import ActionPopup from "../Components/ActionPopup";
const columns = [
  {
    id: "serialNumber",
    label: "S. No.",
  },
  {
    id: "brandName",
    label: "Brand Name",
    sortable: true,
  },
  {
    id: "productName",
    label: "Product Name",
    sortable: true,
  },
  {
    id: "date",
    label: "Date",
    sortable: true,
  },
  {
    id: "message",
    label: "Message",
    sortable: true,
  },

  {
    id: "trash",
    label: "Action",
    actions: true,
    popup: <ActionPopup />,
  },
];
export default columns;
