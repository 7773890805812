import { Box, Stack } from "@mui/material";
import React from "react";

const labelArr = [
  "Strain effect",
  "Scent",
  "Sales type",
  "Accepted payment terms",
  "Location",
];

function InputWrapper({ label, children, position }) {
  return (
    <Stack
      sx={{
        bgcolor: "white",
        padding: "16px 24px",
        border: "1px solid #dddddd",
        borderRadius: "4px",
        marginRight: { md: position % 2 === 0 ? "16px" : "0px", xs: "0" },
        marginBottom: "16px",
      }}
      justifyContent={labelArr.includes(label) ? "" : "space-between"}
      direction={labelArr.includes(label) ? "column" : "row"}
      alignItems={labelArr.includes(label) ? "" : "center"}
      spacing={labelArr.includes(label) ? "4px" : ""}
    >
      <span
        style={{
          fontSize: "14px",
          fontWeight: "300",
          margin: "0",
          textDecoration: "underline",
        }}
      >
        {label}:
      </span>
      <Box>{children}</Box>
    </Stack>
  );
}

export default InputWrapper;
