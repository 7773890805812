import { Box, Grid, Typography } from "@mui/material";
//import { ReactComponent as HomeIcon } from "../../Assets/Icons/HomeIcon.svg";
//import { ReactComponent as RightArrow } from "../../Assets/Icons/BraedCrumpRightArrow.svg";
import theme from "../../theme";
import heroSubTabBanner from "../../Assets/Images/heroSubTabBanner.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import HamburgerMenu from "./HamburgerMenu";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as LogoWhite } from "../../Assets/Icons/LogoWhite.svg";

export default function SmallBannerV2(props) {
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);

  return (
    <>
      {openHamburgerMenu && (
        <HamburgerMenu
          open={openHamburgerMenu}
          onClose={() => setOpenHamburgerMenu(false)}
        />
      )}
      <Grid
        container
        sx={{
          background: `url(${heroSubTabBanner})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          padding: { xs: "1.5rem" },
          display: { xs: "flex", sm: "none" },
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sx={{ justifyContent: "space-between" }} container>
          <Link to={"/"}>
            <LogoWhite />
          </Link>
          <Box onClick={() => setOpenHamburgerMenu(true)}>
            <MenuIcon sx={{ color: "white", width: "25px", height: "25px" }} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
