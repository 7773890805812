import * as React from "react";
import Chip from "@mui/material/Chip";

export default function ChipTag(props) {
  return (
    <>
      <Chip
        sx={{
          background: props.background
            ? props.background
            : "rgba(36, 179, 20, 0.15)",
          padding: { xs: "2px 4px", sm: "4px 8px" },
          borderRadius: "5px",
          color: props.color ? props.color : "#24B314",
          fontSize: props.fontSize ? props.fontSize : { xs: "8px", sm: "11px" },
          fontWeight: props.fontWeight ? props.fontWeight : "400",
        }}
        label={props.label}
      />
    </>
  );
}
