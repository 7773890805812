import React from "react";
import TopHeader from "../Header/TopHeader";
import MenuBar from "../../../Components/Custom/MenuBar";
import SmallBanner from "../../../Components/Custom/SmalBanner";
import InfoSection from "./InfoSection";
import DetailsFormSection from "./DetailsFormSection";
import SmallFooter from "../SmallFooter/SmallFooter";

const ContactUs = () => {
  return (
    <>
      <TopHeader />
      <MenuBar isVisible={false} />
      <SmallBanner
        breadcrumbsMainTitle={"Contact Us"}
        breadcrumbsSmallTitle={"Contact Us"}
      />
      <InfoSection />
      <DetailsFormSection />
      <SmallFooter />
    </>
  );
};

export default ContactUs;
