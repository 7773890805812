import { Box, Stack } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import VerticalSplitOutlinedIcon from "@mui/icons-material/VerticalSplitOutlined";

import demoUser from "../../../../../Assets/Images/DemoUser.svg";

function Header() {
  const iconsList = [
    { src: <SearchOutlinedIcon /> },
    { src: <CallOutlinedIcon /> },
    { src: <VerticalSplitOutlinedIcon /> },
    { src: <MoreVertOutlinedIcon /> },
  ];

  return (
    <Stack
      sx={{ padding: "10px", borderBottom: "1px solid #0000000D" }}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={2}>
        <Box>
          <img src={demoUser} alt="" />
        </Box>
        <Stack>
          <Box sx={{ color: "#2E3A48" }}>Elizabeth Sutter</Box>
          <Box sx={{ color: "#A3ACBA", fontSize: "14px", fontWeight: "300" }}>
            Last seen recently
          </Box>
        </Stack>
      </Stack>
      <Stack direction={"row"} spacing={2}>
        {iconsList.map((icon, index) => (
          <Box sx={{ color: "#2E3A48" }} key={index}>
            {icon.src}
          </Box>
        ))}
      </Stack>
    </Stack>
  );
}

export default Header;
