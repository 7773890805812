import * as React from "react"
const SyncIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#2E3A48"
      d="M9.72 3.24H9a5.76 5.76 0 0 0-2.52 10.941m1.8.58H9a5.76 5.76 0 0 0 2.52-10.942"
    />
    <path
      stroke="#2E3A48"
      strokeWidth={0.768}
      d="m10.08 12.644-1.8 2.115 2.16 1.845M7.92 5.354l1.8-2.115-2.16-1.844"
    />
  </svg>
)
export default SyncIcon
