export function formatDateDifference(dateString) {
  const providedDate = new Date(dateString);
  const currentDate = new Date();

  const timeDifferenceInMilliseconds = currentDate - providedDate;
  const timeDifferenceInDays = Math.floor(
    timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24),
  );

  if (timeDifferenceInDays === 0) return "Today";
  if (timeDifferenceInDays === 1) return "1 day ago";

  return `${timeDifferenceInDays} days ago`;
}
