import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Box, Grid, MenuItem, Select, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import SearchInputWithIcon from "../../../Components/Custom/SearchInput";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import MUITable from "../../../Components/Custom/MUITable";
import restClient from "../../../util/restClient";
import columns from "../../../util/Tables/ProductManagement/showProductManagement";
import theme from "../../../theme";
import Filter from "./components/Filter";
import SyncIcon from "../../../Assets/Icons/SyncIcon";
import SecondaryButton from "../../../Components/Custom/SecondaryButton";
import Modal from "../../../Components/Custom/Modal";
import heroSubTabBannerGreen from "../../../Assets/Images/heroSubTabBannerGreen.png";
import SideBarLayout from "../../../SharedComponents/SideBarLayout";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as LogoWhite } from "../../../Assets/Icons/LogoWhite.svg";
import { ToastContainer } from "react-toastify";
import BasicPagination from "../../../Components/Custom/Pagination";
import DeleteModal from "../../../Components/Custom/DeleteModal";

function BrandManagement() {
  const [pageSize, setPageSize] = useState(9);
  const navigate = useNavigate();
  const { state } = useLocation();
  const userData = useSelector((state) => state.userRegReducer?.userAuth);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isActiveTab, setIsActiveTab] = useState(state?.status ? state?.status : "all");
  const [openPopup, setOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openSyncPopup, setOpenSyncPopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [productCount, setProductCount] = useState({
    all: 0,
    active: 0,
    inactive: 0,
    draft: 0,
    featured: 0,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState("");

  const getProductData = useCallback(
    async (keyword = "", status = "") => {
      setIsLoading(true);
      console.log("1st Draft requests", isActiveTab, status, state?.status);
      try {
        const { data } = await restClient({
          method: "GET",
          url: `/user/products?page=${page}&limit=${pageSize}&Status=${status}&name=${keyword}`,
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        });

        // const featuredRes = await getFeaturedData();

        // let statusRows;
        // if (state?.status) {
        //   statusRows = await getStatusRows();
        // }
        // // console.log("statusRows", statusRows, "featuredRes", featuredRes);

        const newData = [...data?.data?.products];

        // if (statusRows?.length > 0) {
        //   // newData.push([...statusRows]);
        //   newData = [...newData, ...statusRows];
        // }

        console.log("newData", newData);

        console.log(data?.data, "Response products");

        setProductCount({
          all: data?.data?.statistics?.totalProducts,
          active: data?.data?.statistics?.activeProducts,
          inactive: data?.data?.statistics?.inactiveProducts,
          draft: data?.data?.statistics?.draftProducts,
          featured: data?.data?.statistics?.featuredProducts,
        });

        setData(newData);
        setFilteredData(newData);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    [userData?.token, page, pageSize]
  );

  const getFeaturedData = async () => {
    try {
      setIsLoading(true);
      const response = await restClient({
        method: "GET",
        url: `/user/featured-products?page=${page}&limit=${pageSize}`,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      setData(response?.data?.data?.products || []);
      setFilteredData(response?.data?.data?.products || []);
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProductData("", isActiveTab === "all" ? "" : isActiveTab);
  }, [page, pageSize]);

  useEffect(() => {
    if (isActiveTab === "all") {
      getProductData();
    } else if (isActiveTab === "featured") {
      // setFilteredData(data?.filter((item) => item["isFeatured"] === true));
      getFeaturedData();
    } else getProductData("", isActiveTab);

    setPage(1);
    // setFilteredData(
    //   data?.filter((item) => item["Status"]?.toLowerCase() === isActiveTab)
    // );
  }, [isActiveTab]);

  useEffect(() => {
    if (state?.status) {
      setIsActiveTab(state?.status);
      navigate("/product-management", { replace: true });
    }
  }, [state]);

  const handleProductStatusAPI = async (productId, status) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("Status", status);
      const { data } = await restClient({
        method: "PATCH",
        url: `user/products/${productId}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      //pageSize += 10;
      console.log("response Data", data);
      if (data) {
        getProductData("", isActiveTab === "all" ? "" : isActiveTab);
      }
      // setData(data?.data?.products);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleProductDelete = async (productId) => {
    try {
      setIsLoading(true);
      const response = await restClient({
        method: "DELETE",
        url: `user/products/${productId}`,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      if (response) {
        getProductData("", isActiveTab === "all" ? "" : isActiveTab);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  const handleSyncWithMetrc = async () => {
    try {
      setIsLoading("sync");
      const response = await restClient({
        method: "POST",
        url: `user/sync-metrc-products`,
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setOpenSyncPopup(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <DeleteModal
          headingText={"Delete Product"}
          subHeadingText={"Are you sure you want to delete this product?"}
          handleSubmit={() => handleProductDelete(deleteProductId)}
          handleClose={() => setIsModalOpen(false)}
          isLoading={isLoading}
        />
      )}
      <ToastContainer />
      <Modal
        open={openSyncPopup}
        handleClose={() => {
          setOpenSyncPopup(false);
        }}
        isLoading={isLoading === "sync" ? true : false}
        headerText={"Pull Products From Metrc"}
        leftButton={"Cancel"}
        handleSubmit={handleSyncWithMetrc}
        rightButton={"Confirm"}
        maxWidth="xs"
        fullWidth
      >
        This will only pull active products in metrc.
      </Modal>
      <Box
        sx={{
          height: { md: "100vh" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          overflow: "hidden",
          background: theme.palette.primary.dashboardBack,
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "inline-flex" },
            height: "100%",
            width: { xs: "75%", sm: "40%", md: "30%", lg: "20%" },
          }}
        >
          <SideBarLayout />
        </Box>
        <Box
          sx={{
            display: { md: "none" },
          }}
        >
          {open && <SideBarLayout setOpen={setOpen} />}
          <Grid
            item
            container
            sx={{
              padding: { xs: "1.5rem", sm: "1.5rem 2rem", lg: "0 10rem" },
              background: `url(${heroSubTabBannerGreen})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              display: { md: "none" },
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: { md: "none" },
                justifyContent: "space-between",
                alignItems: "center",
              }}
              container
            >
              <Link to={"/"}>
                <LogoWhite />
              </Link>
              <Box
                onClick={() => {
                  setOpen(true);
                  if (typeof window != "undefined" && window.document) {
                    document.body.style.overflow = "hidden";
                  }
                }}
              >
                <MenuIcon sx={{ color: "white", width: "25px", height: "25px" }} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            height: "100vh",
            width: { md: "70%", lg: "80%" },
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              padding: { xs: "20px", sm: "22px 36px" },
              fontWeight: 500,
              backgroundColor: theme.palette.primary.white,
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: { sm: "space-between" },
              alignItems: { sm: "center", lg: "flex-start" },
              rowGap: { xs: "20px", sm: "0px" },
            }}
          >
            <Typography sx={{ fontSize: "24px", color: theme.palette.primary.black }}>Product Management</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <SecondaryButton onClick={() => setOpenSyncPopup(true)} icon={<SyncIcon />} color="black" fontSize={{ xs: "12px", sm: "14px" }}>
                Pull Products From Metrc
              </SecondaryButton>
              <PrimaryButton onClick={() => navigate("/add-new-product")} icon={<AddIcon />} fontSize={{ xs: "12px", sm: "14px" }}>
                Add new product
              </PrimaryButton>
            </Box>
          </Box>
          <Box
            sx={{
              margin: { xs: "12px", lg: "36px" },
              backgroundColor: {
                xs: "transparent",
                md: theme.palette.primary.white,
              },
              border: { md: "1px solid #0000000D" },
              borderRadius: "4px",
            }}
          >
            <Stack
              sx={{
                padding: { sm: "16px", md: "10px 24px" },
                display: "flex",
                flexDirection: { xs: "column", sm: "column" },
                alignItems: "start",
                justifyContent: { sm: "space-between" },
                rowGap: { xs: "20px" },
                columnGap: { sm: "20px" },
              }}
            >
              <Box
                sx={{
                  border: "1px solid lightgrey",
                  borderRadius: "4px",
                  padding: "2px 4px",
                  width: { xs: "90%", sm: "100%" },
                }}
              >
                <SearchInputWithIcon placeholder="Search products" callbackFunction={getProductData} />
              </Box>
              {data && (
                <Box
                  sx={{
                    // padding: { xs: "8px 8px", md: "16px 24px" },
                    maxWidth: "100%",
                    overflowX: "auto",
                    className: "scrollbar",
                  }}
                >
                  <Filter data={data} isActive={isActiveTab} setIsActive={setIsActiveTab} productCount={productCount} />
                </Box>
              )}
            </Stack>
            <Stack
              sx={{
                display: "flex",
                maxHeight: {
                  xs: "50vh",
                  md: "60h",
                  xl: "70vh",
                },
                height: "90%",
                marginBottom: "2rem",
                position: "relative",
                overflowY: "auto",
              }}
            >
              <MUITable
                onRowClick={(rowData) => {
                  navigate(`/product-overview/${rowData._id}`);
                }}
                customClasses="scrollbar"
                columns={columns}
                data={filteredData}
                isLoading={isLoading}
                showPopup={openPopup}
                setShowPopup={setOpenPopup}
                page={page}
                pageSize={pageSize}
                popupValues={[
                  {
                    title: "Active",
                    callbackFunction: (productId, status) => {
                      console.log("status", status);
                      if (status && status === "draft") {
                        navigate(`/edit-product/${productId}`);
                      }
                      handleProductStatusAPI(productId, "active");
                    },
                  },
                  {
                    title: "Inactive",
                    callbackFunction: (productId) => {
                      handleProductStatusAPI(productId, "inactive");
                    },
                  },
                  {
                    title: "Delete",
                    callbackFunction: (productId) => {
                      setIsModalOpen(true);
                      setDeleteProductId(productId);
                    },
                  },
                  {
                    title: "Edit",
                    callbackFunction: (productId) => {
                      navigate(`/edit-product/${productId}`);
                    },
                  },
                  {
                    title: "Feature",
                    callbackFunction: (productId) => {
                      navigate(`/featured-product-payment/${productId}`);
                    },
                  },
                ]}
                // callback={getProductData}
              />
              {productCount[isActiveTab] / pageSize > 0 && (
                <Stack direction="row" sx={{ padding: "10px" }} spacing={4} justifyContent={"flex-end"}>
                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    <span
                      style={{
                        fontSize: "14px",
                        color: theme.palette.primary.black,
                      }}
                    >
                      Rows per page:
                    </span>
                    <Select
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(e.target.value);
                        setPage(1);
                      }}
                      variant="standard"
                      color="success"
                    >
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={27}>27</MenuItem>
                      <MenuItem value={54}>54</MenuItem>
                    </Select>
                  </Stack>
                  <BasicPagination
                    currentPage={page}
                    handlePageChange={(event, page) => {
                      console.log("Page", page);
                      setPage(page);
                    }}
                    count={Math.ceil(productCount[isActiveTab] / pageSize)}
                  />
                </Stack>
              )}
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default BrandManagement;
