import { Box, Grid, Typography } from "@mui/material";
//import { ReactComponent as HomeIcon } from "../../Assets/Icons/HomeIcon.svg";
//import { ReactComponent as RightArrow } from "../../Assets/Icons/BraedCrumpRightArrow.svg";
import theme from "../../theme";
import heroSubTabBanner from "../../Assets/Images/heroSubTabBanner.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import HamburgerMenu from "./HamburgerMenu";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as LogoWhite } from "../../Assets/Icons/LogoWhite.svg";

export default function SmallBanner(props) {
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);

  return (
    <>
      {openHamburgerMenu && (
        <HamburgerMenu onClose={() => setOpenHamburgerMenu(false)} />
      )}
      <Grid
        container
        sx={{
          background: `url(${heroSubTabBanner})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          pb: 7,
          height: "200px",
          width: "100%",
          padding: { xs: "1.5rem", md: "0rem 5rem", lg: "0 10rem" },
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: { sm: "none" },
            justifyContent: "space-between",
          }}
          container
        >
          <Link to={"/"}>
            <LogoWhite />
          </Link>
          <Box onClick={() => setOpenHamburgerMenu(true)}>
            <MenuIcon sx={{ color: "white", width: "25px", height: "25px" }} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            position: { md: "relative" },
            height: { xs: "80%", md: "auto" },
            display: "flex",
            justifyContent: { xs: "center", md: "start" },
            alignItems: { xs: "center", md: "flex-start" },
          }}
        >
          <Box sx={{ display: { xs: "none", md: "inline" } }}>
            <hr
              style={{
                border: "1px solid rgba(255, 255, 255, 0.4)",
                width: "55%",
                position: "absolute",
                top: "30px",
                left: "-20px",
              }}
            ></hr>
            <hr
              style={{
                border: "1px solid rgba(255, 255, 255, 0.4)",
                width: "55%",
                position: "absolute",
                top: "63px",
              }}
            ></hr>
          </Box>
          <Typography
            sx={{
              fontSize: { xs: "35px", sm: "65px" },
              color: theme.palette.primary.white,
              fontWeight: theme.typography.heading1.fontWeight,
              textAlign: { xs: "center", md: "left" },
            }}
          >
            {props.breadcrumbsMainTitle}
          </Typography>
          {/* <Box
          sx={{
            mt: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <HomeIcon />
          </Box>
          <Box
            pl={2}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <RightArrow />
          </Box>
          <Typography
            sx={{
              fontSize: theme.typography.fontSize,
              color: theme.palette.primary.black,
              background: theme.palette.primary.white,
              fontWeight: "500",
              borderRadius: "4px",
              px: 3,
              py: 1,
              ml: 2,
            }}
          >
            {props.breadcrumbsSmallTitle}
          </Typography>
        </Box> */}
        </Grid>
      </Grid>
    </>
  );
}
