import { Box, Stack, LinearProgress } from "@mui/material";

function RatingsBar({ rating, percent, count }) {
  return (
    <Stack alignItems={"center"} direction={"row"}>
      <span style={{ fontWeight: "300", width: "72px" }}>{rating} stars</span>
      <Box sx={{ flexGrow: 1 }}>
        <LinearProgress
          sx={{
            borderRadius: "4px",
            height: "8px",
            bgcolor: "#EBEDF4",
            "& .MuiLinearProgress-bar": {
              borderRadius: "4px",
              bgcolor: "#1B8A79",
            },
          }}
          variant="determinate"
          value={percent}
        />
      </Box>
      <Box sx={{ textAlign: "right", marginLeft: "16px" }}>
        <Box style={{ fontWeight: "300", width: "72px" }}>
          {percent.toFixed(1)}% ({count})
        </Box>
      </Box>
    </Stack>
  );
}

export default RatingsBar;
