import { Box, Grid, Typography } from "@mui/material";
import CreateShopImage from "../../../Assets/Images/Createshop.png";
import ShopBackground from "../../../Assets/Icons/shopBackgrounnd.svg";
import theme from "../../../theme";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import { useNavigate } from "react-router-dom";

export default function CreateShopSection() {
  const navigate = useNavigate();
  return (
    <>
      <Grid container sx={{ padding: "0rem 1.5rem" }}>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "center",
            width: "100%",
            height: "50vh",
            backgroundImage: `url(${ShopBackground})`,
            backgroundSize: "contain",
            backgroundRepeat: "repeat",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              width: { xs: "100%", sm: "80%" },
            }}
          >
            <img style={{ width: "100%" }} src={`${CreateShopImage}`} alt="" />
          </Box>
          <Typography
            sx={{
              fontSize: { xs: "25px", lg: "48px" },
              color: theme.palette.primary.black,
              fontWeight: "600",
              mb: 3,
            }}
          >
            or find a reliable suplier
          </Typography>
          <PrimaryButton onClick={() => navigate("/signup")} width="200px">
            Signup
          </PrimaryButton>
        </Grid>
      </Grid>
    </>
  );
}
