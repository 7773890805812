import { Box, Grid, Stack, Typography } from "@mui/material";
import MainLogo from "../../../Assets/Images/Logo.png";
import SearchInputWithIcon from "../../../Components/Custom/SearchInput";
//import SelectTextFields from "../../../Components/Custom/SelectField";
import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import { ReactComponent as SignUpIcon } from "../../../Assets/Icons/SignUpBtnIcon.svg";
import { ReactComponent as LoginIcon } from "../../../Assets/Icons/LoginIcon.svg";
import SecondaryButton from "../../../Components/Custom/SecondaryButton";
import theme from "../../../theme";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { useCallback, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import restClient from "../../../util/restClient";
import ActionPopup from "../../Admin/BrandManagement/components/ActionPopup";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function TopHeader() {
  const selectUserAuth = (state) => state.userRegReducer?.userAuth;
  const userData = useSelector(selectUserAuth);
  console.log(userData, "userData");
  const [globalSearchData, setGlobalSearchData] = useState([]);
  const [openSearchDropdown, setOpenSearchDropdown] = useState(false);
  const navigate = useNavigate();

  const getStoresAndBrands = useCallback(async (keyword = "") => {
    try {
      // const response = await axios.get(
      //   `https://staging.weedxhub.com/api/admin/scrap/globalSearch/?page=1&pageSize=3&name=${keyword}`
      // );
      const response = await restClient({
        method: "GET",
        url: `/admin/scrap/globalSearch/?page=1&pageSize=3&name=${keyword}`,
      });
      if (response) {
        console.log(response, "global search api response");
        setGlobalSearchData([...response?.data?.data?.documents?.brands, ...response?.data?.data?.documents?.dispensaries]);
        setOpenSearchDropdown(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          display: { xs: "none", sm: "flex" },
          alignItems: "center",
          flexDirection: "row",
          padding: { xs: "1rem 1rem", lg: "1rem 5rem" },
          background: theme.palette.primary.white,
          borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
        }}
      >
        <Grid item xs={3} lg={3}>
          <Link to={"/"}>
            <img src={`${MainLogo}`} alt="logo" />
          </Link>
        </Grid>
        <Grid item xs={5} lg={6}>
          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              border: "1px solid #0000000D",
              borderRadius: "4px",
              background: theme.palette.primary.dashboardBack,
              padding: "0px 2px",
              position: "relative",
            }}
          >
            <Grid item>
              <SearchInputWithIcon callbackFunction={getStoresAndBrands} placeholder={"Stores or Brands"} />
              {openSearchDropdown && globalSearchData && globalSearchData.length > 0 && (
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: 20,
                    background: "white",
                    width: "100%",
                    marginTop: 1,
                    border: "1px solid lightgrey",
                    boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
                    borderRadius: "10px",
                  }}
                >
                  {openSearchDropdown && (
                    <Box
                      onClick={() => {
                        setOpenSearchDropdown(false);
                        setGlobalSearchData([]);
                      }}
                      sx={{
                        position: "absolute",
                        right: 15,
                        top: 15,
                        cursor: "pointer",
                      }}
                    >
                      <CloseIcon />
                    </Box>
                  )}
                  {openSearchDropdown &&
                    globalSearchData &&
                    globalSearchData.length > 0 &&
                    globalSearchData.slice(0, 5).map((data, index) => (
                      <Box
                        key={data?._id}
                        sx={{
                          display: "flex",
                          columnGap: "10px",
                          alignItems: "center",
                          p: 2,
                          cursor: "pointer",
                          borderRadius: index === 0 ? "10px 10px 0px 0px" : index === 3 ? "0px 0px 10px 10px" : "0px",
                          "&:hover": {
                            backgroundColor: "#EFF2F4",
                          },
                        }}
                        onClick={() => {
                          setOpenSearchDropdown(false);
                          if (data?.["Brand Name"]) {
                            console.log("Search brand", data);
                            navigate(`/brand-details/${data?.username}`);
                          } else {
                            navigate(`/dispensary-detail/${data?._id}`);
                          }
                        }}
                      >
                        {data?.["Brand Logo"] && <img style={{ width: "20px", height: "20px" }} src={data?.["Brand Logo"]} alt="" />}
                        {data?.["Business Logo"] && <img style={{ width: "20px", height: "20px" }} src={data?.["Business Logo"]} alt="" />}
                        {data?.["Brand Name"] && (
                          <Typography sx={{ fontSize: "14px" }}>
                            {data?.["Brand Name"]} <span style={{ fontSize: "10px", color: "grey" }}>(Public Brand)</span>
                          </Typography>
                        )}
                        {data?.["Profile Name"] && (
                          <Typography sx={{ fontSize: "14px" }}>
                            {data?.["Profile Name"]} <span style={{ fontSize: "10px", color: "grey" }}>(Dispensary)</span>
                          </Typography>
                        )}
                      </Box>
                    ))}
                </Box>
              )}
            </Grid>
            {/* <Grid item xs={2}>
              <Typography
                sx={{
                  color: theme.palette.primary.lightGrey,
                  fontSize: "24px",
                }}
              >
                |
              </Typography>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                display: "flex",
              }}
            >
              <SelectTextFields />
            </Grid> */}
          </Grid>
        </Grid>
        {!userData?.authorized ? (
          <Grid
            item
            xs={4}
            lg={3}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Link to={"/login"}>
              <SecondaryButton icon={<LoginIcon />}>Log In</SecondaryButton>
            </Link>
            <Link to={"/signup"}>
              <PrimaryButton icon={<SignUpIcon />}>Sign Up</PrimaryButton>
            </Link>
          </Grid>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "1rem 0rem 0rem 5rem",
              marginLeft: "auto",
              padding: "4px 10px",
              bgcolor: theme.palette.primary.main,
              borderRadius: "4px",
            }}
          >
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                bgcolor: "#fff",
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                margin: "auto",
                marginRight: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: theme.palette.primary.main,
                }}
              >
                {userData?.user?.fullname.charAt(0)}
              </Typography>
            </Stack>
            <Box sx={{ marginRight: "10px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: theme.palette.primary.white,
                  fontWeight: 500,
                }}
              >
                {userData?.user?.fullname}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: theme.palette.primary.white,
                }}
              >
                {userData?.user?.email}
              </Typography>
            </Box>
            <ActionPopup
              popUpIcon={{
                icon: (
                  <KeyboardArrowDownIcon
                    sx={{
                      color: theme.palette.primary.black,
                      cursor: "pointer",
                      background: theme.palette.primary.white,
                      borderRadius: "50%",
                      width: 16,
                      height: 16,
                      marginTop: 1,
                    }}
                  />
                ),
              }}
              popupValues={[
                {
                  title: "Marketplace",
                  callbackFunction: () => {
                    navigate("/marketplace");
                    document.body.style.overflow = "auto";
                  },
                },
                {
                  title: "My Account",
                  callbackFunction: () => {
                    navigate("/my-account");
                    document.body.style.overflow = "auto";
                  },
                },
                {
                  title: "Logout",
                  callbackFunction: () => {
                    sessionStorage.removeItem("persist:root");
                    navigate("/");
                    document.body.style.overflow = "auto";
                    window.location.reload(true);
                  },
                },
              ]}
              hoverColor={"#f78b8b"}
            />
          </Box>
        )}
      </Grid>
    </>
  );
}

export default TopHeader;
