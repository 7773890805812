import React from "react";
import FormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import theme from "../../theme";

const InputLabel = styled(FormLabel)((props) => ({
  color: theme.palette.primary.inputLabelColor,
  fontSize: theme.typography.extra_small,
  fontWeight: "500",
  letterSpacing: "0.15px",
  display: "inline-flex",
  marginBottom: "9px",
  lineHeight: "13px",
  marginTop: props.margin ? props.margin : "",
}));

const InputFieldLabel = (props) => {
  return <InputLabel {...props}>{props.children}</InputLabel>;
};

export default InputFieldLabel;
