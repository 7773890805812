import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";

const DatePickerField = ({ onChange, value }) => {
  const [cleared, setCleared] = React.useState(false);

  // React.useEffect(() => {
  //   if (cleared) {
  //     const timeout = setTimeout(() => {
  //       setCleared(false);
  //     }, 1500);

  //     return () => clearTimeout(timeout);
  //   }
  //   return () => {};
  // }, [cleared]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: { lg: "center" },
        }}
      >
        <DatePicker
          value={value || ""}
          sx={{
            border: "none",
            background: "white",
            width: "100%",
          }}
          onChange={onChange}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DatePickerField;
