import React, { useEffect, useState } from "react";
//import BrandBanner from "../../../Assets/Images/brandBanner.png";
import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as Clock } from "../../../Assets/Icons/clock.svg";
import { ReactComponent as Calendar } from "../../../Assets/Icons/calendar.svg";
//import { ReactComponent as Views } from "../../../Assets/Icons/views.svg";
import { ReactComponent as Quote } from "../../../Assets/Icons/quote.svg";
//import blogSubDetail from "../../../Assets/Images/blogSubDetail.png";
import theme from "../../../theme";
import SmallFooter from "../SmallFooter/SmallFooter";
//import PrimaryButton from "../../../Components/Custom/PrimaryButton";
import TopHeader from "../Header/TopHeader";
import MenuBar from "../../../Components/Custom/MenuBar";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import HamburgerMenu from "../../../Components/Custom/HamburgerMenu";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as LogoWhite } from "../../../Assets/Icons/LogoWhite.svg";
import restClient from "../../../util/restClient";

const formatDate = (enteredDate) => {
  const date = new Date(enteredDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${month}/${day}/${year}`;
};

const BlogDetail = (props) => {
  const { id } = useParams();
  const [blog, setBlog] = useState();
  const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);

  useEffect(() => {
    const getBlogDetail = async () => {
      try {
        // const response = await axios.get(
        //   `https://staging.weedxhub.com/api/user/blog/${id}`
        // );
        const response = await restClient({
          method: "GET",
          url: `/user/blog/${id}`,
        });
        console.log(response, "blog detail response");
        if (response?.status === 200) {
          setBlog({ ...response?.data?.data?.blog });
        }
      } catch (err) {
        console.log(err);
      }
    };

    getBlogDetail();
  }, [id]);

  console.log(blog, "blog");

  return (
    <>
      {openHamburgerMenu && <HamburgerMenu onClose={() => setOpenHamburgerMenu(false)} />}
      <TopHeader />
      <MenuBar isVisible={false} />
      <Grid container>
        {blog?.bannerImage && (
          <Grid item xs={12} sx={{ width: "100%", height: { xs: "190px", sm: "auto" } }}>
            <img
              src={blog?.bannerImage}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sx={{
            display: { sm: "none" },
            justifyContent: "space-between",
            position: "absolute",
            padding: "20px",
          }}
          container
        >
          <Link to={"/"}>
            <LogoWhite />
          </Link>
          <Box onClick={() => setOpenHamburgerMenu(true)}>
            <MenuIcon sx={{ color: "white", width: "25px", height: "25px" }} />
          </Box>
        </Grid>
        <Grid
          container
          sx={{
            padding: { xs: "0 1.5rem", lg: "0 10rem" },
          }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                width: { xs: "100%", md: "70%" },
                margin: {
                  xs: "1.5rem 0rem",
                  md: "1.5rem auto",
                  lg: "3rem auto",
                },
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "start", sm: "center", md: "start" },
                columnGap: { xs: "1rem", sm: "2rem" },
                rowGap: { xs: "0.5rem", sm: 0 },
                flexWrap: { xs: "wrap" },
              }}
            >
              {blog?.createdBy && (
                <Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", sm: "16px" },
                      fontWeight: "300",
                      color: theme.palette.primary.black,
                    }}
                  >
                    By {blog?.createdBy}
                  </Typography>
                </Box>
              )}
              <Box sx={{ display: "flex", alignItems: "center", columnGap: "5px" }}>
                <Clock />
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px" },
                    fontWeight: "300",
                    color: theme.palette.primary.black,
                  }}
                >
                  2 minutes read
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: "5px" }}>
                <Calendar />
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px" },
                    fontWeight: "300",
                    color: theme.palette.primary.black,
                  }}
                >
                  {formatDate(blog?.time)}
                </Typography>
              </Box>
              {/* <Box
                sx={{ display: "flex", alignItems: "center", columnGap: "5px" }}
              >
                <Views />
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px" },
                    fontWeight: "300",
                    color: theme.palette.primary.black,
                  }}
                >
                  Views
                </Typography>
              </Box> */}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: { xs: "100%", sm: "70%" }, margin: "auto" }}>
              {blog?.title && (
                <Typography
                  sx={{
                    fontSize: { xs: "28px", md: "36px", lg: "40px" },
                    fontWeight: "600",
                    lineHeight: { lg: "52px" },
                    color: theme.palette.primary.inputLabelColor,
                  }}
                >
                  {blog?.title}
                </Typography>
              )}
              {blog?.description && (
                <Typography
                  sx={{
                    fontSize: { xs: "14px", lg: "16px" },
                    fontWeight: "300",
                    color: theme.palette.primary.mediumGrey,
                    marginBottom: "10px",
                  }}
                  dangerouslySetInnerHTML={{ __html: blog?.description }}
                ></Typography>
              )}
              {/* {blog?.introduction && (
                <Box sx={{ mt: { xs: 2, md: 3, lg: 5 } }}>
                  <Typography
                    sx={{
                      fontSize: { xs: "24px", md: "28px" },
                      fontWeight: "600",
                      color: theme.palette.primary.inputLabelColor,
                      marginBottom: "1rem",
                    }}
                  >
                    Introduction
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", sm: "16px" },
                      fontWeight: "500",
                      color: theme.palette.primary.mediumGrey,
                    }}
                  >
                    {blog?.introduction}
                  </Typography>
                </Box>
              )} */}
            </Box>
          </Grid>

          {/* <Grid item xs={12}>
            {blog?.subDescription &&
              blog?.subDescription.length > 0 &&
              blog?.subDescription.map((data, index) => (
                <Box
                  key={data?._id}
                  sx={{
                    width: { xs: "100%", sm: "70%" },
                    margin: "3rem auto",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "1.5rem",
                  }}
                >
                  <Box>
                    {data?.title && (
                      <Typography
                        sx={{
                          fontSize: { xs: "24px", md: "28px" },
                          fontWeight: "600",
                          color: theme.palette.primary.inputLabelColor,
                          marginBottom: "1rem",
                        }}
                      >
                        {data?.title}
                      </Typography>
                    )}
                    {data?.content &&
                      data?.content.length > 0 &&
                      data?.content?.map((content) => (
                        <Typography
                          sx={{
                            fontSize: { xs: "14px", lg: "16px" },
                            fontWeight: "300",
                            color: theme.palette.primary.mediumGrey,
                            marginBottom: "10px",
                          }}
                          key={content?._id}
                        >
                          {content?.key && <strong>{content?.key}: </strong>}
                          {content?.value}
                        </Typography>
                      ))}
                  </Box>
                  {data?.quote && (
                    <Box>
                      <hr
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.05)",
                          width: "100%",
                        }}
                      ></hr>
                      <Box
                        sx={{
                          display: "flex",
                          columnGap: "1rem",
                          padding: "1rem 0rem",
                        }}
                      >
                        <Quote />
                        <Box>
                          {data?.quote && (
                            <Typography
                              sx={{
                                fontSize: { xs: "16px", md: "20px" },
                                fontWeight: "600",
                                color: theme.palette.primary.inputLabelColor,
                              }}
                            >
                              {data?.quote}
                            </Typography>
                          )}
                          {data?.quoteBy && (
                            <Typography
                              sx={{
                                fontSize: { xs: "14px", md: "16px" },
                                fontWeight: "300",
                                color: theme.palette.primary.mediumGrey,
                                marginTop: { xs: "1rem" },
                              }}
                            >
                              {data?.quoteBy}
                            </Typography>
                          )}
                        </Box>
                      </Box>

                      <hr
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.05)",
                          width: "100%",
                        }}
                      ></hr>
                    </Box>
                  )}
                  {data?.imageUrl &&
                    data?.imageUrl.length > 0 &&
                    data?.imageUrl.map((image, idx) => (
                      <Box key={idx}>
                        <img src={image} alt="" style={{ width: "100%" }} />
                      </Box>
                    ))}
                </Box>
              ))}
          </Grid>

          <Grid item xs={12}>
            {blog?.conclusion && (
              <Box
                sx={{
                  mb: { xs: 2, md: 3, lg: 5 },
                  width: { xs: "100%", sm: "70%" },
                  margin: "auto",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "24px", md: "28px" },
                    fontWeight: "600",
                    color: theme.palette.primary.inputLabelColor,
                    marginBottom: "1rem",
                  }}
                >
                  Conclusion
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "14px", sm: "16px" },
                    fontWeight: "300",
                    color: theme.palette.primary.mediumGrey,
                  }}
                >
                  {blog?.conclusion}
                </Typography>
              </Box>
            )}
          </Grid> */}

          {/* <Grid item xs={12}>
            <Box
              sx={{
                width: { xs: "100%", lg: "70%" },
                margin: "0rem auto",
                marginBottom: "5rem",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                columnGap: { xs: 0, sm: "1rem" },
                rowGap: { xs: "1rem", sm: "0rem" },
              }}
            >
              <PrimaryButton
                backgroundColor="#1877F2"
                fontSize="16px"
                fontWeight="500"
                width={{ xs: "100%", sm: "33%" }}
                padding="0.5rem"
              >
                Continue with Facebook
              </PrimaryButton>
              <PrimaryButton
                backgroundColor="#0A66C2"
                fontSize="16px"
                fontWeight="500"
                width={{ xs: "100%", sm: "33%" }}
                padding="0.5rem"
              >
                Continue with Linkedin
              </PrimaryButton>
              <PrimaryButton
                backgroundColor="#212B36"
                fontSize="16px"
                fontWeight="500"
                width={{ xs: "100%", sm: "33%" }}
                padding="0.5rem"
              >
                Continue with Twitter
              </PrimaryButton>
            </Box>
          </Grid> */}
        </Grid>
      </Grid>
      <SmallFooter />
    </>
  );
};

export default BlogDetail;
