import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import theme from "../../../theme";
import { removeQueryParameters } from "../../../util/functions";

export default function BrandBox(props) {
  return (
    <Box
      sx={{
        cursor: "pointer",
        margin: "20px 10px 0px 10px",
        borderRadius: "5px",
        boxShadow:
          "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
        background: "white",
        height: { md: "300px", xs: "200px" },
        overflow: "hidden",
      }}
      onClick={props.onClick}
    >
      <Box sx={{ height: { md: "240px", xs: "160px" }, overflow: "hidden" }}>
        <img
          src={removeQueryParameters(props.imageUrl)}
          alt=""
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: "5px 5px 0px 0px",
          }}
        />
      </Box>
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          borderTop: "1px solid #0000001A",
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
          height: { md: "60px", xs: "40px" },
        }}
      >
        <Typography
          sx={{
            color: theme.palette.primary.black,
            fontSize: { xs: "8px", sm: "12px", md: "16px" },
            fontWeight: "500",
          }}
        >
          {props.title}
        </Typography>
      </Stack>
    </Box>
  );
}
